/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { commonService, providerService, settingGeneralService } from '../../../services'
import { fetchingProviders, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates } from '../../../constants'
import { auth, formatter, log, validator } from '../../../util'

// UI
import { CustomIdentifierList, Loading, Page, Panel } from '../../../components'
import { Permissions } from '../../../constants'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'

import ActivityLog from '../ActivityLog'
import ProviderInvoice from '../Invoices'
import ProviderInvoicePace from '../InvoicesPace'
// import ClientList from '../Client'
// import FundManager from '../FundManager'

import './styles.css'

const { confirm, error } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const providerModule = 'provider'
const urlRedirect = '/providers'
const ABNDuplicatedMsg = 'Duplicate record found.'

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 4, path: '/custom-identifier' },
  { tabId: 3, path: '/invoices' },
  { tabId: 2, path: '/logs' },
  { tabId: 5, path: '/invoices-pace' },
]

const ABNExemptedReason = [
  { label: 'ATO Excluded Supply', value: 'EXCLS' },
  { label: 'Individual', value: 'N/A' }
]

export class Provider extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      item: {},
      isAllowABNEmpty: false,
      isABNDuplicated: false,
      loading: false,
      loadingSave: false,
      loadingCheckABN: false,
      languageList: [],
      serviceList: [],
      suburbList: [],
      latitude: '',
      longitude: '',
      showSave: false,
      showEdit: true,
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
    this.debounceValidateABNDuplicate = debounce(this.debounceValidateABNDuplicate, 1000)
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchProvider()
    }
    this.fetchSettings()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ suburb, address: place.formatted_address, postcode, state })
  }

  render () {
    const { history } = this.props
    const { currentTab, item, loading, loadingSave, showSave, showEdit, showFundManagerTab } = this.state
    const providerId = this.getId()
    const isInfoTab = currentTab === '1'
    const isItem = item && item.id

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Provider (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.fullname} (View Only)` : showSave ? `${item.fullname} (Edit Mode)` : 'Provider'}>
            {/* {
              this.isEdit() && this.hasAccess('deleteProvider') ? showSave ? (
                <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
                </div>
              ) : null : null} */}
            {showEdit && this.isEdit() && isItem && this.hasAccess(Permissions.PROVIDER.INFO.UPDATE) && !loading
              ? (
                <div className='btn' onClick={this.handleEditButton}>
                  Edit
                </div>)
              : null}
            {((!this.isEdit() && this.hasAccess(Permissions.PROVIDER.INFO.CREATE)) ||
              (showSave && this.isEdit() && isItem && this.hasAccess(Permissions.PROVIDER.INFO.UPDATE)))
              && !loading && isInfoTab
              ? (
                <div className='btn' onClick={this.handleSave}>
                  Save
                </div>)
              : null}

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className={`funder-panel`}>
            <Spin spinning={loadingSave}>
              {this.isEdit() && this.hasAccess(Permissions.PROVIDER.INFO.READ)
                ? <div className={`funder-panel-header ${item && item.active === false ? 'disabled' : ''}`}>
                  {loading ? <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <div className='funder-panel-header-skeleton-avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='funder-panel-header-skeleton' />
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-skeleton' />
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-skeleton' />
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-skeleton' />
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-skeleton' />
                    </Col>
                  </Row>
                    : <Row>
                      <Col lg={3} style={{ textAlign: 'center' }}>
                        <img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' />
                      </Col>
                      <Col lg={5}>
                        <div className='funder-panel-header-label'>Name</div>
                        <div className='funder-panel-header-value'>{item.fullname}<div className='funder-panel-header-subvalue'>{item.acc_ref}</div></div>
                      </Col>
                      <Col lg={4}>
                        <div className='funder-panel-header-label'>ABN</div>
                        <div className='funder-panel-header-value' style={{ textTransform: 'capitalize' }}>{formatter.toABNFormat(item.abn) || '-'}</div>
                      </Col>
                      <Col lg={6}>
                        <div className='funder-panel-header-label'>Acc Name</div>
                        <div className='funder-panel-header-value'>{item.pm_acc_name ? formatter.capitalize(item.pm_acc_name, false) : '-'}</div>
                      </Col>
                      <Col lg={2}>
                        <div className='funder-panel-header-label'>BSB</div>
                        <div className='funder-panel-header-value'>{item.pm_bsb || '-'}</div>
                      </Col>
                      <Col lg={4}>
                        <div className='funder-panel-header-label'>Acc No.</div>
                        <div className='funder-panel-header-value'>{item.pm_bank_acc_no || '-'}</div>
                      </Col>
                    </Row>}
                </div> : null
              }
              <div className='funder-panel-body'>
                <Tabs
                  defaultActiveKey={currentTab}
                  onChange={this.handleTabChange}
                >
                  <TabPane tab='Provider Info' key='1'>
                    {this.infoTab()}
                  </TabPane>
                  {this.isEdit() && this.hasAccess(Permissions.PROVIDER.INFO.READ)
                    ? <TabPane tab='Custom Identifier' key='4'>
                      <CustomIdentifierList key={`cftab${currentTab}`} genreId={providerId} genre={'provider'} history={this.props.history} permission={Permissions.PROVIDER.INFO.UPDATE} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PROVIDER.INVOICE.LIST)
                    ? <TabPane tab='(PRODA) Invoices' key='3'>
                      <ProviderInvoice key={`invtab${currentTab}`} providerId={providerId} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INVOICE_PACE.LIST)
                    ? <TabPane tab='Invoices' key='5'>
                      <ProviderInvoicePace key={`invtab${currentTab}`} providerId={providerId} history={this.props.history} />
                    </TabPane>
                    : null}
                  {this.isEdit() && this.hasAccess(Permissions.PROVIDER.INFO.READ)
                    ? <TabPane tab='Activity Log' key='2'>
                      <ActivityLog key={`actab${currentTab}`} providerId={providerId} history={this.props.history} />
                    </TabPane>
                    : null}
                </Tabs>
              </div>
            </Spin>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { newAccRef, funding, item, isAllowABNEmpty, loading, loadingCheckABN, languageList, serviceList, suburbList } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 19 }
    }
    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const longItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 18, md: 18, lg: 16 }
    }

    const specialFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 11 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    return (
      <div>
        <Form>
          <Loading loading={loading} blur>
            <Panel title='Provider Information'>
              <Row>
                {/* <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                    {getFieldDecorator('acc_ref', {
                      initialValue: item.acc_ref
                    })(
                      <Input readOnly />
                    )}
                  </FormItem>
                </Col> */}
                <Col lg={12}>
                  {/* <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                    {getFieldDecorator('acc_ref', {
                      initialValue: item.acc_ref
                    })(
                      <Input readOnly />
                    )}
                  </FormItem> */}
                </Col>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Active'>
                    {getFieldDecorator('active', {
                      initialValue: item.active !== undefined ? item.active : true,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='ABN' extra='Enter ABN without spacing'>
                    {getFieldDecorator('abn', {
                      initialValue: item.abn || '',
                      rules: [
                        { required: !isAllowABNEmpty, message: ' ' },
                        { validator: this.validateABN }
                      ]
                    })(
                      <Input
                        disabled={isAllowABNEmpty}
                        onChange={this.validateABNDuplicate}
                        onPaste={this.handlePasteABN}
                        addonAfter={loadingCheckABN ? <Icon type={'loading'} className='wd-loading-icon' /> : <span style={{ marginRight: '13px' }} />}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='ATO Excluded Supply'>
                    {getFieldDecorator('is_abn_allowed_empty', {
                      initialValue: item.is_abn_allowed_empty,
                      valuePropName: 'checked',
                    })(
                      <Switch
                        disabled={loadingCheckABN}
                        onChange={this.toggleABNAllowedEmpty}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              {/* <Row>
                <Col lg={12}>

                </Col>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='ABN Exemption Reason'>
                    {getFieldDecorator('abn_exempted_reason', {
                      initialValue: item.abn_exempted_reason || null,
                      rules: [
                        { required: isAllowABNEmpty, message: 'Please select ABN exempted reason.' },
                      ]
                    })(
                      <Select
                        disabled={!isAllowABNEmpty}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {
                          ABNExemptedReason.map((reason) => {
                            return <Option key={reason.value} value={reason.value}>{reason.label}</Option>
                          })
                        }
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row> */}

              <FormItem {...formItemLayout} label='Name' hasFeedback>
                {getFieldDecorator('fullname', {
                  initialValue: item.fullname || '',
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter first name' },
                    { whitespace: true, message: 'Please enter first name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem {...formItemLayout} label='Address' hasFeedback>
                {getFieldDecorator('address', {
                  initialValue: item.address || '',
                  rules: [
                    { whitespace: true, message: 'Please enter address' }
                  ]
                })(
                  <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
                )}
              </FormItem>

              <Row gutter={12} style={{ display: 'none' }}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                    {getFieldDecorator('suburb', {
                      initialValue: item.suburb || ''
                    })(
                      <Input disabled />
                    )}
                  </FormItem>
                </Col>

                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='State'>
                    {getFieldDecorator('state', {
                      initialValue: item.state || ''
                    })(
                      <Select placeholder='Please select a state' disabled>
                        {
                          AustralianStates.map((states) => (
                            <Option key={`state${states.value}`} value={states.value}>{states.name}</Option>
                          ))
                        }
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={12} style={{ display: 'none' }}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                    {getFieldDecorator('postcode', {
                      initialValue: item.postcode || ''
                    })(
                      <Input disabled />
                    )}
                  </FormItem>
                </Col>

                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Country'>
                    {getFieldDecorator('country', {
                      initialValue: 'Australia'
                    })(
                      <Input disabled />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={12}>
                <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
                  {getFieldDecorator('unit_building', {
                    initialValue: item.unit_building,
                    rules: [
                      { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                      { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter unit/building info' }
                    ]
                  })(
                    <Input placeholder='Please Enter Unit No/Building Name' />
                  )}
                </FormItem>
              </Row>

              <Row gutter={12}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                    {getFieldDecorator('phone_number', {
                      initialValue: item.phone_number || '',
                      rules: [
                        { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                        { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                        { whitespace: true, message: 'Please enter phone number' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>

                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Fax' hasFeedback>
                    {getFieldDecorator('fax', {
                      initialValue: item.fax || '',
                      rules: [
                        { min: 2, message: 'Fax Number must be between 2 and 128 characters' },
                        { max: 128, message: 'Fax Number must be between 2 and 128 characters' },
                        { whitespace: true, message: 'Please enter fax number' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Contact Email' hasFeedback>
                    {getFieldDecorator('contact_email', {
                      initialValue: item.contact_email || '',
                      rules: [
                        { type: 'email', message: 'Please provide a valid Email' },
                        { min: 2, message: 'Email must be between 2 and 128 characters' },
                        { max: 128, message: 'Email must be between 2 and 128 characters' },
                        { whitespace: true, message: 'Please enter email' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>

              {/* <FormItem {...formItemLayout} label='Contact Person'>
                {getFieldDecorator('contact_person', {
                  initialValue: item.contact_person || null
                })(
                  <Input />
                )}
              </FormItem>

              <Row gutter={12}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Name' hasFeedback>
                    {getFieldDecorator('after_contact_name', {
                      initialValue: item.after_contact_name || null
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>

                <Col lg={12}>

                  <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Number' hasFeedback>
                    {getFieldDecorator('after_contact_number', {
                      initialValue: item.after_contact_number || null
                    })(
                      <Input />
                    )}
                  </FormItem>

                </Col>

              </Row>
              <Row gutter={12}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Email' hasFeedback>
                    {getFieldDecorator('after_contact_email', {
                      initialValue: item.after_contact_email || null
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row> */}

              <FormItem {...formItemLayout} label='Notes' hasFeedback>
                {getFieldDecorator('notes', {
                  initialValue: item.notes || ''
                })(
                  <TextArea rows={4} />
                )}
              </FormItem>
            </Panel>
            <Panel title='Banking Information'>
              <Row gutter={12}>
                <Col lg={12}>
                  <FormItem {...longItemLayout} label={`Account's Name`} hasFeedback extra='In ABA, the account name will be limited to the first 32 characters only.'>
                    {getFieldDecorator('pm_acc_name', {
                      initialValue: item.pm_acc_name || '',
                      rules: [
                        { min: 2, message: 'Account\'s Name must be between 2 and 64 characters' },
                        { max: 64, message: 'Account\'s Name must be between 2 and 64 characters' },
                        { required: true, message: 'Please enter Account\'s Name' },
                        { whitespace: true, message: 'Please enter Account\'s Name' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col lg={12}>
                  <FormItem {...longItemLayout} label='BSB' hasFeedback extra='Must be in NNN-NNN format'>
                    {getFieldDecorator('pm_bsb', {
                      initialValue: item.pm_bsb || '',
                      rules: [
                        { required: true, message: ' ' },
                        { validator: this.validateBSB }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col lg={12}>
                  <FormItem {...longItemLayout} label='Account Number' hasFeedback>
                    {getFieldDecorator('pm_bank_acc_no', {
                      initialValue: item.pm_bank_acc_no || '',
                      rules: [
                        { required: true, message: ' ' },
                        { validator: this.validateBankAccNo }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Remittance Email' hasFeedback>
                    {getFieldDecorator('email', {
                      initialValue: item.email || '',
                      rules: [
                        { type: 'email', message: 'Please provide a valid Email' },
                        { min: 2, message: 'Email must be between 2 and 128 characters' },
                        { max: 128, message: 'Email must be between 2 and 128 characters' },
                        { required: true, message: 'Please enter email' },
                        { whitespace: true, message: 'Please enter email' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Panel>

            <Panel title='Alerts'>
              <Row>
                {/* <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Public Alert'>
                    {getFieldDecorator('public_alert', {
                      initialValue: item.public_alert || null
                    })(
                      <TextArea rows={4} />
                    )}
                  </FormItem>
                </Col> */}
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Private Alert'>
                    {getFieldDecorator('private_alert', {
                      initialValue: item.private_alert || null
                    })(
                      <TextArea rows={4} />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Panel>
          </Loading>
        </Form>
      </div>
    )
  }

  fetchProvider = async () => {
    if (!this.hasAccess(Permissions.PROVIDER.INFO.READ)) {
      return
    }

    try {
      const refId = this.getRefId()
      let id = null

      if (refId === 'add' || refId === 'new' || !refId) return

      this.setState({ loading: true })
      const item = await providerService.getRef(refId)

      if (item && item.id) {
        id = item.id
        const isAllowABNEmpty = item.is_abn_allowed_empty || false
        this.setState({ item, isAllowABNEmpty, loading: false })
      } else {
        notify.error('Unable to load successfully', 'Unable to load provider successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load provider successfully. Please try again later.')
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'service-provider' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'suburb' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)

    this.setState({
      settings: settings.list,
      languageList: settings.list.filter(item => item.identifier === 'language'),
      serviceList: settings.list.filter(item => item.identifier === 'service-provider'),
      suburbList: settings.list.filter(item => item.identifier === 'suburb')
    })
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({ currentTab: index })

    if (tab && tab.tabId) {
      this.props.history.replace(`/providers/${refId}${tab.path}`)
    }
  }

  handleDelete = () => {
    const { fetchingCompanies, history, match } = this.props
    const { params } = match
    const { id } = params
    const that = this

    confirm({
      title: 'Are you sure you want to delete this provider?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        const { loading, loadingSave, loadingCheckABN } = that.state

        if (loading | loadingSave || loadingCheckABN) {
          return
        }

        that.setState({ loadingSave: true })
        try {

          const r = await providerService.remove(id)

          if (r && r.id) {
            notify.success('Deleted successfully', 'Provider deleted successfully.')
            window.location.replace('/providers')
            history.replace('/providers')
            fetchingCompanies(true)
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete provider successfully. Please try again later.')
        }

        that.setState({ loadingSave: false })
      }
    })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handlePasteABN = (event) => {
    const { form, id } = this.props
    event.preventDefault()
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')
    this.setState({ loadingCheckABN: false, isABNDuplicated: false }, () => {
      if (pastedData) {
        const cleanedData = pastedData.replace(/\D+/g, '')
        form.setFieldsValue({ abn: cleanedData })
        form.validateFieldsAndScroll(['abn']).then(() => {
          this.debounceValidateABNDuplicate(id, cleanedData)
        }).catch(console.log)
      }
    })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingProviders, history } = this.props
        const { item, latitude, longitude, loading, loadingCheckABN, loadingSave } = this.state

        if (loading || loadingCheckABN || loadingSave) return

        this.setState({ loadingSave: true })

        if (longitude && latitude) {
          values.longitude = longitude
          values.latitude = latitude
        }

        if (values.pm_bsb) {
          if (!(values.pm_bsb.indexOf('-') > -1)) {
            values.pm_bsb = values.pm_bsb.substring(0, 3) + '-' + values.pm_bsb.substring(3, 6)
          }
        }

        if (values.is_abn_allowed_empty === true) {
          values.abn_exempted_reason = 'EXCLS'
        } else {
          values.abn_exempted_reason = null
        }

        try {
          if (this.isEdit()) {
            const r1 = await providerService.save(item.id, values)
            this.setState({ item: { ...item, ...values } })

            if (r1 && r1.id) {
              log.updateProvider(r1.id, item, values, [
                'abn_exempted_reason'
              ], [
                { key: 'is_abn_allowed_empty', label: 'ATO Excluded Supply' },
                { key: 'abn_exempted_reason', label: 'ABN Exemption Reason' },
                { key: 'pm_bsb', label: 'BSB' },
                { key: 'pm_bank_acc_no', label: 'Account Number' },
                { key: 'pm_acc_name', label: 'Account\'s Name' },
              ])

              this.handleSaveSuccessful()
              fetchingProviders(true)
            } else {
              this.handleSaveError(r1)
            }
          } else {
            const r2 = await providerService.add(values)

            if (r2 && r2.id) {
              const { id, ref_id, acc_ref } = r2
              log.addProvider(r2.id, `New provider ${values.fullname}`)
              this.setState({ item: { ...item, ...values, id, ref_id, acc_ref } })
              this.handleSaveSuccessful()
              window.location.replace(`/providers/${ref_id}/info`)
              fetchingProviders(true)
            } else {
              this.handleSaveError(r2)
            }
          }

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          this.handleSaveError()
        }

        this.setState({ loadingSave: false })
      }
    })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Provider saved successfully.')
  }

  handleSaveError = (r) => {
    notify.error('Unable to save successfully', r && r.errors ? formatter.toErrorMessage(r.errors) : 'Unable to save provider successfully. Please try again later.')
  }

  validateABN = (rule, value, callback) => {
    const { isAllowABNEmpty, isABNDuplicated } = this.state
    if (value === null || value === undefined || value === '') {
      if (isAllowABNEmpty) {
        callback()
      } else {
        callback(new Error('Please enter ABN'))
      }
    } else if (!validator.isDigit(value) || value.length !== 11) {
      callback(new Error('ABN is invalid in format'))
    } else {
      if (isABNDuplicated) {
        callback(new Error(ABNDuplicatedMsg))
      } else {
        callback()
      }
    }
  }

  validateABNDuplicate = (e) => {
    e.persist()
    const id = this.isEdit() ? this.getId() : null
    const value = e.target.value ? e.target.value.trim() : e.target.value
    this.validateABN(null, value, () => {
      this.debounceValidateABNDuplicate(id, value)
    })
  }

  validateBSB = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      callback(new Error('Please enter BSB'))
    } else if (!validator.isBSB(value)) {
      callback(new Error('BSB is invalid in format'))
    } else {
      callback()
    }
  }

  validateBankAccNo = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      callback(new Error('Please enter Account Number'))
    } else if (!validator.isDigit(value) || value.length > 9) {
      callback(new Error('Account Number is invalid in format'))
    } else {
      callback()
    }
  }

  debounceValidateABNDuplicate = async (id, value) => {
    const { form } = this.props
    const data = {
      id,
      abn: value
    }

    if (value !== '') {
      // set the field value first and disabled all duplicated flags to temporary remove error alert
      form.setFields({ abn: { value: value } })
      this.setState({ loadingCheckABN: true, isABNDuplicated: false })

      const r = await commonService.checkDuplicate(providerModule, data)

      if (r && r.errors) {
        // if error (duplicate detected), flag is true and set error message on input. meanwhile, the validate function will set the input as errorneous input
        this.setState({ loadingCheckABN: false, isABNDuplicated: true })
        const w = window

        confirm({
          title: 'Possible Duplicated ABN Number',
          content: (
            <div style={{ color: 'rgb(238, 27, 27)' }}>
              <p>There is a provider which has the same ABN with entered value.</p>
              <p>Press "Go to" button to check the duplicated ABN or press "OK" to edit.</p>
            </div>
          ),
          okText: 'Go to Duplicated Provider Page',
          cancelText: 'OK',
          onOk () {
            w.open(`${urlRedirect}/${r.ref_id}/info`)
          },
          onCancel () {
          }
        })

        // set the field value with errors
        form.setFields({ abn: { value: value, errors: [{ message: ABNDuplicatedMsg }] } })
      } else {
        this.setState({ loadingCheckABN: false, isABNDuplicated: false })
      }
    } else {
      this.setState({ loadingCheckABN: false, isABNDuplicated: false })
    }
  }

  toggleABNAllowedEmpty = (e) => {
    const { form } = this.props

    this.setState({ isAllowABNEmpty: e }, () => {
      const value = form.getFieldValue('abn')
      const id = this.isEdit() ? this.getId() : null
      if (e === false) {
        this.debounceValidateABNDuplicate(id, value)
      } else {
        // set the field value with errors if the toggle is true
        form.setFields({ abn: { value: value, errors: undefined } })
      }
    })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }
}

const mapDispatchToProps = {
  fetchingProviders,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Provider }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Provider))
