import React, { Component, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { apiHostname } from '../../../../../config'
import { FileUploadMsg } from '../../../../../constants'
import { auth, validator } from '../../../../../util'

// UI
import DatePicker from 'antd/lib/date-picker'
import Drawer from 'antd/lib/drawer'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'

import { Button, FileUpload2 } from '../../../../../components'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { Option } = Select

const dateFormat = 'DD/MM/YYYY'

function FileAddModal (props) {
  const {
    file, fileCategories, form, isEdit, saving, selectedInvoice, subCategories, visible, uploadData, onAddFile, onClose, onUpdateFile
  } = props
  const { getFieldDecorator, resetFields, setFieldsValue, validateFieldsAndScroll } = form || {}
  const mainCatId = validator.isId(file.main_cat_id) ? file.main_cat_id : undefined
  const [fileList, setFileList] = useState([])
  const [selectedMainCatId, setSelectedMainCatId] = useState(mainCatId)
  const [uploadMessage, setUploadMessage] = useState()
  const [uploadedFile, setUploadedFile] = useState()
  const [uploading, setUploading] = useState(false)
  const hasInvoice = !validator.isNullOrUndefined(selectedInvoice)
  const hideFileCatName = (Array.isArray(fileCategories) && fileCategories.length < 2) || !!selectedMainCatId
  const fileCategoryOptions = fileCategories
  const subCategoryOptions = Array.isArray(subCategories) ? subCategories.filter(({ file_cat_id: fileCatId }) => (
    validator.isId(selectedMainCatId)
      ? fileCatId === selectedMainCatId : validator.isId(mainCatId)
        ? fileCatId === mainCatId : true
  )) : []
  const hasUploadedFile = !validator.isNullOrUndefined(uploadedFile)

  const checkFile = useCallback((file) => {
    const isValidFile = !validator.isNullOrUndefined(file) && validator.isValidUploadType(file.type)

    if (isValidFile) {
      setFileList([file])
      setUploadMessage()
    } else {
      setFileList([])
      setUploadedFile({})
      setUploadMessage(FileUploadMsg.UploadMsgWrongFormatDOC)
    }

    return isValidFile
  }, [])

  const changeFile = useCallback((info) => {
    if (!validator.isNullOrUndefined(info) && !validator.isNullOrUndefined(info.file)) {
      const { file } = info
      const { percent, response, status, uid } = file

      if (percent === 100 && !validator.isNullOrUndefined(response) && status === 'done') {
        const { fileUrl, filePath } = response
        const { filename: fileName, path: _filePath } = filePath
        const data = { fileName: fileName || '', fileUrl, filePath: _filePath || '', uid }
        setUploadMessage()
        setUploadedFile(data)
        setUploading(false)
      } else {
        setUploading(true)
      }
    }
  }, [])

  const changeFileCategory = useCallback((mainCatId) => {
    setSelectedMainCatId(mainCatId)
    setFieldsValue({ sub_cat_id: undefined })
  }, [setFieldsValue])

  const changeSubCategory = useCallback((subCatId) => {
    const subCategory = subCategories.find(({ id }) => id === subCatId)

    if (subCategory) {
      const fileCategory = fileCategories.find(({ id }) => id === subCategory.file_cat_id)

      if (fileCategory) {
        const { id } = fileCategory
        setSelectedMainCatId(id)
        setFieldsValue({ main_cat_id: id })
      }
    }
  }, [setFieldsValue, fileCategories, subCategories])

  const closeModal = useCallback(() => {
    if (saving || uploading) {
      return
    }

    setFileList([])
    setSelectedMainCatId()
    setUploadMessage()
    setUploadedFile({})
    resetFields()

    if (typeof onClose === 'function') {
      onClose()
    }
  }, [onClose, resetFields, saving, uploading])

  const filterFileCategory = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const filterSubCategory = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const handleSave = useCallback(() => {
    if (saving || uploading) {
      return
    }

    validateFieldsAndScroll(async (errors, values) => {
      const isFileUploaded = isEdit ? true : hasUploadedFile

      if (!isFileUploaded) {
        setUploadMessage(FileUploadMsg.UploadMsgNoFile)
      }

      if (!errors && isFileUploaded) {
        const { fileName, fileUrl, uid } = hasUploadedFile ? uploadedFile : {}
        values.issuance_date = moment(values.issuance_date).startOf('day').toISOString()

        if (!validator.isNullOrUndefined(uid) && !validator.isEmptyString(uid)) {
          values.name = fileName
          values.url = fileUrl
          values.uid = uid
        }

        if (hasInvoice) {
          if (isEdit && typeof onUpdateFile === 'function') {
            await onUpdateFile(file.id, values, closeModal)
          } else if (typeof onAddFile === 'function') {
            await onAddFile(values, closeModal)
          }
        } else {
          if (isEdit && typeof onUpdateFile === 'function') {
            onUpdateFile(values, closeModal)
          } else if (typeof onAddFile === 'function') {
            onAddFile(values, closeModal)
          }
        }
      }
    })
  }, [
    closeModal, onAddFile, onUpdateFile, validateFieldsAndScroll, file, hasInvoice, hasUploadedFile, isEdit, saving,
    uploadedFile, uploading
  ])

  const removeFile = useCallback((file) => {
    const idx = fileList.indexOf(file)

    if (idx > -1) {
      const newFileList = fileList.slice()
      newFileList.splice(idx, 1)
      setFileList(newFileList)
      setUploadedFile({})
    }
  }, [fileList])

  return (
    <Drawer
      className='pf-file-modal'
      maskClosable={false}
      title={`${isEdit ? 'Edit' : 'Add'} Invoice File`}
      visible={visible}
      width='360'
      onClose={closeModal}
    >
      <Form layout='vertical'>
        <FormItem label='Main Category'>
          {getFieldDecorator('main_cat_id', {
            initialValue: isEdit ? file.main_cat_id : undefined,
            rules: [
              { required: true, message: 'Please select main category' }
            ],
          })(
            <Select
              allowClear
              disabled={(hasInvoice && isEdit) || saving || uploading}
              dropdownMatchSelectWidth={false}
              filterOption={filterFileCategory}
              optionFilterProp='children'
              placeholder='Select Main Category'
              showSearch
              onChange={changeFileCategory}
              style={{ width: '100%' }}
            >
              {Array.isArray(fileCategoryOptions) ? fileCategoryOptions.map(({ id, name, active }) => (
                <Option key={id} disabled={!active} value={id}>{name}</Option>
              )) : null}
            </Select>
          )}
        </FormItem>

        <FormItem label='Sub Category'>
          {getFieldDecorator('sub_cat_id', {
            initialValue: isEdit ? file.sub_cat_id : undefined,
            rules: [
              { required: true, message: 'Please select sub category' }
            ],
          })(
            <Select
              allowClear
              disabled={(hasInvoice && isEdit) || saving || uploading}
              dropdownMatchSelectWidth={false}
              filterOption={filterSubCategory}
              optionFilterProp='children'
              placeholder='Select Sub Category'
              showSearch
              onChange={changeSubCategory}
              style={{ width: '100%' }}
            >
              {Array.isArray(subCategoryOptions) ? subCategoryOptions.map(({ id, file_cat_name: fileCatName, name, active }) => (
                <Option key={id} disabled={!active} value={id}>{hideFileCatName ? '' : `(${fileCatName}) `}{name}</Option>
              )) : null}
            </Select>
          )}
        </FormItem>

        <FormItem label='Issuance Date'>
          {getFieldDecorator('issuance_date', {
            initialValue: validator.isDate(file.issuance_date) ? moment(file.issuance_date).startOf('day') : undefined,
            rules: [
              { required: true, message: 'Please select issuance date' }
            ],
          })(<DatePicker allowClear defaultPickerValue={moment().startOf('day')} disabled={saving || uploading} format={dateFormat} />)}
        </FormItem>

        <FormItem label='Label'>
          {getFieldDecorator('label', {
            initialValue: file.label,
            rules: [
              { required: true, message: 'Please enter label' }
            ],
          })(<Input disabled={saving || uploading} />)}
        </FormItem>

        <FormItem label=''>
          {getFieldDecorator('active', {
            initialValue: typeof file.active === 'boolean' ? file.active : true,
            valuePropName: 'checked',
          })(<Switch checkedChildren='Enable' unCheckedChildren='Disable' disabled={saving || uploading} />)}
        </FormItem>

        {getFieldDecorator('name', {
          initialValue: file.name || (hasUploadedFile ? uploadedFile.fileName : undefined)
        })(<Input type='hidden' />)}

        {getFieldDecorator('invoice_id', {
          initialValue: hasInvoice ? selectedInvoice.id : undefined,
        })(<Input type='hidden' />)}

        {getFieldDecorator('invoice_number', {
          initialValue: hasInvoice ? selectedInvoice.invoice_number : undefined,
        })(<Input type='hidden' />)}

        <FileUpload2
          errorMessage={uploadMessage}
          fileUrl={isEdit ? file.url : undefined}
          loading={saving || uploading}
          upload={{
            action: `${apiHostname}/private/api/files-pace/upload`,
            headers: { Authorization: `Bearer ${auth.getCurrentToken()}` },
            method: 'POST',
            name: 'file',
            data: validator.isObject(uploadData) ? uploadData : {},
            disabled: saving || uploading,
            multiple: false,
            beforeUpload: checkFile,
            onChange: changeFile,
            onRemove: removeFile,
            fileList
          }}
        />
      </Form>

      <div className='modal-button-box'>
        <Button ghost feedback={saving || uploading} onClick={closeModal}>Cancel</Button>
        <Button feedback={saving || uploading} onClick={handleSave}>{isEdit ? 'Update' : 'Add'}</Button>
      </div>
    </Drawer>
  )
}

export class PaceFileAddModal extends Component {
  render () {
    return <FileAddModal {...this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PaceFileAddModal))
