import React, { Component, useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { invoicePaceService } from '../../../services'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'
// UI
import { Loading, Panel, List, ControlLabel, Pager, SearchInput, Button } from '../../../components'
import { InvoiceStatus, Permissions } from '../../../constants'
import Checkbox from 'antd/lib/checkbox'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import Skeleton from 'antd/lib/skeleton'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, validator, exportFilePace } from '../../../util'
import Radio from 'antd/lib/radio'
import Tabs from 'antd/lib/tabs'
import notify from '../../../components/Notification'
import './styles.css'
const { Group: RadioGroup, Button: RadioButton } = Radio
const { TabPane } = Tabs
const { RangePicker } = DatePicker
const { Item: FormItem } = Form
const { Option } = Select
const defaultPageSize = 10
const dateFormat = 'DD/MM/YYYY'
const defaultDateRangeValidation = Object.freeze({ message: 'Please select date range to export', status: 'error' })
const defaultCheckboxValidation = Object.freeze({ message: 'Please select at least one item to export', status: 'error' })
const defaultFilter = { status: { condition: '<>', value: 'inv-closed' } }
const RadioType = Object.freeze({
  Incomplete: Object.freeze({ label: 'Incomplete', value: 'incomplete' }),
  Closed: Object.freeze({ label: 'Closed', value: 'closed' }),
  All: Object.freeze({ label: 'All', value: 'all' }),
})

function getInvoices (hasAccess) {
  return Object.freeze([
    {
      title: 'JID',
      width: 2.5,
      render: ({
        jid_number: jidNumber, private_comment: privateComment, status, comm_scheduled_id: commScheduledId,
        comm_scheduled_at: commScheduledAt, cp_error_count: errorCount
      }) => {
        return (
          <span>
            <span>{formatter.capitalize(jidNumber, false)}</span>

            {([
              InvoiceStatus.Processing.value, InvoiceStatus.PendingAuthorise.value
            ].indexOf(status) > -1 && commScheduledId)
              ? (
                <span>&nbsp;
                  <Tooltip mouseLeaveDelay={0} title={`Comm scheduled at ${formatter.toStandardDate(commScheduledAt)}`} >
                    <Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '11pt', cursor: 'pointer' }} />
                  </Tooltip>
                </span>
              )
              : null}

            {privateComment
              ? (
                <span>&nbsp;
                  <Tooltip mouseLeaveDelay={0} title={`Private Notes: ${privateComment || ''}`} >
                    <Icon type='edit' theme='filled' style={{ color: '#eb7a34', fontSize: '11pt', cursor: 'pointer' }} />
                  </Tooltip>
                </span>
              )
              : null}

            {errorCount > 0
              ? (
                <span>&nbsp;
                  <Tooltip mouseLeaveDelay={0} title='The Plan Period associated with this invoice is no longer active/within period/deleted.'>
                    <Icon type='warning' theme='filled' style={{ color: 'red', fontSize: '12pt', cursor: 'pointer' }} />
                  </Tooltip>
                </span>
              )
              : null}
          </span>
        )
      }
    },
    {
      title: 'Process Date',
      width: 3,
      render: ({ processed_at }) => <span>{formatter.toShortDate(processed_at)}</span>
    },
    {
      title: 'Inv Date',
      width: 3,
      render: ({ invoice_date }) => <span>{formatter.toShortDate(invoice_date)}</span>
    },
    {
      title: 'Inv No',
      width: 3,
      render: ({ invoice_number }) => <span>{invoice_number}</span>
    },
    {
      title: 'Provider',
      width: 3,
      render: ({ provider_ref_id, provider_name, invoice_provider_name }) => {
        return provider_ref_id
          ? <div><a href={`/providers/${provider_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{provider_name}</a></div>
          : invoice_provider_name
            ? <span style={{ fontStyle: 'italic' }}>
              <span className='label-indicator reimb'>R</span>
              {invoice_provider_name}
            </span>
            : <div>N/A</div>
      }
    },
    {
      title: 'Subtotal',
      width: 3,
      render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
    },
    {
      title: 'Rcv Amt',
      width: 3,
      render: ({ received_amount }) => <div>{formatter.toPrice(received_amount)}</div>
    },
    {
      title: 'Item Count',
      width: 2,
      render: ({ item_count }) => <div>{item_count}</div>
    },
    {
      title: 'Status',
      width: 3,
      render: ({ status, status_name, status_color }) => <div className={'status'} style={{ backgroundColor: status_color }}>
        {status_name ? status_name : formatter.capitalize(status)}
      </div>
    },

    {
      title: 'Action',
      width: 1,
      render: ({ id, ref_id }) => <div className='action-buttons'>
        {hasAccess(Permissions.PARTICIPANT.INVOICE_PACE.READ)
          ? <Link to={`/invoices-pace/${ref_id}/info`}>
            {hasAccess(Permissions.PARTICIPANT.INVOICE_PACE.READ) || hasAccess(Permissions.PARTICIPANT.INVOICE_PACE.LIST)
              ? <div style={{ color: '#D66E00' }}>
                <Tooltip mouseLeaveDelay={0} title='Edit'>
                  <Icon type='form' />
                </Tooltip>
              </div>
              : null}
          </Link>
          : null}
      </div>
    }
  ])
}

function InvoiceList ({ type, props, clientId }) {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [init, setInit] = useState(true)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState(defaultFilter)
  const [searchText, setSearchText] = useState()
  const [searching, setSearching] = useState(false)
  const [selectedRadioType, setSelectedRadioType] = useState(RadioType.Incomplete.value)
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState()
  const [selectedStatus, setSelectedStatus] = useState()

  const listByPage = useCallback(({ page, filter: newFilter, text }) => {
    const _filter = { ...filter, ...newFilter, client_id: clientId, invoice_type: type === 'inv-std' ? { condition: '=', value: 'inv-std' } : { condition: '<>', value: 'inv-std' } }
    const _page = typeof page === 'number' && page > 0 ? page : 1
    const _text = searchText || text
    setFilter(_filter)
    setLoading(true)
    setPage(_page)
    invoicePaceService.listByPage(_page, defaultPageSize, _filter, {}, _text)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter, searchText, clientId, type])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const changeRadioType = useCallback(({ target }) => {
    const { value } = target || {}
    setSelectedRadioType(value)
    let statusFilter = {}

    if (value === RadioType.Incomplete.value) {
      statusFilter = { condition: '<>', value: 'inv-closed' }
    } else if (value === RadioType.Closed.value) {
      statusFilter = { condition: '=', value: 'inv-closed' }
    }

    if (selectedStatus) {
      statusFilter = {
        $and: [
          statusFilter,
          { condition: '=', value: selectedStatus }
        ]
      }
    }

    listByPage({ page: 1, filter: { status: statusFilter } })
  }, [listByPage, selectedStatus])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const searchName = useCallback(() => {
    const onSearchName = async (value) => {
      setLoading(true)
      setPage(1)
      setSearchText(value)
      setSearching(true)
      const _filter = { ...filter, client_id: clientId, invoice_type: type === 'inv-std' ? { condition: '=', value: 'inv-std' } : { condition: '<>', value: 'inv-std' } }
      try {
        const response = await invoicePaceService.listByPage(1, defaultPageSize, _filter, {}, value)

        if (validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      } finally {
        setLoading(false)
        setSearching(false)
      }
    }
    return debounce(onSearchName, 500)
  }, [filter, clientId, type])



  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.PARTICIPANT.INVOICE_PACE.LIST)) {
      setInit(false)
      return
    }

    const data = {
      ...defaultFilter,
      client_id: clientId,
      invoice_type: type === 'inv-std' ? { condition: '=', value: 'inv-std' } : { condition: '<>', value: 'inv-std' }
    }

    setLoading(true)
    invoicePaceService.listByPage(1, defaultPageSize, data)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, clientId, type])

  const changeInvoiceDate = useCallback((invoiceDate) => {
    const filter = { invoice_date: undefined }

    if (!validator.isNullOrUndefined(invoiceDate)) {
      filter.invoice_date = {
        $and: [
          { condition: '>=', value: moment(invoiceDate).startOf('day').toISOString() },
          { condition: '<=', value: moment(invoiceDate).endOf('day').toISOString() }
        ]
      }
    }

    setSelectedInvoiceDate(invoiceDate)
    listByPage({ page: 1, filter })
  }, [listByPage])

  const changeStatus = useCallback((status) => {
    setSelectedStatus(status)
    let baseStatusFilter = {}
    if (selectedRadioType === RadioType.Incomplete.value) {
      baseStatusFilter = { condition: '<>', value: 'inv-closed' }
    } else if (selectedRadioType === RadioType.Closed.value) {
      baseStatusFilter = { condition: '=', value: 'inv-closed' }
    }

    let finalStatusFilter = baseStatusFilter
    if (status === 'client-pending-combined') {
      finalStatusFilter = {
        $and: [
          baseStatusFilter,
          { condition: 'in', value: ['client-pending-pms', 'client-pending-auth'] },
        ]
      }
    }
    else if (status === 'client-paid-combined') {
      finalStatusFilter = {
        $and: [
          baseStatusFilter,
          { condition: 'in', value: ['pymt-to-pay', 'pymt-partial-paid'] },
        ]
      }
    }
    else {
      finalStatusFilter = {
        $and: [
          baseStatusFilter,
          { condition: '=', value: status }
        ]
      }
    }

    listByPage({ page: 1, filter: { status: finalStatusFilter } })
  }, [selectedRadioType, listByPage])

  const filterStatus = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])


  return (
    <Panel title={type === 'inv-pm' ? 'Provider Invoices' : 'PM Fee Invoices'}>
      <div className='client-inv-list'>
        {hasAccess(Permissions.PARTICIPANT.INVOICE_PACE.LIST)
          ? <Row style={{ marginBottom: '20px' }} gutter={8}>
            <Col lg={6}>
              <ControlLabel>JID #, Provider, Invoice Number</ControlLabel>
              <SearchInput isSearching={searching} placeholder='Search' onChange={searchName()} />
            </Col>
            <Col lg={4}>
              <div style={{ marginLeft: '12.5pt' }}>
                <ControlLabel>Invoices Completion</ControlLabel>
              </div>
              <RadioGroup value={selectedRadioType} onChange={changeRadioType}>
                {Object.values(RadioType).map(({ label, value }) => (
                  <RadioButton key={value} value={value}>{label}</RadioButton>
                ))}
              </RadioGroup>
            </Col>
            <Col lg={5}>
              <ControlLabel>Invoice Date</ControlLabel>
              <DatePicker
                defaultPickerValue={moment().startOf('day')}
                allowClear
                disabled={loading}
                format={dateFormat}
                placeholder='Invoice Date'
                value={selectedInvoiceDate}
                onChange={changeInvoiceDate}
                style={{ width: '100%' }}
              />
            </Col>
            <Col lg={6}>
              <ControlLabel>Status</ControlLabel>

              <Select
                allowClear
                disabled={loading}
                dropdownMatchSelectWidth={false}
                filterOption={filterStatus}
                optionFilterProp='children'
                placeholder='Select Status'
                showSearch
                value={selectedStatus}
                onChange={changeStatus}
                style={{ width: '100%' }}
              >
                <Option key='client-paid-combined' disabled={loading} value='client-pending-combined'>
                  To Authorise
                </Option>

                <Option key='client-pending-combined' disabled={loading} value='client-paid-combined'>
                  To Pay
                </Option>

                {Object.values(InvoiceStatus)
                  .filter(({ value }) =>
                    !['client-pending-auth', 'client-pending-pms', 'pymt-to-pay', 'pymt-partial-paid', 'all'].includes(value)
                  )
                  .map(({ name, value }) => (
                    <Option key={value} disabled={loading} value={value}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
          : null}

        <Skeleton loading={init} active>
          <Loading loading={loading} blur>
            <List cols={getInvoices(hasAccess)} rows={list} />

            <Pager
              size={defaultPageSize}
              total={total}
              totalText={`Total ${total} records`}
              current={page}
              onChange={changePage}
              style={{ marginTop: '15px' }}
            />
          </Loading>
        </Skeleton>
      </div>
    </Panel>

  )
}

function InvoiceExportModal ({ showModal, onCloseModal, clientId }) {
  const [checkboxValidation, setCheckboxValidation] = useState({})
  const [dateRange, setDateRange] = useState([])
  const [dateRangeValidation, setDateRangeValidation] = useState(defaultDateRangeValidation)
  const [exporting, setExporting] = useState(false)
  const [isDetailExport, setIsDetailExport] = useState(true)
  const [isMainExport, setIsMainExport] = useState(true)

  const changeDateRange = useCallback((dates) => {
    const validDates = Array.isArray(dates)
    const hasDates = validDates && dates.length > 0
    setDateRange(validDates ? dates : [])
    setDateRangeValidation(hasDates ? {} : defaultDateRangeValidation)
  }, [])

  const changeDetailExport = useCallback(({ target }) => {
    const { checked } = target
    const noneChecked = checked !== true && isMainExport !== true
    setCheckboxValidation(noneChecked ? defaultCheckboxValidation : {})
    setIsDetailExport(checked)
  }, [isMainExport])

  const changeMainExport = useCallback(({ target }) => {
    const { checked } = target
    const noneChecked = checked !== true && isDetailExport !== true
    setCheckboxValidation(noneChecked ? defaultCheckboxValidation : {})
    setIsMainExport(checked)
  }, [isDetailExport])

  const closeModal = useCallback(() => {
    if (exporting) {
      return
    }

    setCheckboxValidation({})
    setDateRange([])
    setDateRangeValidation(defaultDateRangeValidation)
    setIsDetailExport(true)
    setIsMainExport(true)

    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }
  }, [onCloseModal, exporting])

  const handleExport = useCallback(() => {
    if (checkboxValidation.status === 'error' || exporting || dateRangeValidation.status === 'error') {
      return
    }


    setExporting(true)
    exportFilePace
      .fetchExport('invoice', {
        start_date: dateRange[0], end_date: dateRange[1], export_main: isMainExport, export_detail: isDetailExport, client_id: clientId
      })
      .catch((e) => {
        notify.error('Unable to export', 'Unable to export invoice successfully. Please try again later.')
        console.log(e)
      })
      .finally(() => {
        setExporting(false)
      })
  }, [checkboxValidation, dateRange, dateRangeValidation, exporting, isDetailExport, isMainExport, clientId])

  return (
    <Modal
      className='ip-export-modal'
      footer={[
        <Button key='close' ghost feedback={exporting} onClick={closeModal}>Cancel</Button>,
        <Button key='submit' feedback={exporting} onClick={handleExport}>Download</Button>
      ]}
      title='Export Invoices'
      visible={showModal}
      onCancel={closeModal}
    >
      <FormItem help={dateRangeValidation.message} validateStatus={dateRangeValidation.status}>
        <RangePicker allowClear format='DD/MM/YYYY' value={dateRange} onChange={changeDateRange} />
      </FormItem>

      <FormItem
        help={checkboxValidation.message}
        label='Please select item(s) to export'
        validateStatus={checkboxValidation.status}
      >
        <label className='label-checkbox'>
          <Checkbox className='ip-checkbox' checked={isMainExport} onChange={changeMainExport} />
          <span>Invoice Export</span>
        </label>

        <label className='label-checkbox'>
          <Checkbox className='ip-checkbox' checked={isDetailExport} onChange={changeDetailExport} />
          <span>Invoice Details Export</span>
        </label>
      </FormItem>
    </Modal>
  )
}

function CreditList (props) {
  const { clientId } = props || {}
  const [currentTab, setCurrentTab] = useState('1')
  const [showExportModal, setShowExportModal] = useState(false)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const handleChangeTab = (key) => {
    setCurrentTab(key)
  }

  const openExportModal = useCallback(() => {
    setShowExportModal(true)
  }, [])

  const closeExportModal = useCallback(() => {
    setShowExportModal(false)
  }, [])


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        {hasAccess(Permissions.PARTICIPANT.INVOICE_PACE.LIST)
          ? (
            <Button onClick={openExportModal}>
              Export
            </Button>
          ) : null}
      </div>
      <Tabs
        type="card"
        activeKey={currentTab}
        onChange={handleChangeTab}
        style={{ marginBottom: 32 }}
      >
        <TabPane tab='Provider Invoices' key='1'>
          <InvoiceList type="inv-pm" props={props} clientId={clientId} />
        </TabPane>

        <TabPane tab='PM Fee Invoices' key='2'>
          <InvoiceList type="inv-std" props={props} clientId={clientId} />
        </TabPane>

      </Tabs>

      <InvoiceExportModal showModal={showExportModal} onCloseModal={closeExportModal} clientId={clientId} />

    </div>

  )

}

export class ClientInvoices extends Component {
  render () {
    return <CreditList {...this.props} />
  }
}

export default ClientInvoices
