import React, { Component, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { formatter, validator } from '../../../../../util'

// UI
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import { Button, List } from '../../../../../components'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form

function getItemReceiveHistoryColumns () {
  return Object.freeze([
    {
      title: 'Received At',
      width: 6,
      render: ({ received_date: receivedDate }) => receivedDate ? formatter.toShortDate(receivedDate) : ''
    },
    {
      title: 'Received Amount',
      width: 6,
      render: ({ amount }) => formatter.toPrice(amount)
    },
    {
      title: 'Notes',
      width: 12,
      render: ({ comment }) => comment || ''
    }
  ])
}

function ReceivedAmountModal (props) {
  const { receivedAmountModal, visible, onCloseModal } = props
  const { idx: iiIdx, invoiceItem, itemReceiveHistory } = receivedAmountModal || {}
  const {
    start_date: iiStartDate, end_date: iiEndDate, category_number: iiCategoryNumber, category_name: iiCategoryName, amount: iiAmount
  } = invoiceItem || {}

  const closeModal = useCallback(() => {
    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }
  }, [onCloseModal])

  return validator.isId(iiIdx) ? (
    <Modal
      className='pid-rcv-amount'
      footer={[
        <Button key='close' ghost onClick={closeModal}>Close</Button>,
      ]}
      maskClosable={false}
      title='Received History'
      visible={visible}
      onCancel={closeModal}
    >
      <Form className='invoice-item-info' layout='vertical'>
        <Row gutter={8}>
          <Col lg={6}>
            <FormItem label='Service Date'>
              {formatter.toShortDate(iiStartDate)} - {formatter.toShortDate(iiEndDate)}
            </FormItem>
          </Col>

          <Col lg={6}>
            <FormItem label='Support Category'>
              {formatter.toCategoryNumber(iiCategoryNumber)} - {iiCategoryName}
            </FormItem>
          </Col>

          <Col lg={6}>
            <FormItem label='Invoiced Amount'>
              {formatter.toPrice(iiAmount)}
            </FormItem>
          </Col>

          <Col lg={6}>
            <FormItem label='Total Received'>

            </FormItem>
          </Col>
        </Row>

        <List cols={getItemReceiveHistoryColumns()} rows={Array.isArray(itemReceiveHistory) ? itemReceiveHistory : []} />
      </Form>
    </Modal>
  ) : null
}

export class PaceReceivedAmountModal extends Component {
  render () {
    return <ReceivedAmountModal {...this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PaceReceivedAmountModal))
