import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'

import { apiHostname } from '../../../../config'
import { FileUploadMsg, Permissions } from '../../../../constants'
import {
  pmRatePaceService, pmRateSetPaceService, pmRateSetCategoryPaceService, pmRateSetCategoryConfigPaceService,
  pmRateSetCategoryGroupPaceService, pmRateSettingsPaceService
} from '../../../../services'
import { refreshRateSets } from '../../../../states/actions'
import { auth, formatter, periodValidator, validator } from '../../../../util'

// UI
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Table from 'antd/lib/table'
import Upload from 'antd/lib/upload'

import { Button, Page, Pager, Panel, ControlLabel, SearchInput, List } from '../../../../components'
import notify from '../../../../components/Notification'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { Option } = Select

const dateFormat = 'DD/MM/YYYY'
const defaultPageSize = 50
const defaultPageSize2 = 20
const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 7 },
  wrapperCol: { sm: 14, md: 14, lg: 16 }
}
const formItemDateLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 18, md: 18, lg: 12 }
}

function getRateSetValuesColumns () {
  return Object.freeze([
    {
      dataIndex: 'category_name',
      key: 'category_name',
      title: 'Support Category',
      width: 200,
      fixed: 'left'
    },
    {
      dataIndex: 'item_name',
      key: 'item_name',
      title: 'Support Item',
      width: 300,
      fixed: 'left'
    },
    {
      dataIndex: 'item_number',
      key: 'item_number',
      title: 'Support Item Number',
      width: 180,
      fixed: 'left'
    },
    {
      dataIndex: 'category_group_name',
      key: 'category_group_name',
      title: 'Group Name',
      width: 150,
      fixed: 'left'
    },
    {
      dataIndex: 'funding_type_label',
      key: 'funding_type_label',
      title: 'Funding Type',
      width: 150,
      fixed: 'left'
    },
    {
      dataIndex: 'start_date',
      key: 'start_date',
      title: 'Start Date',
      render: (start_date) => start_date ? formatter.toShortDate(start_date) : null
    },
    {
      dataIndex: 'end_date',
      key: 'end_date',
      title: 'End Date',
      render: (end_date) => end_date ? formatter.toShortDate(end_date) : null
    },
    {
      dataIndex: 'd0',
      key: 'd0',
      title: <div>ACT</div>,
      render: (d0) => <div>{d0 ? formatter.toPrice(d0, '') : ''}</div>
    },
    {
      dataIndex: 'd1',
      key: 'd1',
      title: <div>NSW</div>,
      render: (d1) => <div>{d1 ? formatter.toPrice(d1, '') : ''}</div>
    },
    {
      dataIndex: 'd2',
      key: 'd2',
      title: <div>NT</div>,
      render: (d2) => <div>{d2 ? formatter.toPrice(d2, '') : ''}</div>
    },
    {
      dataIndex: 'd3',
      key: 'd3',
      title: <div>QLD</div>,
      render: (d3) => <div>{d3 ? formatter.toPrice(d3, '') : ''}</div>
    },
    {
      dataIndex: 'd4',
      key: 'd4',
      title: <div>SA</div>,
      render: (d4) => <div>{d4 ? formatter.toPrice(d4, '') : ''}</div>
    },
    {
      title: <div>TAS</div>,
      dataIndex: 'd5',
      key: 'd5',
      render: (d5) => <div>{d5 ? formatter.toPrice(d5, '') : ''}</div>
    },
    {
      dataIndex: 'd6',
      key: 'd6',
      title: <div>VIC</div>,
      render: (d6) => <div>{d6 ? formatter.toPrice(d6, '') : ''}</div>
    },
    {
      dataIndex: 'd7',
      key: 'd7',
      title: <div>WA</div>,
      render: (d7) => <div>{d7 ? formatter.toPrice(d7, '') : ''}</div>
    },
    {
      dataIndex: 'd20',
      key: 'd20',
      title: <div>Remote</div>,
      render: (d20) => <div>{d20 ? formatter.toPrice(d20, '') : ''}</div>
    },
    {
      dataIndex: 'd30',
      key: 'd30',
      title: <div>Very<br />Remote</div>,
      render: (d30) => <div>{d30 ? formatter.toPrice(d30, '') : ''}</div>
    },
    {
      dataIndex: 'unit',
      key: 'unit',
      title: <div>Unit</div>,
    },
    {
      dataIndex: 'is_quote',
      key: 'is_quote',
      title: <div>Quote?</div>,
      render: (is_quote) => is_quote
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_nf2f_support_provision',
      key: 'is_nf2f_support_provision',
      title: <div>Non-Face-<br />To-Face?</div>,
      render: (is_nf2f) => is_nf2f
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_provider_travel',
      key: 'is_provider_travel',
      title: <div>Provider<br />Travel?</div>,
      render: (is_provider_travel) => is_provider_travel
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_short_notice_cancel',
      key: 'is_short_notice_cancel',
      title: <div>Short Notice<br />Cancellations?</div>,
      render: (is_short_notice_cancel) => is_short_notice_cancel
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_ndia_requested_reports',
      key: 'is_ndia_requested_reports',
      title: <div>NDIA<br />Requested<br />Reports?</div>,
      render: (is_ndia_requested_reports) => is_ndia_requested_reports
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_irregular_sil_supports',
      key: 'is_irregular_sil_supports',
      title: <div>Irregular<br />SIL<br />Supports?</div>,
      render: (is_irregular_sil_supports) => is_irregular_sil_supports
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    }
  ])
}

function RateSetForm ({ isEdit, item, props }) {
  const { form, match } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const { getFieldDecorator, getFieldError, getFieldValue, validateFields } = form
  const [extraEndDate, setExtraEndDate] = useState()
  const [extraStartDate, setExtraStartDate] = useState()
  const [helpEndDate, setHelpEndDate] = useState()
  const [helpStartDate, setHelpStartDate] = useState()
  const [isRateSelected, setIsRateSelected] = useState(isEdit ? !!item.rate_id : false)
  const [loadingRates, setLoadingRates] = useState(true)
  const [revalidateFieldName, setRevalidateFieldName] = useState()
  const [rates, setRates] = useState([])
  const [validateStatusEndDate, setValidateStatusEndDate] = useState()
  const [validateStatusStartDate, setValidateStatusStartDate] = useState()

  const changeRate = useCallback((value) => {
    setIsRateSelected(!!value)
  }, [])

  const revalidateField = useCallback((callback, fieldName) => {
    validateFields([fieldName], { force: true }).catch(() => { }).finally(() => {
      callback()
    })
  }, [validateFields])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const validateEndDate = useCallback((rule, value, callback) => {
    const fvStartDate = getFieldValue('start_date')

    if (validator.isDate(fvStartDate) && validator.isDate(value)) {
      const startDate = moment(fvStartDate).startOf('day')
      const endDate = moment(value).endOf('day')
      const rateId = getFieldValue('rate_id')
      const rfn = revalidateFieldName

      if (validator.isNullOrUndefined(revalidateFieldName)) {
        setRevalidateFieldName('end_date')
      }

      setExtraEndDate()
      periodValidator.validateEndDate({
        startDate,
        endDate,
        item,
        onCheckDate: () => pmRateSetPaceService.checkDates(startDate.toISOString(), endDate.toISOString(), id, rateId),
        onGetLink: (id) => `/settings/rate-set-pace/rate-sets/${id}`
      }).then(({ error, extra, help, validateStatus }) => {
        callback(error)
        setExtraEndDate(extra)
        setHelpEndDate(help)
        setValidateStatusEndDate(validateStatus)

        if (rfn !== 'start_date') {
          revalidateField(callback, 'start_date')
        } else if (!error && getFieldError('start_date')) {
          setRevalidateFieldName('end_date')
        }
      }).catch(console.log)
    } else {
      setExtraEndDate()
      setHelpEndDate()
    }
  }, [getFieldError, getFieldValue, revalidateField, id, item, revalidateFieldName])

  const validateStartDate = useCallback((rule, value, callback) => {
    const fvEndDate = getFieldValue('end_date')

    if (validator.isDate(value) && validator.isDate(fvEndDate)) {
      const startDate = moment(value).startOf('day')
      const endDate = moment(fvEndDate).endOf('day')
      const rateId = getFieldValue('rate_id')
      const rfn = revalidateFieldName

      if (validator.isNullOrUndefined(revalidateFieldName)) {
        setRevalidateFieldName('start_date')
      }

      setExtraStartDate()
      periodValidator.validateStartDate({
        startDate,
        endDate,
        item,
        onCheckDate: () => pmRateSetPaceService.checkDates(startDate.toISOString(), endDate.toISOString(), id, rateId),
        onGetLink: (id) => `/settings/rate-set-pace/rate-sets/${id}`
      }).then(({ error, extra, help, validateStatus }) => {
        callback(error)
        setExtraStartDate(extra)
        setHelpStartDate(help)
        setValidateStatusStartDate(validateStatus)

        if (rfn !== 'end_date') {
          revalidateField(callback, 'end_date')
        } else if (!error && getFieldError('end_date')) {
          setRevalidateFieldName('start_date')
        }
      }).catch(console.log)
    } else {
      setExtraStartDate()
      setHelpStartDate()
    }
  }, [getFieldError, getFieldValue, revalidateField, id, item, revalidateFieldName])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESETS_PACE.READ)) {
      return
    }

    let mounted = true
    pmRatePaceService.getAll({ active: true })
      .then((response) => {
        if (mounted && Array.isArray(response) && response.length > 0) {
          setRates(response)
        }
      })
      .finally(() => {
        if (mounted) {
          setLoadingRates(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess])

  return (
    <Panel className='rsprse-panel'>
      <Row>
        <Col lg={10}>
          <FormItem label='Rate' {...formItemLayout}>
            {getFieldDecorator('rate_id', {
              initialValue: item.rate_id || '',
              rules: [
                { required: true, message: 'Please select Set' }
              ]
            })(
              <Select
                disabled={isEdit}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                loading={loadingRates}
                onChange={changeRate}
                showSearch
                style={{ width: '100%' }}
              >
                {rates.map((rate) => <Option key={rate.id} disabled={!rate.active} value={rate.id}>{rate.name}</Option>)}
              </Select>
            )}
          </FormItem>

          <FormItem label='Name' {...formItemLayout}>
            {getFieldDecorator('name', {
              initialValue: item.name || '',
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter name' },
                { whitespace: true, message: 'Please enter name' }
              ]
            })(
              <Input />
            )}
          </FormItem>
        </Col>

        <Col lg={2}>
          <FormItem label='' {...formItemLayout}>
            {getFieldDecorator('active', {
              initialValue: item.active || true,
              valuePropName: 'checked',
            })(
              <Switch checkedChildren='Enable' unCheckedChildren='Disable' />
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={6}>
          <FormItem
            label='Start Date'
            extra={extraStartDate}
            help={helpStartDate}
            validateStatus={validateStatusStartDate}
            {...formItemDateLayout}
          >
            {getFieldDecorator('start_date', {
              initialValue: item.start_date ? formatter.toMoment(item.start_date) : null,
              rules: [
                { required: true, message: 'Please select start date' },
                { validator: validateStartDate }
              ]
            })(
              <DatePicker defaultPickerValue={moment().startOf('day')} disabled={!isRateSelected} format={dateFormat} />
            )}
          </FormItem>
        </Col>

        <Col lg={6}>
          <FormItem
            label='End Date'
            extra={extraEndDate}
            help={helpEndDate}
            validateStatus={validateStatusEndDate}
            {...formItemDateLayout}
          >
            {getFieldDecorator('end_date', {
              initialValue: item.end_date ? formatter.toMoment(item.end_date) : moment().year(9999).endOf('year'),
              rules: [
                { required: true, message: 'Please select end date' },
                { validator: validateEndDate }
              ]
            })(
              <DatePicker defaultPickerValue={moment().endOf('day')} disabled={!isRateSelected} format={dateFormat} />
            )}
          </FormItem>
        </Col>
      </Row>
    </Panel>
  )
}

function RateSetUpload ({ disableAction, props }) {
  const { refreshRateSets, match } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const [showImportModal, setShowImportModal] = useState(false)
  const [uploadList, setUploadList] = useState([])
  const [uploadMessage, setUploadMessage] = useState()
  const [uploaded, setUploaded] = useState({})
  const [uploading, setUploading] = useState(false)

  const changeFile = useCallback((info) => {
    if (validator.isObject(info) && info.file) {
      const f = info.file
      const { percent, response: r, status, uid } = f

      if (percent === 100 && r && status === 'done') {
        const uploaded = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        setUploadMessage()
        setUploaded(uploaded)
      }
    }
  }, [])

  const checkUpload = useCallback((file) => {
    if (file && (
      file.type === 'text/csv' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    )) {
      setUploadList([file])
      setUploadMessage()
      return true
    } else {
      setUploadList([])
      setUploadMessage(FileUploadMsg.UploadMsgWrongFormatCSV)
      return false
    }
  }, [])

  const closeImportModal = useCallback(() => {
    setShowImportModal(false)
    setUploadList([])
    setUploadMessage()
    setUploaded({})
    setUploading(false)
  }, [])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const importFile = useCallback(async () => {
    if (!Array.isArray(uploadList) || uploadList.length < 1) {
      setUploadMessage(FileUploadMsg.UploadMsgNoFile)
    } else if (!validator.isObject(uploaded) || !uploaded.uid) {
      setUploadMessage(FileUploadMsg.UploadMsgInProgress)
    } else {
      try {
        setUploading(true)
        const response = await pmRateSetPaceService.importFile(id, uploaded)

        if (validator.isObject(response) && validator.isId(response.id)) {
          notify.success('Import Successfully', 'Rate set imported successfully.')
          refreshRateSets(true)
        } else {
          notify.error('Unable to import successfully', 'Unable to import rate set successfully. Please try again later.')
        }
      } catch (e) {
        notify.error('Unable to import successfully', 'Unable to import rate set successfully. Please try again later.')
        console.log(e)
      } finally {
        closeImportModal()
        setUploading(false)
      }
    }
  }, [closeImportModal, refreshRateSets, id, uploadList, uploaded])

  const openImportModal = useCallback(() => {
    setShowImportModal(true)
  }, [])

  const removeFile = useCallback((file) => {
    const idx = uploadList.indexOf(file)

    if (idx > -1) {
      const newList = uploadList.slice()
      newList.shift()
      setUploadList(newList)
    }
  }, [uploadList])

  return hasAccess(Permissions.SETTING.RATESET_IMPORT.UPDATE) ? (
    <>
      <Panel className='rsprse-panel import'>
        <div className='billing-rate-row-title'>Upload a rate set CSV or Excel file to import the latest support categories, support items and rates into the rate set.</div>
        <Button disabled={disableAction} onClick={openImportModal}>Import</Button>
      </Panel>

      <Modal
        className='rsp-upload-modal'
        footer={[
          <Button key='cancel' ghost type='primary' onClick={closeImportModal}>Cancel</Button>,
          <Button key='ok' disabled={!uploaded.uid} feedback={uploading} ghost={!uploaded.uid} type='primary' onClick={importFile}>Start Import</Button>
        ]}
        maskClosable={false}
        title='Import Rate Sets and Categories'
        visible={showImportModal}
        width={580}
        onCancel={uploading ? null : closeImportModal}
        onOk={importFile}
      >
        <div>Select the latest rate set CSV or Excel file to upload and this may take a while to complete.</div>

        <div className='rsp-margin-bottom alert'>Be reminded that this action cannot be undone!</div>

        <div>
          <Upload
            headers={{ Authorization: `Bearer ${auth.getCurrentToken()}` }}
            method='POST'
            action={`${apiHostname}/private/api/pm-rate-sets-pace/file/${id}`}
            data={{ rateId: id }}
            disabled={uploading}
            fileList={uploadList}
            multiple={false}
            name='file'
            beforeUpload={checkUpload}
            onChange={changeFile}
            onRemove={removeFile}
          >
            <Button className='btn-upload' feedback={uploading}><Icon type="upload" /> Select File</Button>
          </Upload>
        </div>

        {uploadMessage ? <div className='alert text-error'>{uploadMessage}</div> : null}
      </Modal>
    </>
  ) : null
}

function getCategoryColumns (categoryGroups, fundingTypes, loadingDropdown, onChangeItem) {
  return Object.freeze([
    {
      key: 'category_name',
      title: 'Category Name',
      width: 10
    },
    {
      key: 'category_number',
      title: 'Category Number',
      width: 4
    },
    {
      key: 'category_group_id',
      title: 'Group Name',
      width: 4,
      render: (item) => (
        <Select
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={loadingDropdown}
          placeholder='Select Group'
          showSearch
          value={item.category_group_id}
          onChange={typeof onChangeItem === 'function' ? onChangeItem('category_group_id', item) : null}
          style={{ width: '100%' }}
        >
          <Option value={null}>&nbsp;</Option>
          {Array.isArray(categoryGroups) && categoryGroups.map(({ id, name, active }) => (
            <Option key={id} disabled={!active} value={id}>{name}</Option>
          ))}
        </Select>
      )
    },
    {
      key: 'funding_type',
      title: 'Funding Type',
      width: 4,
      render: (item) => (
        <Select
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={loadingDropdown}
          placeholder='Select Funding Type'
          showSearch
          value={item.funding_type}
          onChange={typeof onChangeItem === 'function' ? onChangeItem('funding_type', item) : null}
          style={{ width: '100%' }}
        >
          <Option value={null}>&nbsp;</Option>
          {Array.isArray(fundingTypes) && fundingTypes.map(({ id, label, value, active }) => (
            <Option key={id} disabled={!active} value={value}>{label}</Option>
          ))}
        </Select>
      )
    },
  ])
}

function RateSetValuesModal ({ categoryGroups, fundingTypes, loading, loadingDropdown, props, showModal, onCloseModal }) {
  const { match, refreshRateSets } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [saving, setSaving] = useState(false)

  const listByPage = useCallback(async ({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setPage(_page)
    pmRateSetCategoryPaceService
      .listByPage(_page, defaultPageSize2, { rate_set_id: id })
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
  }, [id])

  const closeModal = useCallback(() => {
    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }

    listByPage({ page: 1 })
  }, [listByPage, onCloseModal])

  const changeModalItem = useCallback((key, item) => (value) => {
    item[`_${key}`] = item[key]
    item[key] = value
    item.changed = item[`_${key}`] !== value
    setList([...list])
  }, [list])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const handleSave = useCallback(async () => {
    if (loading || saving) {
      return
    }

    try {
      setSaving(true)
      const response = await pmRateSetCategoryConfigPaceService.saveAll(list.map(({
        id: categoryId, category_config_id: categoryConfigId, category_group_id: categoryGroupId, funding_type: fundingType, changed
      }) => {
        return {
          id: categoryConfigId, category_id: categoryId, category_group_id: categoryGroupId, funding_type: fundingType,
          changed: changed || false
        }
      }))

      if (validator.isObject(response) && validator.isId(response.id)) {
        notify.success('Saved successfully', `Group & funding type saved successfully.`)
        refreshRateSets(true)
        closeModal()
      } else {
        notify.error('Unable to save', `Unable to save group & funding type. Please try again later.`)
      }
    } catch (e) {
      notify.error('Unable to save', `Unable to save group & funding type. Please try again later.`)
    } finally {
      setSaving(false)
    }
  }, [closeModal, refreshRateSets, loading, saving, list])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESETS_PACE.READ)) {
      return
    }

    let mounted = true
    pmRateSetCategoryPaceService
      .listByPage(1, defaultPageSize2, { rate_set_id: id })
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, id])

  return (
    <Modal
      footer={[
        <Button key='cancel' ghost feedback={saving} type='primary' onClick={closeModal}>Cancel</Button>,
        <Button key='ok' feedback={saving} type='primary' onClick={handleSave}>Save</Button>
      ]}
      maskClosable={false}
      title='Edit Group & Funding Type'
      visible={showModal}
      width='80vw'
      onCancel={closeModal}
      onOk={handleSave}
    >
      <List cols={getCategoryColumns(categoryGroups, fundingTypes, loadingDropdown, changeModalItem)} rows={list} />

      <Pager
        current={page}
        size={defaultPageSize2}
        total={total}
        totalText={`Total ${total} categories`}
        onChange={changePage}
        style={{ marginTop: '15px' }}
      />
    </Modal>
  )
}

function RateSetValuesTable ({ props }) {
  const { match, rateSet, refreshRateSets } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const { refresh } = rateSet || {}
  const [categoryGroups, setCategoryGroups] = useState([])
  const [fundingTypes, setFundingTypes] = useState([])
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDropdown, setLoadingDropdown] = useState(false)
  const [searching, setSearching] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const hasValues = Array.isArray(list) && list.length > 0

  const closeEditModal = useCallback(() => {
    setShowEditModal(false)
  }, [])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const openEditModal = useCallback(() => {
    Promise
      .all([
        pmRateSetCategoryGroupPaceService.getAll(),
        pmRateSettingsPaceService.getAllFundingTypes()
      ])
      .then(([categoryGroupResponse, fundingTypeResponse]) => {
        if (Array.isArray(categoryGroupResponse)) {
          setCategoryGroups(categoryGroupResponse)
        }

        if (Array.isArray(fundingTypeResponse)) {
          setFundingTypes(fundingTypeResponse)
        }
      }).finally(() => {
        setLoadingDropdown(false)
      })
    setLoadingDropdown(true)
    setShowEditModal(true)
  }, [])

  const searchName = useCallback(() => {
    const onSearchName = async (value) => {
      setLoading(true)
      setSearching(true)

      try {
        const response = await pmRateSetPaceService.getAllValues({ rate_set_id: id }, value)

        if (Array.isArray(response)) {
          setList(response)
        }
      } finally {
        setLoading(false)
        setSearching(false)
      }
    }
    return debounce(onSearchName, 500)
  }, [id])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESETS_PACE.READ)) {
      return
    }

    let mounted = true
    setLoading(true)
    setLoadingDropdown(true)
    Promise
      .all([
        pmRateSetPaceService.getAllValues({ rate_set_id: id }),
        pmRateSetCategoryGroupPaceService.getAll(),
        pmRateSettingsPaceService.getAllFundingTypes()
      ])
      .then(([response, categoryGroupResponse, fundingTypeResponse]) => {
        if (mounted) {
          if (Array.isArray(response)) {
            setList(response)
          }

          if (Array.isArray(categoryGroupResponse)) {
            setCategoryGroups(categoryGroupResponse)
          }

          if (Array.isArray(fundingTypeResponse)) {
            setFundingTypes(fundingTypeResponse)
          }
        }
      }).finally(() => {
        if (mounted) {
          setLoading(false)
          setLoadingDropdown(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, id])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESETS_PACE.READ)) {
      return
    }

    let mounted = true

    if (refresh === true) {
      setLoading(true)
      pmRateSetPaceService
        .getAllValues({ rate_set_id: id })
        .then((response) => {
          if (mounted && Array.isArray(response)) {
            setList(response)
          }
        }).finally(() => {
          if (mounted) {
            refreshRateSets(false)
            setLoading(false)
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, refreshRateSets, id, refresh])


  return (
    <>
      <Row className='rsp-margin-bottom' gutter={8}>
        <Col lg={6}>
          <ControlLabel>Support Category, Support Item, Support Item Number</ControlLabel>

          <SearchInput isSearching={searching} placeholder='Search' onChange={searchName()} />
        </Col>

        <Col style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
          {hasValues && hasAccess(Permissions.SETTING.RATESETS_PACE.UPDATE)
            ? <Button onClick={openEditModal}>Edit Group & Funding Type</Button>
            : null}
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Table
          columns={getRateSetValuesColumns()}
          dataSource={list}
          loading={loading}
          pagination={{ defaultPageSize }}
          rowKey='id'
          scroll={{ x: true, scrollToFirstRowOnChange: true }}
        />
      </Spin>

      <RateSetValuesModal
        categoryGroups={categoryGroups} fundingTypes={fundingTypes} loading={loading} loadingDropdown={loadingDropdown} props={props}
        showModal={showEditModal} onCloseModal={closeEditModal}
      />
    </>
  )
}

function RateSetEdit (props) {
  const { form, history, match, refreshRateSets } = props || {}
  const { validateFields } = form || {}
  const { params } = match || {}
  const { id } = params || {}
  const [item, setItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [showEdit, setShowEdit] = useState(true)
  const [showSave, setShowSave] = useState(false)

  const isEdit = useCallback(() => {
    return id !== 'add'
  }, [id])

  const handleSave = useCallback(() => {
    if (loading || saving) {
      return
    }

    validateFields(async (errors, values) => {
      if (errors) {
        return
      }

      try {
        setSaving(true)
        let response
        values.start_date = moment(values.start_date).startOf('day').toISOString()
        values.end_date = moment(values.end_date).endOf('day').toISOString()

        if (isEdit()) {
          response = await pmRateSetPaceService.save(id, values)
        } else {
          response = await pmRateSetPaceService.add(values)
        }

        if (validator.isObject(response) && validator.isId(response.id)) {
          setItem({ ...item, ...values, ...response })
          notify.success('Saved successfully', 'Rate set saved successfully.')

          if (!isEdit()) {
            refreshRateSets(true)
            history.replace(`/settings/rate-set-pace/rate-sets/${response.id}`)
          }
        } else {
          notify.error('Unable to save successfully', 'Unable to save rate set successfully. Please try again later.')
        }
      } catch (e) {
        notify.error('Unable to save successfully', 'Unable to save rate set successfully. Please try again later.')
      } finally {
        setSaving(false)
      }
    })
  }, [isEdit, refreshRateSets, validateFields, history, id, item, loading, saving])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const toggleEdit = useCallback(() => {
    setShowEdit(!showEdit)
    setShowSave(true)
  }, [showEdit])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESETS_PACE.READ)) {
      return
    }

    let mounted = true

    if (isEdit()) {
      setLoading(true)
      pmRateSetPaceService.get(id).then((response) => {
        if (mounted && validator.isObject(response) && validator.isId(response.id)) {
          setItem(response)
        }
      }).finally(() => {
        if (mounted) {
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, isEdit, id])

  return (
    <Page.Body>
      <Page.Content nomenu>
        <Page.Header title={!isEdit() ? 'Add New Rate Set' : item.rate_name ? `${item.rate_name} - ${item.name}` : null}>
          {hasAccess(Permissions.SETTING.RATESETS_PACE.UPDATE) && isEdit() && !loading && showEdit
            ? <Button onClick={toggleEdit}>Edit</Button>
            : null}

          {(
            (hasAccess(Permissions.SETTING.RATESETS_PACE.CREATE) && !isEdit()) ||
            (hasAccess(Permissions.SETTING.RATESETS_PACE.UPDATE) && isEdit() && showSave)
          )
            ? <Button disabled={saving} feedback={saving} onClick={handleSave}>Save</Button>
            : null
          }

          <Button disabled={saving} onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Spin spinning={saving}>
          <Skeleton active loading={loading}>
            <RateSetForm isEdit={isEdit()} item={item} props={props} />

            {isEdit() ? <RateSetUpload disableAction={saving} props={props} /> : null}

            {isEdit() ? <RateSetValuesTable disableAction={saving} props={props} /> : null}
          </Skeleton>
        </Spin>
      </Page.Content>
    </Page.Body>
  )
}

export class PaceRateSetEdit extends Component {
  render () {
    return <RateSetEdit {...this.props} />
  }
}

const mapDispatchToProps = {
  refreshRateSets
}

const mapStateToProps = (state) => {
  return { ...state.RateSetPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PaceRateSetEdit))
