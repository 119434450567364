import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import moment from 'moment-timezone'

import Permissions from '../../../../constants/permissions'
import { clientPlanPaceService, pmRateSetCategoryPaceService, pmRateSetCategoryItemPaceService, clientService } from '../../../../services'
import { auth, common, formatter, validator, periodValidator } from '../../../../util'

// UI
import Alert from 'antd/lib/alert'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import { Button, List, Page, Panel } from '../../../../components'
import notify from '../../../../components/Notification'

import './styles.css'

const { searchDropdown } = common
const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { TextArea } = Input
const { Option } = Select

const dateFormat = 'DD/MM/YYYY'
const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 18, md: 18, lg: 18 }
}
const formItemDateLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 18, md: 18, lg: 12 }
}

const FundingTypeFlexible = 'flexible'
const ListItemField = Object.freeze({
  Id: 'id',
  FundingId: 'funding_id',
  PlanId: 'plan_id',
  StartDate: 'start_date',
  EndDate: 'end_date',
  CategoryId: 'category_id',
  CategoryNumber: 'category_number',
  CategoryGroupId: 'category_group_id',
  FundingType: 'funding_type',
  ItemId: 'item_id',
  ItemNumber: 'item_number',
  AllocatedAmount: 'allocated_amount',
  AvailableAmount: 'available_amount',
  CarryOverAmount: 'carry_over_amount',
  SpentAmount: 'spent_amount',
  Amount: 'amount',
  IsPmFee: 'is_pm_fee',
  IsDiffAllocated: 'is_diff_allocated',
})
const ListItemValidateLabel = Object.freeze({
  AllocatedAmount: 'Allocated amount',
  AvailableAmount: 'Balance',
  CarryOverAmount: 'Carry over',
  SpentAmount: 'Spent',
  Amount: 'Remaining balance',
})
const PmFeeCategoryNumber = '14'
const NoPlanPeriodItemAddedMsg = 'Please add at least one support category'

function getDefaultPlanPeriod () {
  return { client_plan_items: [{ cpi_category_number: PmFeeCategoryNumber }] }
}

function getDisplayForecastSpent (spentAmount, totalReceived, totalCredit, totalSpent, forecastSpent) {
  const _spentAmount = toBigNumber(spentAmount).toFixed(2)
  const _totalReceived = toBigNumber(totalReceived).toFixed(2)
  const _totalCredit = toBigNumber(totalCredit).toFixed(2)
  const _forecastSpent = toBigNumber(forecastSpent).minus(toBigNumber(totalSpent)).toFixed(2)
  return toBigNumber(_spentAmount).plus(_totalReceived).minus(_totalCredit).plus(_forecastSpent)
}

function getFundingPeriodItemFromPlanPeriodItem (item) {
  const {
    category_id: categoryId, category_number: categoryNumber, item_id: itemId, item_number: itemNumber,
    allocated_amount: allocatedAmount, available_amount: availableAmount, carry_over_amount: carryOverAmount,
    spent_amount: spentAmount, amount, is_pm_fee: isPmFee, is_diff_allocated: isDiffAllocated
  } = item
  return {
    category_id: categoryId, category_number: categoryNumber, item_id: itemId, item_number: itemNumber,
    allocated_amount: allocatedAmount, available_amount: availableAmount, carry_over_amount: carryOverAmount,
    spent_amount: spentAmount, amount, is_pm_fee: isPmFee, is_diff_allocated: isDiffAllocated
  }
}

function getListItemFieldName (list, idx, field) {
  if (validator.isId(idx) && typeof field === 'string' && field.trim().length > 0) {
    return `${list}[${idx}].${field}`
  }

  return ''
}

function getPlanPeriodItemFieldName (idx, field) {
  return getListItemFieldName('client_plan_items', idx, field)
}

function getUpdatedFundingPeriodItems (addedItems, changedItems) {
  const updatedClientFundings = Object.values(changedItems.reduce((accumulator, changedItem) => {
    const {
      id, client_id: clientId, plan_id: planId, start_date: startDate, end_date: endDate, setup_cost_invoiced: setupCostInvoiced,
      monthly_fee_invoiced: monthlyFeeInvoiced, setup_cost_reason: setupCostReason, monthly_fee_reason: monthlyFeeReason, active,
      cfi_id: cfiId, cfi_category_id: cfiCategoryId, cfi_category_number: cfiCategoryNumber, cfi_item_id: cfiItemId,
      cfi_item_number: cfiItemNumber, cfi_allocated_amount: cfiAllocatedAmount, cfi_available_amount: cfiAvailableAmount,
      cfi_carry_over_amount: cfiCarryOverAmount, cfi_spent_amount: cfiSpentAmount, cfi_amount: cfiAmount, cfi_is_pm_fee: cfiIsPmFee,
      cfi_is_diff_allocated: cfiIsDiffAllocated, deleted
    } = changedItem
    const key = `${planId}-${id}`

    if (!accumulator[key]) {
      // Initialize group with common fields and empty items array
      accumulator[key] = {
        id: id, client_id: clientId, plan_id: planId, start_date: startDate, end_date: endDate, setup_cost_invoiced: setupCostInvoiced,
        monthly_fee_invoiced: monthlyFeeInvoiced, setup_cost_reason: setupCostReason, monthly_fee_reason: monthlyFeeReason, active,
        items: []
      }
    }

    // Deep clone and extract cfi_* fields for items
    const item = {
      id: cfiId, category_id: cfiCategoryId, category_number: cfiCategoryNumber, item_id: cfiItemId, item_number: cfiItemNumber,
      allocated_amount: cfiAllocatedAmount, available_amount: cfiAvailableAmount, carry_over_amount: cfiCarryOverAmount,
      spent_amount: cfiSpentAmount, amount: cfiAmount, is_pm_fee: cfiIsPmFee, is_diff_allocated: cfiIsDiffAllocated
    }

    if (!deleted) {
      accumulator[key].items.push(item)
    }

    return accumulator
  }, {}))

  for (const clientFunding of updatedClientFundings) {
    const { items } = clientFunding

    if (Array.isArray(items)) {
      for (const addedItem of addedItems) {
        items.push(getFundingPeriodItemFromPlanPeriodItem(addedItem))
      }
    }
  }

  return updatedClientFundings
}

function groupPlanPeriodItems (rows) {
  const flexibleMap = new Map()
  const nonFlexibleMap = new Map()

  if (Array.isArray(rows)) {
    for (const row of rows) {
      const {
        cpi_id: cpiId, cpi_category_number: categoryNumber, cpi_category_group_id: categoryGroupId, cpi_funding_type: fundingType,
        cpi_allocated_amount: allocatedAmount, cpi_available_amount: availableAmount, cpi_carry_over_amount: carryOverAmount,
        cpi_spent_amount: spentAmount, cpi_amount: amount, total_expected: totalExpected, total_received: totalReceived,
        total_credit: totalCredit, total_remaining: totalRemaining, forecast_remaining: forecastRemaining, total_spent: totalSpent,
        forecast_spent: forecastSpent, total_processing: totalProcessing, total_to_auth: totalToAuth, total_to_claim: totalToClaim,
        total_to_receive: totalToReceive, total_to_pay: totalToPay, total_closed: totalClosed
      } = row
      const flexibleKey = categoryGroupId
      const nonFlexibleKey = categoryNumber

      if (fundingType === FundingTypeFlexible) {
        const group = flexibleMap.get(flexibleKey) || Object.assign({}, row)
        const {
          cpi_id: gCpiId, cpi_allocated_amount: gAllocatedAmount, cpi_available_amount: gAvailableAmount,
          cpi_carry_over_amount: gCarryOverAmount, cpi_spent_amount: gSpentAmount, cpi_amount: gAmount,
          total_expected: gTotalExpected, total_received: gTotalReceived, total_credit: gTotalCredit,
          total_remaining: gTotalRemaining, forecast_remaining: gForecastRemaining, total_spent: gTotalSpent,
          forecast_spent: gForecastSpent, total_processing: gTotalProcessing, total_to_auth: gTotalToAuth,
          total_to_claim: gTotalToClaim, total_to_receive: gTotalToReceive, total_to_pay: gTotalToPay, total_closed: gTotalClosed
        } = group

        if (gCpiId !== cpiId) {
          group.cpi_allocated_amount = BigNumber(!BigNumber(gAllocatedAmount).isNaN() ? gAllocatedAmount : 0)
            .plus(!BigNumber(allocatedAmount).isNaN() ? allocatedAmount : 0).toFixed(2)
          group.cpi_available_amount = BigNumber(!BigNumber(gAvailableAmount).isNaN() ? gAvailableAmount : 0)
            .plus(!BigNumber(availableAmount).isNaN() ? availableAmount : 0).toFixed(2)
          group.cpi_carry_over_amount = BigNumber(!BigNumber(gCarryOverAmount).isNaN() ? gCarryOverAmount : 0)
            .plus(!BigNumber(carryOverAmount).isNaN() ? carryOverAmount : 0).toFixed(2)
          group.cpi_spent_amount = BigNumber(!BigNumber(gSpentAmount).isNaN() ? gSpentAmount : 0)
            .plus(!BigNumber(spentAmount).isNaN() ? spentAmount : 0).toFixed(2)
          group.cpi_amount = BigNumber(!BigNumber(gAmount).isNaN() ? gAmount : 0)
            .plus(!BigNumber(amount).isNaN() ? amount : 0).toFixed(2)
          group.total_expected = BigNumber(!BigNumber(gTotalExpected).isNaN() ? gTotalExpected : 0)
            .plus(!BigNumber(totalExpected).isNaN() ? totalExpected : 0).toFixed(2)
          group.total_received = BigNumber(!BigNumber(gTotalReceived).isNaN() ? gTotalReceived : 0)
            .plus(!BigNumber(totalReceived).isNaN() ? totalReceived : 0).toFixed(2)
          group.total_credit = BigNumber(!BigNumber(gTotalCredit).isNaN() ? gTotalCredit : 0)
            .plus(!BigNumber(totalCredit).isNaN() ? totalCredit : 0).toFixed(2)
          group.total_remaining = BigNumber(!BigNumber(gTotalRemaining).isNaN() ? gTotalRemaining : 0)
            .plus(!BigNumber(totalRemaining).isNaN() ? totalRemaining : 0).toFixed(2)
          group.forecast_remaining = BigNumber(!BigNumber(gForecastRemaining).isNaN() ? gForecastRemaining : 0)
            .plus(!BigNumber(forecastRemaining).isNaN() ? forecastRemaining : 0).toFixed(2)
          group.total_spent = BigNumber(!BigNumber(gTotalSpent).isNaN() ? gTotalSpent : 0)
            .plus(!BigNumber(totalSpent).isNaN() ? totalSpent : 0).toFixed(2)
          group.forecast_spent = BigNumber(!BigNumber(gForecastSpent).isNaN() ? gForecastSpent : 0)
            .plus(!BigNumber(forecastSpent).isNaN() ? forecastSpent : 0).toFixed(2)
          group.total_processing = BigNumber(!BigNumber(gTotalProcessing).isNaN() ? gTotalProcessing : 0)
            .plus(!BigNumber(totalProcessing).isNaN() ? totalProcessing : 0).toFixed(2)
          group.total_to_auth = BigNumber(!BigNumber(gTotalToAuth).isNaN() ? gTotalToAuth : 0)
            .plus(!BigNumber(totalToAuth).isNaN() ? totalToAuth : 0).toFixed(2)
          group.total_to_claim = BigNumber(!BigNumber(gTotalToClaim).isNaN() ? gTotalToClaim : 0)
            .plus(!BigNumber(totalToClaim).isNaN() ? totalToClaim : 0).toFixed(2)
          group.total_to_receive = BigNumber(!BigNumber(gTotalToReceive).isNaN() ? gTotalToReceive : 0)
            .plus(!BigNumber(totalToReceive).isNaN() ? totalToReceive : 0).toFixed(2)
          group.total_to_pay = BigNumber(!BigNumber(gTotalToPay).isNaN() ? gTotalToPay : 0)
            .plus(!BigNumber(totalToPay).isNaN() ? totalToPay : 0).toFixed(2)
          group.total_closed = BigNumber(!BigNumber(gTotalClosed).isNaN() ? gTotalClosed : 0)
            .plus(!BigNumber(totalClosed).isNaN() ? totalClosed : 0).toFixed(2)
        }

        flexibleMap.set(flexibleKey, group)
      } else {
        nonFlexibleMap.set(nonFlexibleKey, Object.assign({}, row))
      }
    }
  }

  return { flexibleMap, nonFlexibleMap }
}

function handlePasteAmount (event) {
  const pastedText = common.getPastedText(event)

  if (pastedText) {
    const cleanedData = formatter.toBigNumber(formatter.parseAmount(pastedText))
    const amount = !cleanedData.isNaN() ? cleanedData.toFormat() : ''
    // No better alternative than document.execCommand at the moment
    document.execCommand('insertText', false, amount)
  }
}

function newFundingPeriodFromPlanPeriod (values) {
  const {
    client_id: vClientId, start_date: vStartDate, end_date: vEndDate, is_pm_fee: vIsPmFee, setup_cost_invoiced: vSetupCostInvoiced,
    monthly_fee_invoiced: vMonthlyFeeInvoiced, setup_cost_reason: vSetupCostReasaon, monthly_fee_reason: vMonthlyFeeReason,
    active: vActive, client_plan_items: vClientPlanItems
  } = values
  const hasClientPlanItems = Array.isArray(vClientPlanItems) && vClientPlanItems.length > 0
  const clientFunding = {
    client_id: vClientId, start_date: vStartDate, end_date: vEndDate, is_pm_fee: vIsPmFee,
    setup_cost_invoiced: vSetupCostInvoiced, monthly_fee_invoiced: vMonthlyFeeInvoiced,
    setup_cost_reason: vSetupCostReasaon, monthly_fee_reason: vMonthlyFeeReason, active: vActive,
  }

  if (hasClientPlanItems) {
    clientFunding.items = vClientPlanItems.map((item) => getFundingPeriodItemFromPlanPeriodItem(item))
  }

  values.client_fundings = [clientFunding]
  return values
}

function renderColumnSupportCategory ({
  categories, disabled, error, fieldName, getFieldDecorator, initialValue, loadingDropdown, showExtra, onChange
}) {
  return (
    <div className={clsx(error ? 'has-error' : '')}>
      {getFieldDecorator(fieldName, {
        initialValue,
        rules: [
          { required: true, message: 'Please select support category' },
        ]
      })(
        <Select
          disabled={loadingDropdown || disabled}
          dropdownMatchSelectWidth={false}
          filterOption={searchDropdown}
          loading={loadingDropdown}
          placeholder="Select Support Category"
          showSearch
          onChange={onChange}
          style={{ width: '330px', color: disabled ? 'black' : 'inherit' }}
        >
          {categories.map(({ id, category_number: categoryNumber, category_name: categoryName }) => (
            <Option key={id} value={categoryNumber}>
              ({categoryNumber.padStart(2, '0')}) {categoryName}
            </Option>
          ))}
        </Select>
      )}

      {error ? <div className="ant-form-explain">{error}</div> : null}

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </div>
  )
}

function renderColumnSupportItem ({
  categoryItems, disabled, error, fieldName, getFieldDecorator, initialValue, loadingDropdown, showExtra, onChange
}) {
  return (
    <div className={clsx(error ? 'has-error' : '')}>
      {getFieldDecorator(fieldName, {
        initialValue
      })(
        <Select
          allowClear
          disabled={loadingDropdown || disabled}
          dropdownMatchSelectWidth={false}
          filterOption={searchDropdown}
          loading={loadingDropdown}
          placeholder="Select Support Item"
          showSearch
          onChange={onChange}
          style={{ width: '250px', color: disabled ? 'black' : 'inherit' }}
        >
          {categoryItems.map(({ id, item_number: itemNumber, item_name: itemName }) => (
            <Option key={id} value={itemNumber}>({itemNumber}) {itemName}</Option>
          ))}
        </Select>
      )}

      {error ? <div className="ant-form-explain">{error}</div> : null}

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </div>
  )
}

function renderColumnAllocatedAmount ({ allocatedAmount, getFieldDecorator, error, fieldName, showExtra, onChange, onValidate }) {
  return (
    <div className={clsx(error ? 'has-error' : '')}>
      {getFieldDecorator(fieldName, {
        initialValue: !allocatedAmount.isNaN() ? allocatedAmount.toFormat(2) : undefined,
        rules: [
          { required: true, message: 'Please enter allocated amount' },
          { validator: onValidate }
        ]
      })(
        <Input addonBefore='$' onChange={onChange} onPaste={handlePasteAmount} />
      )}

      {error ? <div className="ant-form-explain">{error}</div> : null}

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </div>
  )
}

function renderColumnAvailableBalance ({
  availableAmount, error, fieldNameAA, fieldNameIDA, getFieldDecorator, idaToggled, isDiffAllocated, showExtra, onChangeAvailableAmount,
  onChangeIsDiffAllocated, onValidate
}) {
  return (
    <Row className='flex-box'>
      <Col lg={6}>
        {getFieldDecorator(fieldNameIDA, {
          initialValue: typeof isDiffAllocated === 'boolean' ? isDiffAllocated : false,
          valuePropName: 'checked',
        })(
          <Switch checkedChildren="Diff" unCheckedChildren="Same" onChange={onChangeIsDiffAllocated} />
        )}

        {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
      </Col>

      {isDiffAllocated ? (
        <Col className={clsx(error ? 'has-error' : '')} lg={18}>
          {getFieldDecorator(fieldNameAA, {
            initialValue: !idaToggled && !availableAmount.isNaN() && availableAmount.isGreaterThan(0)
              ? availableAmount.toFormat(2) : undefined,
            rules: [
              { required: true, message: 'Please enter available balance' },
              { validator: onValidate }
            ]
          })(
            <Input addonBefore='$' onChange={onChangeAvailableAmount} onPaste={handlePasteAmount} />
          )}

          {error ? <div className="ant-form-explain">{error}</div> : null}

          {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
        </Col>
      ) : null}
    </Row>
  )
}

function renderColumnCarryOverAmount ({ carryOverAmount, error, fieldName, getFieldDecorator, showExtra, onChange, onValidate }) {
  return (
    <div className={clsx(error ? 'has-error' : '')}>
      {getFieldDecorator(fieldName, {
        initialValue: !carryOverAmount.isNaN() && carryOverAmount.isGreaterThan(0) ? carryOverAmount.toFormat(2) : undefined,
        rules: [
          { validator: onValidate }
        ]
      })(
        <Input addonBefore='$' onChange={onChange} onPaste={handlePasteAmount} />
      )}

      {error ? <div className="ant-form-explain">{error}</div> : null}

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </div>
  )
}

function renderColumnSpent ({ forecastSpent, showExtra, spentAmount }) {
  return (
    <>
      <div>$ {spentAmount.toFormat(2)}</div>

      {showExtra ? (
        <div className="ant-form-extra">
          ({BigNumber(!forecastSpent.isNaN() ? forecastSpent : 0).toFormat(2)})
        </div>
      ) : null}
    </>
  )
}

function renderColumnAction ({ hasAccess, showExtra, onRemove }) {
  return (
    <>
      <div>
        {hasAccess(Permissions.PARTICIPANT.PP_PACE.DELETE) ? (
          <Tooltip mouseLeaveDelay={0} title="Remove Support Category">
            <Icon className='btn-icon' type="delete" onClick={typeof onRemove === 'function' ? onRemove : null} />
          </Tooltip>
        ) : null}
      </div>

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </>
  )
}

function sortListItem ({ aIsPmFee, aCategorySorting, aItemSorting }, { bIsPmFee, bCategorySorting, bItemSorting }) {
  if (aIsPmFee !== bIsPmFee) {
    return aIsPmFee ? -1 : 1
  }

  if (aCategorySorting !== bCategorySorting) {
    return aCategorySorting - bCategorySorting
  }

  return aItemSorting - bItemSorting
}

function toBigNumber (value) {
  const bigNumber = formatter.toBigNumber(value)
  return !bigNumber.isNaN() ? bigNumber : BigNumber(0)
}

function updateFundingPeriodFromPlanPeriod (clonedItem, values) {
  const clonedValues = cloneDeep(values)
  const { client_plan_items: cClientPlanItems, client_funding_items: cClientFundingItems } = clonedItem
  const {
    client_id: vClientId, start_date: vStartDate, end_date: vEndDate, is_pm_fee: vIsPmFee, setup_cost_invoiced: vSetupCostInvoiced,
    monthly_fee_invoiced: vMonthlyFeeInvoiced, setup_cost_reason: vSetupCostReasaon, monthly_fee_reason: vMonthlyFeeReason,
    active: vActive, client_plan_items: vClientPlanItems
  } = clonedValues
  const hasCClientPlanItems = Array.isArray(cClientPlanItems) && cClientPlanItems.length > 0
  const hasCClientFundingItems = Array.isArray(cClientFundingItems) && cClientFundingItems.length > 0
  const hasVClientPlanItems = Array.isArray(vClientPlanItems) && vClientPlanItems.length > 0

  // Has new plan period values to mirror
  if (hasVClientPlanItems) {
    const addedItems = []
    const changedItemLookup = new Map()

    for (let i = 0; i < vClientPlanItems.length; i++) {
      const vItem = vClientPlanItems[i]
      const { id: vId } = vItem

      if (validator.isId(vId)) {
        // potentially changed item
        changedItemLookup.set(vId, vItem)
      } else {
        // newly added item
        addedItems.push(vItem)
      }
    }

    if (hasCClientPlanItems) {
      if (hasCClientFundingItems) {
        // Mirror plan period changes into existing funding period
        const changedItemMap = new Map()

        for (let i = 0; i < cClientPlanItems.length; i++) {
          const cItem = cClientPlanItems[i]
          const { id: cPlanId, cpi_id: cId, cpi_category_id: cCategoryId, cpi_item_id: cItemId } = cItem
          const toUpdateItems = cClientFundingItems.filter(({ plan_id: planId, cfi_category_id: categoryId, cfi_item_id: itemId }) => (
            `${planId || ''}-${categoryId || ''}-${itemId || ''}` === `${cPlanId || ''}-${cCategoryId || ''}-${cItemId || ''}`
          ))
          const vItem = changedItemLookup.get(cId)
          const hasVItem = validator.isObject(vItem) && validator.isId(vItem.id)

          for (const toUpdateItem of toUpdateItems) {
            const item = { ...toUpdateItem }
            const { cfi_id: id } = item

            if (hasVItem) {
              // Mirror plan period item changes into funding period item
              const {
                category_id: vCategoryId, category_number: vCategoryNumber, item_id: vItemId, item_number: vItemNumber,
                allocated_amount: vAllocatedAmount, available_amount: vAvailableAmount, carry_over_amount: vCarryOverAmount,
                spent_amount: vSpentAmount, amount: vAmount, is_diff_allocated: vIsDiffAllocated
              } = vItem
              item.client_id = vClientId
              item.start_date = vStartDate
              item.end_date = vEndDate
              item.is_pm_fee = vIsPmFee
              item.setup_cost_invoiced = vSetupCostInvoiced
              item.monthly_fee_invoiced = vMonthlyFeeInvoiced
              item.setup_cost_reason = vSetupCostReasaon
              item.monthly_fee_reason = vMonthlyFeeReason
              item.active = vActive
              item.cfi_category_id = vCategoryId
              item.cfi_category_number = vCategoryNumber
              item.cfi_item_id = vItemId
              item.cfi_item_number = vItemNumber
              item.cfi_allocated_amount = vAllocatedAmount
              item.cfi_available_amount = vAvailableAmount
              item.cfi_carry_over_amount = vCarryOverAmount
              item.cfi_spent_amount = vSpentAmount
              item.cfi_amount = vAmount
              item.cfi_is_diff_allocated = vIsDiffAllocated
            } else {
              // Mark funding period item as deleted
              item.deleted = true
            }

            changedItemMap.set(id, item)
          }
        }

        if (changedItemMap.size > 0) {
          // Transform funding period changes into the correct format
          clonedValues.client_fundings = getUpdatedFundingPeriodItems(addedItems, Array.from(changedItemMap.values()))
        }

        return clonedValues
      } else {
        // Copy plan period changes as new default funding period
        return newFundingPeriodFromPlanPeriod(clonedValues)
      }
    }
  }

  return clonedValues
}

function updatePlanPeriodItemAmounts (planPeriodItem, { allocatedAmount, availableAmount, carryOverAmount, isDiffAllocated }) {
  const _allocatedAmount = toBigNumber(allocatedAmount).toFixed(2)
  const _availableAmount = toBigNumber(isDiffAllocated === true ? availableAmount : allocatedAmount).toFixed(2)
  const _carryOverAmount = toBigNumber(carryOverAmount).toFixed(2)
  planPeriodItem.cpi_allocated_amount = _allocatedAmount
  planPeriodItem.cpi_available_amount = _availableAmount
  planPeriodItem.cpi_carry_over_amount = _carryOverAmount
  planPeriodItem.cpi_spent_amount = toBigNumber(_allocatedAmount).minus(_availableAmount).toFixed(2)
  planPeriodItem.cpi_amount = toBigNumber(_availableAmount).plus(_carryOverAmount).toFixed(2)
  planPeriodItem.cpi_is_diff_allocated = isDiffAllocated

  if (planPeriodItem._ida_toggled !== true) {
    planPeriodItem._ida_toggled = true
  }

  return planPeriodItem
}

function validateAmount (label, value) {
  if (!validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)) {
    const amount = formatter.toBigNumber(value)

    if (
      !validator.isCurrencyAmount(value) || amount.isNaN() || !amount.isFinite() || amount.decimalPlaces() > 2
    ) {
      return new Error('Please enter a number with 2 decimal places')
    } else if (amount.isLessThan(0)) {
      return new Error(`${validator.isEmptyString(label, true) ? 'Amount' : label} must be greater than 0`)
    }
  }

  return
}

function getPlanPeriodItemColumns (
  categories, categoryItemsMap, form, hasAccess, isEdit, loadingDropdown, onAdd, onRemove, onChangeAllocatedAmount,
  onChangeAvailableAmount, onChangeCategory, onChangeCategoryItem, onChangeCarryOverAmount, onChangeIsDiffAllocated,
  rows
) {
  const { getFieldDecorator, getFieldError, getFieldValue, getFieldsValue, setFieldsValue, validateFields } = form || {}
  const categoryLookup = new Map(categories.map((category) => [category.category_number, category]))
  const fvStartDate = getFieldValue('start_date')
  const fvEndDate = getFieldValue('end_date')

  const changeAllocatedAmount = (idx, isFlexible) => ({ target }) => {
    if (typeof onChangeAllocatedAmount === 'function') {
      const { value } = target || {}
      onChangeAllocatedAmount(idx, value)
    }

    const timer = setTimeout(() => {
      clearTimeout(timer)
      const { client_plan_items: fvClientPlanItems } = getFieldsValue()
      const fieldNameAA = getPlanPeriodItemFieldName(idx, ListItemField.AvailableAmount)
      const fieldNameCOA = getPlanPeriodItemFieldName(idx, ListItemField.CarryOverAmount)
      const toValidateList = [fieldNameAA, fieldNameCOA]

      if (isFlexible) {
        for (let i = 0; i < fvClientPlanItems.length; i++) {
          if (i !== idx) {
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.AllocatedAmount))
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.AvailableAmount))
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.CarryOverAmount))
          }
        }
      }

      validateFields(toValidateList, { force: true }).catch(() => { })
    }, 50)
  }

  const changeAvailableAmount = (idx, isFlexible) => ({ target }) => {
    if (typeof onChangeAvailableAmount === 'function') {
      const { value } = target || {}
      onChangeAvailableAmount(idx, value)
    }

    const timer = setTimeout(() => {
      clearTimeout(timer)
      const { client_plan_items: fvClientPlanItems } = getFieldsValue()
      const fieldNameAA = getPlanPeriodItemFieldName(idx, ListItemField.AllocatedAmount)
      const fieldNameCOA = getPlanPeriodItemFieldName(idx, ListItemField.CarryOverAmount)
      const toValidateList = [fieldNameAA, fieldNameCOA]

      if (isFlexible) {
        for (let i = 0; i < fvClientPlanItems.length; i++) {
          if (i !== idx) {
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.AllocatedAmount))
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.AvailableAmount))
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.CarryOverAmount))
          }
        }
      }

      validateFields(toValidateList, { force: true }).catch(() => { })
    }, 50)
  }

  const changeCarryOverAmount = (idx, isFlexible) => ({ target }) => {
    if (typeof onChangeCarryOverAmount === 'function') {
      const { value } = target || {}
      onChangeCarryOverAmount(idx, value)
    }

    const timer = setTimeout(() => {
      clearTimeout(timer)
      const { client_plan_items: fvClientPlanItems } = getFieldsValue()
      const fieldNameAlA = getPlanPeriodItemFieldName(idx, ListItemField.AllocatedAmount)
      const fieldNameAvA = getPlanPeriodItemFieldName(idx, ListItemField.AvailableAmount)
      const toValidateList = [fieldNameAlA, fieldNameAvA]

      if (isFlexible) {
        for (let i = 0; i < fvClientPlanItems.length; i++) {
          if (i !== idx) {
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.AllocatedAmount))
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.AvailableAmount))
            toValidateList.push(getPlanPeriodItemFieldName(i, ListItemField.CarryOverAmount))
          }
        }
      }

      validateFields(toValidateList, { force: true }).catch(() => { })
    }, 50)
  }

  const changeCategory = (idx) => (value) => {
    const { id, category_group_id: categoryGroupId, funding_type: fundingType } = categoryLookup.get(value) || {}
    setFieldsValue({
      [getPlanPeriodItemFieldName(idx, ListItemField.CategoryId)]: id,
      [getPlanPeriodItemFieldName(idx, ListItemField.CategoryGroupId)]: categoryGroupId,
      [getPlanPeriodItemFieldName(idx, ListItemField.FundingType)]: fundingType,
      [getPlanPeriodItemFieldName(idx, ListItemField.ItemId)]: undefined,
      [getPlanPeriodItemFieldName(idx, ListItemField.ItemNumber)]: undefined
    })

    if (typeof onChangeCategory === 'function') {
      onChangeCategory(idx, value, {
        categoryId: id, categoryGroupId, fundingType, itemId: undefined, itemNumber: undefined, startDate: fvStartDate,
        endDate: fvEndDate
      })
    }
  }

  const changeCategoryItem = (idx) => (value) => {
    const categoryItems = Array.isArray(categoryItemsMap[idx]) ? categoryItemsMap[idx] : []
    const categoryItemLookup = new Map(categoryItems.map((categoryItem) => [categoryItem.item_number, categoryItem]))
    const { id } = categoryItemLookup.get(value) || {}
    setFieldsValue({ [getPlanPeriodItemFieldName(idx, ListItemField.ItemId)]: id })

    if (typeof onChangeCategoryItem === 'function') {
      onChangeCategoryItem(idx, value, { itemId: id })
    }
  }

  const changeIsDiffAllocated = (idx) => (value) => {
    if (typeof onChangeIsDiffAllocated === 'function') {
      onChangeIsDiffAllocated(idx, value)
    }

    const timer = setTimeout(() => {
      clearTimeout(timer)
      const fieldNameAA = getPlanPeriodItemFieldName(idx, ListItemField.AllocatedAmount)
      const fieldNameCOA = getPlanPeriodItemFieldName(idx, ListItemField.CarryOverAmount)
      validateFields([fieldNameAA, fieldNameCOA], { force: true }).catch(() => { })
    }, 50)
  }

  const validateAllocatedAmount = (record, isFlexible, rows) => (rule, value, callback) => {
    const {
      cpi_id: cpiId, cpi_category_group_id: categoryGroupId, cpi_available_amount: availableAmount,
      cpi_carry_over_amount: carryOverAmount, cpi_is_diff_allocated: isDiffAllocated, total_received: totalReceived,
      total_credit: totalCredit, total_spent: totalSpent, forecast_spent: forecastSpent
    } = record
    const error = validateAmount(ListItemValidateLabel.AvailableAmount, value)
    const hasValue = !validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)
    const allocatedAmount = toBigNumber(value)
    const _availableAmount = toBigNumber(availableAmount)

    if (error) {
      callback(error)
    } else if (hasValue && isDiffAllocated === true && _availableAmount.isGreaterThan(allocatedAmount)) {
      callback(new Error('Allocated amount must be greater than or equal to available amount'))
    } else if (hasValue) {
      const _carryOverAmount = toBigNumber(carryOverAmount)
      const spentAmount = allocatedAmount.minus(isDiffAllocated === true ? _availableAmount : allocatedAmount)
      const _forecastSpent = getDisplayForecastSpent(spentAmount, totalReceived, totalCredit, totalSpent, forecastSpent)
      const amount = allocatedAmount.plus(_carryOverAmount)
      let isLessThanForecastedSpent = _forecastSpent.isGreaterThan(amount)

      if (isFlexible) {
        const { flexibleMap } = groupPlanPeriodItems(rows.filter(({ cpi_id: _cpiId }) => _cpiId !== cpiId))
        const {
          cpi_allocated_amount: fAllocatedAmount, cpi_available_amount: fAvailableAmount, cpi_carry_over_amount: fCarryOverAmount,
          total_received: fTotalReceived, total_credit: fTotalCredit, total_spent: fTotalSpent, forecast_spent: fForecastSpent
        } = flexibleMap.get(categoryGroupId) || {}
        const _fAllocatedAmount = toBigNumber(fAllocatedAmount).plus(allocatedAmount)
        const _fAvailableAmount = toBigNumber(fAvailableAmount).plus(availableAmount)
        const _fCarryOverAmount = toBigNumber(fCarryOverAmount).plus(carryOverAmount)
        const _fTotalReceived = toBigNumber(fTotalReceived).plus(totalReceived)
        const _fTotalCredit = toBigNumber(fTotalCredit).plus(totalCredit)
        const _fTotalSpent = toBigNumber(fTotalSpent).plus(totalSpent)
        const _fForecastSpent = toBigNumber(fForecastSpent).plus(forecastSpent)
        const spentAmount = _fAllocatedAmount.minus(isDiffAllocated === true ? _fAvailableAmount : _fAllocatedAmount)
        const _forecastSpent = getDisplayForecastSpent(spentAmount, _fTotalReceived, _fTotalCredit, _fTotalSpent, _fForecastSpent)
        const amount = _fAllocatedAmount.plus(_fCarryOverAmount)
        isLessThanForecastedSpent = isLessThanForecastedSpent && _forecastSpent.isGreaterThan(amount)
      }

      if (isLessThanForecastedSpent && _forecastSpent.isGreaterThan(0)) {
        callback(new Error(
          `${ListItemValidateLabel.Amount} must be greater than or equal to forecasted spent (${_forecastSpent.toFormat(2)})`
        ))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  const validateAvailableBalance = (record, isFlexible, rows) => (rule, value, callback) => {
    const {
      cpi_id: cpiId, cpi_category_group_id: categoryGroupId, cpi_allocated_amount: allocatedAmount,
      cpi_carry_over_amount: carryOverAmount, cpi_is_diff_allocated: isDiffAllocated, total_received: totalReceived,
      total_credit: totalCredit, total_spent: totalSpent, forecast_spent: forecastSpent
    } = record
    const error = validateAmount(ListItemValidateLabel.AllocatedAmount, value)
    const hasValue = !validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)
    const _allocatedAmount = toBigNumber(allocatedAmount)
    const availableAmount = toBigNumber(value)

    if (error) {
      callback(error)
    } else if (hasValue && isDiffAllocated === true && availableAmount.isGreaterThan(_allocatedAmount)) {
      callback(new Error('Available Balance must be less than or equal to allocated amount'))
    } else if (hasValue) {
      const _carryOverAmount = toBigNumber(carryOverAmount)
      const spentAmount = _allocatedAmount.minus(isDiffAllocated === true ? availableAmount : _allocatedAmount)
      const _forecastSpent = getDisplayForecastSpent(spentAmount, totalReceived, totalCredit, totalSpent, forecastSpent)
      const amount = _allocatedAmount.plus(_carryOverAmount)
      let isLessThanForecastedSpent = _forecastSpent.isGreaterThan(amount)

      if (isFlexible) {
        const { flexibleMap } = groupPlanPeriodItems(rows.filter(({ cpi_id: _cpiId }) => _cpiId !== cpiId))
        const {
          cpi_allocated_amount: fAllocatedAmount, cpi_available_amount: fAvailableAmount, cpi_carry_over_amount: fCarryOverAmount,
          total_received: fTotalReceived, total_credit: fTotalCredit, total_spent: fTotalSpent, forecast_spent: fForecastSpent
        } = flexibleMap.get(categoryGroupId) || {}
        const _fAllocatedAmount = toBigNumber(fAllocatedAmount).plus(_allocatedAmount)
        const _fAvailableAmount = toBigNumber(fAvailableAmount).plus(availableAmount)
        const _fCarryOverAmount = toBigNumber(fCarryOverAmount).plus(carryOverAmount)
        const _fTotalReceived = toBigNumber(fTotalReceived).plus(totalReceived)
        const _fTotalCredit = toBigNumber(fTotalCredit).plus(totalCredit)
        const _fTotalSpent = toBigNumber(fTotalSpent).plus(totalSpent)
        const _fForecastSpent = toBigNumber(fForecastSpent).plus(forecastSpent)
        const spentAmount = _fAllocatedAmount.minus(isDiffAllocated === true ? _fAvailableAmount : _fAllocatedAmount)
        const _forecastSpent = getDisplayForecastSpent(spentAmount, _fTotalReceived, _fTotalCredit, _fTotalSpent, _fForecastSpent)
        const amount = _fAllocatedAmount.plus(_fCarryOverAmount)
        isLessThanForecastedSpent = isLessThanForecastedSpent && _forecastSpent.isGreaterThan(amount)
      }

      if (isLessThanForecastedSpent && _forecastSpent.isGreaterThan(0)) {
        callback(new Error(
          `${ListItemValidateLabel.Amount} must be greater than or equal to forecasted spent (${_forecastSpent.toFormat(2)})`
        ))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  const validateCarryOverAmount = (record, isFlexible, rows) => (rule, value, callback) => {
    const {
      cpi_id: cpiId, cpi_category_group_id: categoryGroupId, cpi_allocated_amount: allocatedAmount, cpi_available_amount: availableAmount,
      cpi_is_diff_allocated: isDiffAllocated, total_received: totalReceived, total_credit: totalCredit, total_spent: totalSpent,
      forecast_spent: forecastSpent
    } = record
    const error = validateAmount(ListItemValidateLabel.CarryOverAmount, value)
    const hasValue = !validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)
    const _allocatedAmount = toBigNumber(allocatedAmount)
    const carryOverAmount = toBigNumber(value)
    const _availableAmount = toBigNumber(availableAmount)

    if (error) {
      callback(error)
    } else if (hasValue) {
      const _carryOverAmount = toBigNumber(carryOverAmount)
      const spentAmount = _allocatedAmount.minus(isDiffAllocated === true ? _availableAmount : _allocatedAmount)
      const _forecastSpent = getDisplayForecastSpent(spentAmount, totalReceived, totalCredit, totalSpent, forecastSpent)
      const amount = _allocatedAmount.plus(_carryOverAmount)
      let isLessThanForecastedSpent = _forecastSpent.isGreaterThan(amount)

      if (isFlexible) {
        const { flexibleMap } = groupPlanPeriodItems(rows.filter(({ cpi_id: _cpiId }) => _cpiId !== cpiId))
        const {
          cpi_allocated_amount: fAllocatedAmount, cpi_available_amount: fAvailableAmount, cpi_carry_over_amount: fCarryOverAmount,
          total_received: fTotalReceived, total_credit: fTotalCredit, total_spent: fTotalSpent, forecast_spent: fForecastSpent
        } = flexibleMap.get(categoryGroupId) || {}
        const _fAllocatedAmount = toBigNumber(fAllocatedAmount).plus(_allocatedAmount)
        const _fAvailableAmount = toBigNumber(fAvailableAmount).plus(availableAmount)
        const _fCarryOverAmount = toBigNumber(fCarryOverAmount).plus(carryOverAmount)
        const _fTotalReceived = toBigNumber(fTotalReceived).plus(totalReceived)
        const _fTotalCredit = toBigNumber(fTotalCredit).plus(totalCredit)
        const _fTotalSpent = toBigNumber(fTotalSpent).plus(totalSpent)
        const _fForecastSpent = toBigNumber(fForecastSpent).plus(forecastSpent)
        const spentAmount = _fAllocatedAmount.minus(isDiffAllocated === true ? _fAvailableAmount : _fAllocatedAmount)
        const _forecastSpent = getDisplayForecastSpent(spentAmount, _fTotalReceived, _fTotalCredit, _fTotalSpent, _fForecastSpent)
        const amount = _fAllocatedAmount.plus(_fCarryOverAmount)
        isLessThanForecastedSpent = isLessThanForecastedSpent && _forecastSpent.isGreaterThan(amount)
      }

      if (isLessThanForecastedSpent && _forecastSpent.isGreaterThan(0)) {
        callback(new Error(
          `${ListItemValidateLabel.Amount} must be greater than or equal to forecasted spent (${_forecastSpent.toFormat(2)})`
        ))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  return Object.freeze([
    {
      title: (
        <div className='button-box'>
          <div className='label'>Support Category</div>

          <Tooltip mouseLeaveDelay={0} title="Add Support Category">
            <Icon className='btn-icon' type="plus-circle" onClick={typeof onAdd === 'function' ? onAdd : null} />
          </Tooltip>
        </div>
      ),
      width: 6,
      render: (record, idx) => {
        const { cpi_category_id: categoryId, cpi_category_number: categoryNumber } = record
        const disabled = isEdit && !validator.isNullOrUndefined(categoryNumber)
        const categoryLookup = new Map(categories.map((category) => [category.category_number, category]))
        const clCategoryNumber = !validator.isNullOrUndefined(categoryNumber)
          ? categoryNumber : idx === 0 ? PmFeeCategoryNumber : undefined
        const category = !validator.isNullOrUndefined(clCategoryNumber) ? (categoryLookup.get(clCategoryNumber) || {}) : {}
        const fieldName = getPlanPeriodItemFieldName(idx, ListItemField.CategoryNumber)
        const error = getFieldError(fieldName)
        const initialValue = clCategoryNumber
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.CategoryId), { initialValue: categoryId || category.id })
        return renderColumnSupportCategory({
          categories, disabled, error, fieldName, getFieldDecorator, initialValue, loadingDropdown, showExtra: isEdit,
          onChange: changeCategory(idx)
        })
      }
    },
    {
      title: 'Support Item',
      width: 5,
      render: (record, idx) => {
        const { cpi_id: id, cpi_item_id: itemId, cpi_item_number: itemNumber } = record
        const categoryItems = Array.isArray(categoryItemsMap[idx]) ? categoryItemsMap[idx] : []
        const disabled = isEdit && validator.isId(id) && !validator.isNullOrUndefined(itemNumber)
        const categoryItemLookup = new Map(categoryItems.map((categoryItem) => [categoryItem.item_number, categoryItem]))
        const cilItemNumber = !validator.isNullOrUndefined(itemNumber) ? itemNumber : undefined
        const categoryItem = !validator.isNullOrUndefined(cilItemNumber) ? (categoryItemLookup.get(cilItemNumber) || {}) : {}
        const fieldName = getPlanPeriodItemFieldName(idx, ListItemField.ItemNumber)
        const error = getFieldError(fieldName)
        const initialValue = cilItemNumber
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.ItemId), { initialValue: itemId || categoryItem.id })
        return renderColumnSupportItem({
          categoryItems, disabled, error, fieldName, getFieldDecorator, initialValue, loadingDropdown, showExtra: isEdit,
          onChange: changeCategoryItem(idx)
        })
      }
    },
    {
      title: 'Allocated Amount',
      width: 3,
      render: (record, idx) => {
        const { cpi_funding_type: fundingType, cpi_allocated_amount: allocatedAmount } = record
        const _allocatedAmount = formatter.toBigNumber(allocatedAmount)
        const fieldName = getPlanPeriodItemFieldName(idx, ListItemField.AllocatedAmount)
        const error = getFieldError(fieldName)
        const isFlexible = fundingType === FundingTypeFlexible
        return renderColumnAllocatedAmount({
          allocatedAmount: _allocatedAmount, getFieldDecorator, error, fieldName, showExtra: isEdit,
          onChange: changeAllocatedAmount(idx, isFlexible),
          onValidate: validateAllocatedAmount(record, isFlexible, rows)
        })
      }
    },
    {
      title: 'Available Balance',
      width: 4,
      render: (record, idx) => {
        const {
          cpi_funding_type: fundingType, cpi_allocated_amount: allocatedAmount, cpi_available_amount: availableAmount,
          cpi_is_diff_allocated: isDiffAllocated, _ida_toggled: idaToggled
        } = record
        const _allocatedAmount = formatter.toBigNumber(allocatedAmount)
        const _availableAmount = formatter.toBigNumber(availableAmount)
        const fieldNameAA = getPlanPeriodItemFieldName(idx, ListItemField.AvailableAmount)
        const fieldNameIDA = getPlanPeriodItemFieldName(idx, ListItemField.IsDiffAllocated)
        const error = getFieldError(fieldNameAA)
        const isFlexible = fundingType === FundingTypeFlexible

        if (isDiffAllocated !== true) {
          getFieldDecorator(fieldNameAA, {
            initialValue: !_allocatedAmount.isNaN() && _allocatedAmount.isGreaterThan(0) ? _allocatedAmount.toFormat(2) : undefined
          })
        }

        return renderColumnAvailableBalance({
          availableAmount: _availableAmount, error, fieldNameAA, fieldNameIDA, getFieldDecorator, idaToggled, isDiffAllocated,
          showExtra: isEdit,
          onChangeAvailableAmount: changeAvailableAmount(idx, isFlexible),
          onChangeIsDiffAllocated: changeIsDiffAllocated(idx),
          onValidate: validateAvailableBalance(record, isFlexible, rows)
        })
      }
    },
    {
      title: 'Carry Over Amount',
      width: 3,
      render: (record, idx) => {
        const {
          cpi_funding_type: fundingType, cpi_carry_over_amount: carryOverAmount
        } = record
        const _carryOverAmount = formatter.toBigNumber(carryOverAmount)
        const fieldName = getPlanPeriodItemFieldName(idx, ListItemField.CarryOverAmount)
        const error = getFieldError(fieldName)
        const isFlexible = fundingType === FundingTypeFlexible
        return renderColumnCarryOverAmount({
          carryOverAmount: _carryOverAmount, error, fieldName, getFieldDecorator, showExtra: isEdit,
          onChange: changeCarryOverAmount(idx, isFlexible),
          onValidate: validateCarryOverAmount(record, isFlexible, rows)
        })
      }
    },
    {
      title: (
        <>
          <div>Spent</div>
          {isEdit ? <div>(Forecast)</div> : null}
        </>
      ),
      width: 2,
      render: (record, idx) => {
        const {
          cpi_spent_amount: spentAmount, total_received: totalReceived, total_credit: totalCredit, total_spent: totalSpent,
          forecast_spent: forecastSpent
        } = record
        const _spentAmount = toBigNumber(spentAmount)
        const _forecastSpent = getDisplayForecastSpent(_spentAmount, totalReceived, totalCredit, totalSpent, forecastSpent)
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.SpentAmount), { initialValue: _spentAmount.toFixed(2) })
        return renderColumnSpent({ forecastSpent: _forecastSpent, showExtra: isEdit, spentAmount: _spentAmount })
      }
    },
    {
      title: 'Action',
      width: 1,
      render: (record, idx) => {
        const { id: planId, cpi_id: id, cpi_category_number: categoryNumber, cpi_amount: amount, cpi_is_pm_fee: isPmFee } = record
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.Id), { initialValue: id })
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.PlanId), { initialValue: planId })
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.Amount), { initialValue: toBigNumber(amount).toFixed(2) })
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.IsPmFee), {
          initialValue: !validator.isNullOrUndefined(categoryNumber) ? categoryNumber === PmFeeCategoryNumber : !!isPmFee
        })
        return renderColumnAction({ hasAccess, showExtra: isEdit, onRemove: onRemove(idx) })
      }
    }
  ])
}

function PlanPeriodItemList ({
  categories, categoryItemsMap, form, hasAccess, isEdit, listItems, loading, loadingDropdown, onAdd, onRemove, onChangeAllocatedAmount,
  onChangeAvailableAmount, onChangeCategory, onChangeCategoryItem, onChangeCarryOverAmount, onChangeIsDiffAllocated
}) {
  const rows = Array.isArray(listItems)
    ? listItems.sort((a, b) => {
      const { cpi_category_sorting: aCategorySorting, cpi_item_sorting: aItemSorting, cpi_is_pm_fee: aIsPmFee } = a
      const { cpi_category_sorting: bCategorySorting, cpi_item_sorting: bItemSorting, cpi_is_pm_fee: bIsPmFee } = b
      return sortListItem({ aIsPmFee, aCategorySorting, aItemSorting }, { bIsPmFee, bCategorySorting, bItemSorting })
    })
    : []

  return (
    <Skeleton active loading={loading}>
      {rows.length < 1 ? (
        <Alert className='pp-alert' description={<b>{NoPlanPeriodItemAddedMsg}</b>} type='error' />
      ) : null}

      <List
        cols={getPlanPeriodItemColumns(
          categories, categoryItemsMap, form, hasAccess, isEdit, loadingDropdown, onAdd, onRemove, onChangeAllocatedAmount,
          onChangeAvailableAmount, onChangeCategory, onChangeCategoryItem, onChangeCarryOverAmount, onChangeIsDiffAllocated,
          rows
        )}
        rows={rows}
      />
    </Skeleton>
  )
}

function PlanPeriodItemForm ({
  categories, categoryItemsMap, clientPlanItems, hasAccess, isEdit, loading, loadingDropdown, props, setItem, onChangeCategory
}) {
  const { form } = props || {}

  const addListItem = useCallback(() => {
    if (typeof setItem === 'function') {
      setItem((item) => {
        const { client_plan_items: clientPlanItems } = item
        item.client_plan_items = Array.isArray(clientPlanItems) ? clientPlanItems.concat([{}]) : [{}]
        return { ...item }
      })
    }
  }, [setItem])

  const removeListItem = useCallback((idx) => () => {
    const item = clientPlanItems[idx]
    const {
      cpi_category_number: categoryNumber, cpi_item_number: itemNumber, total_received: totalReceived, total_spent: totalSpent,
      forecast_spent: forecastSpent
    } = item || {}
    const label = categoryNumber || itemNumber
      ? ` ${categoryNumber ? `(${categoryNumber})` : ''}${categoryNumber && itemNumber ? ' ' : ''}${itemNumber ? `${itemNumber}` : ''}`
      : ''
    const cpiCanDelete = !(BigNumber(totalReceived).isGreaterThan(0) || BigNumber(forecastSpent).minus(totalSpent).isGreaterThan(0))

    if (cpiCanDelete) {
      Modal.confirm({
        title: `Are you sure you want to delete this support category${label}?`,
        content: 'Press Ok to continue, Cancel to return',
        onOk: () => {
          if (typeof setItem === 'function') {
            setItem((item) => {
              const { client_plan_items: clientPlanItems } = item
              item.client_plan_items = Array.isArray(clientPlanItems) ? clientPlanItems.filter((_, _idx) => _idx !== idx) : []
              return { ...item }
            })
          }
        }
      })
    } else {
      Modal.error({
        title: `Unable to delete support category${label}`,
        content: 'Please cancel all invoices associated with this support category before deleting the support category'
      })
    }
  }, [setItem, clientPlanItems])

  const changeAllocatedAmount = useCallback((idx, value) => {
    setItem((item) => {
      const { client_plan_items: clientPlanItems } = item

      if (Array.isArray(clientPlanItems) && clientPlanItems.length > 0) {
        const cpi = item.client_plan_items[idx]
        const {
          cpi_available_amount: availableAmount, cpi_carry_over_amount: carryOverAmount, cpi_is_diff_allocated: isDiffAllocated
        } = cpi
        item.client_plan_items[idx] = updatePlanPeriodItemAmounts(cpi, {
          allocatedAmount: value, availableAmount, carryOverAmount, isDiffAllocated
        })
      }

      return { ...item }
    })
  }, [setItem])

  const changeAvailableAmount = useCallback((idx, value) => {
    setItem((item) => {
      const { client_plan_items: clientPlanItems } = item

      if (Array.isArray(clientPlanItems) && clientPlanItems.length > 0) {
        const cpi = item.client_plan_items[idx]
        const {
          cpi_allocated_amount: allocatedAmount, cpi_carry_over_amount: carryOverAmount, cpi_is_diff_allocated: isDiffAllocated
        } = cpi
        item.client_plan_items[idx] = updatePlanPeriodItemAmounts(cpi, {
          allocatedAmount, availableAmount: value, carryOverAmount, isDiffAllocated
        })
      }

      return { ...item }
    })
  }, [setItem])

  const changeCategoryItem = useCallback((idx, value, { itemId }) => {
    setItem((item) => {
      const { client_plan_items: clientPlanItems } = item

      if (Array.isArray(clientPlanItems) && clientPlanItems.length > 0) {
        const cpi = item.client_plan_items[idx]
        cpi.cpi_item_id = itemId
        cpi.cpi_item_number = value
        item.client_plan_items[idx] = cpi
      }

      return { ...item }
    })
  }, [setItem])

  const changeCarryOverAmount = useCallback((idx, value) => {
    setItem((item) => {
      const { client_plan_items: clientPlanItems } = item

      if (Array.isArray(clientPlanItems) && clientPlanItems.length > 0) {
        const cpi = item.client_plan_items[idx]
        const {
          cpi_allocated_amount: allocatedAmount, cpi_available_amount: availableAmount, cpi_is_diff_allocated: isDiffAllocated
        } = cpi
        item.client_plan_items[idx] = updatePlanPeriodItemAmounts(cpi, {
          allocatedAmount, availableAmount, carryOverAmount: value, isDiffAllocated
        })
      }

      return { ...item }
    })
  }, [setItem])

  const changeIsDiffAllocated = useCallback((idx, value) => {
    setItem((item) => {
      const { client_plan_items: clientPlanItems } = item

      if (Array.isArray(clientPlanItems) && clientPlanItems.length > 0) {
        const cpi = item.client_plan_items[idx]
        const {
          cpi_allocated_amount: allocatedAmount, cpi_available_amount: availableAmount, cpi_carry_over_amount: carryOverAmount
        } = cpi
        item.client_plan_items[idx] = updatePlanPeriodItemAmounts(cpi, {
          allocatedAmount, availableAmount, carryOverAmount, isDiffAllocated: value
        })
      }

      return { ...item }
    })
  }, [setItem])

  return (
    <div className='cf-list'>
      <PlanPeriodItemList
        categories={categories} categoryItemsMap={categoryItemsMap} form={form} hasAccess={hasAccess} isEdit={isEdit}
        listItems={clientPlanItems} loading={loading} loadingDropdown={loadingDropdown} onAdd={addListItem} onRemove={removeListItem}
        onChangeAllocatedAmount={changeAllocatedAmount} onChangeAvailableAmount={changeAvailableAmount}
        onChangeCategory={onChangeCategory} onChangeCategoryItem={changeCategoryItem} onChangeCarryOverAmount={changeCarryOverAmount}
        onChangeIsDiffAllocated={changeIsDiffAllocated}
      />
    </div>
  )
}

function PlanPeriodForm ({ clientId, clonedItem, hasAccess, init, isEdit, item, loading, props, roleStartDate, setItem }) {
  const {
    start_date: iStartDate, end_date: iEndDate, role_start_date: iRoleStartDate, role_end_date: iRoleEndDate, iActive,
    client_plan_items: clientPlanItems
  } = item
  const { form, match } = props || {}
  const { params } = match || {}
  const { getFieldDecorator, getFieldError, getFieldValue, validateFields } = form || {}
  const { id, clientRefId } = params || {}
  const endDateErrors = getFieldError('end_date')
  const startDateErrors = getFieldError('start_date')
  const endDate = getFieldValue('end_date')
  const startDate = getFieldValue('start_date')
  const [categories, setCategories] = useState([])
  const [categoryItemsMap, setCategoryItemsMap] = useState({})
  const [extraEndDate, setExtraEndDate] = useState()
  const [extraStartDate, setExtraStartDate] = useState()
  const [helpEndDate, setHelpEndDate] = useState()
  const [helpStartDate, setHelpStartDate] = useState()
  const [loadingDropdown, setLoadingDropdown] = useState(false)
  const [loadingEndDate, setLoadingEndDate] = useState(false)
  const [loadingStartDate, setLoadingStartDate] = useState(false)
  const [revalidateFieldName, setRevalidateFieldName] = useState()
  const [validateStatusEndDate, setValidateStatusEndDate] = useState()
  const [validateStatusStartDate, setValidateStatusStartDate] = useState()
  const showForm = startDate && endDate && !startDateErrors && !endDateErrors
  getFieldDecorator('client_id', { initialValue: clientId })

  const changeCategory = useCallback((idx, categoryNumber, {
    categoryId, categoryGroupId, fundingType, itemId, itemNumber, startDate, endDate
  }) => {
    if (
      !validator.isNullOrUndefined(startDate) && !validator.isNullOrUndefined(endDate) &&
      Array.isArray(clientPlanItems) && clientPlanItems.length > 0
    ) {
      setItem((item) => {
        const cpi = item.client_plan_items[idx]
        cpi.cpi_category_id = categoryId
        cpi.cpi_category_number = categoryNumber
        cpi.cpi_category_group_id = categoryGroupId
        cpi.cpi_funding_type = fundingType
        cpi.cpi_item_id = itemId
        cpi.cpi_item_number = itemNumber
        item.client_plan_items[idx] = cpi
        return { ...item }
      })
      setLoadingDropdown(true)
      pmRateSetCategoryItemPaceService
        .getAllUniques({ category_number: categoryNumber }, { start_date: startDate, end_date: endDate })
        .then((categoryItemResponse) => {
          if (Array.isArray(categoryItemResponse)) {
            const _categoryItemsMap = Object.assign({}, categoryItemsMap)
            _categoryItemsMap[idx] = categoryItemResponse
            setCategoryItemsMap(_categoryItemsMap)
          }
        })
        .finally(() => {
          setLoadingDropdown(false)
        })
    }
  }, [setItem, categoryItemsMap, clientPlanItems])

  const getCategories = useCallback((startDate, endDate) => {
    if (!validator.isNullOrUndefined(startDate) && !validator.isNullOrUndefined(endDate)) {
      setLoadingDropdown(true)
      pmRateSetCategoryPaceService
        .getAllUniques({}, { start_date: startDate, end_date: endDate })
        .then((categoryResponse) => {
          if (Array.isArray(categoryResponse)) {
            const { client_plan_items: clientPlanItems } = item
            setCategories(categoryResponse)

            if (Array.isArray(clientPlanItems) && clientPlanItems.length > 0) {
              for (let i = 0; i < clientPlanItems.length; i++) {
                const {
                  cpi_category_id: categoryId, cpi_category_number: categoryNumber, cpi_item_id: itemId, cpi_item_number: itemNumber
                } = clientPlanItems[i]
                changeCategory(i, categoryNumber, { categoryId, itemId, itemNumber, startDate, endDate })
              }
            }
          }
        })
        .finally(() => {
          setLoadingDropdown(false)
        })
    }
  }, [changeCategory, item])

  const revalidateField = useCallback((callback, fieldName) => {
    validateFields([fieldName], { force: true }).catch(() => { }).finally(() => {
      callback()
    })
  }, [validateFields])

  const changeEndDate = useCallback((value) => {
    if (typeof setItem === 'function') {
      setItem((item) => {
        item.end_date = value
        return { ...item }
      })
    }

    getCategories(iStartDate, value)
  }, [getCategories, setItem, iStartDate])

  const changeStartDate = useCallback((value) => {
    if (typeof setItem === 'function') {
      setItem((item) => {
        item.start_date = value
        return { ...item }
      })
    }

    getCategories(value, iEndDate)
  }, [getCategories, setItem, iEndDate])

  const validateEndDate = useCallback(async (rule, value, callback) => {
    const fvStartDate = getFieldValue('start_date')

    if (validator.isDate(fvStartDate) && validator.isDate(value)) {
      const startDate = moment(fvStartDate).startOf('day')
      const endDate = moment(value).endOf('day')
      const rfn = revalidateFieldName
      setLoadingEndDate(true)

      if (validator.isNullOrUndefined(revalidateFieldName)) {
        setRevalidateFieldName('end_date')
      }

      try {
        setExtraEndDate()
        const { error, extra, help, validateStatus } = await periodValidator.validateEndDate({
          startDate,
          endDate,
          item: clonedItem,
          onCheckDate: () => clientPlanPaceService.checkDates(startDate.toISOString(), endDate.toISOString(), id, clientId),
          onGetLink: (id) => `/participants/${clientRefId}/plan-period-pace/${id}`
        })
        callback(error)
        setExtraEndDate(extra)
        setHelpEndDate(help)
        setLoadingEndDate(false)
        setValidateStatusEndDate(validateStatus)

        if (rfn !== 'start_date') {
          revalidateField(callback, 'start_date')
        } else if (!error && getFieldError('start_date')) {
          setRevalidateFieldName('end_date')
        }
      } catch (e) {
        console.log(e)
        setLoadingEndDate(false)
      }
    } else {
      setExtraEndDate()
      setHelpEndDate()
    }
  }, [getFieldError, getFieldValue, revalidateField, clientId, clientRefId, clonedItem, id, revalidateFieldName])

  const validateStartDate = useCallback(async (rule, value, callback) => {
    const fvEndDate = getFieldValue('end_date')

    if (validator.isDate(value) && validator.isDate(fvEndDate)) {
      const startDate = moment(value).startOf('day')
      const endDate = moment(fvEndDate).endOf('day')
      const rfn = revalidateFieldName
      setLoadingStartDate(true)

      if (validator.isNullOrUndefined(revalidateFieldName)) {
        setRevalidateFieldName('start_date')
      }

      try {
        setExtraStartDate()
        const { error, extra, help, validateStatus } = await periodValidator.validateStartDate({
          startDate,
          endDate,
          item: clonedItem,
          onCheckDate: () => clientPlanPaceService.checkDates(startDate.toISOString(), endDate.toISOString(), id, clientId),
          onGetLink: (id) => `/participants/${clientRefId}/plan-period-pace/${id}`
        })
        callback(error)
        setExtraStartDate(extra)
        setHelpStartDate(help)
        setLoadingStartDate(false)
        setValidateStatusStartDate(validateStatus)

        if (rfn !== 'end_date') {
          revalidateField(callback, 'end_date')
        } else if (!error && getFieldError('end_date')) {
          setRevalidateFieldName('start_date')
        }
      } catch (e) {
        console.log(e)
        setLoadingStartDate(false)
      }
    } else {
      setExtraStartDate()
      setHelpStartDate()
    }
  }, [getFieldError, getFieldValue, revalidateField, clientId, clientRefId, clonedItem, id, revalidateFieldName])

  useEffect(() => {
    let mounted = true

    if (
      !hasAccess([Permissions.PARTICIPANT.PP_PACE.CREATE, Permissions.PARTICIPANT.PP_PACE.READ, Permissions.PARTICIPANT.PP_PACE.UPDATE])
    ) {
      return
    }

    if (validator.isObject(item) && validator.isId(item.id)) {
      const { start_date: startDate, end_date: endDate, client_plan_items: clientPlanItems } = item
      const categoryNumbers = Array.isArray(clientPlanItems)
        ? clientPlanItems.map(({ cpi_category_number: categoryNumber }) => categoryNumber) : []
      Promise.all([
        pmRateSetCategoryPaceService.getAllUniques({}, { start_date: startDate, end_date: endDate }),
        pmRateSetCategoryItemPaceService.getAllUniques(
          { category_number: categoryNumbers }, { start_date: startDate, end_date: endDate }
        )
      ])
        .then(([categoryResponse, categoryItemResponse]) => {
          if (mounted) {
            if (Array.isArray(categoryResponse)) {
              setCategories(categoryResponse)
            }

            if (Array.isArray(categoryItemResponse)) {
              const categoryItemsMap = {}

              for (let i = 0; i < clientPlanItems.length; i++) {
                const { cpi_category_number: categoryNumber } = clientPlanItems[i]
                categoryItemsMap[i] = categoryItemResponse.filter(({ category_number: cn }) => {
                  return BigNumber(cn).isEqualTo(BigNumber(categoryNumber))
                })
              }

              setCategoryItemsMap(categoryItemsMap)
            }
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, item])

  return (
    <>
      <Panel>
        <Skeleton active loading={init}>
          <Row gutter={16}>
            <Col lg={6}>
              <FormItem
                {...formItemDateLayout} label='Start Date' extra={extraStartDate} help={helpStartDate}
                validateStatus={validateStatusStartDate}
              >
                {getFieldDecorator('start_date', {
                  initialValue: iStartDate ? moment(iStartDate) : null,
                  rules: [
                    { required: true, message: 'Please select start date' },
                    { validator: validateStartDate }
                  ]
                })(
                  <DatePicker
                    defaultPickerValue={moment().startOf('day')} format={dateFormat} placeholder='Select Start Date'
                    suffixIcon={loadingStartDate ? <Icon type='loading' /> : null} onChange={changeStartDate}
                  />
                )}
              </FormItem>
            </Col>

            <Col lg={6}>
              <FormItem
                {...formItemDateLayout} label='End Date' extra={extraEndDate} help={helpEndDate} validateStatus={validateStatusEndDate}
              >
                {getFieldDecorator('end_date', {
                  initialValue: iEndDate ? moment(iEndDate) : null,
                  rules: [
                    { required: true, message: 'Please select end date' },
                    { validator: validateEndDate }
                  ]
                })(
                  <DatePicker
                    defaultPickerValue={moment().endOf('day')} disabled={false} format={dateFormat} placeholder='Select End Date'
                    suffixIcon={loadingEndDate ? <Icon type='loading' /> : null} onChange={changeEndDate}
                  />
                )}
              </FormItem>
            </Col>

            <Col lg={6}>
              <FormItem {...formItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: typeof iActive === 'boolean' ? iActive : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled={false}
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={6}>
              <FormItem {...formItemDateLayout} label='Role Start Date'>
                {getFieldDecorator('role_start_date', {
                  initialValue: iRoleStartDate ? moment(iRoleStartDate) : (roleStartDate ? moment(roleStartDate) : undefined),
                  rules: [
                    { required: true, message: 'Please select role start date' },
                  ]
                })(
                  <DatePicker
                    defaultPickerValue={moment().startOf('day')} disabled={false} format={dateFormat} placeholder='Select Role Start Date'
                  />
                )}
              </FormItem>
            </Col>

            <Col lg={6}>
              <FormItem {...formItemDateLayout} label='Role End Date'>
                {getFieldDecorator('role_end_date', {
                  initialValue: iRoleEndDate ? moment(iRoleEndDate) : undefined,
                })(
                  <DatePicker
                    defaultPickerValue={moment().endOf('day')} disabled={false} format={dateFormat} placeholder='Select Role End Date'
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          {/* <Row gutter={16}>
            <Col lg={6}>
              <FormItem {...formItemDateLayout} label="Setup Cost Invoice?">
                {getFieldDecorator('setup_cost_invoiced', {
                  initialValue: isEdit ? (item?.setup_cost_invoiced !== undefined ? item?.setup_cost_invoiced : true) : true,
                  valuePropName: 'checked',
                })(
                  <Switch checkedChildren="Yes, Create" unCheckedChildren="No, Don't Create" />
                )}
              </FormItem>
            </Col>

            {getFieldValue('setup_cost_invoiced') !== true ? (
              <Col lg={6}>
                <FormItem label="No Setup Cost Invoice Reason">
                  {getFieldDecorator('setup_cost_reason', {
                    initialValue: item?.setup_cost_reason || undefined,
                    rules: [
                      { required: true, message: 'Please enter no setup cost invoice reason' },
                      { whitespace: true, message: 'Please enter no setup cost invoice reason' },
                    ]
                  })(
                    <TextArea rows={3} />
                  )}
                </FormItem>
              </Col>
            ) : null}

            <Col lg={6}>
              <FormItem {...formItemDateLayout} label="Monthly Fee Invoice?">
                {getFieldDecorator('monthly_fee_invoiced', {
                  initialValue: isEdit ? (item?.monthly_fee_invoiced !== undefined ? item?.monthly_fee_invoiced : true) : true,
                  valuePropName: 'checked',
                })(
                  <Switch checkedChildren="Yes, Create" unCheckedChildren="No, Don't Create" />
                )}
              </FormItem>
            </Col>

            {getFieldValue('monthly_fee_invoiced') !== true ? (
              <Col lg={6}>
                <FormItem label="No Monthly Fee Invoice Reason">
                  {getFieldDecorator(`monthly_fee_reason`, {
                    initialValue: item?.monthly_fee_reason || undefined,
                    rules: [
                      { required: true, message: 'Please enter no monthly fee invoice reason' },
                      { whitespace: true, message: 'Please enter no monthly fee invoice reason' },
                    ]
                  })(
                    <TextArea rows={3} />
                  )}
                </FormItem>
              </Col>
            ) : null}
          </Row> */}

          {showForm || isEdit ? (
            <PlanPeriodItemForm
              categories={categories} categoryItemsMap={categoryItemsMap} clientPlanItems={clientPlanItems} hasAccess={hasAccess}
              isEdit={isEdit} loading={loading} loadingDropdown={loadingDropdown || loadingEndDate || loadingStartDate}
              props={props} setItem={setItem} onChangeCategory={changeCategory}
            />
          ) : null}
        </Skeleton>
      </Panel>
    </>
  )
}

function PlanPeriodPage ({ props }) {
  const { form, history, match } = props || {}
  const { validateFieldsAndScroll } = form
  const { params } = match || {}
  const { id } = params || {}
  const { clientRefId } = params
  const [clientId, setClientId] = useState()
  const [roleStartDate, setRoleStartDate] = useState()
  const [init, setInit] = useState(id !== 'add')
  const [item, setItem] = useState(getDefaultPlanPeriod())
  const [clonedItem, setClonedItem] = useState(getDefaultPlanPeriod())
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [showEdit, setShowEdit] = useState(true)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const isEdit = useCallback(() => {
    return id !== 'add'
  }, [id])

  const handleSave = useCallback(() => {
    if (loading || saving) {
      return
    }

    setSaving(true)
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        try {
          const { client_plan_items: clientPlanItems } = item

          if (clientPlanItems.length < 1) {
            setSaving(false)
            Modal.error({ title: 'Unable to save plan period', content: NoPlanPeriodItemAddedMsg })
            return
          }

          let response

          if (isEdit()) {
            // Update default client funding by mirroring all updated plan period valuess
            response = await clientPlanPaceService.save(id, updateFundingPeriodFromPlanPeriod(clonedItem, values))
          } else {
            // Create a default client funding by copying all plan period values
            values.setup_cost_invoiced = true
            values.monthly_fee_invoiced = true
            response = await clientPlanPaceService.add(newFundingPeriodFromPlanPeriod(cloneDeep(values)))
          }

          if (validator.isObject(response) && validator.isId(response.id)) {
            setItem((item) => ({ ...item, ...values, id: response.id }))
            notify.success('Saved successfully', 'Plan Period saved successfully.')

            if (isEdit()) {
              setLoading(true)
              const itemResponse = await clientPlanPaceService.get(response.id)
              setLoading(false)

              if (validator.isObject(itemResponse) && validator.isId(itemResponse.id)) {
                setItem(itemResponse)
              }
            } else {
              history.replace(`/participants/${clientRefId}/plan-period-pace`)
            }
          } else {
            notify.error('Unable to save successfully', 'Unable to save plan period successfully. Please try again later.')
          }

          setSaving(false)
        } catch (e) {
          setSaving(false)
          notify.error('Unable to save successfully', 'Unable to save plan period successfully. Please try again later.')
        }
      } else {
        setSaving(false)
      }
    })
  }, [isEdit, validateFieldsAndScroll, clientRefId, clonedItem, history, id, item, loading, saving])

  const toggleEdit = useCallback(() => {
    setShowEdit(!showEdit)
  }, [showEdit])

  useEffect(() => {
    let mounted = true

    if (
      !hasAccess([Permissions.PARTICIPANT.PP_PACE.CREATE, Permissions.PARTICIPANT.PP_PACE.READ, Permissions.PARTICIPANT.PP_PACE.UPDATE])
    ) {
      setInit(false)
      return
    }

    setLoading(true)
    Promise.all([
      isEdit() && validator.isId(id) ? clientPlanPaceService.get(id) : undefined,
      isEdit() ? undefined : clientService.getRef(clientRefId),
    ])
      .then(async ([response, clientResponse]) => {
        if (mounted) {
          if (validator.isObject(response) && validator.isId(response.id)) {
            setItem(response)
            setClonedItem(cloneDeep(response))
            setClientId(response.client_id)
          }

          if (validator.isObject(clientResponse) && validator.isId(clientResponse.id)) {
            const clientId = clientResponse.id
            setClientId(clientId)
            const clientRoleStartDateResponse = await clientPlanPaceService.getRecentRoleStartDate(clientId)

            if (Array.isArray(clientRoleStartDateResponse)) {
              setRoleStartDate(clientRoleStartDateResponse[0].role_start_date)
            }
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, isEdit, clientRefId, id])

  const deleteClientPlan = useCallback(() => {
    if (hasAccess(Permissions.PARTICIPANT.PP_PACE.DELETE)) {
      const { start_date: startDate, end_date: endDate, client_plan_items: clientPlanItems } = item
      let canDelete = true

      for (let i = 0; i < clientPlanItems.length; i++) {
        const { total_received: totalReceived, total_spent: totalSpent, forecast_spent: forecastSpent } = clientPlanItems[i]
        const itemCanDelete = !(BigNumber(totalReceived).isGreaterThan(0) || BigNumber(forecastSpent).minus(totalSpent).isGreaterThan(0))

        if (!itemCanDelete) {
          canDelete = false
          break
        }
      }

      const label = `${formatter.toShortDate(startDate)} - ${formatter.toShortDate(endDate)}`

      if (canDelete) {
        Modal.confirm({
          title: `Are you sure you want to delete this plan period "${label}"?`,
          content: 'Press Ok to continue, Cancel to return',
          onOk: async () => {
            if (loading || saving) {
              return
            }

            setSaving(true)
            clientPlanPaceService.remove(id)
              .then((response) => {
                if (validator.isObject(response) && validator.isId(response.id)) {
                  notify.success('Deleted successfully', `Plan period "${label}" deleted successfully.`)
                  history.replace(`/participants/${clientRefId}/plan-period-pace`)
                } else {
                  notify.error(
                    'Unable to delete successfully', `Unable to delete plan period "${label}" successfully. Please try again later.`
                  )
                }
              })
              .catch(() => {
                notify.error(
                  'Unable to delete successfully', `Unable to delete plan period "${label}" successfully. Please try again later.`
                )
              })
              .finally(() => {
                setSaving(false)
              })
          }
        })
      } else {
        Modal.error({
          title: `Unable to delete plan period "${label}"`,
          content: 'Please cancel all invoices associated with this plan period before deleting the plan period'
        })
      }
    }
  }, [hasAccess, loading, saving, clientRefId, history, id, item])

  return (
    <Page.Body key={id}>
      <Page.Content nomenu>
        <Page.Header title={`${isEdit() ? 'Edit' : 'Add'} Plan Period`}>
          {hasAccess(Permissions.PARTICIPANT.PP_PACE.UPDATE) && isEdit() && showEdit && !loading
            ? <Button onClick={toggleEdit}>Edit</Button>
            : null}

          {hasAccess(Permissions.PARTICIPANT.PP_PACE.DELETE) && isEdit() && !showEdit
            ? <Button ghost feedback={saving} onClick={deleteClientPlan}>Delete</Button>
            : null
          }

          {(
            (hasAccess(Permissions.PARTICIPANT.PP_PACE.CREATE) && !isEdit()) ||
            (hasAccess(Permissions.PARTICIPANT.PP_PACE.UPDATE) && isEdit() && !showEdit)
          )
            ? <Button feedback={saving} onClick={handleSave}>Save</Button>
            : null
          }

          <Button feedback={saving} onClick={history.goBack}>Back</Button>
        </Page.Header>

        <div className='ppp-form'>
          <PlanPeriodForm
            clientId={clientId} clonedItem={clonedItem} hasAccess={hasAccess} init={init} isEdit={isEdit()} item={item}
            loading={loading} props={props} roleStartDate={roleStartDate} setItem={setItem}
          />
        </div>
      </Page.Content>
    </Page.Body>
  )
}

export class PlanPeriodPagePace extends Component {
  render () {
    return <PlanPeriodPage props={this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.PlanPeriodPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PlanPeriodPagePace))
