import React, { Component, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { ExportType, Permissions } from '../../../constants'
import { auth, exportFilePace, formatter, validator } from '../../../util'
import { invoiceExportPaceService, clientService, providerService } from '../../../services'
import debounce from 'lodash.debounce'
// UI
import { Button, Checkbox, List, Page, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import { DatePicker } from 'antd'
import Select from 'antd/lib/select'
const { confirm } = Modal
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { Option } = Select
const pageSize = 20
const timezone = 'Australia/Melbourne'

moment.tz.setDefault(timezone)

const displayFormat = 'DD/MM/YYYY'

const TypeList = [
  { type: ExportType.TYPE_PRODA_PM, path: '/payment-request-pm' },
  { type: ExportType.TYPE_PRODA_STD, path: '/payment-request-std' },
  { type: ExportType.TYPE_PAYMENT, path: '/aba-remittance' }
]

const formItemLayout = {
  labelCol: { sm: 12, md: 12, lg: 12 },
  wrapperCol: { sm: 12, md: 12, lg: 12 }
}

function ABARemittanceRequest ({ type, props, searchText, dateRange, invDateRange, clientId, providerId }) {
  const { form } = props
  const { getFieldDecorator } = form
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingSelect, setLoadingSelect] = useState(false)
  const [loadingAba] = useState(false)
  const [loadingRemittance] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [types, setTypes] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [itemsIdsSelectErrMsg, setItemsIdsSelectErrMsg] = useState('')
  const [modalItems, setModalItems] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [selectedPaymentDate, setSelectedPaymentDate] = useState(null)
  const [selectedExportId, setSelectedExportID] = useState(null)
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([])
    } else {
      const allItemIds = modalItems.map(item => item.id)
      setSelectedIds(allItemIds)
    }
    setSelectAll(!selectAll)
  }

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)

    const startDate = validator.isNotEmptyArray(dateRange) && dateRange.length >= 2 ? new Date(dateRange[0]) : null
    const endDate = validator.isNotEmptyArray(dateRange) && dateRange.length >= 2 ? new Date(dateRange[1]) : null
    const invStartDate = validator.isNotEmptyArray(invDateRange) && invDateRange.length >= 2 ? new Date(invDateRange[0]) : null
    const invEndDate = validator.isNotEmptyArray(invDateRange) && invDateRange.length >= 2 ? new Date(invDateRange[1]) : null
    const data = {
      searchText: searchText,
      startDate: startDate,
      endDate: endDate,
      invStartDate: invStartDate,
      invEndDate: invEndDate,
      clientId: clientId,
      providerId: providerId
    }

    invoiceExportPaceService
      .listExportByPage(_page, pageSize, { type }, {}, data)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
            setTotal(total)
          }

        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [type, searchText, dateRange, invDateRange, clientId, providerId])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
      return
    }

    const startDate = validator.isNotEmptyArray(dateRange) && dateRange.length >= 2 ? new Date(dateRange[0]) : null
    const endDate = validator.isNotEmptyArray(dateRange) && dateRange.length >= 2 ? new Date(dateRange[1]) : null
    const invStartDate = validator.isNotEmptyArray(invDateRange) && invDateRange.length >= 2 ? new Date(invDateRange[0]) : null
    const invEndDate = validator.isNotEmptyArray(invDateRange) && invDateRange.length >= 2 ? new Date(invDateRange[1]) : null
    const data = {
      searchText: searchText,
      startDate: startDate,
      endDate: endDate,
      invStartDate: invStartDate,
      invEndDate: invEndDate,
      clientId: clientId,
      providerId: providerId
    }


    setLoading(true)
    invoiceExportPaceService
      .listExportByPage(1, pageSize, { type }, {}, data)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
            setTotal(total)
          }

        }
      })
      .finally(() => {
        if (mounted) setLoading(false)
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, type, searchText, dateRange, invDateRange, clientId, providerId])

  useEffect(() => {
    if (selectedIds.length === modalItems.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }

    if (selectedIds.length === 0) {
      setItemsIdsSelectErrMsg('Please select at least one item.')
    } else {
      setItemsIdsSelectErrMsg('')
    }
  }, [selectedIds, modalItems])

  const handleCheckboxChange = useCallback((id) => {
    return (e) => {
      const checked = e.target.checked
      setSelectedIds((prevSelectedIds) => {
        if (checked) {
          return [...prevSelectedIds, id]
        } else {
          return prevSelectedIds.filter((selectedId) => selectedId !== id)
        }
      })
    }
  }, [])

  const columnsPayment = [
    {
      title: 'JID',
      width: 3,
      render: ({ jid_number, ref_id }) => (
        <Link to={`/invoices-pace/${ref_id}/info`} rel='noopener noreferrer' target='_blank'>
          {formatter.capitalize(jid_number, false)}
        </Link>
      )
    },
    {
      title: 'Processed',
      width: 2,
      render: ({ processed_at }) => formatter.toShortDate(processed_at)
    },
    {
      title: 'Inv Date',
      width: 2,
      render: ({ invoice_date }) => formatter.toShortDate(invoice_date)
    },
    {
      title: 'Inv No.',
      width: 2,
      render: ({ invoice_number }) => <div>{invoice_number}</div>
    },
    {
      title: 'Participant',
      width: 3,
      render: ({ client_acc_name }) => <div>{client_acc_name}</div>
    },
    {
      title: 'Provider',
      width: 3,
      render: ({ provider_id, provider_acc_name }) => provider_id
        ? <div>{provider_acc_name}</div>
        : provider_acc_name
          ? <div style={{ fontStyle: 'italic' }}>{provider_acc_name}</div>
          : <div>N/A</div>
    },
    {
      title: 'Inv Total',
      width: 4,
      render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
    },
    {
      title: 'PMYT Total',
      width: 5,
      render: (item) => {

        // const receivedPaymentAmount = item.amount
        // const receivedPaymentLabel = item.is_credit_applied ? `Credited` : `Received`
        // const receivedPaymentDate = item.invoice_date
        // return <div>{receivedPaymentLabel} {formatter.toPrice(receivedPaymentAmount)} {formatter.toDate(receivedPaymentDate, displayFormat)}</div>
        return <div>{formatter.toPrice(item.received_amount)}</div>
      }
    },
    {
      title: 'Select',
      width: 1,
      render: (item) => {
        const isChecked = selectedIds.includes(item.id)
        const isFirstItemInInvoice = modalItems.filter(modalItem => modalItem.invoice_number === item.invoice_number).indexOf(item) === 0
        if (isFirstItemInInvoice) {
          return (
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange(item.id)}
            />
          )
        }
        return null
      },
    },
  ]


  const getFile2 = useCallback(async (isSendEmail = false, isDownload = true, values = {}) => {

    const result = list.map(invoice => ({
      id: invoice.id,
      createdDate: invoice.created_at,
      type: invoice.type,
      items: invoice.item_list
    }))

    const filteredResult = result.filter(invoice =>
      invoice.items.some(item => selectedIds.includes(item.id)) && invoice.id === selectedExportId
    )

    const id = filteredResult.map(invoice => invoice.id).join(',')
    const created_at = filteredResult.map(invoice => invoice.createdDate)

    const file = {
      id,
      type: types,
      created_at
    }
    setLoadingSelect(true)

    const value = {
      ...values,
      payment_date: form.getFieldValue('payment_date')
    }

    try {
      await exportFilePace.fetchFile(file, selectedIds, isSendEmail, isDownload, value)

      setTimeout(() => {
        setLoadingSelect(false)
        setIsModalVisible(false)
        if (isSendEmail) {
          notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
        }
      }, 3000)

    } catch (error) {
      setLoadingSelect(false)
    }

  }, [selectedIds, selectedExportId, form, list, types])

  const preGetFile = useCallback((isSendEmail, isDownload) => {
    const { validateFieldsAndScroll } = form

    const title = isSendEmail && isDownload
      ? `Are you sure to export and email selected items?`
      : isSendEmail
        ? `Are you sure to email selected items?`
        : `Are you sure to export selected items?`;

    validateFieldsAndScroll(async (errors, values) => {
      if (selectedIds.length === 0) {
        setItemsIdsSelectErrMsg(itemsIdsSelectErrMsg);
      } else {
        setItemsIdsSelectErrMsg('')
        if (!errors) {
          confirm({
            title: title,
            content: 'Press Ok to continue, Cancel to return',
            onOk () {
              getFile2(isSendEmail, isDownload, values)
            }
          })
        }
      }
    })
  }, [form, selectedIds, getFile2, itemsIdsSelectErrMsg])

  return (
    <div className='invoices'>
      <Skeleton loading={loading} active>
        {list && validator.isNotEmptyArray(list) && list.map((item) => {
          const { id, created_at: createdAt, item_list: invoiceId, payment_date: paymentDate, total_amount: totalAmount, total_clients: totalClient, total_invoices: totalInvoice, total_item_count: totalItemCount, total_providers: totalProvider } = item
          const uniqueInvoiceId = []
          const seenInvoiceNumbers = new Set()

          for (const invoice of invoiceId) {
            if (!seenInvoiceNumbers.has(invoice.jid_number)) {
              seenInvoiceNumbers.add(invoice.jid_number)
              uniqueInvoiceId.push(invoice)
            }
          }

          const sortedModalItems = uniqueInvoiceId.sort((a, b) => {
            if (a.invoice_number !== b.invoice_number) {
              return a.invoice_number - b.invoice_number
            } else {
              return new Date(a.invoice_date) - new Date(b.invoice_date)
            }
          })
          const handlePaymentRequestClick = (types, payDate, id) => {
            setTypes(types)
            setIsModalVisible(true)
            setModalItems(sortedModalItems)
            setSelectedPaymentDate(payDate)
            setSelectedExportID(id)
            const allItemIds = uniqueInvoiceId.map(item => item.id)
            setSelectedIds(allItemIds)
          }

          return (
            <div className={`list-item`} key={`rlet${id}-${formatter.toShortDate(createdAt)}`}>
              <Row>
                <Col lg={21}>
                  <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                    <Col lg={2}>
                      <div className='subtitle'>Export Date</div>
                    </Col>
                    <Col lg={2}>
                      <div className='name title'>{formatter.toShortDate(createdAt)}</div>
                    </Col>
                    <Col lg={2}>
                      <div className='subtitle'>Payment Date</div>
                    </Col>
                    <Col lg={2}>
                      <div className='name title'>{formatter.toShortDate(paymentDate)}</div>
                    </Col>
                    <Col lg={2}>
                      <div className='subtitle'>Provider Count</div>
                    </Col>
                    <Col lg={1}>
                      <div className='name title'>{totalProvider}</div>
                    </Col>
                    <Col lg={2}>
                      <div className='subtitle'>Participant Count</div>
                    </Col>
                    <Col lg={1}>
                      <div className='name title'>{totalClient}</div>
                    </Col>
                    <Col lg={2}>
                      <div className='subtitle'>Invoice Count</div>
                    </Col>
                    <Col lg={1}>
                      <div className='name title'>{totalInvoice}</div>
                    </Col>
                    <Col lg={2}>
                      <div className='subtitle'>Item Count</div>
                    </Col>
                    <Col lg={1}>
                      <div className='name title'>{totalItemCount}</div>
                    </Col>
                    <Col lg={2}>
                      <div className='subtitle'>Total PMYT</div>
                    </Col>
                    <Col lg={2}>
                      <div className='name title'>{formatter.toPrice(totalAmount)}</div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '12px' }}>
                    <Row>
                      <Col lg={2}>
                        <div className='subtitle'>Invoices</div>
                      </Col>
                      <Col lg={22}>
                        {invoiceId
                          .reduce((uniqueInvoices, current) => {
                            if (!uniqueInvoices.some((e) => e.invoice_number === current.invoice_number)) {
                              uniqueInvoices.push(current)
                            }
                            return uniqueInvoices
                          }, [])
                          .sort((a, b) => a.invoice_number - b.invoice_number)
                          .map((e, idx) => {
                            return (
                              <Row key={`invrs-${e.invoice_number}-${idx}`} className={'invoices-row'} gutter={10}>
                                <Col lg={4}>
                                  {idx === 0 && <div className='title'>JID</div>}
                                  <div className='name1'>
                                    <Link to={`/invoices-pace/${e.ref_id}/info`} rel='noopener noreferrer' target='_blank'>
                                      {formatter.capitalize(e.jid_number, false)}
                                    </Link>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className='title'>{e.invoice_number}</div>
                                  <div className='date'>Invoiced on {formatter.toShortDate(e.invoice_date)}</div>
                                </Col>
                                <Col lg={5}>
                                  <div className='name1'>{e.provider_acc_name}</div>
                                  <div className='name2'>{e.client_acc_name}</div>
                                </Col>
                                <Col lg={2}>
                                  {idx === 0 && <div className='title'>Processed</div>}
                                  <div className='date'>{formatter.toShortDate(e.processed_at)}</div>
                                </Col>
                                <Col lg={4}>
                                  {idx === 0 && <div className='title'>Invoice Total</div>}
                                  <div className='date'>{formatter.toPrice(e.amount)}</div>
                                </Col>
                                <Col lg={4}>
                                  {idx === 0 && <div className='title'>Payment Total</div>}
                                  <div className='date'>{formatter.toPrice(e.received_amount)}</div>
                                </Col>
                              </Row>
                            )
                          })}
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Col lg={3}>
                  {loadingAba
                    ? <div className={`list-button disable`} >
                      <img src='/icon/button-loading.svg' alt='loading' />
                    </div>
                    : hasAccess(Permissions.INVOICE.MGMT.READ)
                      ? <div
                        className={`list-button ${loadingAba ? 'disable' : ''}`}
                        onClick={() => handlePaymentRequestClick(ExportType.TYPE_ABA, paymentDate, id)}
                      >
                        <Icon style={{ fontSize: '15px', marginRight: '6px' }} type='download' /> ABA
                      </div>
                      : null
                  }
                  {loadingRemittance
                    ? <div className={`list-button disable`} >
                      <img src='/icon/button-loading.svg' alt='loading' />
                    </div>
                    : hasAccess(Permissions.INVOICE.MGMT.READ)
                      ? <div
                        className={`list-button ${loadingRemittance ? 'disable' : ''}`}
                        onClick={() => handlePaymentRequestClick(ExportType.TYPE_RMT, paymentDate, id)}
                      >
                        <Icon style={{ fontSize: '15px', marginRight: '6px' }} type='download' /> Remittance
                      </div>
                      : null}
                </Col>
              </Row>
            </div>
          )
        })}
        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} records`}
          current={page}
          onChange={changePage}
          style={{ marginTop: '15px' }}
        />
        <Modal
          width='80%'
          title={types === ExportType.TYPE_RMT ? 'Select received amounts to export.' : 'Select and Generate ABA'}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key="cancel" feedback={loadingSelect} onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>,
            <Button style={{ backgroundColor: '#3d34eb' }} key='submit1' feedback={loadingSelect} onClick={() => preGetFile()}>Download Selected</Button>,
            types === ExportType.TYPE_RMT ? <Button style={{ backgroundColor: '#ebc034' }} key='submit2' feedback={loadingSelect} onClick={() => preGetFile(true, false)}>Email Selected</Button> : null,
            types === ExportType.TYPE_RMT ? <Button key='submit3' feedback={loadingSelect} onClick={() => preGetFile(true, true)}>Download & Email Selected</Button> : null
          ]}
        >
          <Spin spinning={loadingSelect} blur>
            <Form>
              <div className='inv-title'>Select / Update Payment Date</div>
              <div className='inv-notice-msg'>You may select a date to set payment date on files.</div>
              <Row>
                <Col lg={8}>
                  <FormItem key={`pdinp-${selectedIds}`} {...formItemLayout} label='Payment Date'>
                    {getFieldDecorator('payment_date', {
                      initialValue: selectedPaymentDate ? formatter.toMoment(selectedPaymentDate) : undefined,
                      rules: [
                        { required: true, message: 'Need to Select Date ' }
                      ]
                    })(
                      <DatePicker
                       defaultPickerValue={moment().startOf('day')}  disabledDate={formatter.disablePreviousDates} format={displayFormat}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={17}></Col>
              </Row>
              <div className='inv-sec-row ' style={{ marginTop: '15px' }}>
                <div className='inv-title'>Select received amounts to export.</div>
                <div className='inv-sec-row' style={{ marginRight: '25px' }}>
                  <div style={{ marginRight: '10px', fontSize: '11px' }}>Select / Deselect All</div>
                  <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
                </div>
              </div>

              {itemsIdsSelectErrMsg ? <div className='inv-err-msg'>{itemsIdsSelectErrMsg}</div> : null}
              <div className='inv-section'>
                <List cols={columnsPayment} rows={modalItems} />
              </div>
            </Form>


          </Spin>
        </Modal>
      </Skeleton>
    </div>
  )
}

function InvoiceRequest ({ type, props, searchText, dateRange, invDateRange, clientId, providerId }) {
  const { form } = props
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingSelect, setLoadingSelect] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [itemsIdsSelectErrMsg, setItemsIdsSelectErrMsg] = useState('')
  const [modalItems, setModalItems] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [selectedExportId, setSelectedExportID] = useState(null)
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([])
    } else {
      const allItemIds = modalItems.map(item => item.id)
      setSelectedIds(allItemIds)
    }
    setSelectAll(!selectAll)
  }

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)

    const startDate = validator.isNotEmptyArray(dateRange) && dateRange.length >= 2 ? new Date(dateRange[0]) : null
    const endDate = validator.isNotEmptyArray(dateRange) && dateRange.length >= 2 ? new Date(dateRange[1]) : null
    const invStartDate = validator.isNotEmptyArray(invDateRange) && invDateRange.length >= 2 ? new Date(invDateRange[0]) : null
    const invEndDate = validator.isNotEmptyArray(invDateRange) && invDateRange.length >= 2 ? new Date(invDateRange[1]) : null
    const data = {
      searchText: searchText,
      startDate: startDate,
      endDate: endDate,
      invStartDate: invStartDate,
      invEndDate: invEndDate,
      clientId: clientId,
      providerId: providerId
    }

    invoiceExportPaceService
      .listExportByPage(_page, pageSize, { type }, {}, data)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [type, searchText, dateRange, invDateRange, clientId, providerId])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
      return
    }
    setLoading(true)
    const startDate = validator.isNotEmptyArray(dateRange) && dateRange.length >= 2 ? new Date(dateRange[0]) : null
    const endDate = validator.isNotEmptyArray(dateRange) && dateRange.length >= 2 ? new Date(dateRange[1]) : null
    const invStartDate = validator.isNotEmptyArray(invDateRange) && invDateRange.length >= 2 ? new Date(invDateRange[0]) : null
    const invEndDate = validator.isNotEmptyArray(invDateRange) && invDateRange.length >= 2 ? new Date(invDateRange[1]) : null
    const data = {
      searchText: searchText,
      startDate: startDate,
      endDate: endDate,
      invStartDate: invStartDate,
      invEndDate: invEndDate,
      clientId: clientId,
      providerId: providerId
    }
    invoiceExportPaceService
      .listExportByPage(1, pageSize, { type }, {}, data)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
            setTotal(total)
          }
        }
      })
      .finally(() => {
        if (mounted) setLoading(false)
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, type, searchText, dateRange, invDateRange, clientId, providerId])

  useEffect(() => {
    if (selectedIds.length === modalItems.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }

    if (selectedIds.length === 0) {
      setItemsIdsSelectErrMsg('Please select at least one item.')
    } else {
      setItemsIdsSelectErrMsg('')
    }
  }, [selectedIds, modalItems])

  const handleCheckboxChange = useCallback((id) => {
    return (e) => {
      const checked = e.target.checked
      setSelectedIds((prevSelectedIds) => {
        if (checked) {
          return [...prevSelectedIds, id]
        } else {
          return prevSelectedIds.filter((selectedId) => selectedId !== id)
        }
      })
    }
  }, [])

  const columnsProda = [
    {
      title: 'Inv No.',
      width: 6,
      render: ({ invoice_number }) => <div>{invoice_number}</div>
    },
    {
      title: 'Invoice Date Date',
      width: 4,
      render: ({ invoice_date }) => formatter.toShortDate(invoice_date)
    },
    {
      title: 'Provider',
      width: 6,
      render: ({ provider_name }) => <div>{provider_name}</div>
    },
    {
      title: 'Participant',
      width: 6,
      render: ({ client_name }) => <div>{client_name}</div>
    },
    {
      title: 'Select',
      width: 1,
      render: (item) => {
        const isChecked = selectedIds.includes(item.id)
        return (
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange(item.id)}
          />
        )
      },
    },
  ]

  const getFile2 = useCallback(async (isSendEmail = false, isDownload = true, values = {}) => {

    const result = list.map(invoice => ({
      id: invoice.id,
      createdDate: invoice.created_at,
      type: invoice.type,
      items: invoice.item_list
    }))

    const filteredResult = result.filter(invoice =>
      invoice.items.some(item => selectedIds.includes(item.id)) && invoice.id === selectedExportId
    )

    const id = filteredResult.map(invoice => invoice.id).join(',')
    const created_at = filteredResult.map(invoice => invoice.createdDate)

    const file = {
      id,
      type: ExportType.TYPE_PRODA,
      created_at
    }
    setLoadingSelect(true)
    try {
      await exportFilePace.fetchFile(file, selectedIds, isSendEmail, isDownload, values)

      setTimeout(() => {
        setLoadingSelect(false)
        setIsModalVisible(false)
      }, 3000)

    } catch (error) {
      setLoadingSelect(false)
    }

  }, [selectedIds, selectedExportId, list])

  const preGetFile = useCallback((isSendEmail, isDownload) => {
    const { validateFieldsAndScroll } = form

    const title = isSendEmail && isDownload
      ? `Are you sure to export and email selected items?`
      : isSendEmail
        ? `Are you sure to email selected items?`
        : `Are you sure to export selected items?`

    validateFieldsAndScroll(async (errors, values) => {
      if (selectedIds.length === 0) {
        setItemsIdsSelectErrMsg(itemsIdsSelectErrMsg)
      } else {
        setItemsIdsSelectErrMsg('')
        if (!errors) {
          confirm({
            title: title,
            content: 'Press Ok to continue, Cancel to return',
            onOk () {
              getFile2(isSendEmail, isDownload, values)
            }
          })
        }
      }
    })
  }, [form, selectedIds, getFile2, itemsIdsSelectErrMsg])

  return (
    <div className='invoices'>
      <Skeleton loading={loading} active>
        {list && validator.isNotEmptyArray(list) && list.map((item) => {
          const { id, created_at: createdAt, item_list: invoiceId, total_amount: totalAmount, total_clients: totalClient, total_invoices: totalInvoice, total_item_count: totalItemCount, total_providers: totalProvider } = item
          const handlePaymentRequestClick = () => {
            setIsModalVisible(true)
            setSelectedExportID(id)
            setModalItems(invoiceId)
            const allItemIds = invoiceId.map(item => item.id)
            setSelectedIds(allItemIds)
          }
          return (
            <div className={`list-item`} key={`rlst${id}-${formatter.toShortDate(createdAt)}`}>
              <Row>
                <Col lg={21}>
                  <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                    <Col lg={24}>
                      <Row>
                        <Col lg={2}>
                          <div className='subtitle'>Export Date</div>
                        </Col>
                        <Col lg={3}>
                          <div className='name title'>{formatter.toShortDate(createdAt)}</div>
                        </Col>
                        <Col lg={3}>
                          <div className='subtitle'>Provider Count</div>
                        </Col>
                        <Col lg={1}>
                          <div className='name title'>{totalProvider}</div>
                        </Col>
                        <Col lg={3}>
                          <div className='subtitle'>Participant Count</div>
                        </Col>
                        <Col lg={1}>
                          <div className='name title'>{totalClient}</div>
                        </Col>
                        <Col lg={2}>
                          <div className='subtitle'>Invoice Count</div>
                        </Col>
                        <Col lg={1}>
                          <div className='name title'>{totalInvoice}</div>
                        </Col>
                        <Col lg={2}>
                          <div className='subtitle'>Item Count</div>
                        </Col>
                        <Col lg={1}>
                          <div className='name title'>{totalItemCount}</div>
                        </Col>
                        <Col lg={2}>
                          <div className='subtitle'>Total Inv</div>
                        </Col>
                        <Col lg={2}>
                          <div className='name title'>{formatter.toPrice(totalAmount)}</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '6px' }}>
                    <Col lg={24}>
                      <Row>
                        <Col lg={2}>
                          <div className='subtitle'>Invoices</div>
                        </Col>
                        <Col lg={22}>
                          {invoiceId.length > 0 ? invoiceId.map((filteredItem, idx) => (
                            <Row key={`invrl-${filteredItem.invoice_number}-${id}-${idx}`} className={'invoices-row'} gutter={10}>
                              <Col lg={4}>
                                <div className='title'>
                                  <Link to={`/invoices-pace/${filteredItem.ref_id}/info`} rel='noopener noreferrer' target='_blank'>
                                    {filteredItem.invoice_number}
                                  </Link>
                                </div>
                              </Col>
                              <Col lg={5}>
                                <div className='date'>{filteredItem.provider_name}</div>
                              </Col>
                              <Col lg={4}>
                                <div className='date'>{filteredItem.client_name}</div>
                              </Col>
                              <Col lg={4}>
                                <div className='date'>Invoiced at {formatter.toShortDate(filteredItem.invoice_date)}</div>
                              </Col>
                              <Col lg={3}>
                                <div className='item'>
                                  {filteredItem.item_count} item{parseInt(filteredItem.item_count) === 1 ? '' : 's'}
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className='date'>{formatter.toPrice(filteredItem.amount)}</div>
                              </Col>
                            </Row>
                          )) : (
                            <Col lg={2}>
                              <div className="subtitle">No Invoices Found</div>
                            </Col>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={3}>
                  {loading
                    ? <div className={`list-button disable`} >
                      <img src='/icon/button-loading.svg' alt='loading' />
                    </div>
                    : hasAccess(Permissions.INVOICE.MGMT.READ)
                      ? <div
                        className={`list-button ${loading ? 'disable' : ''}`}
                        onClick={handlePaymentRequestClick}
                      >
                        <Icon style={{ fontSize: '15px', marginRight: '6px' }} type='download' /> Payment Request
                      </div>
                      : null
                  }
                </Col>
              </Row>
            </div>
          )
        })}
        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} records`}
          current={page}
          onChange={changePage}
          style={{ marginTop: '15px' }}
        />
        <Modal
          width='80%'
          title={'Select and Generate Payment Request'}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key='close' ghost feedback={loadingSelect} onClick={() => setIsModalVisible(false)}>Close</Button>,
            <Button style={{ backgroundColor: '#3d34eb' }} key='submit1' feedback={loadingSelect} onClick={() => preGetFile()}>Download Selected</Button>
          ]}
        >
          <Spin spinning={loadingSelect} blur>
            <Form>
              <div className='inv-sec-row ' style={{ marginTop: '15px' }}>
                <div className='inv-title'>Select invoice to export.</div>
                <div className='inv-sec-row' style={{ marginRight: '25px' }}>
                  <div style={{ marginRight: '10px', fontSize: '11px' }}>Select / Deselect All</div>
                  <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
                </div>
              </div>

              {itemsIdsSelectErrMsg ? <div className='inv-err-msg'>{itemsIdsSelectErrMsg}</div> : null}
              <div className='inv-section'>
                <List cols={columnsProda} rows={modalItems} />
              </div>
            </Form>


          </Spin>
        </Modal>
      </Skeleton>
    </div>
  )
}

function TabList (props) {
  const { match, history } = props || {}
  const { params } = match || {}
  const { type } = params || {}
  const [searchText, setSearchText] = useState()
  const section = TypeList.find(e => e.path === type || e.path === `/${type}`)
  const [filterParam, setFilterParam] = useState(section ? section.type : ExportType.TYPE_PRODA)
  const [dateRange, setDateRange] = useState([])
  const [invDateRange, setInvDateRange] = useState([])
  const [searching, setSearching] = useState(false)
  const [clients, setClients] = useState([])
  const [providers, setProviders] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedClient, setSelectedClient] = useState()
  const [selectedProvider, setSelectedProvider] = useState()

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const changeTab = useCallback((key) => {
    const tab = TypeList.find(({ type: k }) => k === key)
    setFilterParam(key)

    if (tab) {
      history.replace(`/invoices-export-pace${tab.path}`)
    }
  }, [history])

  const handleChangeTab = useCallback((e) => {
    const { value } = e.target
    changeTab(value)
  }, [changeTab])

  const searchName = useCallback(() => {
    const onSearchName = async (value) => {
      setSearching(true)
      setSearchText(value)
      setTimeout(() => {
        setSearching(false)
      }, 1000)
    }
    return debounce(onSearchName, 500)
  }, [])

  const changeDateRange = useCallback((dates) => {
    const validDates = Array.isArray(dates)
    setDateRange(validDates ? dates : [])
  }, [])

  const changeInvDateRange = useCallback((dates) => {
    const validDates = Array.isArray(dates)
    setInvDateRange(validDates ? dates : [])
  }, [])

  const changeClient = useCallback((clientId) => {
    setSelectedClient(clientId)
  }, [])

  const changeProvider = useCallback((providerId) => {
    setSelectedProvider(providerId)
  }, [])

  const filterClient = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const filterProvider = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.INVOICE.INFO_PACE.LIST)) {
      return
    }
    let mounted = true
    setLoading(true)
    Promise.all([
      clientService.getAllDropdowns({ active: true }),
      providerService.getAllDropdowns({ active: true })
    ])
      .then(([clientResponse, providerResponse]) => {
        if (mounted) {
          if (Array.isArray(clientResponse)) {
            setClients(clientResponse)
          }

          if (Array.isArray(providerResponse)) {
            setProviders(providerResponse)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, type])

  return (
    <Page.Body>
      <Page.Content nomenu>
        <Page.Header title='Redownload' />
        {hasAccess(Permissions.INVOICE.MGMT_PACE.LIST) && (
          <Page.Filter>
            <Row style={{ marginBottom: '20px' }} gutter={8}>
              <Col lg={4}>
                <ControlLabel>Invoice Number</ControlLabel>
                <SearchInput isSearching={searching} placeholder="Search" onChange={searchName()} disabled={loading} />
              </Col>
              <Col lg={4}>
                <ControlLabel>Participant</ControlLabel>

                <Select
                  allowClear
                  disabled={loading}
                  dropdownMatchSelectWidth={false}
                  filterOption={filterClient}
                  optionFilterProp='children'
                  placeholder='Select Participant'
                  showSearch
                  value={selectedClient}
                  onChange={changeClient}
                  style={{ width: '100%' }}
                >
                  {clients.map(({ id, first_name: firstName, last_name: lastName, active }) => (
                    <Option key={id} disabled={!active} value={id}>{firstName} {lastName}</Option>
                  ))}
                </Select>
              </Col>
              <Col lg={4}>
                <ControlLabel>Provider</ControlLabel>

                <Select
                  allowClear
                  disabled={loading}
                  dropdownMatchSelectWidth={false}
                  filterOption={filterProvider}
                  optionFilterProp='children'
                  placeholder='Select Provider'
                  showSearch
                  value={selectedProvider}
                  onChange={changeProvider}
                  style={{ width: '100%' }}
                >
                  {providers.map(({ id, fullname, active }) => (
                    <Option key={id} disabled={!active} value={id}>{fullname}</Option>
                  ))}
                </Select>
              </Col>
              <Col lg={10}>
                <Col lg={8}>
                  <ControlLabel>Export Date</ControlLabel>
                  <RangePicker allowClear format='DD/MM/YYYY' value={dateRange} onChange={changeDateRange} disabled={loading} />
                </Col>
                <Col lg={8}>
                  <ControlLabel>Invoice Date</ControlLabel>
                  <RangePicker allowClear format='DD/MM/YYYY' value={invDateRange} onChange={changeInvDateRange} disabled={loading} />
                </Col>
              </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }} gutter={8}>
              <Col lg={10}>
                <ControlLabel>Types of Redownload</ControlLabel>
                <Radio.Group
                  onChange={handleChangeTab}
                  value={filterParam}
                >
                  <Radio.Button value={ExportType.TYPE_PRODA_PM}>Provider Invoice Payment Request</Radio.Button>
                  <Radio.Button value={ExportType.TYPE_PRODA_STD}>PM Fees Payment Request</Radio.Button>
                  <Radio.Button value={ExportType.TYPE_PAYMENT}>ABA / Remittance</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>
        )}
        {filterParam === ExportType.TYPE_PRODA_PM && (
          <InvoiceRequest type="proda-pm" props={props} searchText={searchText} dateRange={dateRange} invDateRange={invDateRange} clientId={selectedClient} providerId={selectedProvider} />
        )}
        {filterParam === ExportType.TYPE_PRODA_STD && (
          <InvoiceRequest type="proda-std" props={props} searchText={searchText} dateRange={dateRange} invDateRange={invDateRange} clientId={selectedClient} providerId={selectedProvider} />
        )}
        {filterParam === ExportType.TYPE_PAYMENT && (
          <ABARemittanceRequest type="payment" props={props} searchText={searchText} dateRange={dateRange} invDateRange={invDateRange} clientId={selectedClient} providerId={selectedProvider} />
        )}
      </Page.Content>
    </Page.Body>
  )
}

export class InvoiceExport extends Component {
  render () {
    return <TabList {...this.props} />
  }
}

export default Form.create()(InvoiceExport)
