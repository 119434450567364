import React, { Component, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import moment from 'moment-timezone'

import { common, formatter, validator } from '../../../../../util'

// UI
import Alert from 'antd/lib/alert'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import { Button } from '../../../../../components'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'

const CreditField = Object.freeze({
  Id: 'id',
  CreditId: 'credit_id',
  InvoiceItemId: 'invoice_item_id',
  Amount: 'amount',
  Comment: 'comment',
  ApplyDate: 'apply_date',
  IsApplyAll: 'is_apply_all'
})

function disablePreviousDates (startDate) {
  return (current) => moment(current) && moment(current) < moment(startDate).startOf('day')
}

function getApplyLimit (applicableCreditLookup, creditId, iiAmount) {
  const { remaining_amount: remainingAmount } = applicableCreditLookup.get(creditId) || {}
  const _creditAmount = formatter.toBigNumber(remainingAmount || 0)
  const _iiAmount = formatter.toBigNumber(iiAmount)
  return BigNumber.minimum(_creditAmount, _iiAmount)
}

function getCreditFieldName (idx, field) {
  if (validator.isId(idx) && typeof field === 'string' && field.trim().length > 0) {
    return `credits[${idx}].${field}`
  }

  return ''
}

function getTotalApplied (appliedCredits, idx, creditAmount) {
  let totalApplied = BigNumber(0)

  if (Array.isArray(appliedCredits)) {
    totalApplied = appliedCredits.reduce((accumulator, appliedCredit, _idx) => {
      const { amount } = appliedCredit
      const _amount = idx === _idx ? creditAmount : formatter.toBigNumber(!formatter.toBigNumber(amount).isNaN() ? amount : 0)
      return BigNumber(accumulator).plus(_amount)
    }, 0)
  }

  return totalApplied
}

function handlePasteAmount (event) {
  const pastedText = common.getPastedText(event)

  if (pastedText) {
    const cleanedData = formatter.toBigNumber(formatter.parseAmount(pastedText))
    const amount = !cleanedData.isNaN() ? cleanedData.toFormat() : ''
    // No better alternative than document.execCommand at the moment
    document.execCommand('insertText', false, amount)
  }
}

function setInitialValues (setFieldsValue, appliedCredits, invoiceItem) {
  if (validator.isObject(appliedCredits) && validator.isObject(invoiceItem)) {
    const appliedCreditsList = Object.values(appliedCredits)

    for (let idx = 0; idx < appliedCreditsList.length; idx++) {
      const values = appliedCreditsList[idx]
      const fnCreditId = getCreditFieldName(idx, CreditField.CreditId)
      const fnInvoiceItemId = getCreditFieldName(idx, CreditField.InvoiceItemId)
      const fnAmount = getCreditFieldName(idx, CreditField.Amount)
      const fnComment = getCreditFieldName(idx, CreditField.Comment)
      const fnApplyDate = getCreditFieldName(idx, CreditField.ApplyDate)
      const fnIsApplyAll = getCreditFieldName(idx, CreditField.IsApplyAll)
      setFieldsValue({
        [fnCreditId]: values[CreditField.CreditId], [fnInvoiceItemId]: values[CreditField.InvoiceItemId],
        [fnAmount]: values[CreditField.Amount], [fnComment]: values[CreditField.Comment],
        [fnApplyDate]: values[CreditField.ApplyDate], [fnIsApplyAll]: values[CreditField.IsApplyAll]
      })
    }
  }
}

function CreditApplyModal (props) {
  const { creditModal, form, isDecimal, isString, visible, onCancelModal, onCloseModal, onConfirmModal } = props
  const { applicableCreditLookup, applicableCredits, appliedCreditUsage, appliedCredits, idx: iiIdx, invoiceItem } = creditModal || {}
  const {
    getFieldDecorator, getFieldValue, getFieldsValue, resetFields, setFieldsValue, validateFields, validateFieldsAndScroll
  } = form || {}
  const {
    id: iiId, start_date: iiStartDate, end_date: iiEndDate, category_number: iiCategoryNumber, category_name: iiCategoryName,
    amount: iiAmount
  } = invoiceItem || {}
  const { credits } = getFieldsValue()
  const totalApplied = getTotalApplied(credits)
  const hasApplicableCredits = Array.isArray(applicableCredits) && applicableCredits.length > 0

  useEffect(() => {
    setInitialValues(setFieldsValue, appliedCredits, invoiceItem)
  }, [setFieldsValue, appliedCredits, invoiceItem])

  const changeAmount = useCallback((idx, creditId) => ({ target }) => {
    const { value } = target || {}
    const _value = formatter.toBigNumber(value)
    const applyLimit = getApplyLimit(applicableCreditLookup, creditId, iiAmount)
    const isApplyAll = applyLimit.isEqualTo(_value)
    setFieldsValue({ [getCreditFieldName(idx, CreditField.IsApplyAll)]: isApplyAll })
  }, [setFieldsValue, applicableCreditLookup, iiAmount])

  const changeIsApplyAll = useCallback((idx, creditId) => (value) => {
    const applyLimit = getApplyLimit(applicableCreditLookup, creditId, iiAmount)
    setFieldsValue({ [getCreditFieldName(idx, CreditField.Amount)]: value === true ? applyLimit.toFormat(2) : undefined })
    const timer = setTimeout(() => {
      clearTimeout(timer)
      validateFields([getCreditFieldName(idx, CreditField.Amount)], { force: true }).catch(() => { })
    }, 10)
  }, [setFieldsValue, validateFields, applicableCreditLookup, iiAmount])

  const closeModal = useCallback(() => {
    resetFields()

    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }
  }, [onCloseModal, resetFields])

  const handleCancel = useCallback((iiIdx, idx) => () => {
    if (typeof onCancelModal === 'function') {
      const { credits } = getFieldsValue()

      if (Array.isArray(credits) && credits[idx]) {
        const fnAmount = getCreditFieldName(idx, CreditField.Amount)
        const fnComment = getCreditFieldName(idx, CreditField.Comment)
        const fnApplyDate = getCreditFieldName(idx, CreditField.ApplyDate)
        const fnIsApplyAll = getCreditFieldName(idx, CreditField.IsApplyAll)
        setFieldsValue({ [fnAmount]: undefined, [fnComment]: undefined, [fnApplyDate]: undefined, [fnIsApplyAll]: false })
        onCancelModal(iiIdx, credits[idx])
      }
    } else {
      Modal.error({
        title: 'Unable to cancel credit application',
        content: 'Unable to cancel credit application successfully. Please try again later.'
      })
    }
  }, [getFieldsValue, setFieldsValue, onCancelModal])

  const handleSave = useCallback((idx) => () => {
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (typeof onConfirmModal === 'function') {
          const { credits } = values
          onConfirmModal(idx, credits, closeModal)
        } else {
          Modal.error({
            title: 'Unable to apply credit',
            content: 'Unable to apply credit successfully. Please try again later.'
          })
        }
      }
    })
  }, [closeModal, onConfirmModal, validateFieldsAndScroll])

  const validateAmount = useCallback((idx, creditId) => (rule, value, callback) => {
    const _value = formatter.toBigNumber(value)
    const { remaining_amount: remainingAmount } = applicableCreditLookup.get(creditId) || {}
    const applyLimit = getApplyLimit(applicableCreditLookup, creditId, iiAmount)
    const creditUsage = appliedCreditUsage[creditId] || BigNumber(0)
    const currentRemaining = BigNumber(!BigNumber(remainingAmount).isNaN() ? remainingAmount : applyLimit).minus(creditUsage)
    const totalApplied = getTotalApplied(credits, idx, _value)

    if (isString(value) && !isDecimal(value)) {
      callback(new Error('Please enter a number with 2 decimal places'))
    } else if (_value.isLessThanOrEqualTo(0)) {
      callback(new Error('Credit apply amount must be greater than 0'))
    } else if (_value.isGreaterThan(applyLimit) || _value.isGreaterThan(currentRemaining) || totalApplied.isGreaterThan(applyLimit)) {
      const displayLimit = BigNumber.minimum(applyLimit, currentRemaining)
      callback(new Error(`Credit apply amount exceeds the allowed limit ($ ${displayLimit.toFormat(2)})`))
    } else {
      callback()
    }
  }, [isDecimal, isString, applicableCreditLookup, appliedCreditUsage, credits, iiAmount])

  return validator.isId(iiIdx) ? (
    <Modal
      className='pid-credit'
      footer={[
        <Button key='close' ghost onClick={closeModal}>Close</Button>,
        <Button key='confirm' disabled={!hasApplicableCredits || totalApplied.isLessThanOrEqualTo(0)} onClick={handleSave(iiIdx)}>
          Confirm
        </Button>
      ]}
      maskClosable={false}
      title='Apply Credit'
      visible={visible}
      onCancel={closeModal}
    >
      <Alert
        className='pid-margin-bottom'
        description={(
          <strong>
            <em>**The credit amount will be applied and recorded only when the invoice is successfully saved and processed.**</em>
          </strong>
        )}
        type='info'
      />

      <Form className='invoice-item-info' layout='vertical'>
        <Row gutter={8}>
          <Col lg={6}>
            <FormItem label='Service Date'>
              {formatter.toShortDate(iiStartDate)} - {formatter.toShortDate(iiEndDate)}
            </FormItem>
          </Col>

          <Col lg={6}>
            <FormItem label='Support Category'>
              {formatter.toCategoryNumber(iiCategoryNumber)} - {iiCategoryName}
            </FormItem>
          </Col>

          <Col lg={6}>
            <FormItem label='Invoiced Amount'>
              {formatter.toPrice(iiAmount)}
            </FormItem>
          </Col>

          <Col lg={6}>
            <FormItem label='Total Applied'>
              {formatter.toPrice(totalApplied)}
            </FormItem>
          </Col>
        </Row>
      </Form>

      {hasApplicableCredits ? <div className='credit-list-title'>Available Credits</div> : <Alert description='No credits available' />}

      <Form className='credit-info' layout='vertical'>
        {hasApplicableCredits ? applicableCredits.map(({
          id: creditId, category_number: categoryNumber, category_name: categoryName, category_group_name: categoryGroupName,
          remaining_amount: remainingAmount
        }, idx) => {
          const fvAmount = getFieldValue(getCreditFieldName(idx, CreditField.Amount))
          const fvIsApplyAll = getFieldValue(getCreditFieldName(idx, CreditField.IsApplyAll))
          const isRequired = !formatter.toBigNumber(fvAmount).isNaN()
          const creditUsage = BigNumber(appliedCreditUsage[creditId])
          const currentRemaining = BigNumber(remainingAmount).minus(!creditUsage.isNaN() ? creditUsage : 0)

          return (
            <div key={creditId} className='credit-list-row'>
              <div className='title'>
                {formatter.toCategoryNumber(categoryNumber)} - {categoryName}{categoryGroupName ? ` (${categoryGroupName})` : ''}
                {' - '}{formatter.toPrice(currentRemaining)}

                {getFieldDecorator(getCreditFieldName(idx, CreditField.CreditId), {
                  initialValue: creditId
                })(
                  <Input type='hidden' />
                )}
              </div>

              <Row gutter={8}>
                <Col lg={4}>
                  <FormItem>
                    {getFieldDecorator(getCreditFieldName(idx, CreditField.ApplyDate), {
                      initialValue: undefined,
                      rules: [
                        { required: isRequired, message: 'Please select apply date' }
                      ],
                    })(
                      <DatePicker
                        allowClear defaultPickerValue={moment().startOf('day')} disabledDate={disablePreviousDates(iiStartDate)}
                        format={dateFormat} placeholder='Apply Date'
                      />
                    )}
                  </FormItem>
                </Col>

                <Col lg={10}>
                  <FormItem required style={{ marginBottom: 0 }}>
                    <div className='pid-amount-box'>
                      <FormItem>
                        {getFieldDecorator(getCreditFieldName(idx, CreditField.IsApplyAll), {
                          initialValue: undefined,
                          valuePropName: 'checked'
                        })(
                          <Switch
                            checkedChildren='Apply All'
                            unCheckedChildren="Apply Partial"
                            onChange={changeIsApplyAll(idx, creditId)}
                          />
                        )}
                      </FormItem>

                      <FormItem className={clsx(fvIsApplyAll ? 'fi-readonly' : '')}>
                        {getFieldDecorator(getCreditFieldName(idx, CreditField.Amount), {
                          initialValue: undefined,
                          rules: [
                            { required: isRequired, message: 'Please enter apply amount' },
                            { validator: validateAmount(idx, creditId) }
                          ]
                        })(
                          <Input
                            addonBefore='$' readOnly={fvIsApplyAll} placeholder='Apply Amount' onChange={changeAmount(idx, creditId)}
                            onPaste={handlePasteAmount}
                          />
                        )}
                      </FormItem>
                    </div>
                  </FormItem>
                </Col>

                <Col lg={8}>
                  <FormItem>
                    {getFieldDecorator(getCreditFieldName(idx, CreditField.Comment), {
                      initialValue: undefined,
                      rules: [
                        { whitespace: true, message: 'Please enter apply notes' }
                      ]
                    })(
                      <TextArea placeholder='Apply Notes' rows={1} />
                    )}
                  </FormItem>

                  <FormItem hidden>
                    {getFieldDecorator(getCreditFieldName(idx, CreditField.Id), {
                      initialValue: undefined
                    })(
                      <Input />
                    )}
                  </FormItem>

                  <FormItem hidden>
                    {getFieldDecorator(getCreditFieldName(idx, CreditField.InvoiceItemId), {
                      initialValue: iiId
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>

                <Col lg={2}>
                  <FormItem>
                    {isRequired ? (
                      <Button ghost onClick={handleCancel(iiIdx, idx)}>
                        <Tooltip title='Cancel & Reset'>
                          <Icon type='undo' />
                        </Tooltip>
                      </Button>
                    ) : null}
                  </FormItem>
                </Col>
              </Row>
            </div>
          )
        }) : null}
      </Form>
    </Modal>
  ) : null
}

export class PaceCreditApplyModal extends Component {
  render () {
    return <CreditApplyModal {...this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PaceCreditApplyModal))
