import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { debounce, isEqual } from 'lodash'
import { fetchInvoicesList, fetchInvoicesSummary, invoicesInfoReset, invoicesListFetched } from '../../../states/actions/invoice-fs'
// import { fetchTotalPending } from '../../../states/actions'
import { ExportType, FileUploadMsg, InvoiceV2Menu, InvoiceListType, InvoiceUpdateType, InvoiceType, Permissions, ReportRequestType } from '../../../constants'
import { auth, common, exportFile, formatter, validator } from '../../../util'
import { apiHostname } from '../../../config'
import { clientService, invoiceListService, providerService, reportSchedulerService, reportSchedulerPaceService } from '../../../services'

// UI
import { Button, Checkbox, List, Loading, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import StatusUpdateModal from '../StatusUpdateModal'
import './styles.css'

import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'

const { Item: FormItem } = Form
const { TextArea } = Input
const Option = Select.Option
const { Group: RadioGroup, Button: RadioButton } = Radio
const { RangePicker } = DatePicker
const { confirm, error, info, warning } = Modal

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const pageSize = 20

const dateFormat = 'DD/MM/YYYY'
const urlRedirect = '/invoices'

const firstProvider = {
  id: null,
  fullname: 'Select All Provider'
}

const firstClient = {
  id: null,
  first_name: 'Select All Participant'
}

const firstStatus = {
  reference: null,
  value: 'all',
  name: 'All'
}

const AllSorting = { processed_at: -1, invoice_date: -1, client_name: 1, id: 1 }
const StatsSorting = { processed_at: 1, invoice_date: 1, client_name: 1, id: 1 }
const ToRcvSorting = { id: 1, processed_at: 1, invoice_date: 1, client_name: 1 }

const hasAccess = (accessLevel) => {
  return auth.hasAccess(accessLevel)
}

const getSelectedStatus = (filter, value = '') => {
  if (!validator.isObject(filter)) {
    filter = {}
  }

  if (value.indexOf(' ') >= 0) {
    const words = value.split(' ')

    if (Array.isArray(words)) {
      filter.$and = []

      for (let i = 0; i < words.length; i++) {
        if (words[i]) {
          // filter.$and.push({
          //   $or: [
          //     { id_numbering: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { client_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { client_ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { provider_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { provider_abn: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { string_invoice_date: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { string_invoice_created_date: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { string_last_received_date: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { string_payment_date: { condition: 'ilike', value: `%${words[i]}%` } },
          //     { invoice_number: { condition: 'ilike', value: `%${words[i]}%` } },
          //     // { status: { condition: 'ilike', value: `%${words[i]}%` } }
          //   ]
          // })
          filter.$and.push({
            $or: [
              { id_numbering: { condition: 'ilike', value: `%${words[i]}%` } },
              { invoice_provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { invoice_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { invoice_abn: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_abn: { condition: 'ilike', value: `%${words[i]}%` } },
            ]
          })
        }
      }
    }
  } else {
    if (Array.isArray(filter.$and)) {
      delete filter.$and
    }
  }

  return filter
}

const checkItemSelect = (selectedInvs, list, that) => {
  let isSelectedAll = null

  if (list && validator.isArray(list)) {
    for (let i = 0; i < list.length; i++) {
      const itm = list[i]

      if (selectedInvs.findIndex(e => e.id === itm.id) === -1) {
        isSelectedAll = false
        break
      }
    }

    if (isSelectedAll !== false) {
      isSelectedAll = true
    }
  } else {
    isSelectedAll = false
  }

  if (that) {
    that.setState({ isSelectedAll: !!isSelectedAll })
  }

  return { isSelectedAll }
}

const getCurrentStatusList = (prevState, that = null) => {
  const { statusList, invListType } = prevState

  let statusCurrentList = []
  if (invListType === InvoiceListType.INV_LIST_PM) {
    statusCurrentList = statusList.filter(e => e.reference_2.indexOf('_pm') > -1).concat([firstStatus])
  } else if (invListType === InvoiceListType.INV_LIST_STD) {
    statusCurrentList = statusList.filter(e => e.reference_2.indexOf('_std') > -1).concat([firstStatus])
  } else {
    statusCurrentList = statusList
  }

  if (that) {
    that.setState({ statusCurrentList })
  }

  return { statusCurrentList }
}

export class InvoiceList extends Component {
  constructor(props) {
    super(props)
    const { match, location } = this.props
    const { key = undefined } = location
    this.state = {
      currentPage: 1,
      list: [],
      total: 0,
      filter: {},
      filterSummary: undefined,
      invListType: null,
      sort: Object.assign({}, StatsSorting),
      actionButtons: [],
      columns: [],
      fileList: [],
      fileUploadedList: {},
      rcvAmtUpdates: [],
      isListItem: undefined,
      isLoaded: false,
      disabledFields: {},
      isSearching: false,
      isSelectedAll: false,
      isShowDateRangePicker: false,
      isShowImportModal: false,
      isShowStatusDate: false,
      loadingList: false,
      loadingListLoaded: false,
      loadingData: true,
      loadingDataSelect: false,
      loadingSummary: false,
      loadingSummit: false,
      isGenerating: false,
      clientList: [],
      providerList: [],
      statusList: [],
      statusCurrentList: [],
      searchText: null,
      selectedStatus: firstStatus.reference,
      selectedInvs: [],
      selectedNotesInput: [],
      selectedClientId: undefined,
      selectedClientIds: [],
      selectedProviderId: undefined,
      selectedProviderIds: [],
      selectedProcessAt: null,
      selectedInvDate: null,
      summary: {},
      showStatusUpdateModal: false,
      statusUpdateAction: null,
      statusUpdateModalInvList: [],
      uploadErrorMsg: '',
      currentTS: Date.now(),
      pageKey: key,
      getInvoiceListType: this.getInvoiceListType.bind(this),
      isCheckMsgShow: false,
      isCheckFile: true,
      isCheckInvoice: true,
      startDate: null,
      endDate: null,
      isCheckDateRange: false,
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { invoicesList: { list, total, ts: currentTS, is_list_item: isListItem, filterSummary }, invoicesSummary = {}, loadingList, loadingSummary, location, match, fetchInvoicesList, fetchInvoicesSummary } = nextProps

    const { type = InvoiceListType.INV_LIST_ALL } = common.getPath(match)
    const { page = 1, q, status } = common.getQueryStringSearchParams(location.search)
    const filter = getSelectedStatus(prevState.filter, q)

    // for different invoice list type, set different filter in advance
    if (type) {
      if (type === InvoiceListType.INV_LIST_PM) {
        filter.invoice_type = {
          $or: [
            { condition: '=', value: InvoiceType.INV_TYPE_PM.value },
            { condition: '=', value: InvoiceType.INV_TYPE_RMB.value }
          ]
        }
      } else if (type === InvoiceListType.INV_LIST_STD) {
        filter.invoice_type = { condition: '=', value: InvoiceType.INV_TYPE_STD.value }
      } else {
        delete filter.invoice_type
      }
    } else {
      delete filter.invoice_type
    }

    // reset the selected inv list if current inv list type diff with prev one
    let selectedInvs = prevState.selectedInvs
    let rcvAmtUpdates = prevState.rcvAmtUpdates
    if (prevState.invListType !== type) {
      selectedInvs = []
      rcvAmtUpdates = []
    }

    // if detect different invoice type, 1. refetch the inv list 2. refetch summary 3. reset status list with correct listing
    let statusCurrentList = prevState.statusCurrentList
    let selectedStatus = status ? status : prevState.selectedStatus
    let sort = prevState.sort
    if (prevState.invListType !== null && prevState.invListType !== type) {
      const { statusCurrentList: scl } = getCurrentStatusList({ ...prevState, invListType: type })
      statusCurrentList = scl

      // --- enable if wanna stay previous status if switch between PM/STD
      // if (scl.findIndex(e => e.value === selectedStatus) < 0 && scl.length > 0) {
      //   selectedStatus = scl[0].value
      // }
      // --- enable if wanna force reset status to the first status if switch between PM/STD
      selectedStatus = validator.isNotEmptyArray(statusCurrentList) ? statusCurrentList[0].value : firstStatus.value

      // set sort if detected selectedStatus === 'all' or ''
      sort = selectedStatus === 'all'
        ? Object.assign({}, AllSorting)
        : selectedStatus === 'pymt-req-submit'
          ? Object.assign({}, ToRcvSorting)
          : Object.assign({}, StatsSorting)

      // reset the selected inv list if upcoming status is different with prev one
      if (selectedStatus !== prevState.selectedStatus) {
        selectedInvs = []
      }

      // update filter status for selectedStatus change
      if (selectedStatus === 'client-pending-pms') {
        filter.status = {
          $or: [
            { condition: '=', value: selectedStatus },
            { condition: '=', value: 'client-pending-auth' }
          ]
        }
      } else {
        filter.status = { condition: '=', value: selectedStatus }
      }

      fetchInvoicesList({ loading: true, currentPage: 1, pageSize, filter, sort, searchText: filter.$and ? '' : prevState.searchText, isListItem: selectedStatus === 'pymt-req-submit' })
      fetchInvoicesSummary({ loading: true, invoiceType: type })
    } else if (prevState.invListType === null && type) {
      // required to set the correct sorting when invListType is null. or else the sorting is wrong when refreshing when status === ALL (default is Stats Sorting)
      sort = selectedStatus === 'all'
        ? Object.assign({}, AllSorting)
        : selectedStatus === 'pymt-req-submit'
          ? Object.assign({}, ToRcvSorting)
          : Object.assign({}, StatsSorting)
    }

    // set isLoaded flag if the list is not equal
    let isLoaded = prevState.isLoaded
    const isListDiff = !isEqual(list, prevState.list) // to check the both list are totally identical or not (data identity)
    const isListDiffContext = list !== prevState.list // to check the both list are identical (same key) or not. it could be same data list but diff key
    if (!isLoaded && isListDiff) {
      isLoaded = true
    }

    // if list is not equal, get the latest flag for isSelectedAll
    let isSelectedAll = prevState.isSelectedAll
    let isSearching = prevState.isSearching
    let columns = prevState.columns
    let actionButtons = prevState.actionButtons
    if (isListDiff) {
      const r = checkItemSelect(selectedInvs, list)
      isSelectedAll = r.isSelectedAll
    }

    // if list is not equal on key, set isSearching to false
    if (isListDiffContext) {
      isSearching = false

      const data = prevState.getInvoiceListType(type, selectedStatus)
      columns = data.columns
      actionButtons = data.actionButtons
    }

    // let columns = prevState.columns
    // if (isListDiff) {
    //   console.log('inv list gsrp 2', isListDiff, type, selectedStatus)
    //   columns = getInvoiceListType(type, selectedStatus)
    // }

    const statusDateLabel = {
      'client-authed': "Auth'd Date", 'pymt-req-submit': "Claimed Date", 'pymt-to-pay': "Received Date"
    }[selectedStatus]
    const state = {
      ...prevState,
      list,
      actionButtons,
      columns,
      currentPage: page && !isNaN(parseInt(page)) ? parseInt(page) : prevState.currentPage || 1,
      currentTS,
      filter,
      filterSummary,
      invListType: type,
      isListItem,
      isLoaded,
      isSearching,
      isSelectedAll,
      isShowStatusDate: ['client-authed', 'pymt-req-submit', 'pymt-to-pay'].indexOf(selectedStatus) > -1,
      loadingList: isLoaded ? false : loadingList,
      loadingListLoaded: isLoaded ? loadingList : false,
      loadingSummary,
      pageKey: location.key,
      searchText: q || '',
      selectedInvs,
      selectedStatus,
      statusCurrentList,
      statusDateLabel,
      summary: invoicesSummary,
      sort,
      total
    }

    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount() {
    this.fetchDataList()
    this.fetchInoviceSummary()
  }



  redirectUrl = (query) => {
    const { history, location } = this.props
    const params = new URLSearchParams(query)

    history.replace({ pathname: location.pathname, search: params.toString() })
  }

  getTitle() {
    const { invListType } = this.state

    if (invListType === InvoiceListType.INV_LIST_PM) {
      return '(PRODA) Provider Invoices'
    } else if (invListType === InvoiceListType.INV_LIST_STD) {
      return '(PRODA) PM Fees'
    }

    return 'Invoices'
  }

  render() {
    const {
      form
    } = this.props
    const { getFieldDecorator } = form
    const {
      filterSummary,
      isGenerating,
      isListItem,
      isSearching,
      isSelectedAll,
      isShowDateRangePicker,
      isShowImportModal,
      isShowStatusDate,
      clientList,
      providerList,
      actionButtons,
      columns,
      currentPage,
      fileList,
      invListType,
      loadingData,
      loadingList,
      loadingListLoaded,
      loadingSummary,
      loadingSummit,
      list,
      pageKey,
      rcvAmtUpdates,
      searchText,
      selectedInvs,
      selectedStatus,
      selectedClientId,
      selectedProviderId,
      selectedProcessAt,
      selectedInvDate,
      selectedStatusDate,
      showStatusUpdateModal,
      statusCurrentList,
      statusDateLabel,
      statusUpdateAction,
      statusUpdateModalInvList,
      summary,
      total,
      uploadErrorMsg,
      isCheckMsgShow,
      isCheckFile,
      isCheckInvoice,
      isCheckDateRange
    } = this.state

    const onChangePromptInfo = {
      title: `There are receiving amounts that yet to update`,
      content: <div>
        <p>If you navigate to other page, all updated values in this page will be cleared.</p>
        <p style={{ color: 'red', fontWeight: 'bold' }}>ARE YOU SURE?</p>
      </div>
    }

    const isStdList = invListType === InvoiceListType.INV_LIST_STD

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={InvoiceV2Menu} backLink='/' />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={this.getTitle()}>
            {hasAccess(Permissions.INVOICE.INFO.LIST) && !loadingList
              ? <Button feedback={isGenerating} onClick={() => this.toggleExportSelect()}>
                Export
              </Button>
              : null}

            {hasAccess(Permissions.INVOICE.INFO.CREATE) && !loadingData
              ? <Link to={`${urlRedirect}/add`}>
                <div className='btn'>
                  New Invoice
                </div>
              </Link>
              : null}
          </Page.Header>

          {/* <Row gutter={8}>
            <Col lg={8}>
            </Col>

            <Col lg={16} style={{ display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end' }}>
              {isShowDateRangePicker
                ? <RangePicker format='DD/MM/YYYY' open={isShowDateRangePicker} onChange={(d1) => this.export(d1)} />
                : null}
            </Col>
          </Row> */}
          {isShowDateRangePicker
            ? <Modal
              width='450px'
              title='Select Invoices Export Items'
              visible={isShowDateRangePicker}
              onCancel={() => this.toggleExportSelect(false)}
              footer={[
                <Button key='close' ghost feedback={isGenerating} onClick={() => this.toggleExportSelect(false)}>Cancel</Button>,
                <Button key='submit' feedback={isGenerating} onClick={() => this.preCheckExport()}>Download</Button>
              ]}
            >
               <Spin spinning={isGenerating} blur>
                <Form>
                  <div className='inv-title'>Please select item(s) to export:</div>
                  <div>
                    <RangePicker format='DD/MM/YYYY' onChange={this.handleDateChange} />
                    {isCheckDateRange && (
                      <div className='checkbox-warning-text'>Please select a date range.</div>
                    )}
                  </div>
                  <div>
                    <Checkbox
                      checked={isCheckFile}
                      onClick={f => this.handleCheckboxClick(f, { isCheckFile: true })}
                    />
                    <span className='checkbox-text'>Invoice Export</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={isCheckInvoice}
                      onClick={f => this.handleCheckboxClick(f, { isCheckInvoice: true })}
                    />
                    <span className='checkbox-text'>Invoice Details Export</span>
                  </div>
                  {isCheckMsgShow && (
                    <div className='checkbox-warning-text'>Please select at least one item.</div>
                  )}
                </Form>
              </Spin>
            </Modal>
            : null}

          <div className='summary-section'>
            <Spin spinning={loadingSummary} tip={'Loading...'}>
              {isStdList
                ? <Row style={{ marginTop: '10px' }}>
                  <Col lg={2} className='col' />
                  <Col lg={4} className='col'>
                    <div className='number'>{summary.count_drafted || 0}</div>
                    Drafts
                  </Col>
                  <Col lg={4} className='col'>
                    <div className='number'>{summary.count_rejected || 0}</div>
                    Rejected
                  </Col>
                  <Col lg={5} className='col'>
                    <div className='number'>{summary.count_claim || 0}</div>
                    To Claim
                    <div className='sum'>{formatter.toPrice(summary.sum_claim || 0)}</div>
                  </Col>
                  <Col lg={5} className='col'>
                    <div className='number'>{summary.count_rcv || 0}</div>
                    To Receive
                    <div className='sum'>{formatter.toPrice(summary.sum_rcv || 0)}</div>
                  </Col>
                  <Col lg={2} className='col' />
                  {/* <Col lg={6} className='col'>
                    <div className='number'>{summary.count_duration_avg !== undefined ? formatter.toDayHourText(summary.count_duration_avg) : 'N/A'}</div>
                    Avg to Complete
                  </Col> */}
                  <Col lg={1} className='col' />
                </Row>
                : <Row style={{ marginTop: '10px' }}>
                  <Col lg={3} className='col'>
                    <div className='number'>{summary.count_drafted || 0}</div>
                    Drafts
                  </Col>
                  <Col lg={3} className='col'>
                    <div className='number'>{summary.count_rejected || 0}</div>
                    Rejected
                  </Col>
                  <Col lg={4} className='col'>
                    <div className='number'>{summary.count_authorised || 0}</div>
                    To Authorise
                    <div className='sum'>{formatter.toPrice(summary.sum_authorised || 0)}</div>
                  </Col>
                  <Col lg={4} className='col'>
                    <div className='number'>{summary.count_claim || 0}</div>
                    To Claim
                    <div className='sum'>{formatter.toPrice(summary.sum_claim || 0)}</div>
                  </Col>
                  <Col lg={4} className='col'>
                    <div className='number'>{summary.count_rcv || 0}</div>
                    To Receive
                    <div className='sum'>{formatter.toPrice(summary.sum_rcv || 0)}</div>
                  </Col>
                  <Col lg={4} className='col'>
                    <div className='number'>{summary.count_topay || 0}</div>
                    To Pay
                    <div className='sum'>{formatter.toPrice(summary.sum_topay || 0)}</div>
                  </Col>
                  {/* <Col lg={6} className='col'>
                    <div className='number'>{summary.count_duration_avg !== undefined ? formatter.toDayHourText(summary.count_duration_avg) : 'N/A'}</div>
                    Avg to Complete
                  </Col> */}
                </Row>}

            </Spin>
          </div>

          <Form>
            <Row style={{ marginTop: '10px' }}>
              <Col lg={16}>
                <div className='buttons-start'>
                  <RadioGroup value={selectedStatus} onChange={this.handleChangeOption}>
                    {statusCurrentList.map(e => (
                      <RadioButton value={e.value}>{e.name}</RadioButton>
                    ))}
                  </RadioGroup>
                  {validator.isNotEmptyArray(statusCurrentList)
                    ? <div className='action-button' style={{ marginLeft: '12px' }} onClick={loadingList || loadingListLoaded ? null : () => this.onRefreshCurrentPage()}>
                      <Icon type='redo' style={{ color: '#888' }} />
                    </div>
                    : null}
                </div>
              </Col>
              <Col lg={8}>
                <div className='buttons-end' style={{ height: '40px' }}>
                  {actionButtons.map(e => (e))}
                </div>
              </Col>
            </Row>
            <div className='dropdown-section'>
              <Row gutter={8} style={{ marginTop: '10px' }}>
                <Col lg={7}>
                  <FormItem>
                    {getFieldDecorator('filter_client_ref_id', {
                      initialValue: selectedClientId
                    })(
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder='Select Participant'
                        optionFilterProp='children'
                        notFoundContent='Not found'
                        filterOption={(input, option) => this.findClients(input, option)}
                        onChange={(e) => this.handleChangeSelect(e, 'selectedClientId')}
                        disabled={loadingList}
                      >
                        {clientList.map((client, idx) => {
                          return <Option key={`client${idx}`} value={client.ref_id}>
                            <span>{client.first_name} {client.last_name} {client.ndis_number ? <span className='clientId'>({client.ndis_number})</span> : null}</span>
                          </Option>
                        })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col lg={9}>
                  <FormItem>
                    {getFieldDecorator('filter_provider_ref_id', {
                      initialValue: selectedProviderId
                    })(
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder='Select Provider'
                        optionFilterProp='children'
                        notFoundContent='Not found'
                        filterOption={(input, option) => this.findProviders(input, option)}
                        onChange={(e) => this.handleChangeSelect(e, 'selectedProviderId')}
                        disabled={loadingList}
                      >
                        {providerList.map((pvd, idx) => {
                          return <Option key={`provider${idx}`} value={pvd.ref_id}>
                            <span>{pvd.fullname} {pvd.abn ? <span className='clientId'>({pvd.abn})</span> : null}</span>
                          </Option>
                        })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col lg={10}></Col>
              </Row>
              <Row gutter={8} style={{ marginTop: '10px' }}>
                <Col lg={4}>
                  {isShowStatusDate ? (
                    <FormItem>
                      {getFieldDecorator('filter_string_status_date_db', {
                        initialValue: selectedStatusDate
                      })(
                        <DatePicker
                          defaultPickerValue={moment()}
                          allowClear
                          style={{ width: '100%' }}
                          format={dateFormat}
                          placeholder={statusDateLabel}
                          onChange={(date) => this.handleChangeSelect(date, 'selectedStatusDate')}
                          disabled={loadingList}
                        />
                      )}
                    </FormItem>
                  ) : (
                    <FormItem>
                      {getFieldDecorator('filter_string_processed_date_db', {
                        initialValue: selectedProcessAt
                      })(
                        <DatePicker
                          defaultPickerValue={moment()}
                          allowClear
                          style={{ width: '100%' }}
                          format={dateFormat}
                          placeholder={'Process Date'}
                          onChange={(date) => this.handleChangeSelect(date, 'selectedProcessAt')}
                          disabled={loadingList}
                        />
                      )}
                    </FormItem>
                  )}

                </Col>
                <Col lg={4}>
                  <FormItem>
                    {getFieldDecorator('filter_string_invoice_date_db', {
                      initialValue: selectedInvDate
                    })(
                      <DatePicker
                        defaultPickerValue={moment()}
                        allowClear
                        style={{ width: '100%' }}
                        format={dateFormat}
                        placeholder={'Invoice Date'}
                        onChange={(v) => this.handleChangeSelect(v, 'selectedInvDate')}
                        disabled={loadingList}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={8}>
                  <div className='search-input'>
                    <SearchInput
                      placeholder='Enter Invoice # / JID # / Participant Info'
                      onChange={(v) => this.handleChangeSelect(v, 'searchText')}
                      value={searchText}
                      isSearching={isSearching}
                      disabled={(!isSearching && (loadingList || loadingListLoaded))}
                    />
                  </div>
                </Col>
                <Col lg={2} />
                <Col lg={6}>
                  {validator.isNotEmptyArray(list) && !loadingList && !isListItem && !(selectedStatus === 'cancelled' || selectedStatus === 'all' || selectedStatus === 'rejected')
                    ? <div className='buttons-end' style={{ height: '40px', marginRight: '25px' }}>
                      <div style={{ marginRight: '10px', fontSize: '11px', fontWeight: '400', color: '#333' }}>Select / Deselect All ({selectedInvs.length} item{selectedInvs.length === 1 ? '' : 's'} selected)</div>
                      <Checkbox
                        checked={isSelectedAll}
                        onClick={f => this.updateItemSelectAll(f)}
                      />
                    </div>
                    : null}
                </Col>
              </Row>

              {filterSummary ? (
                <div className='summary-section filter'>
                  <Spin spinning={loadingSummary || loadingListLoaded} tip={'Loading...'}>
                    <div className='content'>
                      <div className='item'>
                        <span className='label'>Invoice Count :</span>

                        <span>{filterSummary.count}</span>
                      </div>

                      <div className='item'>
                        <span className='label'>Invoice Total Sum :</span>

                        <span>{formatter.toPrice(filterSummary.subtotal)}</span>
                      </div>

                      <div className='item'>
                        <span className='label'>Client Count :</span>

                        <span>{filterSummary.client_count}</span>
                      </div>

                      <div className='item'>
                        <span className='label'>Provider Count :</span>

                        <span>{filterSummary.provider_count}</span>
                      </div>
                    </div>
                  </Spin>
                </div>
              ) : null}
            </div>

            <div className='list-section'>
              <Skeleton loading={loadingList} active>
                <Spin spinning={loadingListLoaded} tip={'Getting Invoice...'}>
                  {isListItem
                    ? list.map(e => this.getRcvRows(e))
                    : <List cols={columns} rows={list} />}

                  <Pager
                    current={currentPage}
                    size={pageSize}
                    total={total}
                    totalText={`Total ${total} invoices`}
                    onChange={this.changePage}
                    style={{ marginTop: '15px' }}
                    onChangePromptInfo={onChangePromptInfo}
                    onChangePrompt={isListItem && validator.isNotEmptyArray(rcvAmtUpdates)}
                  />
                </Spin>
              </Skeleton>
            </div>

            <StatusUpdateModal
              key={pageKey}
              actionType={statusUpdateAction}
              invoiceList={statusUpdateModalInvList}
              listType={invListType}
              onClose={() => this.triggerStatusUpdateModal()}
              onUpdate={this.onUpdateInvoices}
              visible={showStatusUpdateModal}
            />

            <Modal
              width='600px'
              key={`invimportrcv`}
              visible={isShowImportModal}
              title={'Import Invoice Received Amount'}
              onCancel={loadingSummit ? () => { } : () => { this.triggerRcvImportUploadModal(false) }}
              footer={[
                <Loading loading={false} blur>
                  <Button
                    key='rcvclose'
                    ghost
                    feedback={loadingSummit}
                    onClick={loadingSummit ? () => { } : () => { this.triggerRcvImportUploadModal(false) }}
                  >
                    Close
                  </Button>
                  <Button
                    style={{ backgroundColor: '#3d34eb', color: '#FFF' }}
                    key='rcvconfirm'
                    ghost
                    feedback={loadingSummit}
                    onClick={() => this.handleSubmitImportRequest()}
                  >
                    Confirm
                  </Button>
                </Loading>
              ]}
            >
              <Loading loading={loadingSummary} blur>
                <Form>
                  <div className='inv-title' style={{ margin: '0px' }}>Upload the specified file for the request.</div>

                  <div className='panel-content error'>{ReportRequestType.INV_RCV_IMPORT.uploadText}</div>

                  <div style={{ marginTop: '20px' }}>
                    <Upload
                      method={'POST'}
                      // action={`${apiHostname}/private/api/report/scheduler/file`}
                      action={`${apiHostname}/private/api/report-pace/scheduler/file`}
                      name={'file'}
                      onRemove={this.fileRemove}
                      onChange={this.fileChange}
                      beforeUpload={this.fileSet}
                      headers={{ Authorization: `Bearer ${auth.getCurrentToken()}` }}
                      fileList={fileList}
                      multiple={false}
                    >
                      <Button>
                        <Icon type='upload' /> Select File
                      </Button>
                    </Upload>
                  </div>
                  {uploadErrorMsg
                    ? <div className='panel-content error'>{uploadErrorMsg}</div>
                    : null}
                </Form>
              </Loading>
            </Modal>
          </Form>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchList({ page = 1, searchText, selectedStatus }, isRedirect = true) {
    const { filter, loading, selectedStatus: prevStatus, searchText: prevSearchText, sort } = this.state

    if (isRedirect) {
      this.redirectUrl({ page, q: searchText !== undefined ? searchText : prevSearchText, status: selectedStatus || prevStatus })
    }

    this.fetchInvoicesList({ currentPage: page, filter, loading, selectedStatus: selectedStatus || prevStatus, searchText: searchText !== undefined ? searchText : prevSearchText, sort })
  }

  fetchInvoicesList = async ({ loading = true, currentPage = 1, filter = {}, selectedStatus = firstStatus.value, sort = {}, searchText }) => {
    if (!hasAccess(Permissions.INVOICE.INFO.LIST)) return

    try {
      const { fetchInvoicesList } = this.props

      if (selectedStatus === firstStatus.value) {
        delete filter.status
      } else if (selectedStatus) {
        if (selectedStatus === 'client-pending-pms') {
          filter.status = {
            $or: [
              { condition: '=', value: selectedStatus },
              { condition: '=', value: 'client-pending-auth' }
            ]
          }
        } else {
          filter.status = { condition: '=', value: selectedStatus }
        }
      }

      this.setState({ currentPage, filter, searchText })
      fetchInvoicesList({ loading, currentPage, pageSize, filter, sort, searchText: filter.$and ? '' : searchText, isListItem: selectedStatus === 'pymt-req-submit' })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load invoices successfully. Please try again later.')
    }
  }

  fetchInoviceSummary = async () => {
    const { invListType } = this.state
    this.props.fetchInvoicesSummary({ loading: true, invoiceType: invListType })
  }

  fetchDataList = async () => {
    const { invListType } = this.state
    this.setState({ loadingData: true, loadingDataSelect: true })

    const s = await invoiceListService.getAllStatus('options')
    const statusList = validator.isNotEmptyArray(s) ? s.filter(e => e.reference_2 && e.reference_2.indexOf('opts') > -1) : []
    const { statusCurrentList } = getCurrentStatusList({ ...this.state, statusList })
    const selectedStatus = this.state.selectedStatus ? this.state.selectedStatus : validator.isNotEmptyArray(statusCurrentList) ? statusCurrentList[0].value : firstStatus.value
    const { columns, actionButtons } = this.getInvoiceListType(invListType, selectedStatus)

    this.setState({
      actionButtons,
      columns,
      statusList,
      statusCurrentList,
      selectedStatus,
      loadingData: false
    }, async () => {
      this.fetchList({ page: 1 }, false)

      const c = await clientService.listAllClients()
      const p = await providerService.listAllProviders()

      const clientList = validator.isNotEmptyArray(c) ? [firstClient].concat(c) : []
      const providerList = validator.isNotEmptyArray(p) ? [firstProvider].concat(p) : []

      this.setState({
        clientList,
        providerList,
        loadingDataSelect: false
      })
    })
  }

  changePage = (currentPage = 1) => {
    this.fetchList({ page: currentPage })
    this.setState({ rcvAmtUpdates: [] })
    // const { filter, selectedStatus, searchText, sort } = this.state
    // console.log('change page', selectedStatus)
    // this.redirectUrl({ page: currentPage, q: searchText, status: selectedStatus })
    // this.fetchInvoicesList({ currentPage, filter, selectedStatus, searchText, sort })
  }

  handleChangeOption = (e) => {
    const value = e.target.value
    const newFilter = this.resetFilterOption()

    this.setState({
      filter: newFilter,
      selectedStatus: value,
      selectedInvs: [],
      isSelectedAll: false,
      sort: value === 'all'
        ? Object.assign({}, AllSorting)
        : value === 'pymt-req-submit'
          ? Object.assign({}, ToRcvSorting)
          : Object.assign({}, StatsSorting)
    }, async () => {
      this.fetchInoviceSummary()
      this.fetchList({ page: 1, selectedStatus: value })
    })
  }

  handleChangeSelect = (e, type) => {
    if (type === 'searchText') {
      this.onSearchName(e)
    } else if (type === 'selectedProcessAt') {
      const v = e ? formatter.toDBStringDate(e) : null
      this.onSearchValue(v, type)
    } else if (type === 'selectedInvDate') {
      const v = e ? formatter.toDBStringDate(e) : null
      this.onSearchValue(v, type)
    } else if (type === 'selectedStatusDate') {
      const v = e ? formatter.toDBStringDate(e) : null
      this.onSearchValue(v, type)
    } else if (type === 'selectedClientId' || type === 'selectedProviderId') {
      this.onSearchValue(e, type)
    }
  }

  onSearchName(value) {
    this.setState({ isSearching: true })
    this.fetchList({ page: 1, searchText: value })
  }

  onSearchValue(value, type) {
    this.setState({ [type]: value }, () => {
      const { filter } = this.state

      if (type === 'selectedProcessAt') {
        if (value === null || value === '') {
          delete filter['string_processed_date_db']
        } else {
          filter.string_processed_date_db = { condition: '=', value: value }
        }
      } else if (type === 'selectedInvDate') {
        if (value === null || value === '') {
          delete filter['string_invoice_date_db']
        } else {
          filter.string_invoice_date_db = { condition: '=', value: value }
        }
      } else if (type === 'selectedStatusDate') {
        if (value === null || value === '') {
          delete filter['string_status_date_db']
        } else {
          filter.string_status_date_db = { condition: '=', value: value }
        }
      } else if (type === 'selectedProviderId') {
        if (value === null || value === '') {
          delete filter['provider_ref_id']
        } else {
          filter.provider_ref_id = { condition: '=', value: value }
        }
      } else if (type === 'selectedClientId') {
        if (value === null || value === '') {
          delete filter['client_ref_id']
        } else {
          filter.client_ref_id = { condition: '=', value: value }
        }
      }

      this.fetchList({ page: 1, [type]: value })
    })
  }

  onRefreshCurrentPage = () => {
    const { currentPage } = this.state
    this.fetchList({ page: currentPage })
    this.fetchInoviceSummary()
    this.setState({ rcvAmtUpdates: [], selectedInvs: [] })
  }

  resetFilterOption = () => {
    const { form } = this.props
    const { filter } = this.state

    delete filter['string_processed_date_db']
    delete filter['string_invoice_date_db']
    delete filter['string_status_date_db']
    delete filter['provider_ref_id']
    delete filter['client_ref_id']

    form.setFieldsValue({
      filter_string_processed_date_db: undefined,
      filter_string_invoice_date_db: undefined,
      filter_string_status_date_db: undefined,
      filter_provider_ref_id: undefined,
      filter_client_ref_id: undefined,
    })

    this.setState({
      filter,
      selectedClientId: undefined,
      selectedProviderId: undefined,
      selectedProcessAt: undefined,
      selectedInvDate: undefined,
      selectedStatusDate: undefined
    })

  }

  triggerStatusUpdateModal = (isUpdateModal = false, action = null, invoiceInfo = undefined) => {
    const { loadingList, loadingListLoaded, selectedInvs } = this.state
    if (loadingList || loadingListLoaded) return

    let statusUpdateModalInvList = []
    if (invoiceInfo) {
      statusUpdateModalInvList = [invoiceInfo]
    } else {
      statusUpdateModalInvList = selectedInvs.slice()
    }

    if (isUpdateModal) {
      if (validator.isNotEmptyArray(statusUpdateModalInvList)) {
        this.setState({
          showStatusUpdateModal: isUpdateModal,
          statusUpdateAction: action,
          statusUpdateModalInvList
        })
      } else {
        notify.error(`Unable to ${action}`, 'Please select at least one invoice.')
      }
    } else {
      this.setState({
        showStatusUpdateModal: isUpdateModal,
        statusUpdateAction: null,
        statusUpdateModalInvList: []
      })
    }
  }

  triggerStatusUpdateModalRcv = (isUpdateModal = false, action = null) => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { loadingList, loadingListLoaded, list, rcvAmtUpdates } = this.state
    if (loadingList || loadingListLoaded) return

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        let statusUpdateModalInvList = []

        if (validator.isNotEmptyArray(rcvAmtUpdates)) {
          for (let i = 0; i < rcvAmtUpdates.length; i++) {
            const upt = rcvAmtUpdates[i]
            const keySps = upt.split('_')

            if (validator.isNotEmptyArray(keySps)) {
              const invId = parseInt(keySps[1])
              const invItemId = parseInt(keySps[2])
              const inv = list.find(e => e.id === invId)

              if (inv && inv.id && (formatter.toPriceFloat(values[`received_subtotal${upt}`]) || values[`is_closed${upt}`] === true)) {
                const itm = inv.items.find(e => e.id === invItemId)

                const data = {
                  id: invId,
                  item_id: invItemId,
                  update_received_subtotal: formatter.toPriceFloat(values[`received_subtotal${upt}`]),
                  update_received_notes: values[`received_notes${upt}`],
                  update_received_date: values[`received_date${upt}`],
                  update_is_closed: values[`is_closed${upt}`],
                  id_numbering: inv.id_numbering,
                  invoice_number: inv.invoice_number,
                  provider_name: inv.provider_name,
                  client_name: inv.client_name,
                  item_inv_date: itm.inv_date,
                  item_cat_item_name: itm.cat_item_name,
                  item_cat_item_identifier: itm.cat_item_identifier,
                  item_seq: itm.item_seq,
                  item_subtotal: itm.subtotal,
                  item_rcv_subtotal: itm.received_subtotal
                }

                statusUpdateModalInvList.push(data)
              }
            }
          }

          if (validator.isNotEmptyArray(statusUpdateModalInvList)) {
            this.setState({
              showStatusUpdateModal: isUpdateModal,
              statusUpdateAction: action,
              statusUpdateModalInvList
            })
          } else {
            notify.error(`Unable to update payment`, 'Please update at least one invoice.')
          }
        } else {
          notify.error(`Unable to update payment`, 'Please update at least one invoice.')
        }
      } else {
        notify.error('Unable to update payment', 'Please resolve the issue on inputs.')
      }
    })
  }

  getInvoiceListType = (invType, selectedStatus) => {
    let columns = []
    let actionButtons = []
    if (invType === InvoiceListType.INV_LIST_PM) {
      columns = this.getPMColumns(selectedStatus, invType, this)
      actionButtons = this.getActionButtons(selectedStatus, invType, this)
    } else if (invType === InvoiceListType.INV_LIST_STD) {
      columns = this.getPMColumns(selectedStatus, invType, this)
      actionButtons = this.getActionButtons(selectedStatus, invType, this)
    }

    return { columns, actionButtons }
  }

  getActionButtons = (status, invType, that) => {
    const buttons = []
    const btnStyle = { marginLeft: '10px' }

    const authoriseButtons = (
      <Button key={`btn-${invType}-${status}`} style={{ ...btnStyle, backgroundColor: '#00ff00', color: '#1d2c47' }} onClick={() => this.triggerStatusUpdateModal(true, InvoiceUpdateType.INV_UPDATE_AUTHORISED)}>
        Approve
      </Button>
    )

    const rcvButtons = (
      <div className='buttons-end'>
        {hasAccess(Permissions.REPORT.SCHEDULER.LIST) && hasAccess(Permissions.REPORT.SCHEDULER.CREATE)
          ? <Button key={`btn-${invType}-${status}-import-rcv`} style={{ ...btnStyle, backgroundColor: '#1d2c47' }} onClick={() => this.triggerRcvImportUploadModal(true)}>
            Import PYMT Summary
          </Button>
          : null}
        <Button key={`btn-${invType}-${status}`} style={{ ...btnStyle, backgroundColor: '#eb7a34' }} onClick={() => this.triggerStatusUpdateModalRcv(true, InvoiceUpdateType.INV_UPDATE_RCV_PYMT)}>
          Update Payment
        </Button>
      </div>
    )

    const pymtReqButtons = (
      <Button key={`btn-${invType}-${status}`} style={{ ...btnStyle, backgroundColor: '#274faa' }} onClick={() => this.triggerStatusUpdateModal(true, InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST)}>
        Generate Pymt Req
      </Button>
    )

    const abaRmtButtons = (
      <Button key={`btn-${invType}-${status}`} style={{ ...btnStyle, backgroundColor: '#0033cc' }} onClick={() => this.triggerStatusUpdateModal(true, InvoiceUpdateType.INV_UPDATE_ABA_RMT)}>
        Generate ABA/REMIT
      </Button>
    )

    const deleteButtons = (
      <Button key={`btn-${invType}-${status}`} style={{ ...btnStyle, backgroundColor: '#1d2c47' }} onClick={() => this.triggerStatusUpdateModal(true, InvoiceUpdateType.INV_UPDATE_DELETE)}>
        Delete
      </Button>
    )

    if (status === 'client-pending-pms') {
      buttons.push(authoriseButtons)
    } else if (status === 'client-authed') {
      buttons.push(pymtReqButtons)
    } else if (status === 'pymt-req-submit') {
      buttons.push(rcvButtons)
    } else if (status === 'pymt-to-pay') {
      buttons.push(abaRmtButtons)
    } else if (status === 'inv-drafted') {
      buttons.push(deleteButtons)
    } else if (status === 'rejected') {
    } else if (status === 'cancelled') {
    } else if (status === 'all') {
    }

    return buttons
  }

  getPMColumns = (status, invType, that) => {
    let column = []

    const id = {
      title: 'JID',
      width: 2.5,
      render: ({ id_numbering, status_reference, comm_id, comm_scheduled_at, invoice_private_comment, inv_item_budget_id }) => {
        return <span>
          {formatter.capitalize(id_numbering, false)}
          {(status_reference === '003' && comm_id)
            ? <span>&nbsp;
              <Tooltip mouseLeaveDelay={0} title={`Comm scheduled at ${formatter.toStandardDate(comm_scheduled_at)}`} >
                <Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '11pt', cursor: 'pointer' }} />
              </Tooltip>
            </span>
            : null}
          {invoice_private_comment
            ? <span>&nbsp;
              <Tooltip mouseLeaveDelay={0} title={`Private Notes: ${invoice_private_comment || ''}`} >
                <Icon type='edit' theme='filled' style={{ color: '#eb7a34', fontSize: '11pt', cursor: 'pointer' }} />
              </Tooltip>
            </span>
            : null}
          {!inv_item_budget_id
            ? <span>&nbsp;
              <Tooltip mouseLeaveDelay={0} title={`The Service Booking Attached with this invoice is no longer active/within period/deleted.`} >
                <Icon type='warning' theme='filled' style={{ color: 'red', fontSize: '12pt', cursor: 'pointer' }} />
              </Tooltip>
            </span>
            : null}
        </span>
      }
    }

    const processAt = {
      title: 'Processed',
      width: 3,
      render: ({ processed_at }) => {
        return processed_at
          ? <div>{formatter.toShortDate(processed_at)} ({formatter.toDayCount(processed_at)})</div>
          : <div>N/A</div>
      }
    }

    const authedDate = {
      title: "Auth'd Date",
      width: 3,
      render: ({ status_date }) => {
        return status_date
          ? <div>{formatter.toShortDate(status_date)} ({formatter.toDayCount(status_date)})</div>
          : <div>N/A</div>
      }
    }

    const toPayDate = {
      title: "Received Date",
      width: 3,
      render: ({ status_date }) => {
        return status_date
          ? <div>{formatter.toShortDate(status_date)} ({formatter.toDayCount(status_date)})</div>
          : <div>N/A</div>
      }
    }

    const invDate = {
      title: 'Inv Date',
      width: 2,
      render: ({ invoice_date }) => { return <div>{formatter.toShortDate(invoice_date)}</div> }
    }

    const invNo = {
      title: 'Inv No.',
      width: 2,
      render: ({ invoice_number }) => { return <div>{invoice_number}</div> }
    }

    const provider = {
      title: 'Provider',
      width: 4,
      render: ({ provider_ref_id, provider_name, invoice_provider_name }) => {
        return provider_ref_id
          ? <div className='pmcol-name'><a href={`/providers/${provider_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{provider_name}</a></div>
          : invoice_provider_name
            ? <span className='pmcol-name italic'>
              <span className='label-indicator reimb'>R</span>
              {invoice_provider_name}
            </span>
            : <div className='pmcol-name'>N/A</div>
      }
    }

    const client = {
      title: 'Participant',
      width: 4,
      render: ({ client_ref_id, client_name }) => { return <div className='pmcol-name'><a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_name}</a></div> }
    }

    const itemQty = {
      title: 'Item',
      width: 1,
      render: ({ qty }) => { return <div>{qty}</div> }
    }

    const invSubtotal = {
      title: 'Inv Total',
      width: 2,
      render: ({ subtotal }) => { return <div>{formatter.toPrice(subtotal)}</div> }
    }

    const invRcvSubtotal = {
      title: 'Rcv Total',
      width: 2,
      render: ({ received_subtotal }) => { return <div>{formatter.toPrice(received_subtotal)}</div> }
    }

    const statusCol = {
      title: 'Status',
      width: 2,
      render: ({ status, status_name, status_color }) => { return <div className='status' style={{ backgroundColor: status_color || undefined }}>{status_name}</div> }
    }

    const draftedDate = {
      title: 'Drafted At',
      width: 3,
      render: ({ status_date }) => { return <div>{formatter.toStandardLongDate(status_date)}</div> }
    }

    const rejectedDate = {
      title: 'Rejected At',
      width: 3,
      render: ({ status_date }) => { return <div>{formatter.toStandardLongDate(status_date)}</div> }
    }

    const cancelledDate = {
      title: 'Cancelled At',
      width: 3,
      render: ({ status_date }) => { return <div>{formatter.toStandardLongDate(status_date)}</div> }
    }

    const invCheckButton = (item) => {
      const isChecked = that.state.selectedInvs.find(e => e.id === item.id) || false

      return hasAccess(Permissions.INVOICE.INFO.UPDATE)
        ? <div className='button-checklist' style={{ color: '#D66E00' }}>
          <Checkbox
            checked={isChecked}
            onClick={(e) => this.updateItemSelect(e, item)}
          />
        </div>
        : null
    }

    const invViewIcon = (item) => {
      return hasAccess(Permissions.INVOICE.INFO.READ)
        ? <Link to={`${urlRedirect}/${item.ref_id}/info`}>
          <div className='button-last' style={{ color: '#D66E00' }}>
            <Tooltip mouseLeaveDelay={0} title='Manage invoice'>
              <Icon type='form' />
            </Tooltip>
          </div>
        </Link>
        : null
    }

    const invAuthoriseIcon = (item) => {
      const isAuthorised = item.status_reference >= '005'
      return !hasAccess(Permissions.INVOICE.INFO.UPDATE)
        ? null
        : !isAuthorised
          ? <Tooltip mouseLeaveDelay={0} title='Authorise Invoice'>
            <div style={{ cursor: 'pointer' }} onClick={() => this.triggerStatusUpdateModal(true, InvoiceUpdateType.INV_UPDATE_AUTHORISED, item)}>
              <Icon type='check-circle' style={{ color: '#aaa', marginTop: '2px', marginRight: '10px', fontSize: '15px' }} />
            </div>
          </Tooltip>
          : <Icon type='check-circle' theme='filled' style={{ color: '#2ec77a', marginTop: '2px', marginRight: '10px' }} />
    }

    const invRejectIcon = (item) => {
      const isRejected = item.status_reference === '010'
      return !hasAccess(Permissions.INVOICE.INFO.UPDATE)
        ? null
        : !isRejected
          ? <Tooltip mouseLeaveDelay={0} title='Reject Invoice'>
            <div style={{ cursor: 'pointer' }} onClick={() => this.triggerStatusUpdateModal(true, InvoiceUpdateType.INV_UPDATE_REJECTED, item)}>
              <Icon type='close-circle' style={{ color: '#aaa', marginTop: '2px', marginRight: '10px', fontSize: '15px' }} />
            </div>
          </Tooltip>
          : <Icon type='close-circle' theme='filled' style={{ color: '#cc0000', marginTop: '2px', marginRight: '10px' }} />
    }

    const actionsAuthorise = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isStatusMatched = item.status === status
        const isDeleted = item.is_delete === true

        return isDeleted
          ? null
          : <div className='buttons' key='authorise'>
            {/* { isStatusMatched ? invAuthoriseIcon(item) : null } */}
            {isStatusMatched ? invRejectIcon(item) : null}
            {isStatusMatched ? invCheckButton(item) : null}
            {invViewIcon(item)}
          </div>
      }
    }


    const actionsClaim = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isStatusMatched = item.status === status
        const isDeleted = item.is_delete === true

        return isDeleted
          ? null
          : <div className='buttons' key='claim'>
            {isStatusMatched ? invRejectIcon(item) : null}
            {isStatusMatched ? invCheckButton(item) : null}
            {invViewIcon(item)}
          </div>
      }
    }

    const actionsReject = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isStatusMatched = item.status === status
        const isDeleted = item.is_delete === true

        return isDeleted
          ? null
          : <div className='buttons' key='reject'>
            {isStatusMatched ? invRejectIcon(item) : null}
            {invViewIcon(item)}
          </div>
      }
    }

    const actionsDrafted = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isStatusMatched = item.status === status
        const isDeleted = item.is_delete === true

        return isDeleted
          ? null
          : <div className='buttons' key='drafted'>
            {isStatusMatched ? invCheckButton(item) : null}
            {invViewIcon(item)}
          </div>
      }
    }

    const actionsToPay = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isStatusMatched = item.status === status
        const isDeleted = item.is_delete === true

        return isDeleted
          ? null
          : <div className='buttons' key='topay'>
            {isStatusMatched ? invRejectIcon(item) : null}
            {isStatusMatched ? invCheckButton(item) : null}
            {invViewIcon(item)}
          </div>
      }
    }

    const actions = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isDeleted = item.is_delete === true

        return isDeleted
          ? null
          : <div className='buttons' key='normal'>
            {invViewIcon(item)}
          </div>
      }
    }

    if (status === 'client-pending-pms') {
      column = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, actionsAuthorise]
    } else if (status === 'client-authed') {
      column = [id, authedDate, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, actionsClaim]
    } else if (status === 'pymt-req-submit') {
      column = [id]
    } else if (status === 'pymt-to-pay') {
      column = [id, toPayDate, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, actionsToPay]
    } else if (status === 'inv-drafted') {
      column = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, draftedDate, actionsDrafted]
    } else if (status === 'rejected') {
      column = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, rejectedDate, actionsReject]
    } else if (status === 'cancelled') {
      column = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, cancelledDate, actions]
    } else if (status === 'all') {
      column = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, statusCol, actions]
    }

    return column
  }

  getRcvRows = (item = {}) => {
    const status = item.status
    const { form } = this.props
    const { rcvAmtUpdates, selectedNotesInput, loadingList, loadingListLoaded, currentTS, disabledFields } = this.state
    const { getFieldDecorator, getFieldValue } = form
    // upper row
    const id = {
      title: 'JID',
      width: 2.5,
      render: ({ id_numbering, invoice_private_comment, inv_item_budget_id }) => {
        return <span>
          {formatter.capitalize(id_numbering, false)}
          {invoice_private_comment
            ? <span>&nbsp;
              <Tooltip mouseLeaveDelay={0} title={`Private Notes: ${invoice_private_comment || ''}`} >
                <Icon type='edit' theme='filled' style={{ color: '#eb7a34', fontSize: '11pt', cursor: 'pointer' }} />
              </Tooltip>
            </span>
            : null}
          {!inv_item_budget_id
            ? <span>&nbsp;
              <Tooltip mouseLeaveDelay={0} title={`The Service Booking Attached with this invoice is no longer active/within period/deleted.`} >
                <Icon type='warning' theme='filled' style={{ color: 'red', fontSize: '12pt', cursor: 'pointer' }} />
              </Tooltip>
            </span>
            : null}
        </span>
      }
    }

    const claimedDate = {
      title: 'Claimed Date',
      width: 3,
      render: ({ status_date }) => {
        return status_date
          ? <div>{formatter.toShortDate(status_date)} ({formatter.toDayCount(status_date)})</div>
          : <div>N/A</div>
      }
    }

    const invDate = {
      title: 'Inv Date',
      width: 2,
      render: ({ invoice_date }) => { return <div>{formatter.toShortDate(invoice_date)}</div> }
    }

    const invNo = {
      title: 'Inv No.',
      width: 2,
      render: ({ invoice_number }) => { return <div>{invoice_number}</div> }
    }

    const provider = {
      title: 'Provider',
      width: 4,
      render: ({ provider_ref_id, provider_name, invoice_provider_name }) => {
        return provider_ref_id
          ? <div className='pmcol-name'><a href={`/providers/${provider_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{provider_name}</a></div>
          : invoice_provider_name
            ? <span className='pmcol-name italic'>
              <span className='label-indicator reimb'>R</span>
              {invoice_provider_name}
            </span>
            : <div className='pmcol-name'>N/A</div>
      }
    }

    const client = {
      title: 'Participant',
      width: 4,
      render: ({ client_ref_id, client_name }) => { return <div><a className='a' href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_name}</a></div> }
    }

    const itemQty = {
      title: 'Item',
      width: 1,
      render: ({ qty }) => { return <div>{qty}</div> }
    }

    const invSubtotal = {
      title: 'Inv Total',
      width: 2,
      render: ({ subtotal }) => { return <div>{formatter.toPrice(subtotal)}</div> }
    }

    const invRcvSubtotal = {
      title: 'Rcv Total',
      width: 2,
      render: ({ received_subtotal }) => { return <div>{formatter.toPrice(received_subtotal)}</div> }
    }

    const invViewIcon = (item) => {
      return hasAccess(Permissions.INVOICE.INFO.READ)
        ? <Link to={`${urlRedirect}/${item.ref_id}/info`}>
          <div className='button-last' style={{ color: '#D66E00' }}>
            <Tooltip mouseLeaveDelay={0} title='Manage invoice'>
              <Icon type='form' />
            </Tooltip>
          </div>
        </Link>
        : null
    }

    const invRejectIcon = (item) => {
      const isRejected = item.status_reference === '010'
      return !hasAccess(Permissions.INVOICE.INFO.UPDATE)
        ? null
        : !isRejected
          ? <Tooltip mouseLeaveDelay={0} title='Reject Invoice'>
            <div style={{ cursor: 'pointer' }} onClick={() => this.triggerStatusUpdateModal(true, InvoiceUpdateType.INV_UPDATE_REJECTED, item)}>
              <Icon type='close-circle' style={{ color: '#aaa', marginTop: '2px', marginRight: '10px', fontSize: '15px' }} />
            </div>
          </Tooltip>
          : <Icon type='close-circle' theme='filled' style={{ color: '#cc0000', marginTop: '2px', marginRight: '10px' }} />
    }

    const actionsRcv = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isStatusMatched = item.status === status
        const isDeleted = item.is_delete === true

        return isDeleted
          ? null
          : <div className='buttons' key='authorise'>
            {isStatusMatched ? invRejectIcon(item) : null}
            {invViewIcon(item)}
          </div>
      }
    }

    const infoCol = [id, claimedDate, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, actionsRcv]

    // lower row
    const seq = {
      title: '',
      width: 1,
      render: ({ item_seq }) => { return <div>{formatter.capitalize(item_seq, false)}</div> }
    }

    const invStartDate = {
      title: 'Service Start',
      width: 2,
      render: ({ inv_date }) => { return <div>{formatter.toShortDate(inv_date)}</div> }
    }

    const invEndDate = {
      title: 'Service End',
      width: 2,
      render: ({ inv_end_date }) => { return <div>{formatter.toShortDate(inv_end_date)}</div> }
    }

    const supportItem = {
      title: 'Item Number',
      width: 6,
      render: ({ cat_item_name, cat_item_identifier }) => { return <span>{cat_item_name} ({cat_item_identifier})</span> }
    }

    const unit = {
      title: 'Unit',
      width: 1,
      render: ({ unit }) => { return <div>{unit}</div> }
    }

    const invRate = {
      title: 'Inv Rate',
      width: 2,
      render: ({ inv_rate }) => { return <div>{formatter.toPrice(inv_rate)}</div> }
    }

    const invAmt = {
      title: 'Inv Amt',
      width: 2,
      render: ({ subtotal }) => { return <div>{formatter.toPrice(subtotal)}</div> }
    }

    const rcvAmt = {
      title: 'Rcv\'d',
      width: 2,
      render: ({ received_subtotal, is_closed }) => { return <div style={{ color: is_closed ? '#2ec77a' : undefined }}>{formatter.toPrice(received_subtotal)}</div> }
    }

    const remainingAmt = {
      title: 'Remaining',
      width: 2,
      render: ({ subtotal, received_subtotal, is_closed }) => { return <div style={{ color: is_closed ? '#2ec77a' : undefined }}>{formatter.toPrice(subtotal - received_subtotal)}</div> }
    }

    const crdAvailable = {
      title: 'Credit?',
      width: 1,
      render: ({ credit_id }) => {
        return <div>
          {credit_id
            ? <Icon type='check-circle' theme='filled' style={{ color: '#2ec77a' }} />
            : <Icon type='close-circle' theme='filled' style={{ color: '#aaa' }} />}
        </div>
      }
    }

    const rcvDateInput = {
      title: 'Rcv Date',
      width: 1,
      render: (itm) => {
        const { id, inv_id, inv_date, item_seq, received_remaining, is_closed } = itm
        const idf = `_${inv_id}_${id}_${item_seq}_${currentTS}`

        return <div>
          <FormItem
            style={{ width: '120px' }}
          >
            {getFieldDecorator(`received_date${idf}`, {
              initialValue: null,
              rules: [
                { validator: (r, v, c) => this.validateRcvAmtDate(r, v, c, inv_date) }
              ]
            })(
              <DatePicker
                defaultPickerValue={moment(new Date())}
                format={dateFormat}
                placeholder={''}
              />
            )}
          </FormItem>
        </div>
      }
    }

    const rcvInput = {
      title: 'Rcv Amt',
      width: 4,
      render: (itm) => {
        const { id, inv_id, item_seq, received_remaining, is_closed } = itm
        const idf = `_${inv_id}_${id}_${item_seq}_${currentTS}`
        const v = getFieldValue(`received_subtotal${idf}`)
        const isRcvLessThanRemaining = (v === null || v === undefined || v === '') ? false : formatter.toPriceFloat(v) < formatter.toPriceFloat(received_remaining)

        return (
          <FormItem
            extra={isRcvLessThanRemaining ? <div style={{ color: '#D66E00' }}><strong>NOT full Inv Amt!</strong></div> : null}
          >
            {getFieldDecorator(`received_subtotal${idf}`, {
              initialValue: null,
              rules: [
                { whitespace: true, message: 'Please enter rcv amt' },
                { validator: (r, v, c) => this.validateInvItemRcvAmount(r, v, c, received_remaining, idf) }
              ]
            })(
              <Input
                onChange={(e) => this.onInputChange(e, 'received_subtotal', 'is_checked', idf, received_remaining)}
                onPaste={this.handlePasteAmt(`received_subtotal${idf}`, idf, received_remaining)}
                addonBefore='$'
                disabled={is_closed || loadingList || loadingListLoaded}
              />
            )}
          </FormItem>
        )
      }
    }

    const rcvNoteInput = {
      title: 'Rcv Notes',
      width: 2,
      render: (item) => {
        const { id, inv_id, item_seq, received_remaining, is_closed } = item
        const idf = `_${inv_id}_${id}_${item_seq}_${currentTS}`
        const v = getFieldValue(`received_notes${idf}`)
        const isNoteInput = selectedNotesInput.find(e => e === id)

        return (
          <div>
            <div onClick={() => this.updateRcvNoteExpand(id)}>
              <Icon
                type={isNoteInput ? 'minus-circle' : 'plus-circle'}
                theme='filled'
                style={{ fontSize: '15px', color: v ? 'red' : '#333', padding: '10px 5px 6px 6px', cursor: 'pointer' }}
              />
            </div>
            <FormItem
              style={{
                display: isNoteInput ? 'inline' : 'none',
                position: 'absolute',
                backgroundColor: '#fff',
                border: '1px solid #88888833',
                borderRadius: '8px',
                padding: '9px',
                zIndex: 1000,
                width: '160px'
              }}
            >
              {getFieldDecorator(`received_notes${idf}`, {
                initialValue: null,
                rules: [
                  { whitespace: true, message: 'Please enter rcv notes' },
                  { validator: (r, v, c) => this.validateInvItemRcvNote(r, v, c, idf) }
                ]
              })(
                <TextArea
                  onChange={(e) => this.onInputChange(e, 'received_notes', 'is_checked', idf, received_remaining)}
                  row={2}
                  disabled={is_closed || loadingList || loadingListLoaded}
                />
              )}
            </FormItem>
          </div>
        )
      }
    }

    const itemClosedSw = {
      title: 'Closed?',
      width: 1,
      render: ({ id, inv_id, item_seq, received_remaining, is_closed }) => {
        const idf = `_${inv_id}_${id}_${item_seq}_${currentTS}`

        return (
          <div className='buttons'>
            <FormItem>
              {getFieldDecorator(`is_closed${idf}`, {
                initialValue: is_closed,
                valuePropName: 'checked'
              })(
                <Switch
                  disabled={disabledFields[`is_closed${idf}`]}
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  onChange={(e) => this.onInputChange(e, 'is_closed', 'is_checked', idf, received_remaining, is_closed)}
                />
              )}
            </FormItem>
          </div>
        )
      }
    }

    const itemUpdateCheck = {
      title: '',
      width: 1,
      render: ({ id, inv_id, item_seq, is_closed }) => {
        const idf = `_${inv_id}_${id}_${item_seq}_${currentTS}`
        const isChecked = rcvAmtUpdates.find(e => e === idf)
        // console.log('checked item', `is_checked${idf}`, isChecked)

        return (
          <div style={{ padding: '8px 5px 6px 6px' }}>
            <Checkbox
              key={`checkbxc${idf}`}
              checked={!!isChecked}
              onClick={e => this.onRcvItemCheckChange(e, idf)}
              disabled={is_closed}
            />
          </div>

        )
      }
    }

    const itemCol = [seq, invStartDate, invEndDate, supportItem, unit, invRate, invAmt, rcvAmt, remainingAmt, crdAvailable, rcvInput, rcvDateInput, rcvNoteInput, itemClosedSw, itemUpdateCheck]

    const toTableWidth = (width) => {
      return { width: `${(100 * width / 36).toFixed(2)}%` }
    }

    const isItems = validator.isNotEmptyArray(item.items)

    return <div key={`rowxv-${item.id_numbering}`} className='rcv-row'>
      <Form>
        <table>
          <tbody>
            <tr>
              {infoCol.map((col, idx) => {
                const { key, onSort, onSortItem, title, titleRender, width } = col
                return (
                  <td key={`cdx${idx}`} className='row-title' style={{ ...toTableWidth(width) }}>
                    <span>{title}</span>
                  </td>
                )
              })}
            </tr>
            <tr>
              {infoCol.map((col, idx) => {
                const { key, onSort, onSortItem, title, titleRender, width, additional } = col
                return (
                  <td key={`cdv${idx}`} className={`row-content ${isItems ? 'row-border' : ''}`} style={{ ...toTableWidth(width) }}>
                    {col.render
                      ? col.render(item, idx, additional)
                      : col.key
                        ? item[col.key]
                        : null}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
        {isItems
          ? <table className='table-second'>
            <tbody>
              <tr>
                {itemCol.map((col, idx) => {
                  const { key, onSort, onSortItem, title, titleRender, width } = col
                  return (
                    <td key={`cdxc${idx}`} className='row-title' style={{ ...toTableWidth(width) }}>
                      <span>{title}</span>
                    </td>
                  )
                })}
              </tr>
              {item.items.map(f => {
                return (
                  <tr>
                    {itemCol.map((col, idx) => {
                      const { key, onSort, onSortItem, title, titleRender, width, additional } = col
                      return (
                        <td key={`cdvc${idx}`} className={`row-content`} style={{ ...toTableWidth(width) }}>
                          {col.render
                            ? col.render(f, idx, additional)
                            : col.key
                              ? f[col.key]
                              : null}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          : null}
      </Form>
    </div>
  }

  updateItemSelect = (e, item) => {
    const check = e.target.checked
    let { selectedInvs, list } = this.state

    const data = {
      id: item.id,
      invoice_number: item.invoice_number,
      processed_at: item.processed_at,
      status: item.status,
      status_reference: item.status_reference,
      status_date: item.status_date,
      client_name: item.client_name,
      provider_name: item.provider_name,
      subtotal: item.subtotal,
      received_subtotal: item.received_subtotal
    }

    const c = selectedInvs.findIndex(e => e.id === data.id)
    if (c > -1) {
      selectedInvs.splice(c, 1)
    } else {
      selectedInvs.push(data)
    }

    this.setState({
      selectedInvs
    }, () => {
      checkItemSelect(selectedInvs, list, this)
    })
  }

  updateItemSelectAll = (e) => {
    const check = e.target.checked

    const { selectedInvs, list } = this.state

    let newSelectList = selectedInvs.slice()

    if (list && validator.isArray(list)) {
      for (let i = 0; i < list.length; i++) {
        const itm = list[i]
        const idx = newSelectList.findIndex(e => e.id === itm.id)

        if (check) {
          if (idx > -1) {
            newSelectList.splice(idx, 1)
          }
        } else {
          if (idx === -1) {
            const data = {
              id: itm.id,
              invoice_number: itm.invoice_number,
              processed_at: itm.processed_at,
              status: itm.status,
              status_reference: itm.status_reference,
              status_date: itm.status_date,
              client_name: itm.client_name,
              provider_name: itm.provider_name,
              subtotal: itm.subtotal,
              received_subtotal: itm.received_subtotal
            }

            newSelectList.push(data)
          }
        }
      }

      this.setState({
        isSelectedAll: !check,
        selectedInvs: newSelectList.slice()
      })
    }
  }

  updateRcvNoteExpand = (id, value) => {
    const { selectedNotesInput } = this.state

    const c = selectedNotesInput.findIndex(e => e === id)

    if (c > -1) {
      selectedNotesInput.splice(c, 1)
    } else {
      selectedNotesInput.push(id)
    }

    this.setState({ selectedNotesInput })
  }

  // need to readjust find functions if option layout is changed
  findClients = (input, option) => {
    // console.log('option c 1', option)
    const clientArr = option.props.children.props.children
    const clientNdis = option.props.children.props.children[option.props.children.props.children.length - 1] // ndis span block is at last position of props.children array
    // console.log('option c', clientNdis, option)
    const c = `${clientArr[0]} ${clientArr[2]} ${clientNdis && validator.isNotEmptyArray(clientNdis.props.children) ? clientNdis.props.children[1] : ''}`
    return c.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findProviders = (input, option) => {
    // console.log('option p', option)
    const providerArr = option.props.children.props.children
    const providerNdis = option.props.children.props.children[option.props.children.props.children.length - 1] // ndis span block is at last position of props.children array
    const p = `${providerArr[0]} ${providerNdis && validator.isNotEmptyArray(providerNdis.props.children) ? providerNdis.props.children[1] : ''}`
    return p.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  onUpdateInvoices = async (invoices, file, actionType) => {
    const { invoicesList } = this.props
    const { currentPage, searchText, selectedStatus } = this.state
    const list = invoicesList.list
    const total = invoicesList.total

    if (selectedStatus === 'pymt-req-submit' || selectedStatus === 'pymt-to-pay') {
      // load the whole list again if it is rcv payment update or to pay
      this.setState({ rcvAmtUpdates: [], selectedInvs: [] })
      this.fetchList({ page: currentPage, searchText, selectedStatus }, false)
    } else {
      this.setState({ rcvAmtUpdates: [], selectedInvs: [] })

      if (validator.isNotEmptyArray(list)) {
        // manually update the data without loading and apply the status update changes to reducer state instead
        for (let i = 0; i < list.length; i++) {
          const inv = list[i]
          const updateInv = invoices.find(e => e.id === inv.id)

          if (updateInv && updateInv.id) {
            list[i].status = updateInv.status
            list[i].status_date = updateInv.status_date
            list[i].status_name = updateInv.status_name
            list[i].status_color = updateInv.status_color
            list[i].status_reference = updateInv.status_reference
          }
        }

        this.props.invoicesListFetched({ invoices: { list, total }, loading: false })
        this.setState({ list, total })
      }
    }

    this.fetchInoviceSummary()

    if (file && file.id) {
      // if the payment type is 'payment', make it to download ABA only. RMT will be redirected to export page to download
      if (file.type === ExportType.TYPE_PAYMENT) {
        file.type = ExportType.TYPE_ABA
      }

      await exportFile.fetchFiles(file.id, file.type, file.created_at)

      // redirect to export page when ABA/RMT generated
      if (selectedStatus === 'pymt-to-pay') {
        this.preCheckNavigateToExportPage(file)
      }
    }
  }

  onInputChange = (e, field, updateField, idf, maxRcvAmount, isItemDefaultClosed = false) => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form
    const value = typeof e === 'boolean' ? e : e.target.value ? e.target.value.trim() : e.target.value

    const rcvAmt = field === 'received_subtotal' ? value : getFieldValue(`received_subtotal${idf}`)
    const rcvNote = field === 'received_notes' ? value : getFieldValue(`received_notes${idf}`)
    const rcvDate = getFieldValue(`received_date${idf}`)
    const isEmptyRcvAmt = rcvAmt === null || rcvAmt === undefined || rcvAmt === ''
    const isEmptyRcvNote = rcvNote === null || rcvNote === undefined || rcvNote === ''
    const isUpdatedCheck = field === 'is_closed' && isItemDefaultClosed === false ? value : getFieldValue(`is_closed${idf}`)

    if ((!isEmptyRcvAmt || !isEmptyRcvNote)) {
      this.onRcvItemCheckChange(undefined, idf, true, true)

      if (!rcvDate) {
        setFieldsValue({ [`received_date${idf}`]: moment(new Date()) })
      }
    } else if (isUpdatedCheck) {
      if (isEmptyRcvAmt) {
        setFieldsValue({ [`received_date${idf}`]: undefined })
        setFieldsValue({ [`received_notes${idf}`]: undefined })
      }

      this.onRcvItemCheckChange(undefined, idf, true, value)
    } else {
      if (isEmptyRcvAmt) {
        setFieldsValue({ [`received_date${idf}`]: undefined })
        setFieldsValue({ [`received_notes${idf}`]: undefined })
      }

      this.onRcvItemCheckChange(undefined, idf, true, false)
    }

    if (field === 'received_subtotal') {
      const rcvVal = formatter.toPriceFloat(rcvAmt)
      const maxVal = formatter.toPriceFloat(maxRcvAmount)
      // auto trigger is closed trigger if rcv amt === max rcv amt
      if (rcvVal === maxVal) {
        setFieldsValue({ [`is_closed${idf}`]: true })
      } else {
        setFieldsValue({ [`is_closed${idf}`]: false })
      }
    }
  }

  onRcvItemCheckChange = (e, idf, isForcedSet = false, forcedValue) => {
    const { rcvAmtUpdates } = this.state

    const s = rcvAmtUpdates.findIndex(f => f === idf)
    if (s > -1) {
      if (!isForcedSet) {
        rcvAmtUpdates.splice(s, 1)
      } else if (isForcedSet && forcedValue === false) {
        rcvAmtUpdates.splice(s, 1)
      }
    } else {
      if (!isForcedSet) {
        rcvAmtUpdates.push(idf)
      } else if (isForcedSet && forcedValue === true) {
        rcvAmtUpdates.push(idf)
      }
    }

    this.setState({ rcvAmtUpdates })
  }

  validateRcvAmtDate = (rule, value, callback, invDate) => {
    // different with inv page, empty rcv date at here is allowed. the api will set current date as rcv date if submitted rcv date is blank
    if (value === null || value === undefined || value === '' || value === 0) {
      callback()
    } else {
      if (invDate) {
        const m = moment(invDate).startOf('day')
        const v = moment.isMoment(value) ? value : moment(value)

        if (v.isBefore(m)) {
          callback(new Error(`Receive Date cannot be earlier than invoice date.`))
        }
      }

      callback()
    }
  }

  validateInvItemRcvAmount = (rule, value, callback, maxRcvAmount, idf) => {
    const { form } = this.props
    const { validateFields, setFieldsValue } = form

    if (value === null || value === undefined || value === '') {
      callback()
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Item Receive Amount is not number or decimal format`))
      } else {
        value = formatter.toPriceFloat(value)
        const maxVal = formatter.toPriceFloat(maxRcvAmount)

        if (maxVal < value) {
          callback(new Error(`Item Receive Amount cannot exceed To Receive Amount`))
          setFieldsValue({ [`received_date${idf}`]: undefined })
          setFieldsValue({ [`received_notes${idf}`]: undefined })
          this.onRcvItemCheckChange(undefined, idf, false, false)
          this.setState((prevState) => ({
            disabledFields: {
              ...prevState.disabledFields,
              [`is_closed${idf}`]: true,
            },
          }));
        } else if (value === 0) {
          callback(new Error(`Item Receive Amount cannot be zero`))
        } else if (value < 0) {
          callback(new Error(`Item Receive Amount cannot be less than zero`))
        } else {
          this.setState((prevState) => ({
            disabledFields: {
              ...prevState.disabledFields,
              [`is_closed${idf}`]: false,
            },
          }));
          callback()
        }

        // if (parseFloat(value) === 0) {
        //   callback(new Error(`Item Receive Amount cannot be zero`))
        // } else {
        //   callback()
        // }
      }
    }

    validateFields([`received_notes${idf}`])
  }

  validateInvItemRcvNote = (rule, value, callback, idf) => {
    const { form } = this.props
    const { getFieldValue } = form

    if (value === null || value === undefined || value === '') {
      callback()
    } else {
      const rcvAmt = getFieldValue(`received_subtotal${idf}`)
      // if rcv amt is empty but rcv notes has value, trigger error
      if (rcvAmt === null || rcvAmt === undefined || rcvAmt === '') {
        callback(new Error(`Item Receive Amount cannot be zero`))
      } else {
        callback()
      }
    }
  }

  /** export related */
  toggleExportSelect = (visible) => {
    if (!visible) {
      // Refresh the modal content when it is closed
      this.resetModalState()
    }

    this.setState({ isShowDateRangePicker: !this.state.isShowDateRangePicker })
  }

  resetModalState = () => {
    this.setState({
      isCheckFile: true,
      isCheckInvoice: true,
      isCheckMsgShow: false,
      isCheckDateRange: true
    })
  }

  handleDateChange = (dates) => {
    if (Array.isArray(dates) && dates.length > 1) {
      this.setState({ startDate: dates[0], endDate: dates[1], isCheckDateRange: false })
    } else {
      this.setState({ startDate: null, endDate: null, isCheckDateRange: true })
    }
  }

  handleCheckboxClick(e, { isCheckFile, isCheckInvoice }) {
    const check = e.target.checked
    this.setState({
      isCheckFile: isCheckFile === undefined ? this.state.isCheckFile : check,
      isCheckInvoice: isCheckInvoice === undefined ? this.state.isCheckInvoice : check
    }, () => {
      const {isCheckFile,isCheckInvoice } = this.state

      if (!(isCheckFile || isCheckInvoice)) {
        this.setState({ isCheckMsgShow: true })
      } else {
        this.setState({ isCheckMsgShow: false })
      }
    })
  }

  preCheckExport() {
    const {startDate, endDate, isCheckFile, isCheckInvoice , isCheckMsgShow, isCheckDateRange } = this.state

    if (!startDate || !endDate) {
      this.setState({ isCheckDateRange: true })
      return
    }

    if (!isCheckMsgShow && !isCheckDateRange) {
      this.export(startDate, endDate, isCheckFile, isCheckInvoice)
    }
  }

  async export(startDate, endDate, isCheckFile, isCheckInvoice) {
    this.setState({ isShowDateRangePicker: false, isGenerating: true })
    const data = {
      start_date: startDate,
      end_date: endDate,
      export_file: isCheckFile,
      export_invoice: isCheckInvoice
    }

    try {
      this.setState({ isGenerating: true })
      // window.location.href = `${apiHostname}/api/export/list/client${queryString.stringify({ filter: JSON.stringify(filter) })}`
      const r = await exportFile.fetchExport('invoice', data)
      setTimeout(() => {
        this.setState({ isGenerating: false, isShowDateRangePicker: false })
      }, 3000)
    } catch (e) {
      notify.error('Unable to export', 'Unable to get invoice export successfully. Please try again later.')
      this.setState({ isGenerating: false,isShowDateRangePicker: false })
    }
  }

  preCheckNavigateToExportPage = (file) => {
    const { navigateToExportPage } = this

    confirm({
      title: 'Do you want to send Remittance Advice(s) now?',
      content: (
        <div>
          <p>Navigate to Invoice Export Page and send Remittance Advice(s).</p>
          <p>Confirm?</p>
        </div>
      ),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk() {
        navigateToExportPage(file)
      },
      onCancel() {
      }
    })
  }

  navigateToExportPage = (file) => {
    window.open(`/invoices-export/aba-remittance?export_id=${file.ref_id}&export_type=rmt`)
  }

  /** upload modal related */
  triggerRcvImportUploadModal = (isShowImportModal) => {
    this.setState({
      isShowImportModal,
      fileList: [],
      fileUploadedList: {},
      loadingSummit: false
    })
  }

  handlePasteAmt = (key, idf, received_remaining) => (event) => {
    event.preventDefault()
    const { form } = this.props
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')

    if (pastedData) {
      const cleanedData = pastedData.replace(/[^0-9,.]/g, '')
      form.setFieldsValue({ [key]: cleanedData })
      this.onInputChange({target: { value: cleanedData }}, 'received_subtotal', 'is_checked', idf, received_remaining);
      form.validateFieldsAndScroll([key]).catch(console.log)
    }
  }

  handleSubmitImportRequest = async () => {
    const { navigateToReportRequestPage } = this
    const { fileList, fileUploadedList } = this.state

    if (fileList.length === 0) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgNoFile })
    } else if (!fileUploadedList.uid) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgInProgress })
    } else {
      this.setState({ loadingSummit: true })

      const body = {
        ...fileUploadedList,
        type: ReportRequestType.INV_RCV_IMPORT.code
      }

      try {
        // const r = await reportSchedulerService.createScheduleReport(body)
        const r = await reportSchedulerPaceService.addScheduleReport(body)

        if (r && r.id) {
          this.triggerRcvImportUploadModal(false)
          confirm({
            title: 'Import Received Amount Successfully',
            content: (
              <div>
                <p>Import Received Amount Request is made successfully. The process is scheduled and will be processed shortly.</p>
                <p>You could go to "Report Generate Request" section to view the progress.</p>
              </div>
            ),
            okText: 'Go to Report Request Page',
            cancelText: 'Back',
            onOk() {
              // eslint-disable-next-line no-lone-blocks
              navigateToReportRequestPage()
            },
            onCancel() {
            }
          })
        } else {
          notify.error('Unable to Import Received Amount', 'Unable to made the request. Please try again later.')
          this.triggerRcvImportUploadModal(false)
        }
      } catch (e) {
        notify.error('Unable to Import Received Amount', e && e.errors ? formatter.toErrorMessage(e.errors) : 'Unable to made the request. Please try again later.')

        this.triggerRcvImportUploadModal(false)
      }
    }
  }

  navigateToReportRequestPage = () => {
    // window.open(`/reports/custom-generate`)
    window.open(`/reports-pace/custom-generate`)
  }

  /** file related */
  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({ fileList: newFileList, fileUploadedList: {} })
    }
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        this.setState({ fileUploadedList: data, uploadErrorMsg: '' })
      }
    }
  }

  fileSet = (file) => {
    if (file && (
      file.type === 'text/csv' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    )) {
      this.setState({ fileList: [file], uploadErrorMsg: '' })
      return true
    } else {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgWrongFormatCSV })
      return false
    }

    // return false
  }
}

const mapDispatchToProps = {
  fetchInvoicesList,
  fetchInvoicesSummary,
  invoicesInfoReset,
  invoicesListFetched
}

const mapStateToProps = (state) => {
  return { ...state.InvoiceFS }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(InvoiceList))
