import React, { Component, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'

import { FileUploadModule, InvoiceGstMode, InvoiceStatus, InvoiceTypePace as InvoiceType, Permissions } from '../../../../constants'
import {
  clientService, clientFundingPaceService, creditPaceService, invoicePaceService, pmRateSetCategoryItemPaceService,
  providerService, settingFileService, settingGeneralService, settingGSTRateService
} from '../../../../services'
import {
  setFormAttachmentCount, setOnRevertToDraft, setOnSave, setOnSaveAndProcess, setOnSaveAsDraft, setSelectedInvoice, setSelectedInvoiceType
} from '../../../../states/actions'
import { auth, common, formatter, validator } from '../../../../util'

// UI
import Alert from 'antd/lib/alert'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Progress from 'antd/lib/progress'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import { Button, ControlLabel, List, Panel } from '../../../../components'
import notify from '../../../../components/Notification'
import CreditApplyModal from '../Credit/ApplyModal'
import FileAddModal from '../File/AddModal'
import ReceivedAmountModal from './ReceivedAmountModal'

import './styles.css'

const { searchDropdown } = common
const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { TextArea } = Input
const { Button: RadioButton, Group: RadioGroup } = Radio
const { Option } = Select

const dropdownFormItemLayout = {
  labelCol: { sm: 10, md: 8, lg: 6 },
  wrapperCol: { sm: 10, md: 14, lg: 16 }
}
const formItemLayout = {
  labelCol: { sm: 10, md: 8, lg: 6 },
  wrapperCol: { sm: 10, md: 14, lg: 16 }
}
const providerFormItemLayout1 = {
  labelCol: { sm: 12, md: 6, lg: 4 },
  wrapperCol: { sm: 12, md: 14, lg: 16 }
}
const providerFormItemLayout2 = {
  labelCol: { sm: 12, md: 10, lg: 8 },
  wrapperCol: { sm: 12, md: 14, lg: 16 }
}

const dateFormat = 'DD/MM/YYYY'
const urlRedirect = '/invoices'

const FundingTypeFlexible = 'flexible'
const InvoiceAbnModes = Object.freeze([
  { key: 'EnterProviderAbn', label: 'Enter Provider ABN', value: 'invoice_abn' },
  { key: 'Reimbursement', label: 'Reimbursement', value: 'REIMB' },
  { key: 'ATOExcludedSupply', label: 'ATO Excluded Supply', value: 'EXCLS' }
])
const InvoiceAbnMode = Object.freeze(Object.fromEntries(InvoiceAbnModes.map((item) => [item.key, item.value])))
const InvoiceItemField = Object.freeze({
  Id: 'id',
  InvoiceId: 'invoice_id',
  ClientFundingId: 'client_funding_id',
  RateSetId: 'rate_set_id',
  StartDate: 'start_date',
  EndDate: 'end_date',
  CategoryId: 'category_id',
  CategoryNumber: 'category_number',
  CategoryName: 'category_name',
  CategoryGroupId: 'category_group_id',
  FundingType: 'funding_type',
  CategoryItemId: 'category_item_id',
  CategoryItemNumber: 'category_item_number',
  CategoryItemName: 'category_item_name',
  MaxRate: 'max_rate',
  Unit: 'unit',
  InputRate: 'input_rate',
  Rate: 'rate',
  Amount: 'amount',
  GstAmount: 'gst_amount',
  CreditAmount: 'credit_amount',
  ReceivedAmount: 'received_amount',
  GstCode: 'gst_code',
  GstMode: 'gst_mode',
  ClaimType: 'claim_type',
  ClaimTypeReason: 'claim_type_reason',
  Comment: 'comment',
  PrivateComment: 'private_comment',
  Closed: 'closed',
  Deleted: 'deleted',
  DisplayAmount: 'display_amount',
  GstInclusive: 'gst_inclusive'
})

const ClientNoBankInfoMsg = 'Participant does not have any bank info.'
const ClientNoRmtEmailMsg = 'Participant\'s Remittance email is not configured.'
const DifferenceOf = 'Difference of'
const InvDuplicatedAbnMissingMsg = 'Please enter ABN before validating invoice number.'
const InvDuplicatedProviderMissingMsg = 'Please select provider before validating invoice number.'
const InvoiceItemRequiredMsg = 'Please add at least one invoice item.'
const MultiplePlanPeriodsLinkedMsg = 'Multiple plan periods are linked to the selected service start and end dates.'
const MultipleRateSetsLinkedMsg = 'Multiple rate sets are linked to the selected service start and end dates.'
const NoPlanPeriodLinkedMsg = 'No plan period available on selected service start and end dates.'
const NoRateSetLinkedMsg = 'No rate set available on selected service start and end dates.'
const OverBudgetMsg = 'The invoiced amount exceeds the budget for the selected support category.'
const PlanPeriodEndedMsg = 'The plan period has ended.'
const PlanPeriodEndedMsg2 = 'The plan period associated with this invoice item has ended.'
const PlanPeriodEnded60DaysMsg = 'The plan period associated with this invoice item ended more than 60 days ago.'
const PlanPeriodEnded90DaysMsg = 'The plan period associated with this invoice item ended more than 90 days ago. The claim may not be successful even if submitted.'
const PlanPeriodMismatchMsg = 'The plan period associated with this invoice item is no longer active/within period/deleted.'
const TotalAmountLessThanExpectedMsg = 'Total Inv Amt is less than Expected Inv Amt.'
const PressCofirmToProceed = 'Press "Confirm" to proceed.'

const TotalAmountMismatchMsg = 'Total Invoiced Amount and Expected Invoiced Amount do not match.'
const TotalAmountMismatchMsg2 = 'different from Total Inv Amt.'
const RcvAmountEarlyCloseMsg = 'The received amount and amount to pay are not matched. Confirm with your received amount again.'
const RcvAmountOverPayMsg = 'Current Receive Amount exceeds To Receive Amount'
const ItemsSelectErrMsg = 'No item is selected.'

function getApplicableCredits (credits, selectedCategoryNumber, selectedCategoryGroupId) {
  if (Array.isArray(credits)) {
    const creditIdSet = new Set()
    const categoryGroupIdSet = new Set()

    for (let i = 0; i < credits.length; i++) {
      const { id, category_number: categoryNumber, category_group_id: categoryGroupId, funding_type: fundingType, closed } = credits[i]
      const isSameCategoryNumber = BigNumber(selectedCategoryNumber).isEqualTo(categoryNumber) && !closed
      const isSameCategoryGroupId = BigNumber(selectedCategoryGroupId).isEqualTo(categoryGroupId) && !closed

      if (isSameCategoryNumber) {
        creditIdSet.add(id)
      } else if (isSameCategoryGroupId && fundingType === FundingTypeFlexible) {
        categoryGroupIdSet.add(categoryGroupId)
      }
    }

    return credits.filter(({ id, category_group_id: categoryGroupId }) => creditIdSet.has(id) || categoryGroupIdSet.has(categoryGroupId))
  }

  return []
}

function getAppliedCreditAmount (appliedCreditsMap) {
  if (appliedCreditsMap && appliedCreditsMap.size > 0) {
    return Array.from(appliedCreditsMap.values()).reduce((accumulator, appliedCredits) => {
      const creditAmount = getInvoiceItemCreditAmount(appliedCredits)
      return BigNumber(accumulator).plus(!BigNumber(creditAmount).isNaN() ? creditAmount : 0).toFixed(2)
    }, 0)
  }

  return BigNumber().toFixed(2)
}

function getAppliedCreditUsage (appliedCreditsMap) {
  const appliedCreditUsage = {}

  for (const appliedCredits of appliedCreditsMap.values()) {
    for (const [creditId, credit] of Object.entries(appliedCredits)) {
      const { amount } = credit
      const creditUsage = appliedCreditUsage[creditId]
      const _amount = formatter.toBigNumber(!formatter.toBigNumber(amount).isNaN() ? amount : 0)
      const _creditUsage = formatter.toBigNumber(!formatter.toBigNumber(creditUsage).isNaN() ? creditUsage : 0)
      appliedCreditUsage[creditId] = BigNumber(_amount).plus(_creditUsage)
    }
  }

  return appliedCreditUsage
}

function getClientFundingSummary (clientFundings) {
  const clientFundingLookup = new Map()

  for (const cf of clientFundings) {
    const { id, start_date: startDate, end_date: endDate } = cf
    const clientFunding = clientFundingLookup.get(id) || { id, start_date: startDate, end_date: endDate }
    const { client_funding_items: _clientFundingItems } = clientFunding
    clientFunding.client_funding_items = Array.isArray(_clientFundingItems) ? _clientFundingItems.concat(cf) : [cf]
    clientFundingLookup.set(id, clientFunding)
  }

  return Array.from(clientFundingLookup.values())
}

function getClientFundingUsage (clientFundings, invoiceClientFundingUsages) {
  const hasInvoiceClientFundingUsages = Array.isArray(invoiceClientFundingUsages) && invoiceClientFundingUsages.length > 0
  const invoiceClientFundingUsageLookup = new Map(
    hasInvoiceClientFundingUsages ? invoiceClientFundingUsages.map((icfu) => [icfu.cfi_id, icfu]) : []
  )
  const flexibleMap = new Map()
  const nonFlexibleMap = new Map()

  if (Array.isArray(clientFundings) && clientFundings.length > 0) {
    const flexibleKeySet = new Set()

    for (const clientFunding of clientFundings) {
      const { client_funding_items: clientFundingItems } = clientFunding

      for (const clientFundingItem of clientFundingItems) {
        const {
          id: cfId, cfi_id: cfiId, cfi_category_number: categoryNumber, cfi_category_name: categoryName,
          cfi_category_group_id: categoryGroupId, cfi_category_group_name: categoryGroupName, cfi_funding_type: fundingType,
          cfi_item_number: itemNumber, forecast_remaining: forecastRemaining
        } = clientFundingItem
        const clientFundingUsage = invoiceClientFundingUsageLookup.get(cfiId) || {}
        const hasClientFundingUsage = validator.isObject(clientFundingUsage) && validator.isId(clientFundingUsage.cfi_id)
        const { forecast_remaining: cfuForecastRemaining } = clientFundingUsage
        const _forecastRemaining = !hasClientFundingUsage ? forecastRemaining : cfuForecastRemaining

        if (fundingType === FundingTypeFlexible) {
          const key = getClientFundingUsageFlexibleKey(cfId, categoryGroupId)
          const categoryNumberKey = `${key}-${categoryNumber}`
          const flexibleUage = flexibleMap.get(key) || { category_name: categoryGroupName || categoryName, forecast_remaining: 0 }
          const { category_number: flexibleCategoryNumber, forecast_remaining: flexibleForecastRemaining } = flexibleUage

          if (!flexibleKeySet.has(categoryNumberKey)) {
            const hasComma = typeof flexibleCategoryNumber === 'string' && typeof categoryNumber === 'string'
            flexibleUage.category_number =
              `${flexibleCategoryNumber || ''}${hasComma ? ', ' : ''}${formatter.toCategoryNumber(categoryNumber)}`
            flexibleUage.forecast_remaining = BigNumber(BigNumber(flexibleForecastRemaining).isNaN() ? 0 : flexibleForecastRemaining)
              .plus(BigNumber(_forecastRemaining).isNaN() ? 0 : _forecastRemaining).toFixed(2)
            flexibleMap.set(key, flexibleUage)
            flexibleKeySet.add(categoryNumberKey)
          }
        } else {
          const key = getClientFundingUsageNonFlexibleKey(cfId, categoryNumber, itemNumber)
          nonFlexibleMap.set(key, {
            category_number: formatter.toCategoryNumber(categoryNumber), category_name: categoryGroupName || categoryName,
            forecast_remaining: _forecastRemaining
          })
        }
      }
    }
  }

  return { flexibleMap, nonFlexibleMap }
}

function getClientFundingUsageKey (fundingType, cfId, categoryGroupId, categoryNumber, itemNumber) {
  return fundingType === FundingTypeFlexible ? `${cfId}-${categoryGroupId}` : `${cfId}-${categoryNumber || ''}-${itemNumber || ''}`
}

function getClientFundingUsageFlexibleKey (cfId, categoryGroupId) {
  return getClientFundingUsageKey(FundingTypeFlexible, cfId, categoryGroupId)
}

function getClientFundingUsageNonFlexibleKey (cfId, categoryNumber, itemNumber) {
  return getClientFundingUsageKey(null, cfId, null, categoryNumber, itemNumber)
}

function getDefaultInvoiceAbnMode (invoiceAbn) {
  if ([InvoiceAbnMode.Reimbursement, InvoiceAbnMode.ATOExcludedSupply].indexOf(invoiceAbn) > -1) {
    return invoiceAbn
  }
}

function getFileColumns (hasAccess, editFile, removeFile) {
  const handleDelete = (idx) => () => {
    if (typeof removeFile === 'function') {
      removeFile(idx)
    }
  }

  const handleEdit = (file) => () => {
    if (typeof editFile === 'function') {
      editFile(file)
    }
  }

  return Object.freeze([
    {
      title: 'File Category',
      width: 8,
      render: ({ main_cat_name: catName, sub_cat_name: subCatName }) => (
        <div>
          <div className='title'>{subCatName}</div>
          <div className='sub-title'>{catName}</div>
        </div>
      )
    },
    {
      title: 'Label',
      width: 8,
      render: ({ label, name }) => {
        return (
          <div>
            <div className='title'>{label}</div>
            <div className='sub-title'>{name ? `[${formatter.toStandardFileName(name)}]` : ''}</div>
          </div>
        )
      }
    },
    {
      title: 'Issuance Date',
      width: 3,
      render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
    },
    {
      title: 'Is Mail Attachment?',
      width: 3,
      render: ({ sub_cat_is_attach_mail_comm: isAttachMailComm }) => (
        <div className={clsx('icon', isAttachMailComm ? 'active' : '')}>
          <Icon type='check-circle' theme='filled' />
        </div>
      )
    },
    {
      title: 'Enabled',
      width: 1,
      render: ({ active }) => (
        <div className={clsx('icon', active ? 'active' : '')}>
          <Icon type='check-circle' theme='filled' />
        </div>
      )
    },
    {
      title: 'Action',
      width: 1,
      render: (item, idx) => (
        <div className='button-box'>
          {hasAccess(Permissions.INVOICE.FILES_PACE.UPDATE) ? (
            <Tooltip mouseLeaveDelay={0} title='Edit Details'>
              <Icon type='form' onClick={handleEdit(item, idx)} />
            </Tooltip>
          ) : null}

          {hasAccess(Permissions.INVOICE.FILES_PACE.DELETE) ? (
            <Tooltip mouseLeaveDelay={0} title='Delete File'>
              <Popconfirm
                cancelText='No'
                okText='Yes'
                title={`Are you sure you want to delete ${item.label}?`}
                onConfirm={handleDelete(idx)}
              >
                <Icon type='delete' />
              </Popconfirm>
            </Tooltip>
          ) : null}
        </div>
      )
    }
  ])
}

function getDisplayExpectedAmount (expectedAmount, amount) {
  return [
    formatter.toBigNumber(expectedAmount || 0).toFormat(2),
    `(${formatter.toBigNumber(expectedAmount || 0).minus(amount || 0).toFormat(2)})`
  ].join(' ')
}

function getDisplayAmount (amount, gstAmount) {
  return [
    formatter.toBigNumber(amount || 0).toFormat(2), `[${formatter.toBigNumber(gstAmount || 0).toFormat(2)}]`
  ].join(' ')
}

function getInvoiceAmount (idx, invoiceItems, amount, gstAmount) {
  const { totalAmount, totalGstAmount } = invoiceItems.filter((_, _idx) => _idx !== idx).reduce((accumulator, invoiceItem) => {
    const { totalAmount, totalGstAmount } = accumulator
    const { amount, gst_amount: gstAmount } = invoiceItem
    let _amount = formatter.toBigNumber(amount)
    _amount = !_amount.isNaN() ? _amount : BigNumber(0)
    let _gstAmount = formatter.toBigNumber(gstAmount)
    _gstAmount = !_gstAmount.isNaN() ? _gstAmount : BigNumber(0)
    accumulator.totalAmount = _amount.plus(totalAmount).toFixed(2)
    accumulator.totalGstAmount = _gstAmount.plus(totalGstAmount).toFixed(2)
    return accumulator
  }, { totalAmount: 0, totalGstAmount: 0 })
  const invoiceAmount = BigNumber(amount).plus(totalAmount).toFixed(2)
  const invoiceGstAmount = BigNumber(gstAmount).plus(totalGstAmount).toFixed(2)
  return { invoiceAmount, invoiceGstAmount }
}

function getInvoiceItemAmount (unit, rate) {
  // Convert unit and rate to BigNumber, then sum them together and return as amount
  const _unit = isValidDecimal(unit) ? formatter.toBigNumber(unit) : BigNumber(0)
  const _rate = isValidDecimal(rate) ? formatter.toBigNumber(rate) : BigNumber(0)
  return _unit.times(_rate).toFixed(2)
}

function getInvoiceItemClientFundings (clientFundings, iiStartDate, iiEndDate, clientFundingId) {
  if (
    Array.isArray(clientFundings) && !validator.isNullOrUndefined(iiStartDate) && !validator.isNullOrUndefined(iiEndDate) &&
    moment(iiStartDate).isSameOrBefore(iiEndDate)
  ) {
    const matchingClientFundings = []
    const overlapClientFundingSet = new Set()

    for (const clientFunding of clientFundings) {
      const { start_date: cfStartDate, end_date: cfEndDate } = clientFunding

      // Check if the invoice item service period is fully inside client funding period
      if (moment(iiStartDate).isSameOrAfter(cfStartDate) && moment(iiEndDate).isSameOrBefore(cfEndDate)) {
        matchingClientFundings.push(clientFunding)
      }

      // Check if the invoice item service period overlaps with client funding period
      if (moment(iiStartDate).isBefore(cfEndDate) && moment(iiEndDate).isAfter(cfStartDate)) {
        overlapClientFundingSet.add(`${formatter.toShortDate(cfStartDate)} - ${formatter.toShortDate(cfEndDate)}`)
      }
    }

    const isMismatchClientFunding = validator.isId(clientFundingId) && matchingClientFundings.length > 0 &&
      !BigNumber(matchingClientFundings[0].id).isEqualTo(clientFundingId)
    const isMultipleClientFundings = overlapClientFundingSet.size > 1
    const isSingleClientFunding = matchingClientFundings.length === 1
    return {
      clientFundings: isSingleClientFunding ? matchingClientFundings : [], isMismatchClientFunding, isMultipleClientFundings,
      isSingleClientFunding, overlapClientFundings: Array.from(overlapClientFundingSet)
    }
  }

  return {
    clientFundings: [], isMismatchClientFunding: false, isMultipleClientFundings: false, isSingleClientFunding: false,
    overlapClientFundings: []
  }
}

function getInvoiceItemCreditAmount (appliedCredits) {
  if (validator.isObject(appliedCredits)) {
    const appliedCreditsList = Object.values(appliedCredits)
    return appliedCreditsList.reduce((accumulator, appliedCredit) => {
      const { amount } = appliedCredit
      const _amount = formatter.toBigNumber(!formatter.toBigNumber(amount).isNaN() ? amount : 0)
      return BigNumber(accumulator).plus(_amount).toFixed(2)
    }, 0)
  }

  return BigNumber().toFixed(2)
}

function getInvoiceItemGstAmount (unit, rateGstAmount) {
  // Convert unit and per unit GST Amount to BigNumber, then mulitply them together and return as total GST amount
  const _unit = isValidDecimal(unit) ? formatter.toBigNumber(unit) : BigNumber(0)
  const _rateGstAmount = isValidDecimal(rateGstAmount) ? formatter.toBigNumber(rateGstAmount) : BigNumber(0)
  return _unit.times(_rateGstAmount)
}

function getInvoiceItemInputRate (gstType, gstMode, maxRate) {
  // Convert max rate and GST rate to BigNumber, then calculate the input rate and GST amount and return both
  const { value: gstRate } = gstType || {}
  const _gstRate = BigNumber(isValidDecimal(gstRate) ? gstRate : 0)
  const _maxRate = isValidDecimal(maxRate) ? formatter.toBigNumber(maxRate) : BigNumber(0)
  const rateGstAmount = BigNumber(_maxRate).times(_gstRate.div(_gstRate.plus(100)))
  let inputRate = _maxRate.toFixed(2)

  if (gstMode === InvoiceGstMode.Exclusive) {
    inputRate = BigNumber(inputRate).minus(rateGstAmount)
  }

  return { inputRate: BigNumber(inputRate).toFixed(2), rateGstAmount: BigNumber(rateGstAmount) }
}

function getInvoiceItemFieldName (idx, field) {
  if (validator.isId(idx) && typeof field === 'string' && field.trim().length > 0) {
    return `invoice_items[${idx}].${field}`
  }

  return ''
}

function getInvoiceItemMaxRate (maxRate) {
  return BigNumber(maxRate).isNaN() || BigNumber(maxRate).isEqualTo(0) ? 'No Limit' : BigNumber(maxRate).toFormat(2)
}

function getInvoiceItemTabIndex (idx, offset) {
  idx = !BigNumber(idx).isNaN() ? idx : 0
  offset = !BigNumber(offset).isNaN() ? offset : 0
  return (idx * 11) + offset + 7
}

function getInvoiceItemReceiveHistoryMap (itemReceiveHistory) {
  const historyMap = new Map()

  if (Array.isArray(itemReceiveHistory) && itemReceiveHistory.length > 0) {
    for (const history of itemReceiveHistory) {
      const { invoice_item_id: iiId } = history
      const list = historyMap.get(iiId) || []
      list.push(history)
      historyMap.set(iiId, list)
    }
  }

  return historyMap
}

function getMatchingCategories (categories, startDate, endDate) {
  const iiStartDate = moment(startDate).startOf('day')
  const iiEndDate = moment(endDate).endOf('day')

  if (
    Array.isArray(categories) && !validator.isNullOrUndefined(startDate) && !validator.isNullOrUndefined(endDate) &&
    moment(iiStartDate).isSameOrBefore(iiEndDate)
  ) {
    const matchingCategories = []
    const overlapRateSetSet = new Set()

    for (const category of categories) {
      const {
        start_date: cfStartDate, end_date: cfEndDate, rs_start_date: rsStartDate, rs_end_date: rsEndDate
      } = category
      // Ensure sufficient overlap between the client funding period and rate set period
      const overlapStart = moment.max(moment(cfStartDate), moment(rsStartDate))
      const overlapEnd = moment.min(moment(cfEndDate), moment(rsEndDate))

      // Check if the invoice item service period is fully inside the overlap period
      if ((overlapStart.isSameOrBefore(iiStartDate) && overlapEnd.isSameOrAfter(iiEndDate))) {
        matchingCategories.push(category)
      }

      // Check if the invoice item service period overlaps with rate set period
      if (moment(iiStartDate).isBefore(rsEndDate) && moment(iiEndDate).isAfter(rsStartDate)) {
        overlapRateSetSet.add(`${formatter.toShortDate(rsStartDate)} - ${formatter.toShortDate(rsEndDate)}`)
      }
    }

    const isMultipleRateSets = overlapRateSetSet.size > 1
    const isSingleRateSet = overlapRateSetSet.size === 1
    return { categories: matchingCategories, isMultipleRateSets, isSingleRateSet, overlapRateSets: Array.from(overlapRateSetSet) }
  }

  return { categories: [], isMultipleRateSets: false, isSingleRateSet: false, overlapRateSets: [] }
}

function getMatchingClientFundings (clientFundings, invoiceItems) {
  if (Array.isArray(clientFundings) && Array.isArray(invoiceItems)) {
    const matchingClientFundingsList = []
    const matchingClientFundingIdSet = new Set()
    const overlapClientFundingsList = []

    for (const invoiceItem of invoiceItems) {
      const { start_date: iiStartDate, end_date: iiEndDate } = invoiceItem

      if (moment(iiStartDate).isAfter(iiEndDate)) {
        continue
      }

      const matchingClientFundings = []
      const overlapClientFundings = []

      for (const clientFunding of clientFundings) {
        const { id: cfId, start_date: cfStartDate, end_date: cfEndDate } = clientFunding

        // Check if the invoice item service period is fully inside client funding period
        if (moment(iiStartDate).isSameOrAfter(cfStartDate) && moment(iiEndDate).isSameOrBefore(cfEndDate)) {
          if (!matchingClientFundingIdSet.has(cfId)) {
            matchingClientFundings.push(clientFunding)
            matchingClientFundingIdSet.add(cfId)
          }
        }

        // Check if the invoice item service period overlaps with client funding period
        if (moment(iiStartDate).isBefore(cfEndDate) && moment(iiEndDate).isAfter(cfStartDate)) {
          overlapClientFundings.push(clientFunding)
        }
      }

      const isMultipleClientFundings = matchingClientFundings.length > 1 || overlapClientFundings.length > 1
      const isSingleClientFunding = matchingClientFundings.length === 1

      if (isMultipleClientFundings) {
        overlapClientFundingsList.push(isMultipleClientFundings)
      } else if (isSingleClientFunding) {
        matchingClientFundingsList.push(matchingClientFundings)
      }
    }

    return {
      clientFundings: matchingClientFundingsList.flat(),
      isMultipleClientFundings: overlapClientFundingsList.some((isMultipleClientFundings) => isMultipleClientFundings)
    }
  }

  return { clientFundings: [], isMultipleClientFundings: false }
}

function getRate (gstType, gstMode, inputRate) {
  // Convert input rate and GST rate to BigNumber, then calculate the effective rate and GST amount and return both
  const { value: gstRate } = gstType || {}
  const _gstRate = BigNumber(isValidDecimal(gstRate) ? gstRate : 0)
  const _inputRate = isValidDecimal(inputRate) ? formatter.toBigNumber(inputRate) : BigNumber(0)
  let rate = _inputRate.toFixed(2)
  let rateGstAmount = BigNumber(rate).times(_gstRate.div(_gstRate.plus(100)))

  if (gstMode === InvoiceGstMode.Exclusive) {
    rateGstAmount = BigNumber(rate).times(_gstRate).div(100)
    rate = BigNumber(rate).plus(rateGstAmount)
  }

  return { rate: BigNumber(rate).toFixed(2), rateGstAmount: BigNumber(rateGstAmount) }
}

function handlePasteAmount (event) {
  const pastedText = common.getPastedText(event)

  if (pastedText) {
    const cleanedData = formatter.toBigNumber(formatter.parseAmount(pastedText))
    const amount = !cleanedData.isNaN() ? cleanedData.toFormat() : ''
    // No better alternative than document.execCommand at the moment
    document.execCommand('insertText', false, amount)
  }
}

// To validate whether user entered a decimal number with maximum 2 decimal places
// Do not use it to validate numbers in general
function isDecimal (value) {
  const _value = formatter.toBigNumber(value)
  return validator.isCurrencyAmount(value) && isValidDecimal(value) && _value.decimalPlaces() < 3
}

function isInvoiceClosed (item) {
  if (validator.isObject(item)) {
    return item.status === InvoiceStatus.Closed.value
  }

  return false
}

function isInvoiceDrafted (item) {
  if (validator.isObject(item)) {
    return item.status === InvoiceStatus.Drafted.value
  }

  return false
}

function isInvoiceOngoing (item) {
  if (validator.isObject(item)) {
    return [
      InvoiceStatus.Processing.value, InvoiceStatus.PendingAuthorise.value, InvoiceStatus.ToAuthorise.value,
      InvoiceStatus.ToClaim.value, InvoiceStatus.ToReceive.value, InvoiceStatus.ToPay.value, InvoiceStatus.PartiallyPaid.value,
      InvoiceStatus.Closed.value
    ].indexOf(item.status) > -1
  }

  return false
}

function isInvoiceProcessing (item) {
  if (validator.isObject(item)) {
    return item.status === InvoiceStatus.Processing.value
  }

  return false
}

function isInvoiceReadOnly (item) {
  if (validator.isObject(item)) {
    return [
      InvoiceStatus.Processing.value, InvoiceStatus.ToAuthorise.value, InvoiceStatus.ToClaim.value, InvoiceStatus.ToReceive.value,
      InvoiceStatus.ToPay.value, InvoiceStatus.PartiallyPaid.value, InvoiceStatus.Cancelled.value, InvoiceStatus.Closed.value
    ].indexOf(item.status) > -1
  }

  return false
}

function isInvoiceToReceive (item) {
  if (validator.isObject(item)) {
    return item.status === InvoiceStatus.ToReceive.value
  }

  return false
}

function isString (value) {
  return !validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)
}

// To validate numbers in general
function isValidDecimal (value) {
  const _value = formatter.toBigNumber(value)
  return !_value.isNaN() && _value.isFinite()
}

function mergeClientFundingItemsByCategoryGroup (clientFundingItems) {
  if (Array.isArray(clientFundingItems) && clientFundingItems.length > 0) {
    const categoryGroupMap = new Map()

    return clientFundingItems.reduce((accumulator, clientFundingItem) => {
      const {
        cfi_id: cfiId, cfi_category_group_id: categoryGroupId, cfi_category_group_name: categoryGroupName,
        cfi_category_number: categoryNumber, cfi_allocated_amount: allocatedAmount, total_remaining: totalRemaining,
        forecast_remaining: forecastRemaining
      } = clientFundingItem

      if (!validator.isId(categoryGroupId)) {
        accumulator.push(clientFundingItem)
      } else {
        const categoryGroup = categoryGroupMap.get(categoryGroupId) || {
          cfi_id: cfiId, cfi_category_group_id: categoryGroupId, cfi_category_number: formatter.toCategoryNumber(categoryNumber),
          cfi_category_name: categoryGroupName, cfi_allocated_amount: allocatedAmount, total_remaining: totalRemaining,
          forecast_remaining: forecastRemaining, items: []
        }
        const {
          cfi_category_number: cgCategoryGroupNumber, cfi_allocated_amount: cgAllocatedAmount, total_remaining: cgTotalRemaining,
          forecast_remaining: cgForecastRemaining, items: cgItems
        } = categoryGroup
        const hasCategoryGroup = categoryGroupMap.has(categoryGroupId)
        cgItems.push(clientFundingItem)

        if (hasCategoryGroup) {
          categoryGroup.cfi_category_number = `${cgCategoryGroupNumber}, ${formatter.toCategoryNumber(categoryNumber)}`
          categoryGroup.cfi_allocated_amount = BigNumber(cgAllocatedAmount).plus(allocatedAmount).toFixed(2)
          categoryGroup.total_remaining = BigNumber(cgTotalRemaining).plus(totalRemaining).toFixed(2)
          categoryGroup.forecast_remaining = BigNumber(cgForecastRemaining).plus(forecastRemaining).toFixed(2)
        } else {
          accumulator.push(categoryGroup)
        }

        categoryGroupMap.set(categoryGroupId, categoryGroup)
      }

      return accumulator
    }, [])
  }

  return clientFundingItems
}

function patchValuesBeforeSave (values, files, appliedCreditsMap) {
  if (validator.isObject(values)) {
    const { invoice_date: invoiceDate, invoice_items: invoiceItems } = values
    values.invoice_date = moment(invoiceDate).startOf('day').toISOString()

    if (Array.isArray(invoiceItems)) {
      values.invoice_items = invoiceItems.map((invoiceItem) => {
        const { id, start_date: startDate, end_date: endDate } = invoiceItem
        invoiceItem.startDate = moment(startDate).startOf('day').toISOString()
        invoiceItem.endDate = moment(endDate).startOf('day').toISOString()

        if (appliedCreditsMap && appliedCreditsMap.has(id)) {
          const appliedCredits = appliedCreditsMap.get(id)
          invoiceItem.credits = Array.from(Object.values(appliedCredits)).filter(({ amount }) => !formatter.toBigNumber(amount).isNaN())
        }

        return invoiceItem
      })
    }

    if (Array.isArray(files)) {
      values.files = files.map((file) => {
        const { issuance_date: issuanceDate } = file
        file.issuance_date = moment(issuanceDate).startOf('day').toISOString()
        return file
      })
    }
  }

  return values
}

function validateClientFundingUsage (idx, getFieldsValue, clientFundingUsage) {
  const { invoice_items: fvInvoiceItems } = getFieldsValue()
  const {
    client_funding_id: cfId, category_number: categoryNumber, category_group_id: categoryGroupId, funding_type: fundingType,
    category_item_number: categoryItemNumber
  } = fvInvoiceItems[idx]
  const { flexibleMap, nonFlexibleMap } = clientFundingUsage
  const flexibleKey = getClientFundingUsageFlexibleKey(cfId, categoryGroupId)
  const nonFlexibleKey = getClientFundingUsageNonFlexibleKey(cfId, categoryNumber, categoryItemNumber)
  const nonFlexibleKey2 = getClientFundingUsageNonFlexibleKey(cfId, categoryNumber, null)

  if (fundingType === FundingTypeFlexible) {
    const {
      category_number: categoryNumber, category_name: categoryName, forecast_remaining: forecastRemaining
    } = flexibleMap.get(flexibleKey) || {}

    if (!BigNumber(forecastRemaining).isNaN()) {
      const currentUsed = fvInvoiceItems
        .filter(
          ({
            client_funding_id: cfId, category_group_id: categoryGroupId
          }) => getClientFundingUsageFlexibleKey(cfId, categoryGroupId) === flexibleKey
        )
        .reduce((accumulator, invoiceItem) => {
          const { amount } = invoiceItem
          const _amount = formatter.toBigNumber(!formatter.toBigNumber(amount).isNaN() ? amount : 0)
          return BigNumber(accumulator).plus(_amount).toFixed(2)
        }, 0)
      return {
        budgetName: `${categoryName || ''} - ${categoryNumber || ''}`,
        isWithinBudget: BigNumber(forecastRemaining).isGreaterThanOrEqualTo(currentUsed)
      }
    }
  } else {
    const {
      category_number: categoryNumber, category_name: categoryName, forecast_remaining: forecastRemaining
    } = nonFlexibleMap.get(nonFlexibleKey) || nonFlexibleMap.get(nonFlexibleKey2) || {}

    if (!BigNumber(forecastRemaining).isNaN()) {
      const currentUsed = fvInvoiceItems
        .filter(
          ({
            client_funding_id: cfId, category_number: categoryNumber, category_item_number: categoryItemNumber
          }) => getClientFundingUsageNonFlexibleKey(cfId, categoryNumber, categoryItemNumber) === nonFlexibleKey
        )
        .reduce((accumulator, invoiceItem) => {
          const { amount } = invoiceItem
          const _amount = formatter.toBigNumber(!formatter.toBigNumber(amount).isNaN() ? amount : 0)
          return BigNumber(accumulator).plus(_amount).toFixed(2)
        }, 0)
      return {
        budgetName: `${categoryName || ''} - ${categoryNumber || ''}`,
        isWithinBudget: BigNumber(forecastRemaining).isGreaterThanOrEqualTo(currentUsed)
      }
    }
  }

  return { budgetName: '', isWithinBudget: false }
}

function validateInvoiceItemsCount (invoiceItems) {
  return !Array.isArray(invoiceItems) || invoiceItems.length < 1 ? InvoiceItemRequiredMsg : undefined
}

function SelectClientBox ({
  changeClient, toggleEditClient, clientWarnings, clients, isEdit, isEditClient, loadingClient, loadingDropdown, loadingProvider,
  props, selectedClient
}) {
  const { form } = props || {}
  const { getFieldDecorator } = form || {}

  return isEditClient ? (
    <div className='form-padding-left'>
      <FormItem {...dropdownFormItemLayout} label='Participant'>
        {getFieldDecorator('client_id', {
          initialValue: undefined,
          rules: [
            { required: true, message: 'Please select a participant' }
          ]
        })(
          <Select
            disabled={loadingProvider}
            dropdownMatchSelectWidth={false}
            filterOption={searchDropdown}
            loading={loadingDropdown}
            notFoundContent='Not found'
            optionFilterProp='children'
            placeholder='Participant'
            showSearch
            tabIndex={1}
            onChange={changeClient}
            style={{ width: '100%' }}
          >
            {clients
              .filter(({ active }) => active === true)
              .map(({ id, first_name: firstName, last_name: lastName, ndis_number: ndisNumber, cp_count: cpCount }) => {
                return (
                  <Option key={id} value={id}>
                    <div>{firstName} {lastName}{cpCount < 1 ? ' (PRODA)' : ''}</div>

                    <div className='subtitle'>{ndisNumber}</div>
                  </Option>
                )
              })}
          </Select>
        )}
      </FormItem>
    </div>
  ) : validator.isId(selectedClient.id) ? (
    <>
      <Panel className='dropdown-box' type='custom'>
        <Skeleton active loading={loadingClient}>
          <div className='row-title'>
            <div>
              <span className='pid-label'>
                <Link to={`/participants/${selectedClient.ref_id}/info`} rel='noopener noreferrer' target='_blank'>
                  {selectedClient.first_name} {selectedClient.last_name}
                </Link>
              </span>

              <span className='pid-tag'>{selectedClient.ndis_number}</span>
            </div>

            {!isEdit ? (
              <Tooltip title='Change Participant'>
                <span className='btn-edit' onClick={toggleEditClient}><Icon type='edit' /></span>
              </Tooltip>
            ) : null}
          </div>

          <div className='row-icon'>
            <Icon className='pid-icon' type='calendar' theme='twoTone' twoToneColor='#ed6d1e' />

            <div>
              {selectedClient.dob
                ? `${formatter.toShortDate(selectedClient.dob)} (${formatter.toYearCount(selectedClient.dob)} years old)`
                : 'N/A'}
            </div>
          </div>

          <div className='row-icon'>
            <Icon className='pid-icon' type='dollar' theme='twoTone' twoToneColor='#ed6d1e' />

            <div>
              INV AUTH: {formatter.toYesNo(selectedClient.pm_is_auth_req)}{' '}
              {selectedClient.pm_auth_amount ? `(Max ${formatter.toPrice(selectedClient.pm_auth_amount)})` : `(No Limit)`}
              {selectedClient.pm_auth_email ? `; ${selectedClient.pm_auth_email}` : ''}
            </div>
          </div>

          <div className='row-icon'>
            <Icon className='pid-icon' type='bank' theme='twoTone' twoToneColor='#ed6d1e' />

            <div>
              {selectedClient.pm_bsb || selectedClient.pm_bank_acc_no
                ? [
                  `BSB: ${selectedClient.pm_bsb || 'N/A'}`,
                  `ACC: ${selectedClient.pm_bank_acc_no || 'N/A'}`,
                  `${selectedClient.pm_rmt_email ? `REMIT Email Available.` : 'No REMIT Email Configured.'}`
                ].join(', ')
                : 'N/A'}
            </div>
          </div>
        </Skeleton>
      </Panel>

      {Array.isArray(clientWarnings) ? (
        <ol>{clientWarnings.map((warning, idx) => (<li key={idx} className=''>{warning}</li>))}</ol>
      ) : null}
    </>
  ) : null
}

function SelectProviderBox ({
  changeProvider, toggleEditProvider, providerWarnings, providers, isEdit, isEditProvider, loadingClient, loadingDropdown,
  loadingProvider, props, selectedProvider
}) {
  const { form } = props || {}
  const { getFieldDecorator } = form || {}

  return isEditProvider ? (
    <div className='form-padding-right'>
      <FormItem {...dropdownFormItemLayout} label='Provider'>
        {getFieldDecorator('provider_id', {
          initialValue: undefined,
          rules: [
            { required: true, message: 'Please select a provider' }
          ]
        })(
          <Select
            disabled={loadingClient}
            dropdownMatchSelectWidth={false}
            filterOption={searchDropdown}
            loading={loadingDropdown}
            notFoundContent='Not found'
            optionFilterProp='children'
            placeholder='Provider'
            showSearch
            tabIndex={2}
            onChange={changeProvider}
            style={{ width: '100%' }}
          >
            {providers.map(({ id, abn, fullname }) => {
              return (
                <Option key={id} value={id}>
                  <div>{fullname}</div>

                  <div className='subtitle'>{abn}</div>
                </Option>
              )
            })}
          </Select>
        )}
      </FormItem>
    </div>
  ) : validator.isId(selectedProvider.id) ? (
    <>
      <Panel className='dropdown-box' type='custom'>
        <Skeleton active loading={loadingProvider}>
          <div className='row-title'>
            <div>
              <span className='pid-label'>
                <Link to={`/providers/${selectedProvider.ref_id}/info`} rel='noopener noreferrer' target='_blank'>
                  {selectedProvider.fullname}
                </Link>
              </span>

              {selectedProvider.abn ? <span className='pid-tag'>{formatter.formatABN(selectedProvider.abn)}</span> : null}
            </div>

            {!isEdit ? (
              <Tooltip title='Change Provider'>
                <span className='btn-edit' onClick={toggleEditProvider}><Icon type='edit' /></span>
              </Tooltip>
            ) : null}
          </div>

          <div className='row-icon'>
            <Icon className='pid-icon' type='home' theme='twoTone' twoToneColor='#ed6d1e' />

            <div>{selectedProvider.unit_building} {selectedProvider.address}</div>
          </div>

          <div className='row-icon'>
            <Icon className='pid-icon' type='mail' theme='twoTone' twoToneColor='#ed6d1e' />

            <div>{selectedProvider.email || 'N/A'}</div>
          </div>

          <div className='row-icon'>
            <Icon className='pid-icon' type='bank' theme='twoTone' twoToneColor='#ed6d1e' />

            <div>
              {selectedProvider.pm_bsb || selectedProvider.pm_bank_acc_no
                ? `BSB: ${selectedProvider.pm_bsb || 'N/A'}, ACC: ${selectedProvider.pm_bank_acc_no || 'N/A'}`
                : 'N/A'}
            </div>
          </div>
        </Skeleton>
      </Panel>

      {Array.isArray(providerWarnings) ? (
        <ol>{providerWarnings.map((warning, idx) => (<li key={idx} className=''>{warning}</li>))}</ol>
      ) : null}
    </>
  ) : null
}

function FilePanel ({
  fileCategories, fileSubCategories, files, hasAccess, props, showFileModal, onAddFile, onRemoveFile, onUpdateFile,
  onCloseModal, onOpenModal
}) {
  const { form } = props || {}
  const { getFieldValue } = form || {}
  const clientId = getFieldValue('client_id')
  const uploadData = validator.isId(clientId)
    ? { client_id: clientId, is_temp: true, module: FileUploadModule.Invoice } : undefined
  const [isEdit, setIsEdit] = useState(false)
  const [selectedFile, setSelectedFile] = useState({})
  const fileCategoryLookup = new Map(
    Array.isArray(fileCategories) && fileCategories.length > 0
      ? fileCategories.map((fileCategory) => [fileCategory.id, fileCategory]) : []
  )
  const fileSubCategoryLookup = new Map(
    Array.isArray(fileSubCategories) && fileSubCategories.length > 0
      ? fileSubCategories.map((fileSubCategory) => [fileSubCategory.id, fileSubCategory]) : []
  )

  const patchFile = useCallback((file) => {
    const { main_cat_id: mainCatId, sub_cat_id: subCatId } = file
    const { name: catName } = fileCategoryLookup.get(mainCatId) || {}
    const {
      name: subCatName, is_attach_mail_comm: subCatIsAttachMailComm
    } = fileSubCategoryLookup.get(subCatId) || {}
    file.main_cat_name = catName || ''
    file.sub_cat_name = subCatName || ''
    file.sub_cat_is_attach_mail_comm = subCatIsAttachMailComm || false
    return file
  }, [fileCategoryLookup, fileSubCategoryLookup])

  const addFile = useCallback((file, callback) => {
    if (typeof onAddFile === 'function') {
      onAddFile(patchFile(file), callback)
    }
  }, [patchFile, onAddFile])

  const editFile = useCallback((file) => {
    if (typeof onOpenModal === 'function') {
      setIsEdit(true)
      setSelectedFile(file)
      onOpenModal()
    }
  }, [onOpenModal])

  const updateFile = useCallback((file, callback) => {
    if (typeof onUpdateFile === 'function') {
      onUpdateFile(patchFile(file), callback)
    }
  }, [patchFile, onUpdateFile])

  const closeModal = useCallback(() => {
    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }
  }, [onCloseModal])

  const openModal = useCallback(() => {
    if (typeof onOpenModal === 'function') {
      const invoiceDate = getFieldValue('invoice_date')
      const invoiceNumber = getFieldValue('invoice_number')
      setIsEdit(false)
      setSelectedFile({ issuance_date: invoiceDate, label: invoiceNumber })
      onOpenModal()
    }
  }, [getFieldValue, onOpenModal])

  return (
    <Panel
      className='pf-file-list'
      title='Files'
      subtitle={(hasAccess(Permissions.INVOICE.FILES_PACE.CREATE)
        ? <Button onClick={openModal}>Add File</Button>
        : null)}
    >
      <Spin spinning={false}>
        <List cols={getFileColumns(hasAccess, editFile, onRemoveFile)} rows={files} />
      </Spin>

      <FileAddModal
        file={selectedFile} fileCategories={fileCategories} isEdit={isEdit} subCategories={fileSubCategories}
        visible={showFileModal} uploadData={uploadData} onAddFile={addFile} onClose={closeModal} onUpdateFile={updateFile}
      />
    </Panel>
  )
}

function PlanPeriodSummaryRow ({ className, clientFundingItem }) {
  const {
    cfi_category_number: categoryNumber, cfi_category_name: categoryName, cfi_allocated_amount: allocatedAmount,
    total_remaining: totalRemaining, forecast_remaining: forecastRemaining
  } = clientFundingItem
  const percentUsed = BigNumber(allocatedAmount).isGreaterThan(0)
    ? BigNumber(totalRemaining).div(allocatedAmount).times(100).toFixed(2)
    : BigNumber(0).toFixed(2)
  const strokeColor = BigNumber(percentUsed).isLessThanOrEqualTo(30)
    ? '#f5222d' : BigNumber(percentUsed).isGreaterThanOrEqualTo(95)
      ? '#52c41a' : undefined

  return (
    <div className={clsx('funding-list-box', className)}>
      <Row className='funding-list'>
        <Col className='title' lg={8}>{categoryName} ({formatter.toCategoryNumber(categoryNumber)})</Col>

        <Col lg={8}>
          <span className='ipp-text-bold'>({formatter.toPrice(forecastRemaining)})</span>
          {' / '}
          <span className='ipp-text-bold'>{formatter.toPrice(totalRemaining)}</span>
          {' / '}
          {formatter.toPrice(allocatedAmount)}
        </Col>

        <Col lg={8}>
          <Progress percent={BigNumber(percentUsed).toNumber()} strokeColor={strokeColor} />
        </Col>
      </Row>
    </div>
  )
}

function PlanPeriodSummary ({ appliedCreditUsage, credits, isStartEndDateSelected, item, loadingClientFundings, matchingClientFundings }) {
  return (
    <>
      {loadingClientFundings
        ? <Alert className='pid-alert' description={<Skeleton active loading />} />
        : !isStartEndDateSelected ? null : Array.isArray(matchingClientFundings) && matchingClientFundings.length > 0 ? (
          matchingClientFundings.map((clientFunding, idx) => {
            const { start_date: startDate, end_date: endDate, client_funding_items: clientFundingItems } = clientFunding
            const { isDue } = formatter.toPeriodStatus(endDate, moment())
            const title = (
              <div className='pid-title'>
                <span>{formatter.toShortDate(startDate)} - {formatter.toShortDate(endDate)}</span>

                {isDue ? <span className='highlight'>{' '}({PlanPeriodEndedMsg})</span> : null}
              </div>
            )
            const mergedItems = mergeClientFundingItemsByCategoryGroup(clientFundingItems)

            return (
              <Alert
                key={idx}
                className='pid-alert'
                description={Array.isArray(mergedItems) ? mergedItems.map((clientFundingItem) => {
                  const { cfi_id: cfiId, items: categoryGroupItems } = clientFundingItem
                  const hasCategoryGroupItems = Array.isArray(categoryGroupItems) && categoryGroupItems.length > 0

                  return hasCategoryGroupItems ? (
                    <Fragment key={cfiId}>
                      <PlanPeriodSummaryRow key={cfiId} clientFundingItem={clientFundingItem} />

                      {categoryGroupItems.map((item, idx) => (
                        <PlanPeriodSummaryRow key={`${cfiId}-${idx || ''}`} className='sub' clientFundingItem={item} />
                      ))}
                    </Fragment>
                  ) : <PlanPeriodSummaryRow key={cfiId} clientFundingItem={clientFundingItem} />
                }) : null}
                message={title}
                type='info'
              />
            )
          })
        ) : (
          <Alert
            className='pid-alert'
            message={<b>No active plan period available on selected service start and end dates.</b>}
            type='error'
          />
        )}

      {!loadingClientFundings && !isInvoiceClosed(item) && Array.isArray(credits) && credits.length > 0 ? (
        <Alert
          className='pid-alert'
          description={(
            <div className='credit-list'>
              {credits.map((credit, idx) => {
                const {
                  id, category_number: categoryNumber, category_name: categoryName, category_group_name: categoryGroupName, amount,
                  remaining_amount: remainingAmount, private_comment: privateComment, created_at: createdAt
                } = credit
                const creditUsage = appliedCreditUsage[id]
                const currentRemaining = BigNumber(remainingAmount).minus(creditUsage)
                const currentUsed = BigNumber(amount).isGreaterThan(0)
                  ? BigNumber(currentRemaining).div(amount).times(100).toFixed(2)
                  : BigNumber(0).toFixed(2)
                const percentUsed = BigNumber(amount).isGreaterThan(0)
                  ? BigNumber(remainingAmount).div(amount).times(100).toFixed(2)
                  : BigNumber(0).toFixed(2)

                return (
                  <Row key={id} className='list-item' gutter={16}>
                    <Col className='col-idx' lg={8}>
                      <div className='label'>{idx + 1}.</div>

                      <div>
                        {categoryName} ({formatter.toCategoryNumber(categoryNumber)}){categoryGroupName ? ` - ${categoryGroupName}` : ''}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <span className={clsx('ipp-text-bold', BigNumber(currentUsed).isLessThanOrEqualTo(30) ? 'highlight' : '')}>
                        ({formatter.toPrice(currentRemaining)})
                      </span>
                      {' '}
                      <span className={clsx('ipp-text-bold', BigNumber(percentUsed).isLessThanOrEqualTo(30) ? 'highlight' : '')}>
                        {formatter.toPrice(remainingAmount)}
                      </span>
                      {' / '}
                      {formatter.toPrice(amount)}
                    </Col>

                    <Col lg={2}>{formatter.toShortDate(createdAt)}</Col>

                    <Col lg={8}>{privateComment}</Col>
                  </Row>
                )
              })}
            </div>
          )}
          message={<b>Available credits to apply</b>}
          type='success'
        />
      ) : null}
    </>
  )
}

function InvoiceItemList ({
  appliedCreditsMap, categories, claimTypes, claimTypeReasons, clientFundingUsage, clientFundings, credits, defaultCategoryItemDropdownMap,
  gstTypes, gstTypeLookup, hasAccess, invoice, isEdit, loadingClientFundings, props, onAddItem, onRemoveItem, onUndoRemoveItem,
  onOpenCreditModal, onOpenReceivedAmountModal, setTallyError, setTallyWarning
}) {
  const {
    amount: iAmount, expected_amount: iExpectedAmount, gst_amount: iGstAmount, credit_amount: iCreditAmount,
    received_amount: iReceivedAmount, items
  } = invoice || {}
  const { form } = props || {}
  const { getFieldDecorator, getFieldError, getFieldValue, getFieldsValue, setFieldsValue, validateFields } = form || {}
  const [categoryItemDropdownMap, setCategoryItemDropdownMap] = useState({})
  const [helpDisplayAmount, setHelpDisplayAmount] = useState()
  const [helpDisplayExpectedAmount, setHelpDisplayExpectedAmount] = useState()
  const [init, setInit] = useState(true)
  const [selectedGstTypeLookup, setSelectedGstTypeLookup] = useState({})
  const [validateStatusDisplayAmount, setValidateStatusDisplayAmount] = useState('success')
  const [validateStatusDisplayExpectedAmount, setValidateStatusDisplayExpectedAmount] = useState('success')
  const canApplyCredit = !isEdit || isInvoiceDrafted(invoice)
  let _amount = formatter.toBigNumber(getFieldValue('amount') || iAmount)
  _amount = BigNumber(!_amount.isNaN() ? _amount : 0).toFixed(2)
  let _expectedAmount = formatter.toBigNumber(getFieldValue('expected_amount') || iExpectedAmount)
  _expectedAmount = BigNumber(!_expectedAmount.isNaN() ? _expectedAmount : 0).toFixed(2)
  let _gstAmount = formatter.toBigNumber(getFieldValue('gst_amount') || iGstAmount)
  _gstAmount = BigNumber(!_gstAmount.isNaN() ? _gstAmount : 0).toFixed(2)
  const displayAmount = getDisplayAmount(_amount, _gstAmount)
  const displayExpectedAmount = getDisplayExpectedAmount(_expectedAmount, _amount)
  const totalCreditAmount = BigNumber(getAppliedCreditAmount(appliedCreditsMap))

  const getFormFieldValue = useCallback((idx, field) => {
    return getFieldValue(getInvoiceItemFieldName(idx, field))
  }, [getFieldValue])

  const addInvoiceItem = useCallback(() => {
    if (typeof onAddItem === 'function') {
      onAddItem()
    }
  }, [onAddItem])

  const removeInvoiceItem = useCallback((idx, item) => () => {
    if (typeof onRemoveItem === 'function') {
      const { invoice_items: invoiceItems } = getFieldsValue()
      const { invoiceAmount, invoiceGstAmount } = getInvoiceAmount(idx, invoiceItems, 0, 0)
      setFieldsValue({
        amount: BigNumber(invoiceAmount).toFormat(2),
        display_amount: getDisplayAmount(invoiceAmount, invoiceGstAmount),
        gst_amount: BigNumber(invoiceGstAmount).toFormat(2),
        [getInvoiceItemFieldName(idx, InvoiceItemField.Deleted)]: true
      })
      onRemoveItem(idx, item)
    }
  }, [getFieldsValue, onRemoveItem, setFieldsValue])

  const undoRemoveInvoiceItem = useCallback((idx, item) => () => {
    if (typeof onUndoRemoveItem === 'function') {
      const amount = getFormFieldValue(idx, InvoiceItemField.Amount)
      const gstAmount = getFormFieldValue(idx, InvoiceItemField.GstAmount)
      const { invoice_items: invoiceItems } = getFieldsValue()
      const { invoiceAmount, invoiceGstAmount } = getInvoiceAmount(idx, invoiceItems, amount, gstAmount)
      setFieldsValue({
        amount: BigNumber(invoiceAmount).toFormat(2),
        display_amount: getDisplayAmount(invoiceAmount, invoiceGstAmount),
        gst_amount: BigNumber(invoiceGstAmount).toFormat(2),
        [getInvoiceItemFieldName(idx, InvoiceItemField.Deleted)]: false
      })
      onUndoRemoveItem(idx, item)
    }
  }, [getFieldsValue, getFormFieldValue, onUndoRemoveItem, setFieldsValue])

  const updateTally = useCallback((idx, gstType, gstMode, inputRate, unit) => {
    // Calculate and tally "Invoiced Rate [GST Rate]", "Invoiced Amount [GST Amount]", "Total Inv Amt [GST Amt]"
    const { invoice_items: invoiceItems } = getFieldsValue()
    const { rate, rateGstAmount } = getRate(gstType, gstMode, inputRate)
    const amount = getInvoiceItemAmount(unit, rate)
    const gstAmount = getInvoiceItemGstAmount(unit, rateGstAmount)
    const { invoiceAmount, invoiceGstAmount } = getInvoiceAmount(idx, invoiceItems, amount, gstAmount)
    setFieldsValue({
      amount: BigNumber(invoiceAmount).toFormat(2),
      display_amount: getDisplayAmount(invoiceAmount, invoiceGstAmount),
      gst_amount: BigNumber(invoiceGstAmount).toFormat(2),
      [getInvoiceItemFieldName(idx, InvoiceItemField.Amount)]: BigNumber(amount).toFormat(2),
      [getInvoiceItemFieldName(idx, InvoiceItemField.DisplayAmount)]: getDisplayAmount(amount, gstAmount),
      [getInvoiceItemFieldName(idx, InvoiceItemField.GstAmount)]: BigNumber(gstAmount).toFormat(2),
      [getInvoiceItemFieldName(idx, InvoiceItemField.Rate)]: BigNumber(rate).toFormat(2)
    }, () => {
      validateFields([getInvoiceItemFieldName(idx, InvoiceItemField.DisplayAmount)], { force: true })
    })
  }, [getFieldsValue, setFieldsValue, validateFields])

  const changeCategory = useCallback((idx, categoryLookup) => (categoryId) => {
    const category = categoryLookup.get(categoryId)

    if (validator.isObject(category) && validator.isId(category.id)) {
      const {
        id: cfId, client_id: clientId, cfi_category_number: categoryNumber, cfi_category_name: categoryName,
        cfi_category_group_id: categoryGroupId, cfi_funding_type: fundingType
      } = category
      const iiStartDate = getFormFieldValue(idx, InvoiceItemField.StartDate)
      const iiEndDate = getFormFieldValue(idx, InvoiceItemField.EndDate)
      const _iiStartDate = moment(iiStartDate).startOf('day').toISOString()
      const _iiEndDate = moment(iiEndDate).endOf('day').toISOString()
      categoryItemDropdownMap[idx] = { loading: true, categoryItems: [] }
      setCategoryItemDropdownMap({ ...categoryItemDropdownMap })
      pmRateSetCategoryItemPaceService.getAll({
        category_number: categoryNumber,
        client_id: clientId,
        $and: [
          {
            $or: [
              // { rs_start_date: { condition: '>=', value: _iiStartDate }, rs_end_date: { condition: '<=', value: _iiEndDate } },
              { rs_start_date: { condition: '<=', value: _iiStartDate }, rs_end_date: { condition: '>=', value: _iiEndDate } },
              // { rs_start_date: { $and: [{ condition: '>=', value: _iiStartDate }, { condition: '<=', value: _iiEndDate }] } },
              // { rs_end_date: { $and: [{ condition: '>=', value: _iiStartDate }, { condition: '<=', value: _iiEndDate }] } }
            ]
          }
        ]
      })
        .then((categoryItems) => {
          const _categoryItems = (Array.isArray(categoryItems) ? categoryItems : []).filter(({
            start_date: ciStartDate, end_date: ciEndDate
          }) => {
            // Filter out support items using item start and end dates
            if (validator.isDate(ciStartDate) && validator.isDate(ciEndDate)) {
              return moment(iiStartDate).isSameOrAfter(ciStartDate) && moment(iiEndDate).isSameOrBefore(ciEndDate)
            } else if (validator.isDate(ciStartDate)) {
              return moment(iiStartDate).isSameOrAfter(ciStartDate)
            } else if (validator.isDate(ciEndDate)) {
              return moment(iiEndDate).isSameOrBefore(ciEndDate)
            }

            return true
          })
          categoryItemDropdownMap[idx] = { loading: false, categoryItems: _categoryItems }
          setCategoryItemDropdownMap({ ...categoryItemDropdownMap })
        })
        .catch(() => {
          categoryItemDropdownMap[idx] = { loading: false, categoryItems: [] }
          setCategoryItemDropdownMap({ ...categoryItemDropdownMap })
        })
      setFieldsValue({
        [getInvoiceItemFieldName(idx, InvoiceItemField.ClientFundingId)]: cfId,
        [getInvoiceItemFieldName(idx, InvoiceItemField.CategoryNumber)]: categoryNumber,
        [getInvoiceItemFieldName(idx, InvoiceItemField.CategoryName)]: categoryName,
        [getInvoiceItemFieldName(idx, InvoiceItemField.CategoryGroupId)]: categoryGroupId,
        [getInvoiceItemFieldName(idx, InvoiceItemField.FundingType)]: fundingType,
        [getInvoiceItemFieldName(idx, InvoiceItemField.CategoryItemId)]: undefined,
        [getInvoiceItemFieldName(idx, InvoiceItemField.CategoryItemNumber)]: undefined,
        [getInvoiceItemFieldName(idx, InvoiceItemField.CategoryItemName)]: undefined
      })
    }
  }, [getFormFieldValue, setFieldsValue, categoryItemDropdownMap])

  const changeCategoryItem = useCallback((idx, categoryItemLookup) => (categoryItemId) => {
    const categoryItem = categoryItemLookup.get(categoryItemId)

    if (validator.isObject(categoryItem) && validator.isId(categoryItem.id)) {
      const { rate_set_id: rsId, item_number: itemNumber, item_name: itemName, value } = categoryItem
      const { invoice_items: invoiceItems } = getFieldsValue()
      const gstMode = getFormFieldValue(idx, InvoiceItemField.GstMode)
      const unit = getFormFieldValue(idx, InvoiceItemField.Unit)
      const selectedGstType = selectedGstTypeLookup[idx]
      const { inputRate, rateGstAmount } = getInvoiceItemInputRate(selectedGstType, gstMode, value)
      const rate = BigNumber(!BigNumber(value).isNaN() ? value : 0).toFixed(2)
      const amount = getInvoiceItemAmount(unit, rate)
      const gstAmount = getInvoiceItemGstAmount(unit, rateGstAmount)
      const { invoiceAmount, invoiceGstAmount } = getInvoiceAmount(idx, invoiceItems, amount, gstAmount)
      const timer = setTimeout(() => {
        clearTimeout(timer)
        setFieldsValue({
          amount: BigNumber(invoiceAmount).toFormat(2),
          display_amount: getDisplayAmount(invoiceAmount, invoiceGstAmount),
          gst_amount: BigNumber(invoiceGstAmount).toFormat(2),
          [getInvoiceItemFieldName(idx, InvoiceItemField.Amount)]: BigNumber(amount).toFormat(2),
          [getInvoiceItemFieldName(idx, InvoiceItemField.RateSetId)]: rsId,
          [getInvoiceItemFieldName(idx, InvoiceItemField.CategoryItemNumber)]: itemNumber,
          [getInvoiceItemFieldName(idx, InvoiceItemField.CategoryItemName)]: itemName,
          [getInvoiceItemFieldName(idx, InvoiceItemField.DisplayAmount)]: getDisplayAmount(amount, gstAmount),
          [getInvoiceItemFieldName(idx, InvoiceItemField.GstAmount)]: BigNumber(gstAmount).toFormat(2),
          [getInvoiceItemFieldName(idx, InvoiceItemField.InputRate)]: BigNumber(inputRate).toFormat(2),
          [getInvoiceItemFieldName(idx, InvoiceItemField.MaxRate)]: getInvoiceItemMaxRate(value),
          [getInvoiceItemFieldName(idx, InvoiceItemField.Rate)]: BigNumber(rate).toFormat(2)
        })
      }, 10)
    }
  }, [getFieldsValue, getFormFieldValue, setFieldsValue, selectedGstTypeLookup])

  const changeEndDate = useCallback((idx) => (value) => {
    if (validator.isDate(value)) {
      const fnStartDate = getInvoiceItemFieldName(idx, InvoiceItemField.StartDate)
      let startDate = getFieldValue(fnStartDate)
      value = moment(value).endOf('day')

      if (!validator.isDate(startDate)) {
        startDate = moment(value).startOf('day')
        setFieldsValue({ [fnStartDate]: startDate })
      }
    }
  }, [getFieldValue, setFieldsValue])

  const changeGstMode = useCallback((idx) => (value) => {
    const gstMode = value === false ? InvoiceGstMode.Exclusive : InvoiceGstMode.Inclusive
    const { invoice_items: invoiceItems } = getFieldsValue()
    const inputRate = getFormFieldValue(idx, InvoiceItemField.InputRate)
    const maxRate = getFormFieldValue(idx, InvoiceItemField.MaxRate)
    const unit = getFormFieldValue(idx, InvoiceItemField.Unit)
    const selectedGstType = selectedGstTypeLookup[idx]
    const { rate } = getRate(selectedGstType, gstMode, inputRate)

    // Cap effective rate to max rate when it exceeded max rate
    if (!BigNumber(maxRate).isNaN() && BigNumber(maxRate).isLessThan(rate)) {
      const { inputRate: preTaxInputRate, rateGstAmount } = getInvoiceItemInputRate(selectedGstType, gstMode, maxRate)
      const newInputRate = value === false ? preTaxInputRate : maxRate
      const amount = getInvoiceItemAmount(unit, maxRate)
      const gstAmount = getInvoiceItemGstAmount(unit, rateGstAmount)
      const { invoiceAmount, invoiceGstAmount } = getInvoiceAmount(idx, invoiceItems, amount, gstAmount)
      setFieldsValue({
        amount: BigNumber(invoiceAmount).toFormat(2),
        display_amount: getDisplayAmount(invoiceAmount, invoiceGstAmount),
        gst_amount: BigNumber(invoiceGstAmount).toFormat(2),
        [getInvoiceItemFieldName(idx, InvoiceItemField.Amount)]: BigNumber(amount).toFormat(2),
        [getInvoiceItemFieldName(idx, InvoiceItemField.DisplayAmount)]: getDisplayAmount(amount, gstAmount),
        [getInvoiceItemFieldName(idx, InvoiceItemField.GstAmount)]: BigNumber(gstAmount).toFormat(2),
        [getInvoiceItemFieldName(idx, InvoiceItemField.InputRate)]: BigNumber(newInputRate).toFormat(2),
        [getInvoiceItemFieldName(idx, InvoiceItemField.Rate)]: BigNumber(maxRate).toFormat(2)
      })
    } else {
      updateTally(idx, selectedGstType, gstMode, inputRate, unit)
    }
  }, [getFieldsValue, getFormFieldValue, setFieldsValue, updateTally, selectedGstTypeLookup])

  const changeGstType = useCallback((idx) => (value) => {
    const gstMode = getFormFieldValue(idx, InvoiceItemField.GstMode)
    const inputRate = getFormFieldValue(idx, InvoiceItemField.InputRate)
    const unit = getFormFieldValue(idx, InvoiceItemField.Unit)
    const gstType = gstTypeLookup.get(value) || {}
    selectedGstTypeLookup[idx] = gstType
    setSelectedGstTypeLookup(selectedGstTypeLookup)
    validateFields([getInvoiceItemFieldName(idx, InvoiceItemField.InputRate)], { force: true }, () => {
      updateTally(idx, gstType, gstMode, inputRate, unit)
    })
  }, [getFormFieldValue, updateTally, validateFields, gstTypeLookup, selectedGstTypeLookup])

  const changeInputRate = useCallback((idx) => ({ target }) => {
    const { value } = target || {}
    const gstMode = getFormFieldValue(idx, InvoiceItemField.GstMode)
    const unit = getFormFieldValue(idx, InvoiceItemField.Unit)
    const selectedGstType = selectedGstTypeLookup[idx]
    validateFields([getInvoiceItemFieldName(idx, InvoiceItemField.InputRate)], { force: true }, () => {
      updateTally(idx, selectedGstType, gstMode, value, unit)
    })
  }, [getFormFieldValue, updateTally, validateFields, selectedGstTypeLookup])

  const changeStartDate = useCallback((idx) => (value) => {
    if (validator.isDate(value)) {
      const fnEndDate = getInvoiceItemFieldName(idx, InvoiceItemField.EndDate)
      let endDate = getFieldValue(fnEndDate)
      value = moment(value).startOf('day')

      if (!validator.isDate(endDate)) {
        endDate = moment(value).endOf('day')
        setFieldsValue({ [fnEndDate]: endDate })
      }
    }
  }, [getFieldValue, setFieldsValue])

  const changeUnit = useCallback((idx) => ({ target }) => {
    const { value } = target || {}
    const gstMode = getFormFieldValue(idx, InvoiceItemField.GstMode)
    const inputRate = getFormFieldValue(idx, InvoiceItemField.InputRate)
    const selectedGstType = selectedGstTypeLookup[idx]
    validateFields([getInvoiceItemFieldName(idx, InvoiceItemField.InputRate)], { force: true }, () => {
      updateTally(idx, selectedGstType, gstMode, inputRate, value)
    })
  }, [getFormFieldValue, updateTally, validateFields, selectedGstTypeLookup])

  const validateDisplayUnit = useCallback((idx) => (rule, value, callback) => {
    const { isWithinBudget } = validateClientFundingUsage(idx, getFieldsValue, clientFundingUsage)

    if (!isWithinBudget) {
      callback(new Error(' '))
    } else {
      callback()
    }
  }, [getFieldsValue, clientFundingUsage])

  const validateEndDate = useCallback((idx) => (rule, value, callback) => {
    if (validator.isDate(value)) {
      const fnStartDate = getInvoiceItemFieldName(idx, InvoiceItemField.StartDate)
      const startDate = getFieldValue(fnStartDate)

      if (validator.isDate(startDate) && moment(value).endOf('day').isBefore(moment(startDate).startOf('day'))) {
        callback(new Error('Service end date must be after service start date'))
      } else {
        const startDateErrors = getFieldError(fnStartDate)
        callback()

        if (Array.isArray(startDateErrors) && startDateErrors.length > 0) {
          validateFields([fnStartDate], { force: true })
        }
      }
    } else {
      callback()
    }
  }, [getFieldError, getFieldValue, validateFields])

  const validateInputRate = useCallback((idx) => (rule, value, callback) => {
    const gstMode = getFieldValue(getInvoiceItemFieldName(idx, InvoiceItemField.GstMode))
    const maxRate = formatter.toBigNumber(getFieldValue(getInvoiceItemFieldName(idx, InvoiceItemField.MaxRate)))
    const selectedGstType = selectedGstTypeLookup[idx]
    const { rate } = getRate(selectedGstType, gstMode, value)

    if (isString(value)) {
      if (!isDecimal(value)) {
        callback(new Error('Please enter a number with 2 decimal places'))
      } else if (BigNumber(maxRate).isLessThan(rate)) {
        callback(new Error('Invoiced rate exceeded max rate'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }, [getFieldValue, selectedGstTypeLookup])

  const validateStartDate = useCallback((idx) => (rule, value, callback) => {
    if (validator.isDate(value)) {
      const fnEndDate = getInvoiceItemFieldName(idx, InvoiceItemField.EndDate)
      const endDate = getFieldValue(fnEndDate)

      if (validator.isDate(endDate) && moment(value).startOf('day').isAfter(moment(endDate).endOf('day'))) {
        callback(new Error('Service start date must be before service end date'))
      } else {
        const endDateErrors = getFieldError(fnEndDate)
        callback()

        if (Array.isArray(endDateErrors) && endDateErrors.length > 0) {
          validateFields([fnEndDate], { force: true })
        }
      }
    } else {
      callback()
    }
  }, [getFieldError, getFieldValue, validateFields])

  const validateUnit = useCallback((idx) => (rule, value, callback) => {
    if (isString(value) && !isDecimal(value)) {
      callback(new Error('Please enter a number with 2 decimal places'))
    } else {
      callback()
    }
  }, [])

  const filterGstType = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const filterClaimType = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const filterClaimReason = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const openCreditModal = useCallback((idx) => () => {
    if (typeof onOpenCreditModal === 'function') {
      const { invoice_items: invoiceItems } = getFieldsValue()
      onOpenCreditModal(idx, invoiceItems[idx] || {})
    }
  }, [getFieldsValue, onOpenCreditModal])

  const openReceivedAmountModal = useCallback((idx) => () => {
    if (typeof onOpenReceivedAmountModal === 'function') {
      const { invoice_items: invoiceItems } = getFieldsValue()
      onOpenReceivedAmountModal(idx, invoiceItems[idx] || {})
    }
  }, [getFieldsValue, onOpenReceivedAmountModal])

  useEffect(() => {
    let mounted = true

    const isValid = validator.isObject(categoryItemDropdownMap) && Object.keys(categoryItemDropdownMap).length < 1
    const isValidDefault = validator.isObject(defaultCategoryItemDropdownMap) && Object.keys(defaultCategoryItemDropdownMap).length > 0

    if (mounted && isValid && isValidDefault) {
      setCategoryItemDropdownMap(defaultCategoryItemDropdownMap)
    }

    return () => {
      mounted = false
    }
  }, [categoryItemDropdownMap, defaultCategoryItemDropdownMap])

  useEffect(() => {
    if (init && validator.isObject(invoice) && validator.isId(invoice.id)) {
      const { items } = invoice

      if (Array.isArray(items) && items.length > 0) {
        const selectedGstTypeLookup = {}

        for (let i = 0; i < items.length; i++) {
          const { gst_code: gstCode } = items[i]
          const gstType = gstTypeLookup.get(gstCode) || {}
          selectedGstTypeLookup[i] = gstType
        }

        setSelectedGstTypeLookup(selectedGstTypeLookup)
      }

      setInit(false)
    }
  }, [init, invoice, gstTypeLookup])

  useEffect(() => {
    const diffAmount = BigNumber(_amount).minus(_expectedAmount)
    const diffExpectedAmount = BigNumber(_expectedAmount).minus(_amount)

    if (!diffAmount.isEqualTo(0)) {
      const helpDisplayAmount = `${DifferenceOf} ${diffAmount.toFormat(2)}`
      setHelpDisplayAmount(helpDisplayAmount)
      setHelpDisplayExpectedAmount(`${DifferenceOf} ${diffExpectedAmount.toFormat(2)}`)
      setValidateStatusDisplayAmount('error')
      setValidateStatusDisplayExpectedAmount('error')

      if (diffAmount.isGreaterThan(0)) {
        setTallyError(
          <>
            Total Inv Amt (<b>{BigNumber(_amount).toFormat(2)}</b>) is more than Expected Inv Amt
            {" "}(<b>{BigNumber(_expectedAmount).toFormat(2)}</b>). {DifferenceOf} <b>{diffAmount.toFormat(2)}</b>
          </>
        )
        setTallyWarning()
      } else {
        setTallyError()
        setTallyWarning(
          <>
            <div>
              Total Inv Amt (<b>{BigNumber(_amount).toFormat(2)}</b>) is less than Expected Inv Amt
              {" "}(<b>{BigNumber(_expectedAmount).toFormat(2)}</b>). {DifferenceOf} <b>{diffAmount.toFormat(2)}</b>
            </div>

            <div>{PressCofirmToProceed}</div>
          </>
        )
      }
    } else {
      setHelpDisplayAmount()
      setHelpDisplayExpectedAmount()
      setValidateStatusDisplayAmount('success')
      setValidateStatusDisplayExpectedAmount('success')
      setTallyError()
      setTallyWarning()
    }
  }, [setTallyError, setTallyWarning, _amount, _expectedAmount])

  return (
    <Skeleton active loading={false}>
      {Array.isArray(items) ? items.map((item, idx) => {
        const {
          id: iiId, invoice_id: invoiceId, client_funding_id: clientFundingId, rate_set_id: rateSetId, start_date: startDate,
          end_date: endDate, category_id: categoryId, category_group_id: categoryGroupId, funding_type: fundingType,
          category_number: categoryNumber, category_name: categoryName, category_item_id: categoryItemId,
          category_item_number: categoryItemNumber, category_item_name: categoryItemName, max_rate: maxRate, unit,
          input_rate: inputRate, rate, amount, gst_amount: gstAmount, credit_amount: creditAmount, received_amount: receivedAmount,
          gst_code: gstCode, gst_mode: gstMode, claim_type: claimType, claim_type_reason: claimTypeReason, comment, deleted
        } = item
        const initialDisplayAmount = [
          `${BigNumber(!BigNumber(amount).isNaN() ? amount : 0).toFormat(2)}`,
          `[${BigNumber(!BigNumber(gstAmount).isNaN() ? gstAmount : 0).toFormat(2)}]`
        ].join(' ')
        const { loading: loadingCategoryDropdown, categoryItems } = categoryItemDropdownMap[idx] || { categoryItems: [] }
        const fvCategoryId = getFormFieldValue(idx, InvoiceItemField.CategoryId)
        const fvCategoryNumber = getFormFieldValue(idx, InvoiceItemField.CategoryNumber)
        const fvCategoryGroupId = getFormFieldValue(idx, InvoiceItemField.CategoryGroupId)
        const fvStartDate = getFormFieldValue(idx, InvoiceItemField.StartDate)
        const fvEndDate = getFormFieldValue(idx, InvoiceItemField.EndDate)
        const fvRate = getFormFieldValue(idx, InvoiceItemField.Rate)
        const displayRate = !formatter.toBigNumber(fvRate).isNaN() ? fvRate : !BigNumber(rate).isNaN() ? rate : undefined
        const {
          categories: matchingCategories, isMultipleRateSets, isSingleRateSet, overlapRateSets
        } = getMatchingCategories(categories, fvStartDate, fvEndDate)
        const {
          clientFundings: matchingClientFundings, isMismatchClientFunding, isMultipleClientFundings, isSingleClientFunding,
          overlapClientFundings
        } = getInvoiceItemClientFundings(clientFundings, fvStartDate, fvEndDate, clientFundingId)
        const isCategorySelected = !!fvCategoryId
        const isCategoryItemSelected = !!getFormFieldValue(idx, InvoiceItemField.CategoryItemId)
        const isClaimTypeCancellation = getFormFieldValue(idx, InvoiceItemField.ClaimType) === 'CANC'
        const isGstCodeP1 = getFormFieldValue(idx, InvoiceItemField.GstCode) === 'p1'
        const isNoClientFunding = Array.isArray(matchingClientFundings) && matchingClientFundings.length < 1
        const isStartEndDateSelected = validator.isDate(fvStartDate) && validator.isDate(fvEndDate)
        const { isDue, isDuePeriod1, isDuePeriod2 } = isSingleClientFunding
          ? formatter.toPeriodStatus(matchingClientFundings[0].end_date, moment()) : {}
        let isGstInclusive = getFormFieldValue(idx, InvoiceItemField.GstInclusive)
        isGstInclusive = typeof isGstInclusive === 'boolean' ? isGstInclusive : true
        const categoryLookup = new Map(
          Array.isArray(matchingCategories) && matchingCategories.length > 0
            ? matchingCategories.map((category) => [category.rsc_id, category]) : []
        )
        const categoryItemLookup = new Map(
          Array.isArray(categoryItems) && categoryItems.length > 0
            ? categoryItems.map((categoryItem) => [categoryItem.category_item_id, categoryItem]) : []
        )
        const { budgetName, isWithinBudget } = validateClientFundingUsage(idx, getFieldsValue, clientFundingUsage)
        const hasCredit = getApplicableCredits(
          credits, fvCategoryNumber || categoryNumber, fvCategoryGroupId || categoryGroupId
        ).length > 0
        const hasError = !loadingClientFundings && !loadingCategoryDropdown && isStartEndDateSelected && (
          isMismatchClientFunding || isMultipleClientFundings || isNoClientFunding || isMultipleRateSets ||
          (!isSingleRateSet && !isMultipleRateSets) || (isCategorySelected && isCategoryItemSelected && !isWithinBudget)
        )
        const multipleClientFundingsText = overlapClientFundings.length > 0 ? `(${overlapClientFundings.join(', ')})` : ''
        const multipleRateSetsText = overlapRateSets.length > 0 ? `(${overlapRateSets.join(', ')})` : ''
        const overBudgetText = budgetName.length > 0 ? `(${budgetName})` : ''

        return (
          <div key={idx} className={clsx('item-box', hasError ? 'has-error' : '')}>
            {hasAccess([Permissions.INVOICE.INFO_PACE.CREATE, Permissions.INVOICE.INFO_PACE.UPDATE]) && deleted ? (
              <div className='button-box-undo'>
                <Tooltip mouseLeaveDelay={0} title='Undo Delete'>
                  <Icon type='undo' onClick={undoRemoveInvoiceItem(idx, item)} />
                </Tooltip>
              </div>
            ) : null}

            <div className={deleted ? 'pid-blur' : ''} >
              <Row gutter={8}>
                <Col md={1} lg={1}>
                  <FormItem colon={false} label={idx + 1} />
                </Col>

                <Col md={5} lg={3}>
                  <FormItem label='Service Start Date'>
                    {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.StartDate), {
                      initialValue: !validator.isNullOrUndefined(startDate) ? moment(startDate) : undefined,
                      rules: [
                        { required: true, message: 'Service start date is required' },
                        { validator: validateStartDate(idx) }
                      ]
                    })(
                      <DatePicker
                        defaultPickerValue={moment().startOf('day')}
                        disabled={isInvoiceReadOnly(invoice)}
                        format={dateFormat}
                        tabIndex={getInvoiceItemTabIndex(idx, 1)}
                        onChange={changeStartDate(idx)}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>

                <Col md={5} lg={3}>
                  <FormItem label='Service End Date'>
                    {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.EndDate), {
                      initialValue: !validator.isNullOrUndefined(endDate) ? moment(endDate) : undefined,
                      rules: [
                        { required: true, message: 'Service end date is required' },
                        { validator: validateEndDate(idx) }
                      ]
                    })(
                      <DatePicker
                        defaultPickerValue={moment().endOf('day')}
                        disabled={isInvoiceReadOnly(invoice)}
                        format={dateFormat}
                        tabIndex={getInvoiceItemTabIndex(idx, 2)}
                        onChange={changeEndDate(idx)}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </Col>

                <Col md={5} lg={4}>
                  <FormItem label='GST Type'>
                    {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.GstCode), {
                      initialValue: gstCode || 'p2',
                      rules: [
                        { required: true, message: 'Please select GST type' }
                      ]
                    })(
                      <Select
                        disabled={isInvoiceReadOnly(invoice)}
                        dropdownMatchSelectWidth={false}
                        filterOption={filterGstType}
                        notFoundContent='No GST type available'
                        optionFilterProp='children'
                        placeholder='Select GST type'
                        showSearch
                        tabIndex={getInvoiceItemTabIndex(idx, 3)}
                        onChange={changeGstType(idx)}
                      >
                        {gstTypes.map(({ item_name: name, item_code: value }) => (
                          <Option key={value} value={value}>{name}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>

                <Col md={7} lg={4}>
                  <FormItem label='Claim Type'>
                    {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.ClaimType), {
                      initialValue: claimType || ''
                    })(
                      <Select
                        disabled={isInvoiceReadOnly(invoice)}
                        dropdownMatchSelectWidth={false}
                        filterOption={filterClaimType}
                        notFoundContent='No claim type available'
                        optionFilterProp='children'
                        placeholder='Select claim type'
                        showSearch
                        tabIndex={getInvoiceItemTabIndex(idx, 4)}
                        onChange={() => { }}
                      >
                        {claimTypes.map(({ name, value }) => (
                          <Option key={value} value={value}>{value ? `${value.trim()} - ${name.trim()}` : name.trim()}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>

                <Col md={{ offset: 1, span: 19 }} lg={{ offset: 0, span: 6 }}>
                  {isClaimTypeCancellation ? (
                    <FormItem label='Cancellation Reason'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.ClaimTypeReason), {
                        initialValue: claimTypeReason,
                        rules: [
                          { required: true, message: 'Please select cancellation reason' }
                        ]
                      })(
                        <Select
                          disabled={isInvoiceReadOnly(invoice)}
                          dropdownMatchSelectWidth={false}
                          filterOption={filterClaimReason}
                          notFoundContent='No cancellation reason available'
                          optionFilterProp='children'
                          placeholder='Select cancellation reason'
                          showSearch
                          tabIndex={getInvoiceItemTabIndex(idx, 5)}
                          onChange={() => { }}
                        >
                          {claimTypeReasons.map(({ name, value }) => (
                            <Option key={value} value={value}>{`${value.trim()} - ${name.trim()}`}</Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  ) : null}
                </Col>

                <Col md={2} lg={2}>
                  {isEdit && isInvoiceToReceive(invoice) ? (
                    <FormItem label='Closed?'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.Closed), {
                        initialValue: false,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          checkedChildren='Yes'
                          unCheckedChildren='No'
                          disabled={isInvoiceReadOnly(invoice)}
                          tabIndex={11}
                        />
                      )}
                    </FormItem>
                  ) : null}
                </Col>

                <Col md={1} lg={1}>
                  {!isInvoiceReadOnly(invoice) ? (
                    <div className='button-box'>
                      {hasAccess([Permissions.INVOICE.INFO_PACE.CREATE, Permissions.INVOICE.INFO_PACE.UPDATE]) && !deleted ? (
                        <Tooltip mouseLeaveDelay={0} title='Delete Item'>
                          <Popconfirm
                            cancelText='No'
                            okText='Yes'
                            title='Are you sure you want to delete this item?'
                            onConfirm={removeInvoiceItem(idx, item)}
                          >
                            <Icon type='delete' />
                          </Popconfirm>
                        </Tooltip>
                      ) : null}
                    </div>
                  ) : null}
                </Col>
              </Row>

              {isSingleClientFunding && isSingleRateSet && isStartEndDateSelected ? (
                <Row gutter={8}>
                  <Col md={1} lg={1} />

                  <Col md={10} lg={8}>
                    <FormItem label='Support Category'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CategoryId), {
                        initialValue: categoryId,
                        rules: [
                          { required: true, message: 'Please select support category' }
                        ]
                      })(
                        <Select
                          disabled={isInvoiceReadOnly(invoice) || loadingClientFundings}
                          dropdownMatchSelectWidth={false}
                          filterOption={searchDropdown}
                          notFoundContent='No support category available'
                          optionFilterProp='children'
                          placeholder='Select support category'
                          showSearch
                          tabIndex={getInvoiceItemTabIndex(idx, 6)}
                          onChange={changeCategory(idx, categoryLookup)}
                        >
                          {matchingCategories.map(({
                            start_date: startDate, end_date: endDate, rsc_id: rscId, cfi_category_number: categoryNumber,
                            cfi_category_name: categoryName
                          }, idx) => (
                            <Option key={idx} value={rscId}>
                              ({formatter.toShortDate(startDate)} - {formatter.toShortDate(endDate)})
                              {' '}
                              {formatter.toCategoryNumber(categoryNumber)} - {categoryName}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CategoryNumber), {
                        initialValue: categoryNumber
                      })(
                        <Input />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CategoryName), {
                        initialValue: categoryName
                      })(
                        <Input />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CategoryGroupId), {
                        initialValue: categoryGroupId
                      })(
                        <Input />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.FundingType), {
                        initialValue: fundingType
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>

                  <Col md={12} lg={14}>
                    <FormItem label='Support Item'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CategoryItemId), {
                        initialValue: categoryItemId,
                        rules: [
                          { required: true, message: 'Please select support item' }
                        ]
                      })(
                        <Select
                          disabled={isInvoiceReadOnly(invoice) || loadingClientFundings || !isCategorySelected}
                          dropdownMatchSelectWidth={false}
                          filterOption={searchDropdown}
                          loading={loadingCategoryDropdown}
                          notFoundContent='No support item available'
                          optionFilterProp='children'
                          placeholder='Select support item'
                          showSearch
                          tabIndex={getInvoiceItemTabIndex(idx, 7)}
                          onChange={changeCategoryItem(idx, categoryItemLookup)}
                        >
                          {categoryItems.map(({ category_item_id: id, item_number: itemNumber, item_name: itemName }) => (
                            <Option key={id} value={id}>{itemNumber} - {itemName}</Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CategoryItemNumber), {
                        initialValue: categoryItemNumber
                      })(
                        <Input />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CategoryItemName), {
                        initialValue: categoryItemName
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              ) : null}

              {isSingleClientFunding && isSingleRateSet && isCategoryItemSelected ? (
                <Row align='bottom' gutter={8} type='flex'>
                  <Col xs={0} lg={1} />

                  <Col md={{ offset: 1, span: 3 }} lg={{ offset: 0, span: 2 }}>
                    <FormItem className='fi-readonly' label='Max Rate'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.MaxRate), {
                        initialValue: getInvoiceItemMaxRate(maxRate)
                      })(
                        <Input readOnly tabIndex={Number.MAX_SAFE_INTEGER} />
                      )}
                    </FormItem>
                  </Col>

                  <Col md={4} lg={2}>
                    <FormItem label='Unit'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.Unit), {
                        initialValue: !BigNumber(unit).isNaN() ? BigNumber(unit).toNumber() : undefined,
                        rules: [
                          { required: true, message: 'Please enter unit' },
                          { validator: validateUnit(idx) }
                        ]
                      })(
                        <Input
                          disabled={isInvoiceReadOnly(invoice)}
                          onChange={changeUnit(idx)}
                          tabIndex={getInvoiceItemTabIndex(idx, 8)}
                          onPaste={handlePasteAmount}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Col md={6} lg={4}>
                    <FormItem label='Invoiced Rate [GST Rate]'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.InputRate), {
                        initialValue: !BigNumber(inputRate).isNaN() ? BigNumber(inputRate).toNumber() : undefined,
                        rules: [
                          { required: true, message: 'Please enter invoiced rate' },
                          { validator: validateInputRate(idx) }
                        ]
                      })(
                        <Input
                          addonAfter={displayRate}
                          disabled={isInvoiceReadOnly(invoice)}
                          maxLength={14}
                          tabIndex={getInvoiceItemTabIndex(idx, 9)}
                          onChange={changeInputRate(idx)}
                          onPaste={handlePasteAmount}
                          style={{ width: '100%' }}
                        />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.Rate), {
                        initialValue: !BigNumber(rate).isNaN() ? BigNumber(rate).toFixed(2) : undefined
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>

                  <Col md={3} lg={2}>
                    <FormItem label='GST'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.GstInclusive), {
                        initialValue: gstMode !== InvoiceGstMode.Exclusive,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          checkedChildren={isInvoiceReadOnly(invoice) || !isGstCodeP1 ? 'GST FREE' : 'Inclusive'}
                          unCheckedChildren={isInvoiceReadOnly(invoice) || !isGstCodeP1 ? 'GST FREE' : 'Exclusive'}
                          disabled={isInvoiceReadOnly(invoice) || !isGstCodeP1}
                          tabIndex={getInvoiceItemTabIndex(idx, 10)}
                          onChange={changeGstMode(idx)}
                        />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.GstMode), {
                        initialValue: !validator.isNullOrUndefined(gstMode)
                          ? gstMode : (isGstInclusive ? InvoiceGstMode.Inclusive : InvoiceGstMode.Exclusive)
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>

                  <Col md={6} lg={4}>
                    <FormItem className='fi-readonly fi-highlight' label='Invoiced Amount [GST Amount]'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.DisplayAmount), {
                        initialValue: initialDisplayAmount,
                        rules: [
                          { validator: validateDisplayUnit(idx) }
                        ]
                      })(
                        <Input readOnly tabIndex={Number.MAX_SAFE_INTEGER} />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.Amount), {
                        initialValue: !BigNumber(amount).isNaN() ? BigNumber(amount).toFixed(2) : undefined
                      })(
                        <Input />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.GstAmount), {
                        initialValue: !BigNumber(gstAmount).isNaN() ? BigNumber(gstAmount).toFixed(2) : undefined
                      })(
                        <Input />
                      )}
                    </FormItem>

                    <FormItem hidden>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CreditAmount), {
                        initialValue: !BigNumber(creditAmount).isNaN() ? BigNumber(creditAmount).toFixed(2) : undefined
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>

                  <Col md={{ offset: 1, span: 7 }} lg={{ offset: 0, span: 4 }}>
                    <FormItem label='Item Notes'>
                      {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.Comment), {
                        initialValue: comment
                      })(
                        <TextArea disabled={false} rows={1} tabIndex={getInvoiceItemTabIndex(idx, 11)} />
                      )}
                    </FormItem>
                  </Col>

                  <Col md={6} lg={3}>
                    {canApplyCredit && hasCredit ? (
                      <FormItem className='fi-readonly fi-highlight' label='Applied Credit'>
                        {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.CreditAmount), {
                          initialValue: !BigNumber(creditAmount).isNaN() ? BigNumber(creditAmount).toFormat(2) : undefined
                        })(
                          <Input readOnly tabIndex={Number.MAX_SAFE_INTEGER} />
                        )}
                      </FormItem>
                    ) : (
                      <FormItem className='fi-readonly fi-highlight' label='Received Amount'>
                        {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.ReceivedAmount), {
                          initialValue: !BigNumber(receivedAmount).isNaN() ? BigNumber(receivedAmount).toFormat(2) : undefined
                        })(
                          <Input readOnly tabIndex={Number.MAX_SAFE_INTEGER} />
                        )}
                      </FormItem>
                    )}
                  </Col>

                  <Col lg={2}>
                    <FormItem className='col-button'>
                      {isEdit && isInvoiceOngoing(invoice) ? (
                        <Tooltip mouseLeaveDelay={0} title='View Received History'>
                          <Button onClick={openReceivedAmountModal(idx)}>
                            <Icon type='read' />
                          </Button>
                        </Tooltip>
                      ) : null}

                      {hasAccess([
                        Permissions.INVOICE.CREDIT_APPLY_PACE.CREATE, Permissions.INVOICE.CREDIT_APPLY_PACE.UPDATE
                      ]) && canApplyCredit && hasCredit ? (
                        <Tooltip mouseLeaveDelay={0} title='Apply Credit'>
                          <Button onClick={openCreditModal(idx)}>
                            <Icon type='dollar' />
                          </Button>
                        </Tooltip>
                      ) : null}
                    </FormItem>
                  </Col>
                </Row>
              ) : null}

              {!loadingClientFundings && !loadingCategoryDropdown && isStartEndDateSelected ? (
                <>
                  {isMultipleClientFundings ? (
                    <Row gutter={8}>
                      <Col md={1} lg={1} />

                      <Col className='highlight' md={23} lg={23}>{MultiplePlanPeriodsLinkedMsg} {multipleClientFundingsText}</Col>
                    </Row>
                  ) : isNoClientFunding ? (
                    <Row gutter={8}>
                      <Col md={1} lg={1} />

                      <Col className='highlight' md={23} lg={23}>{NoPlanPeriodLinkedMsg}</Col>
                    </Row>
                  ) : isSingleClientFunding ? (
                    <Row gutter={8}>
                      <Col md={1} lg={1} />

                      <Col className='highlight' md={23} lg={23}>
                        {isDuePeriod2
                          ? PlanPeriodEnded90DaysMsg : isDuePeriod1
                            ? PlanPeriodEnded60DaysMsg : isDue
                              ? PlanPeriodEndedMsg2 : ''}
                      </Col>
                    </Row>
                  ) : null}

                  {isMultipleRateSets ? (
                    <Row gutter={8}>
                      <Col md={1} lg={1} />

                      <Col className='highlight' md={23} lg={23}>{MultipleRateSetsLinkedMsg} {multipleRateSetsText}</Col>
                    </Row>
                  ) : !isSingleRateSet && !isMultipleRateSets ? (
                    <Row gutter={8}>
                      <Col md={1} lg={1} />

                      <Col className='highlight' md={23} lg={23}>{NoRateSetLinkedMsg}</Col>
                    </Row>
                  ) : null}

                  {isMismatchClientFunding ? (
                    <Row gutter={8}>
                      <Col md={1} lg={1} />

                      <Col className='highlight' md={23} lg={23}>{PlanPeriodMismatchMsg}</Col>
                    </Row>
                  ) : isCategorySelected && isCategoryItemSelected && !isWithinBudget ? (
                    <Row gutter={8}>
                      <Col md={1} lg={1} />

                      <Col className='highlight' md={23} lg={23}>{OverBudgetMsg} {overBudgetText}</Col>
                    </Row>
                  ) : null}
                </>
              ) : null}

              <FormItem hidden>
                {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.Id), {
                  initialValue: iiId
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem hidden>
                {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.InvoiceId), {
                  initialValue: invoiceId
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem hidden>
                {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.ClientFundingId), {
                  initialValue: clientFundingId
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem hidden>
                {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.RateSetId), {
                  initialValue: rateSetId
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem hidden>
                {getFieldDecorator(getInvoiceItemFieldName(idx, InvoiceItemField.Deleted), {
                  initialValue: deleted
                })(
                  <Input />
                )}
              </FormItem>
            </div>
          </div>
        )
      }) : null}

      <div className='item-box button'>
        {!isInvoiceReadOnly(invoice) ? <Button onClick={addInvoiceItem}>Add Item</Button> : null}
      </div>

      <div className='item-box total'>
        <Row align='top' gutter={8} justify='start' type='flex'>
          <Col md={1} lg={1} />

          <Col md={4} lg={3}>
            <FormItem colon={false} label='Expected Inv Amt' />
          </Col>

          <Col md={6} lg={3}>
            <FormItem
              className='fi-readonly fi-highlight' help={helpDisplayExpectedAmount} validateStatus={validateStatusDisplayExpectedAmount}
            >
              {getFieldDecorator('display_expected_amount', {
                initialValue: displayExpectedAmount
              })(
                <Input key={`dea-${displayExpectedAmount}`} readOnly tabIndex={Number.MAX_SAFE_INTEGER} />
              )}
            </FormItem>
          </Col>

          <Col md={6} lg={4}>
            <FormItem colon={false} label='Total Inv Amt [GST Amt]' />
          </Col>

          <Col md={6} lg={4}>
            <FormItem className='fi-readonly fi-highlight' help={helpDisplayAmount} validateStatus={validateStatusDisplayAmount}>
              {getFieldDecorator('display_amount', {
                initialValue: displayAmount
              })(
                <Input key={`da-${displayAmount}`} readOnly tabIndex={Number.MAX_SAFE_INTEGER} />
              )}
            </FormItem>

            <FormItem hidden>
              {getFieldDecorator('amount', {
                initialValue: !BigNumber(_amount).isNaN() ? BigNumber(_amount).toFixed(2) : undefined
              })(
                <Input key={`a-${_amount}`} />
              )}
            </FormItem>

            <FormItem hidden>
              {getFieldDecorator('gst_amount', {
                initialValue: !BigNumber(_gstAmount).isNaN() ? BigNumber(_gstAmount).toFixed(2) : undefined
              })(
                <Input key={`ga-${_gstAmount}`} />
              )}
            </FormItem>

            {canApplyCredit ? null : (
              <FormItem hidden>
                {getFieldDecorator('credit_amount', {
                  initialValue: !BigNumber(iCreditAmount).isNaN() ? BigNumber(iCreditAmount).toFixed(2) : undefined
                })(
                  <Input />
                )}
              </FormItem>
            )}
          </Col>

          <Col md={{ offset: 1, span: 4 }} lg={{ offset: 0, span: 4 }}>
            {canApplyCredit && appliedCreditsMap.size > 0
              ? <FormItem colon={false} label='Total Credit Amt' /> : isEdit
                ? <FormItem colon={false} label='Total Rcv Amt' /> : null}
          </Col>

          <Col md={6} lg={3}>
            {canApplyCredit ? (
              <FormItem className='fi-readonly fi-highlight'>
                {getFieldDecorator('credit_amount', {
                  initialValue: !BigNumber(totalCreditAmount).isNaN() ? BigNumber(totalCreditAmount).toFormat(2) :
                    !BigNumber(iCreditAmount).isNaN() ? BigNumber(iCreditAmount).toFormat(2) : undefined
                })(
                  <Input readOnly tabIndex={Number.MAX_SAFE_INTEGER} />
                )}
              </FormItem>
            ) : isEdit ? (
              <FormItem className='fi-readonly fi-highlight'>
                {getFieldDecorator('received_amount', {
                  initialValue: !BigNumber(iReceivedAmount).isNaN() ? BigNumber(iReceivedAmount).toFormat(2) : undefined
                })(
                  <Input readOnly tabIndex={Number.MAX_SAFE_INTEGER} />
                )}
              </FormItem>
            ) : null}
          </Col>
        </Row>
      </div>
    </Skeleton>
  )
}

function InvoiceDetail (props) {
  const {
    form, history, match, selectedInvoice, selectedInvoiceType, setFormAttachmentCount, setOnSave, setOnSaveAndProcess,
    setOnRevertToDraft, setOnSaveAsDraft, setSelectedInvoice, setSelectedInvoiceType
  } = props || {}
  const {
    getFieldDecorator, getFieldError, getFieldValue, getFieldsValue, resetFields, setFieldsValue, validateFields,
    validateFieldsAndScroll
  } = form || {}
  const { params } = match || {}
  const { id } = params || {}
  const item = validator.isObject(selectedInvoice) ? selectedInvoice : { items: [{ id: -1, gst_code: 'p2' }] }
  const { id: invoiceId, client_funding_usages: invoiceClientFundingUsages, item_receive_history: itemReceiveHistory } = item
  const [appliedCreditsMap, setAppliedCreditsMap] = useState(new Map())
  const [categories, setCategories] = useState([])
  const [claimTypes, setClaimTypes] = useState([])
  const [claimTypeReasons, setClaimTypeReasons] = useState([])
  const [clientWarnings, setClientWarnings] = useState([])
  const [clients, setClients] = useState([])
  const [clientFundings, setClientFundings] = useState([])
  const [creditModal, setCreditModal] = useState({})
  const [credits, setCredits] = useState([])
  const [defaultCategoryItemDropdownMap, setDefaultCategoryItemDropdownMap] = useState({})
  const [files, setFiles] = useState([])
  const [fileCategories, setFileCategories] = useState([])
  const [fileSubCategories, setFileSubCategories] = useState([])
  const [gstTypes, setGstTypes] = useState([])
  const [gstTypeLookup, setGstTypeLookup] = useState(new Map())
  const [isEditClient, setIsEditClient] = useState(id === 'add')
  const [isEditProvider, setIsEditProvider] = useState(id === 'add')
  const [init, setInit] = useState(id !== 'add')
  const [itemsError, setItemsError] = useState()
  const [loadingClient, setLoadingClient] = useState(false)
  const [loadingDropdown, setLoadingDropdown] = useState(false)
  const [loadingProvider, setLoadingProvider] = useState(false)
  const [loadingInvNumber, setLoadingInvNumber] = useState(false)
  const [loadingClientFundings, setLoadingClientFundings] = useState(false)
  const [providerWarnings, setProviderWarnings] = useState([])
  const [providers, setProviders] = useState([])
  const [receivedAmountModal, setReceivedAmountModal] = useState({})
  const [selectedClient, setSelectedClient] = useState({})
  const [selectedProvider, setSelectedProvider] = useState({})
  const [showCreditModal, setShowCreditModal] = useState(false)
  const [showFileModal, setShowFileModal] = useState(false)
  const [showReceivedAmountModal, setShowReceivedAmountModal] = useState(false)
  const [tallyError, setTallyError] = useState()
  const [tallyWarning, setTallyWarning] = useState()

  const { invoice_items: fvInvoiceItems } = getFieldsValue()
  const fvInvoiceAbn = getFieldValue('invoice_abn')
  const fvInvoiceAbnMode = (
    getFieldValue('invoice_abn_mode') || getDefaultInvoiceAbnMode(item.invoice_abn) || InvoiceAbnMode.EnterProviderAbn
  )
  const feInvoiceNumberErrors = getFieldError('invoice_number')
  const isAbnExempted = !!getFieldValue('is_invoice_abn_exempted')
  const isClientSelected = selectedClient && validator.isId(selectedClient.id)
  const isInvoiceDateSelected = !!getFieldValue('invoice_date')
  const isInvoiceNumberEntered = !!getFieldValue('invoice_number')
  const isInvoiceRequiredAbn = typeof item.is_invoice_required_abn === 'boolean' ? item.is_invoice_required_abn : false
  const isProviderSelected = selectedProvider && validator.isId(selectedProvider.id)
  const isProviderAbnNotExempted = (
    isProviderSelected && selectedProvider.is_abn_allowed_empty === true && selectedProvider.abn_exempted_reason === 'N/A'
  )
  const isPmInvoice = selectedInvoiceType === InvoiceType.INV_TYPE_PM
  const isReimbursement = selectedInvoiceType === InvoiceType.INV_TYPE_RMB
  const isStartEndDateSelected = Array.isArray(fvInvoiceItems) && fvInvoiceItems.length > 0 && fvInvoiceItems.some(({
    start_date: startDate, end_date: endDate
  }) => validator.isDate(startDate) && validator.isDate(endDate))
  const canProceed = isInvoiceDateSelected && isInvoiceNumberEntered && isClientSelected &&
    (Array.isArray(feInvoiceNumberErrors) ? feInvoiceNumberErrors.length < 1 : true)
  const { clientFundings: matchingClientFundings } = getMatchingClientFundings(clientFundings, fvInvoiceItems)
  const clientFundingUsage = getClientFundingUsage(matchingClientFundings, invoiceClientFundingUsages)
  const appliedCreditUsage = getAppliedCreditUsage(appliedCreditsMap)
  const itemReceiveHistoryMap = getInvoiceItemReceiveHistoryMap(itemReceiveHistory)

  const _checkInvoiceNumber = useCallback((clientId, providerId, type, value, refId) => {
    if (
      !validator.isNullOrUndefined(clientId) && !validator.isNullOrUndefined(clientId) &&
      isString(value)
    ) {
      setLoadingInvNumber(true)
      const data = {
        client_id: clientId,
        invoice_number: value,
        type: type === InvoiceType.INV_TYPE_RMB.value ? InvoiceType.INV_TYPE_RMB.value : InvoiceType.INV_TYPE_PM.value,
        ref_id: validator.isId(refId) ? refId : undefined
      }

      if (!validator.isNullOrUndefined(providerId) && !validator.isNullOrUndefined(providerId)) {
        data.provider_id = providerId
      }

      const invoiceabn = form.getFieldValue('invoice_abn')

      if (invoiceabn) {
        data.invoice_abn = invoiceabn
      }

      invoicePaceService
        .getDuplicateInvoiceNumber(data)
        .then((response) => {
          if (validator.isObject(response) && response.invalid) {
            setLoadingInvNumber(false)
            Modal.confirm({
              title: 'Possible Duplicated Invoice',
              content: (
                <div style={{ color: 'rgb(238, 27, 27)' }}>
                  <p>There is an invoice which has the same invoice number as entered.</p>
                  <p>Press "Go to" button to check the duplicated invoice or press "OK" to edit.</p>
                </div>
              ),
              cancelText: 'OK',
              okText: 'Go to Duplicated Invoice Page',
              onOk: () => {
                if (window) {
                  let newTab

                  if (response.type === 'proda') {
                    newTab = window.open(`${urlRedirect}/${response.ref_id}/info`, '_blank', 'noopener,noreferrer')
                  } else {
                    newTab = window.open(`${urlRedirect}-pace/${response.ref_id}/info`, '_blank', 'noopener,noreferrer')
                  }

                  if (newTab) {
                    newTab.opener = null
                  }
                }
              }
            })
          } else {
            setLoadingInvNumber(false)
          }
        })
    }
  }, [form])

  const checkInvoiceNumber = useRef(debounce(_checkInvoiceNumber, 500))

  const checkSelectedClient = useCallback((client, invoiceType) => {
    if (!client || !validator.isId(client.id) || !invoiceType) {
      return
    }

    const {
      first_name: firstName, last_name: lastName, pm_bank_acc_no: bankAccNo, pm_bsb: bsb, private_alert: privateAlert,
      public_alert: publicAlert, pm_rmt_email: rmtEmail, pm_rmt_email_name: rmtEmailName
    } = client
    const title = `Participant: ${firstName} ${lastName}`
    setClientWarnings([])

    if (invoiceType === InvoiceType.INV_TYPE_RMB) {
      const clientWarnings1 = []
      const clientWarnings2 = []

      if (!(bankAccNo && bsb)) {
        clientWarnings1.push(ClientNoBankInfoMsg)
        clientWarnings2.push('Please furnish banking details for this participant for reimbursement.')
      }

      if (!(rmtEmail && rmtEmailName)) {
        clientWarnings1.push('Please furnish remittance email and recipient for this participant for reimbursement.')
        clientWarnings2.push(ClientNoRmtEmailMsg)
      }

      if (clientWarnings1.length > 0) {
        setClientWarnings(clientWarnings1)
      }

      if (clientWarnings2.length > 0) {
        Modal.error({
          title,
          content: (
            <div>
              <ol>{clientWarnings2.map((warning, idx) => <li key={idx} className=''>{warning}</li>)}</ol>
              <p>Click <strong>Go to Participant</strong> to be redirected to the participant's profile.</p>
            </div>
          ),
          okText: 'Go to Participant',
          onOk: () => {
            if (window) {
              const newTab = window.open(`/participants/${client.ref_id}/info`, '_blank', 'noopener,noreferrer')

              if (newTab) {
                newTab.opener = null
              }
            }
          }
        })
      }
    }

    if (privateAlert) {
      Modal.warning({
        title,
        content: (
          <div>
            {publicAlert
              ? <div dangerouslySetInnerHTML={{ __html: formatter.toHtmlLineBreak(publicAlert) }} />
              : null}
            {privateAlert
              ? <div dangerouslySetInnerHTML={{ __html: formatter.toHtmlLineBreak(privateAlert) }} />
              : null}
          </div>
        ),
        okText: 'OK',
        onOk: () => { }
      })
    }
  }, [])

  const checkSelectedProvider = useCallback((provider) => {
    if (!provider || !validator.isId(provider.id)) {
      return
    }

    const { fullname, private_alert: privateAlert, public_alert: publicAlert } = provider
    const title = `Provider: ${fullname}`
    setProviderWarnings([])

    if (privateAlert) {
      Modal.warning({
        title,
        content: (
          <div>
            {publicAlert
              ? <div dangerouslySetInnerHTML={{ __html: formatter.toHtmlLineBreak(publicAlert) }} />
              : null}
            {privateAlert
              ? <div dangerouslySetInnerHTML={{ __html: formatter.toHtmlLineBreak(privateAlert) }} />
              : null}
          </div>
        ),
        okText: 'OK',
        onOk: () => { }
      })
    }
  }, [])

  const isEdit = useCallback(() => {
    return id !== 'add'
  }, [id])

  const listAllClients = useCallback(() => {
    setLoadingDropdown(true)
    clientService
      .listAllClients()
      .then((clients) => {
        if (Array.isArray(clients)) {
          setClients(clients)
        }
      }).finally(() => {
        setLoadingDropdown(false)
      })
  }, [])

  const listAllProviders = useCallback(() => {
    setLoadingDropdown(true)
    providerService
      .listAllProviders()
      .then((providers) => {
        if (Array.isArray(providers)) {
          setProviders(providers)
        }
      }).finally(() => {
        setLoadingDropdown(false)
      })
  }, [])

  const refreshClientFundingsAndCredits = useCallback((selectedClient, { selectedInvoice, selectedInvoiceType }) => {
    const { id: clientId } = selectedClient || {}
    const { items } = selectedInvoice || {}

    if (validator.isId(clientId)) {
      setLoadingClientFundings(true)
      Promise.all([
        clientFundingPaceService.getAllDropdowns({ client_id: clientId }),
        clientFundingPaceService.getAll({ client_id: clientId }),
        creditPaceService.getAll({ client_id: clientId, remaining_amount: { condition: '>', value: 0 } })
      ])
        .then(async ([categories, clientFundings, credits]) => {
          if (Array.isArray(categories)) {
            const categoryItemDropdownMap = {}
            setCategories(categories.filter(({ cfi_category_number: categoryNumber }) => {
              if (selectedInvoiceType === InvoiceType.INV_TYPE_STD) {
                return BigNumber(categoryNumber).isEqualTo(14)
              }

              return !BigNumber(categoryNumber).isEqualTo(14)
            }))

            if (Array.isArray(items) && items.length > 0) {
              for (let i = 0; i < items.length; i++) {
                const { rate_set_id: rsId, category_id: categoryId } = items[i]

                try {
                  const categoryItems = await pmRateSetCategoryItemPaceService.getAll({
                    rate_set_id: rsId, category_id: categoryId, client_id: clientId
                  })
                  categoryItemDropdownMap[i] = { loading: false, categoryItems }
                } catch {
                  categoryItemDropdownMap[i] = { loading: false, categoryItems: [] }
                }
              }

              setDefaultCategoryItemDropdownMap({ ...categoryItemDropdownMap })
            }
          } else {
            setLoadingClientFundings(false)
          }

          if (Array.isArray(clientFundings)) {
            setClientFundings(getClientFundingSummary(clientFundings.filter(({ cfi_is_pm_fee: isPmFee }) => {
              return isPmFee === (selectedInvoiceType === InvoiceType.INV_TYPE_STD)
            })))
          }

          if (Array.isArray(credits)) {
            setCredits(credits)
          }
        })
        .catch(() => {
          notify.error('Unable to refresh successfully', 'Unable to refresh plan periods & credits. Please try again later.')
          setLoadingClientFundings(false)
        }).finally(() => {
          setLoadingClientFundings(false)
        })
    }
  }, [])

  const changeAbn = useCallback(({ target }) => {
    // const { value } = target || {}
    // console.log(111, 'changeAbn', value)
  }, [])

  const changeClient = useCallback((clientId) => {
    if (!clientId || loadingClient) {
      return
    }

    setLoadingClient(true)
    setIsEditClient(false)
    clientService.get(clientId)
      .then((response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          checkSelectedClient(response, selectedInvoiceType)
          refreshClientFundingsAndCredits(response, { selectedInvoiceType })
          setSelectedClient(response)
        } else {
          setSelectedClient({})
        }
      })
      .finally(() => {
        setLoadingClient(false)
      })
  }, [checkSelectedClient, refreshClientFundingsAndCredits, loadingClient, selectedInvoiceType])

  const changeInvoiceAbnMode = useCallback(({ target }) => {
    const { value } = target || {}
    setFieldsValue({ invoice_abn: value !== InvoiceAbnMode.EnterProviderAbn ? value : undefined })
  }, [setFieldsValue])

  const changeInvoiceType = useCallback(({ target }) => {
    const { value } = target || {}
    checkSelectedClient(selectedClient, value)

    if (value === InvoiceType.INV_TYPE_RMB) {
      setIsEditProvider(true)
      setSelectedProvider({})
    } else {
      checkSelectedProvider(selectedProvider, value)
    }

    resetFields()
    setSelectedInvoiceType(value)
    refreshClientFundingsAndCredits(selectedClient, { selectedInvoiceType: value })
  }, [
    checkSelectedClient, checkSelectedProvider, resetFields, setSelectedInvoiceType, selectedClient, selectedProvider,
    refreshClientFundingsAndCredits
  ])

  const changeInvoiceNumber = useCallback(({ target }) => {
    const { value } = target || {}

    if (value) {
      checkInvoiceNumber.current(selectedClient.id, selectedProvider.id, selectedInvoiceType.value, value, id)
    }
  }, [id, selectedClient, selectedProvider, selectedInvoiceType])

  const changeProvider = useCallback((providerId) => {
    if (!providerId || loadingProvider) {
      return
    }

    setLoadingProvider(true)
    setIsEditProvider(false)
    providerService.get(providerId)
      .then((response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          checkSelectedProvider(response)
          setSelectedProvider(response)
        } else {
          setSelectedProvider({})
        }
      })
      .finally(() => {
        setLoadingProvider(false)
      })
  }, [checkSelectedProvider, loadingProvider])

  const closeCreditModal = useCallback(() => {
    setShowCreditModal(false)
  }, [])

  const closeFileModal = useCallback(() => {
    setShowFileModal(false)
  }, [])

  const closeReceivedAmountModal = useCallback(() => {
    setShowReceivedAmountModal(false)
  }, [])

  const handleApplyCredit = useCallback((idx, credits, callback) => {
    setAppliedCreditsMap((appliedCreditsMap) => {
      if (Array.isArray(credits)) {
        for (const credit of credits) {
          const { credit_id: creditId, invoice_item_id: iiId } = credit
          const appliedCredits = appliedCreditsMap.get(iiId) || {}
          const _values = Object.assign({}, credit)
          appliedCredits[creditId] = _values
          appliedCreditsMap.set(iiId, appliedCredits)
          const fnCreditAmount = getInvoiceItemFieldName(idx, InvoiceItemField.CreditAmount)
          const creditAmount = BigNumber(getInvoiceItemCreditAmount(appliedCredits))
          setFieldsValue({ [fnCreditAmount]: !creditAmount.isNaN() ? creditAmount.toFormat(2) : undefined })
        }

        if (typeof callback === 'function') {
          callback()
        }
      }

      return appliedCreditsMap
    })
  }, [setFieldsValue])

  const handleCancelCredit = useCallback((idx, credit, callback) => {
    setAppliedCreditsMap((appliedCreditsMap) => {
      const { credit_id: creditId, invoice_item_id: iiId } = credit
      const appliedCredits = appliedCreditsMap.get(iiId) || {}
      delete appliedCredits[creditId]
      appliedCreditsMap.set(iiId, appliedCredits)
      const fnCreditAmount = getInvoiceItemFieldName(idx, InvoiceItemField.CreditAmount)
      const creditAmount = BigNumber(getInvoiceItemCreditAmount(appliedCredits))
      setFieldsValue({ [fnCreditAmount]: !creditAmount.isNaN() ? creditAmount.toFormat(2) : undefined })

      if (typeof callback === 'function') {
        callback()
      }

      return appliedCreditsMap
    })
  }, [setFieldsValue])

  const handleRefreshClientFundingsAndCredits = useCallback((client) => () => {
    refreshClientFundingsAndCredits(client, { selectedInvoiceType })
  }, [refreshClientFundingsAndCredits, selectedInvoiceType])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const openCreditModal = useCallback((idx, invoiceItem) => {
    const { id, category_number: categoryNumber, category_group_id: categoryGroupId } = invoiceItem
    const applicableCredits = getApplicableCredits(credits, categoryNumber, categoryGroupId)
    const applicableCreditLookup = new Map(applicableCredits.map((credit) => [credit.id, credit]))
    const appliedCreditUsage = getAppliedCreditUsage(appliedCreditsMap)
    const appliedCredits = appliedCreditsMap.get(id) || {}
    setCreditModal({ applicableCreditLookup, applicableCredits, appliedCreditUsage, appliedCredits, idx, invoiceItem })
    setShowCreditModal(true)
  }, [appliedCreditsMap, credits])

  const openFileModal = useCallback(() => {
    setShowFileModal(true)
  }, [])

  const openReceivedAmountModal = useCallback((idx, invoiceItem) => {
    const itemReceiveHistory = itemReceiveHistoryMap.get(invoiceItem.id)
    setReceivedAmountModal({ idx, invoiceItem, itemReceiveHistory })
    setShowReceivedAmountModal(true)
  }, [itemReceiveHistoryMap])

  const pasteAbn = useCallback((event) => {
    const pastedText = common.getPastedText(event)

    if (pastedText) {
      const cleanedData = formatter.parseAbn(pastedText)
      // No better alternative than document.execCommand at the moment
      document.execCommand('insertText', false, cleanedData)
    }
  }, [])

  const toggleEditClient = useCallback(() => {
    listAllClients()
    setIsEditClient(!isEditClient)
    setSelectedClient({})
  }, [listAllClients, isEditClient])

  const toggleEditProvider = useCallback(() => {
    listAllProviders()
    setIsEditProvider(!isEditProvider)
    setSelectedProvider({})
  }, [listAllProviders, isEditProvider])

  const validateAbn = useCallback((invoiceAbnMode) => (rule, value, callback) => {
    if (
      isString(value) && invoiceAbnMode === InvoiceAbnMode.EnterProviderAbn && (!validator.isDigit(value) || value.length !== 11)
    ) {
      callback(new Error('Please enter valid ABN'))
    } else {
      callback()
    }
  }, [])

  const validateExpectedAmount = useCallback((rule, value, callback) => {
    if (isString(value) && !isDecimal(value)) {
      callback(new Error('Please enter a number with 2 decimal places'))
    } else {
      callback()
    }
  }, [])

  const validateInvoiceNumber = useCallback((rule, value, callback) => {
    if (isString(value)) {
      let abn = selectedProvider.abn

      if (isReimbursement) {
        abn = fvInvoiceAbn

        if (validator.isNullOrUndefined(abn) || validator.isEmptyString(abn, true)) {
          validateFieldsAndScroll(['invoice_abn'], { force: true })
          return callback(new Error(InvDuplicatedAbnMissingMsg))
        }
      } else if (!isProviderSelected) {
        validateFieldsAndScroll(['provider_id'], { force: true })
        return callback(new Error(InvDuplicatedProviderMissingMsg))
      }

      callback()
    } else {
      callback()
    }
  }, [validateFieldsAndScroll, fvInvoiceAbn, isProviderSelected, isReimbursement, selectedProvider])

  const addFile = useCallback((file, callback) => {
    if (validator.isObject(file)) {
      const _files = Array.isArray(files) ? files : []
      const fileList = _files.concat(file)
      setFormAttachmentCount(fileList.filter(({ sub_cat_is_attach_mail_comm: isAttach }) => isAttach).length)
      setFiles(fileList)

      if (typeof callback === 'function') {
        callback()
      }
    }
  }, [setFormAttachmentCount, files])

  const removeFile = useCallback((idx) => {
    if (BigNumber(idx).isGreaterThan(-1)) {
      const _files = Array.isArray(files) ? files : []
      const fileList = _files.filter((_, _idx) => _idx !== idx)
      setFormAttachmentCount(fileList.filter(({ sub_cat_is_attach_mail_comm: isAttach }) => isAttach).length)
      setFiles(fileList)
    }
  }, [setFormAttachmentCount, files])

  const updateFile = useCallback((file, callback) => {
    if (validator.isObject(file)) {
      const fileList = Array.isArray(files) ? files : []
      const idx = fileList.findIndex(({ id }) => id === file.id)
      setFiles(fileList.map((_file, _idx) => _idx !== idx ? _file : file))

      if (typeof callback === 'function') {
        callback()
      }
    }
  }, [files])

  const addInvoiceItem = useCallback(() => {
    const invoice = Object.assign({}, item)
    const { items } = invoice
    invoice.items.push({ id: -1 * (items.length + 1) })
    setItemsError(validateInvoiceItemsCount(invoice.items))
    setSelectedInvoice(invoice)
  }, [setSelectedInvoice, item])

  const removeInvoiceItem = useCallback((idx, toRemove) => {
    const { items } = item

    if (validator.isObject(toRemove) && !validator.isNullOrUndefined(toRemove.id) && items[idx].id === toRemove.id) {
      const { id } = toRemove
      const invoice = Object.assign({}, item)

      if (id > 0) {
        items[idx].deleted = true
        invoice.items = items
      } else {
        // TODO - TODO fix delete bug
        invoice.items = items.filter((_, _idx) => _idx !== idx)
      }

      setAppliedCreditsMap((appliedCreditsMap) => {
        appliedCreditsMap.delete(id)
        return appliedCreditsMap
      })
      setItemsError(validateInvoiceItemsCount(invoice.items))
      setSelectedInvoice(invoice)
    }
  }, [setSelectedInvoice, item])

  const undoRemoveInvoiceItem = useCallback((idx, toRemove) => {
    const { items } = item

    if (validator.isObject(toRemove) && !validator.isNullOrUndefined(toRemove.id) && items[idx].id === toRemove.id) {
      const { id } = toRemove
      const invoice = Object.assign({}, item)

      if (id > 0) {
        items[idx].deleted = false
      }

      invoice.items = items
      setItemsError(validateInvoiceItemsCount(invoice.items.filter(({ deleted }) => !deleted)))
      setSelectedInvoice(invoice)
    }
  }, [setSelectedInvoice, item])

  useEffect(() => {
    if (!hasAccess([Permissions.INVOICE.INFO_PACE.CREATE, Permissions.INVOICE.INFO_PACE.UPDATE])) {
      return
    }

    const saveInvoice = async (title, saveFunc, values, resolve) => {
      try {
        const patchedValues = patchValuesBeforeSave(values, files, appliedCreditsMap)
        const response = await (isEdit() ? saveFunc(invoiceId, patchedValues) : saveFunc(patchedValues))

        if (validator.isObject(response) && validator.isId(response.id)) {
          notify.success(`${formatter.capitalize(title)} successfully`, `Invoice ${title} successfully.`)

          if (isEdit()) {
            if (window) {
              window.location.reload()
            }
          } else {
            history.replace(`/invoices-pace/${response.ref_id}`)
          }
        } else {
          notify.error(`Unable to ${title} successfully`, `Unable to ${title} successfully. Please try again later.`)
        }
      } catch (e) {
        notify.error(`Unable to ${title} successfully`, `Unable to ${title} successfully. Please try again later.`)
      } finally {
        resolve()
      }
    }

    const onSave = (title, saveFunc) => () => {
      return new Promise((resolve) => {
        validateFields(async (errors, values) => {
          if (errors) {
            return resolve()
          }

          const totalCreditAmount = BigNumber(getAppliedCreditAmount(appliedCreditsMap))

          if (!validator.isNullOrUndefined(itemsError || tallyError)) {
            Modal.error({
              title: `Unable to ${title} successfully`,
              content: itemsError || tallyError
            })
            return resolve()
          } else if (tallyWarning) {
            Modal.confirm({
              title: TotalAmountLessThanExpectedMsg,
              content: tallyWarning,
              cancelText: 'Cancel',
              okText: 'Confirm',
              onCancel: () => {
                return resolve()
              },
              onOk: async () => {
                await saveInvoice(title, saveFunc, values, resolve)
              }
            })
          } else if (title === 'save as draft' && totalCreditAmount.isGreaterThan(0)) {
            Modal.confirm({
              title: 'Credits will not be saved',
              content: 'You have credits applied and will not be saved when saving as draft. Are you sure you want to proceed?',
              cancelText: 'Cancel',
              okText: 'Confirm',
              onCancel: () => {
                return resolve()
              },
              onOk: async () => {
                await saveInvoice(title, saveFunc, values, resolve)
              }
            })
          } else {
            await saveInvoice(title, saveFunc, values, resolve)
          }
        })
      })
    }
    setOnRevertToDraft(isEdit() ? onSave('revert to draft', invoicePaceService.revertDraft) : undefined)
    setOnSave(isEdit() ? onSave('save', invoicePaceService.update) : undefined)
    setOnSaveAndProcess(onSave('save and process', isEdit() ? invoicePaceService.updateAndProcess : invoicePaceService.saveAndProcess))
    setOnSaveAsDraft(onSave('save as draft', isEdit() ? invoicePaceService.updateDraft : invoicePaceService.saveAsDraft))
  }, [
    hasAccess, history, isEdit, setOnRevertToDraft, setOnSave, setOnSaveAndProcess, setOnSaveAsDraft, validateFields, files,
    invoiceId, itemsError, tallyError, tallyWarning, appliedCreditsMap
  ])

  useEffect(() => {
    if (!hasAccess([Permissions.INVOICE.INFO_PACE.CREATE, Permissions.INVOICE.INFO_PACE.READ, Permissions.INVOICE.INFO_PACE.UPDATE])) {
      if (isEdit()) {
        setInit(false)
      }

      return
    }

    if (validator.isObject(item) && validator.isId(item.id)) {
      const { client_id: clientId, provider_id: providerId } = item
      const validClients = Array.isArray(clients)
      const validProviders = Array.isArray(providers)

      if (validClients && validator.isId(clientId)) {
        const client = clients.find(({ id }) => BigNumber(id).isEqualTo(clientId))

        if (client && validator.isId(client.id)) {
          const { invoice_type: invoiceType } = item
          setSelectedClient(client)
          refreshClientFundingsAndCredits(client, {
            selectedInvoice: item, selectedInvoiceType: invoiceType === InvoiceType.INV_TYPE_STD.value
              ? InvoiceType.INV_TYPE_STD : InvoiceType.INV_TYPE_PM
          })
        }
      }

      if (validProviders && validator.isId(providerId)) {
        const provider = providers.find(({ id }) => BigNumber(id).isEqualTo(providerId))

        if (provider && validator.isId(provider.id)) {
          setSelectedProvider(provider)
        }
      }

      if (isEdit()) {
        setInit(false)
      }
    } else if (isEdit()) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        setInit(false)
      }, 10000)
    }
  }, [hasAccess, isEdit, refreshClientFundingsAndCredits, clients, providers, item])

  useEffect(() => {
    if (!hasAccess(Permissions.INVOICE.INFO_PACE.CREATE, Permissions.INVOICE.INFO_PACE.READ, Permissions.INVOICE.INFO_PACE.UPDATE)) {
      if (!isEdit()) {
        setInit(false)
      }

      return
    }

    let mounted = true
    setLoadingDropdown(true)
    Promise.all([
      clientService.getAllDropdowns(),
      providerService.getAllDropdowns(),
      settingGeneralService.getAll({ identifier: ['invoice-claim-type', 'invoice-claim-type-reason', 'invoice-status'] }),
      settingGSTRateService.getItemAll(),
      settingFileService.getAllFileCatDropdowns({ module_type: 'invoice' }),
      settingFileService.getAllSubCatDropdowns({ module_type: 'invoice' })
    ]).then(([clients, providers, settings, gstTypes, fileCategories, fileSubCategories]) => {
      if (mounted) {
        if (Array.isArray(clients)) {
          setClients(clients)
        }

        if (Array.isArray(providers)) {
          setProviders(providers)
        }

        if (Array.isArray(settings)) {
          setClaimTypes(settings.filter(({ identifier }) => identifier === 'invoice-claim-type'))
          setClaimTypeReasons(settings.filter(({ identifier }) => identifier === 'invoice-claim-type-reason'))
        }

        if (Array.isArray(gstTypes)) {
          setGstTypes(gstTypes)
          setGstTypeLookup(new Map(gstTypes.map((gstType) => [gstType.item_code, gstType])))
        }

        if (Array.isArray(fileCategories)) {
          setFileCategories(fileCategories)
        }

        if (Array.isArray(fileSubCategories)) {
          setFileSubCategories(fileSubCategories)
        }
      }
    }).finally(() => {
      if (mounted) {
        if (!isEdit()) {
          setInit(false)
        }

        setLoadingDropdown(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [hasAccess, isEdit])

  return (
    <Skeleton active loading={init}>
      <Form className='pid-box'>
        <Row className='pid-margin-bottom pid-panel pid-panel-top'>
          <Col lg={12}>
            <div className='wrapper-box'>
              {!isEdit() || (isEdit() && isInvoiceDrafted(item)) ? (
                <ControlLabel>Select Invoice Type</ControlLabel>
              ) : null}

              <RadioGroup
                className='rg-invoice-type'
                buttonStyle='solid'
                disabled={(isEdit() && !isInvoiceDrafted(item))}
                value={selectedInvoiceType}
                onChange={changeInvoiceType}
              >
                {(isEdit() && item.invoice_type === InvoiceType.INV_TYPE_STD.value) ? (
                  <RadioButton value={InvoiceType.INV_TYPE_STD}>{InvoiceType.INV_TYPE_STD.name}</RadioButton>
                ) : null}

                {!isEdit() || (isEdit() && item.invoice_type === InvoiceType.INV_TYPE_PM.value) ? (
                  <RadioButton value={InvoiceType.INV_TYPE_PM}>{InvoiceType.INV_TYPE_PM.name}</RadioButton>
                ) : null}

                {!isEdit() || (isEdit() && (isInvoiceDrafted(item) || item.invoice_type === InvoiceType.INV_TYPE_RMB.value)) ? (
                  <RadioButton value={InvoiceType.INV_TYPE_RMB}>{InvoiceType.INV_TYPE_RMB.name}</RadioButton>
                ) : null}
              </RadioGroup>
            </div>
          </Col>

          <Col lg={12}>
            <div className='status-box wrapper-box'>
              {isEdit() && item.status_name
                ? <div className='pid-status' style={{ backgroundColor: item.status_color }}>{item.status_name}</div>
                : null}
            </div>
          </Col>
        </Row>

        <Row className='pid-panel' gutter={16}>
          <Col lg={12}>
            <SelectClientBox
              changeClient={changeClient} toggleEditClient={toggleEditClient} clientWarnings={clientWarnings} clients={clients}
              isEdit={isEdit()} isEditClient={isEditClient} loadingClient={loadingClient} loadingDropdown={loadingDropdown}
              loadingProvider={loadingProvider} props={props} selectedClient={selectedClient}
            />
          </Col>

          <Col lg={12}>
            {isReimbursement ? null : (
              <SelectProviderBox
                changeProvider={changeProvider} toggleEditProvider={toggleEditProvider} providerWarnings={providerWarnings}
                providers={providers} isEdit={isEdit()} isEditProvider={isEditProvider} loadingClient={loadingClient}
                loadingDropdown={loadingDropdown} loadingProvider={loadingProvider} props={props} selectedProvider={selectedProvider}
              />
            )}
          </Col>
        </Row>

        {(isEdit() && isInvoiceRequiredAbn) || (!isEdit() && (isProviderAbnNotExempted || isReimbursement)) ? (
          <Panel title='Provider Info'>
            {!isProviderAbnNotExempted && isReimbursement ? (
              <FormItem {...providerFormItemLayout1} hasFeedback label='Provider Name'>
                {getFieldDecorator('invoice_provider_name', {
                  initialValue: item.invoice_provider_name,
                  rules: [
                    { required: true, message: 'Please enter provider name' }
                  ]
                })(
                  <Input disabled={isInvoiceReadOnly(item)} />
                )}
              </FormItem>
            ) : null}

            <FormItem {...providerFormItemLayout1} colon={false} label=' '>
              {getFieldDecorator('invoice_abn_mode', {
                initialValue: fvInvoiceAbnMode,
                rules: [
                  { required: true, message: 'Please select ABN type' }
                ]
              })(
                <RadioGroup disabled={isInvoiceReadOnly(item)} options={InvoiceAbnModes} onChange={changeInvoiceAbnMode} />
              )}
            </FormItem>

            <Row gutter={16}>
              <Col lg={12}>
                <FormItem
                  {...providerFormItemLayout2}
                  extra='Enter ABN without spacing'
                  hasFeedback
                  label={isProviderAbnNotExempted && isPmInvoice ? 'Invoice ABN' : 'Provider ABN'}
                >
                  {getFieldDecorator('invoice_abn', {
                    initialValue: item.invoice_abn,
                    rules: [
                      { required: !isAbnExempted, message: 'Please enter ABN' },
                      { whitespace: true, message: 'Please enter ABN' },
                      { validator: validateAbn(fvInvoiceAbnMode) }
                    ]
                  })(
                    <Input
                      disabled={isInvoiceReadOnly(item) || fvInvoiceAbnMode !== InvoiceAbnMode.EnterProviderAbn || isAbnExempted}
                      onChange={changeAbn} onPaste={pasteAbn}
                    />
                  )}
                </FormItem>
              </Col>

              {isPmInvoice ? (
                <Col lg={12}>
                  <FormItem {...providerFormItemLayout2} label='ATO Excluded Supply?'>
                    {getFieldDecorator('is_invoice_abn_exempted', {
                      initialValue: typeof item.is_invoice_abn_exempted === 'boolean' ? item.is_invoice_abn_exempted : false,
                      valuePropName: 'checked'
                    })(
                      <Switch checkedChildren='Yes' unCheckedChildren='No' disabled={isInvoiceReadOnly(item)} />
                    )}
                  </FormItem>
                </Col>
              ) : null}
            </Row>
          </Panel>
        ) : null}

        <div className='pid-panel'>
          {selectedClient.private_alert ? (
            <Alert
              className='pid-margin-bottom'
              description={<div dangerouslySetInnerHTML={{ __html: formatter.toHtmlLineBreak(selectedClient.private_alert) }} />}
              message={<b>Client: {selectedClient.first_name} {selectedClient.last_name}</b>}
              type='warning'
              showIcon
            />
          ) : null}

          {selectedProvider.private_alert ? (
            <Alert
              className='pid-margin-bottom'
              description={<div dangerouslySetInnerHTML={{ __html: formatter.toHtmlLineBreak(selectedProvider.private_alert) }} />}
              message={<b>Provider: {selectedProvider.fullname}</b>}
              type='warning'
              showIcon
            />
          ) : null}
        </div>

        {isClientSelected && ((!isReimbursement && isProviderSelected) || isReimbursement) ? (
          <Panel title='Invoice Detail'>
            <Spin spinning={false}>
              <Row gutter={16}>
                <Col lg={12}>
                  <FormItem {...formItemLayout} label='Invoice Date'>
                    {getFieldDecorator('invoice_date', {
                      initialValue: validator.isDate(item.invoice_date) ? moment(item.invoice_date) : undefined,
                      rules: [
                        { required: true, message: 'Please select invoice date' }
                      ]
                    })(
                      <DatePicker
                        defaultPickerValue={moment().startOf('day')}
                        disabled={isInvoiceProcessing(item)}
                        format={dateFormat} onChange={() => { }}
                        tabIndex={3}
                      />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} hasFeedback={!loadingInvNumber} label='Invoice Number'>
                    {getFieldDecorator('invoice_number', {
                      initialValue: item.invoice_number,
                      rules: [
                        { required: true, message: 'Please enter invoice number' },
                        { whitespace: true, message: 'Please enter invoice number' },
                        { validator: validateInvoiceNumber }
                      ]
                    })(
                      <Input
                        disabled={isInvoiceProcessing(item)}
                        onChange={changeInvoiceNumber}
                        addonAfter={(loadingInvNumber
                          ? <Icon type='loading' className='wd-loading-icon' /> : <span style={{ marginRight: 13 }} />
                        )}
                        tabIndex={4}
                      />
                    )}
                  </FormItem>
                </Col>

                <Col lg={12}>
                  <FormItem {...formItemLayout} label='Remittance Comment'>
                    {getFieldDecorator('comment', {
                      initialValue: item.comment,
                      rules: [
                        { whitespace: true, message: 'Please enter remittance comment' }
                      ]
                    })(
                      <TextArea disabled={false} rows={4} tabIndex={6} />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={12}>
                  <FormItem {...formItemLayout} hasFeedback label='Expected Invoiced Amount'>
                    {getFieldDecorator('expected_amount', {
                      initialValue: !BigNumber(item.expected_amount).isNaN()
                        ? BigNumber(item.expected_amount).toFormat(2) : undefined,
                      rules: [
                        { required: true, message: 'Please enter expected invoiced amount' },
                        { validator: validateExpectedAmount }
                      ]
                    })(
                      <Input addonBefore='$' disabled={isInvoiceReadOnly(item)} tabIndex={5} onPaste={handlePasteAmount} />
                    )}
                  </FormItem>

                  {isEdit() && isInvoiceOngoing(item) ? (
                    <FormItem {...formItemLayout} label='Process Date'>
                      {getFieldDecorator('processed_at', {
                        initialValue: validator.isDate(item.processed_at) ? formatter.toStandardLongDate(item.processed_at) : undefined,
                      })(
                        <Input disabled />
                      )}
                    </FormItem>
                  ) : null}
                </Col>

                <Col lg={12}>
                  <FormItem {...formItemLayout} label='Private Notes'>
                    {getFieldDecorator('private_comment', {
                      initialValue: item.private_comment,
                      rules: [
                        { whitespace: true, message: 'Please enter private notes' }
                      ]
                    })(
                      <TextArea disabled={false} rows={4} tabIndex={7} />
                    )}
                  </FormItem>
                </Col>
              </Row>

              {!isEditClient ? (
                <FormItem hidden>
                  {getFieldDecorator('client_id', {
                    initialValue: selectedClient ? selectedClient.id : undefined
                  })(
                    <Input />
                  )}
                </FormItem>
              ) : null}

              {!isEditProvider ? (
                <FormItem hidden>
                  {getFieldDecorator('provider_id', {
                    initialValue: selectedProvider ? selectedProvider.id : undefined
                  })(
                    <Input />
                  )}
                </FormItem>
              ) : null}

              <FormItem hidden>
                {getFieldDecorator('invoice_type', {
                  initialValue: selectedInvoiceType ? selectedInvoiceType.value : undefined
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem hidden>
                {getFieldDecorator('is_sdb_invoice', {
                  initialValue: selectedInvoiceType ? selectedInvoiceType.value === InvoiceType.INV_TYPE_STD.value : false
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem hidden>
                {getFieldDecorator('is_invoice_required_abn', {
                  initialValue: typeof item.is_invoice_required_abn === 'boolean'
                    ? item.is_invoice_required_abn
                    : !validator.isNullOrUndefined(fvInvoiceAbn)
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem hidden>
                {getFieldDecorator('is_drafted', {
                  initialValue: !isEdit()
                })(
                  <Input />
                )}
              </FormItem>
            </Spin>
          </Panel>
        ) : null}

        {canProceed && !isEdit() ? (
          <FilePanel
            fileCategories={fileCategories} fileSubCategories={fileSubCategories} files={files} hasAccess={hasAccess} props={props}
            showFileModal={showFileModal} onAddFile={addFile} onRemoveFile={removeFile} onUpdateFile={updateFile}
            onCloseModal={closeFileModal} onOpenModal={openFileModal}
          />
        ) : null}

        {canProceed ? (
          <Panel
            title='Items'
            subtitle={(
              isStartEndDateSelected
                ? <Button onClick={handleRefreshClientFundingsAndCredits(selectedClient)}>Refresh Plan Periods & Credits</Button>
                : null
            )}
          >
            <PlanPeriodSummary
              appliedCreditUsage={appliedCreditUsage} credits={credits} isStartEndDateSelected={isStartEndDateSelected} item={item}
              loadingClientFundings={loadingClientFundings} matchingClientFundings={matchingClientFundings}
            />

            {!validator.isNullOrUndefined(itemsError)
              ? <Alert className='pid-alert' description={<b>{InvoiceItemRequiredMsg}</b>} type='error' />
              : null}

            <InvoiceItemList
              appliedCreditsMap={appliedCreditsMap} categories={categories} claimTypes={claimTypes} claimTypeReasons={claimTypeReasons}
              clientFundingUsage={clientFundingUsage} clientFundings={clientFundings} credits={credits}
              defaultCategoryItemDropdownMap={defaultCategoryItemDropdownMap} gstTypes={gstTypes} gstTypeLookup={gstTypeLookup}
              hasAccess={hasAccess} invoice={item} isEdit={isEdit()} loadingClientFundings={loadingClientFundings} props={props}
              onAddItem={addInvoiceItem} onRemoveItem={removeInvoiceItem} onUndoRemoveItem={undoRemoveInvoiceItem}
              onOpenCreditModal={openCreditModal} onOpenReceivedAmountModal={openReceivedAmountModal} setTallyError={setTallyError}
              setTallyWarning={setTallyWarning}
            />
          </Panel>
        ) : null}

        {canProceed ? (
          <CreditApplyModal
            creditModal={creditModal} isDecimal={isDecimal} isString={isString} visible={showCreditModal}
            onCancelModal={handleCancelCredit} onCloseModal={closeCreditModal} onConfirmModal={handleApplyCredit}
          />
        ) : null}

        {canProceed ? (
          <ReceivedAmountModal
            receivedAmountModal={receivedAmountModal} visible={showReceivedAmountModal} onCloseModal={closeReceivedAmountModal}
          />
        ) : null}
      </Form>
    </Skeleton >
  )
}

export class PaceInvoiceDetail extends Component {
  render () {
    return <InvoiceDetail {...this.props} />
  }
}

const mapDispatchToProps = {
  setFormAttachmentCount,
  setOnRevertToDraft,
  setOnSave,
  setOnSaveAndProcess,
  setOnSaveAsDraft,
  setSelectedInvoice,
  setSelectedInvoiceType
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PaceInvoiceDetail))
